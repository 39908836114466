export class TypeListeArticlesModel {
    typesListeArticleEpicerie: TypeListeArticleModel[];
    typesListeArticleInventaire: TypeListeArticleModel[];
}

export class TypeListeArticleModel {
    id: number;
    libelle: string;
    validationRequise: boolean;
    actif: boolean;
    estGratuit: boolean;
    frequence: TypeListArticleFrequenceEnum;
    type: TypeListeEnum;
    dateDebutInventaire?: Date;
    dateFinInventaire?: Date;
}

export enum TypeListeEnum {
    Epicerie,
    Inventaire
}


export enum TypeListArticleFrequenceEnum {
    Quotidienne,
    Hebdomadaire,
    Mensuelle
}
