<div class="loader-container" *ngIf="isLoadingBonLivraison">
  <app-loader message="Chargement..."></app-loader>
</div>
<div class="details-container" *ngIf="!isLoadingBonLivraison && detailsBonLivraison != null">
  <div class="right-buttons">
    <button (click)="validate()" class="generic-button validate-button" [class.red-button]="!canValidate" [class.green-button]="canValidate" *ngIf="isModifiable">
      Valider
    </button>
  </div>
  <div class="buttons-container">
    <div class="save-button" title="Valider" (click)="validate()" [class.red-button]="!canValidate" [class.green-button]="canValidate" *ngIf="isModifiable">
      <div></div>
    </div>
  </div>
  <app-bon-livraison-info-header [bonLivraison]="detailsBonLivraison"></app-bon-livraison-info-header>
  <div class="prestation-container" *ngFor="let prestation of detailsBonLivraison.bonLivraisonPrestations">
    <div class="prestation-libelle restaurant-type-background">{{ prestation.libelle }}</div>
    <div *ngFor="let famillePlat of prestation.bonLivraisonFamillePlats | filterAllowedFamillePlat:detailsBonLivraison.settings">
      <div class="famille-plat-libelle">{{ famillePlat.libelle }}</div>
      <div *ngFor="let plat of famillePlat.bonLivraisonPlats">
        <div class="plat-section">
          <div class="plat-libelle">{{ plat.libelle }}</div>
          <div class="plat-temperature">
            <app-livraison-temperature [plat]="plat" [settings]="detailsBonLivraison.settings" [isModifiable]="detailsBonLivraison.peutLivrer"></app-livraison-temperature>
          </div>
          <div class="plat-dlc">
            <app-livraison-dlc [plat]="plat" [dateDLCMin]="detailsBonLivraison.dateDLCMin" [isModifiable]="detailsBonLivraison.peutLivrer"></app-livraison-dlc>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-bonlivraison-info-panel [bonLivraison]="detailsBonLivraison"></app-bonlivraison-info-panel>
</div>
