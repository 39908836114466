<div [formGroup]="simplePrestaFormGroup">
  <div class="prestation-header pointerHover" [restaurantColor]="{'type': currentType, 'background':true}">
    <div class="nowrap header-libelle" (click)="toggle()">
      <span class="prestation-row-span chevron" [class.down]="prestation.isCollapsed"></span>
      <span class="prestation-row-span" [title]="prestation.prestationTooltipInfo">{{prestation.prestation}}</span>
    </div>
    <div class="prestation-estimation-container-header">
      <div class="prestation-estimation-container">
        <!-- EFFECTIF QUANTITE -->
        <input #prestaInput [formControl]="simplePrestaFormGroup.effectifQuantiteControl" [linkFormControl]="simplePrestaFormGroup.effectifQuantiteControl"
          [title]="effectifQuantiteControlError" [class.effectif-hidden]="!isCurrent" type="number" class="prestation-estimation"
          (blur)="handlePrestaFocusOut()" [placeholder]="simplePrestaFormGroup.effectifQuantitePreco" selectAllOnFocus [inputNumberRange]="{'fc':simplePrestaFormGroup.effectifQuantiteControl,'max':inputMaxValue}">
      </div>
      <div *ngIf="hasRegime" class="prestation-estimation-container">
        <!-- EFFECTIF S/PORC -->
        <label>dont SP</label>
        <input #prestaRegimeInputRef [formControl]="simplePrestaFormGroup.effectifSansPorcQuantiteControl" [linkFormControl]="simplePrestaFormGroup.effectifSansPorcQuantiteControl"
          [title]="effectifSansPorcQuantiteControlError" [class.effectif-hidden]="!isCurrent" type="number" class="prestation-estimation"
          (focusout)="handlePrestaSansPorcFocusOut()" [placeholder]="simplePrestaFormGroup.effectifSansPorcQuantitePreco" selectAllOnFocus
          [inputNumberRange]="{'fc':simplePrestaFormGroup.effectifSansPorcQuantiteControl,'max':inputMaxValue}">
      </div>
    </div>
  </div>
  <div class="collapsable" [class.collapsed]="prestation.isCollapsed">
    <!-- FAMILLE PLATS LIST -->
    <div *ngFor="let famillePlatControl of simplePrestaFormGroup.famillePlatsControls" class="famille-plat" [attr.data-order]="famillePlatControl.ordre">
      <div class="header">
        <span class="prestation-row-span">{{famillePlatControl.famillePlatName}}</span>
        <div *ngIf="!isModeStandard" class="prestation-estimation-container">
          <!-- FAMILLE PLAT -->
          <div class="close-zone" (click)="applyZeroPortions(famillePlatControl)">
            <div class="close-button" *ngIf="famillePlatControl.autoriserEffectifZero">
            </div>
          </div>
          <input readonly="readonly" tabindex="-1" [formControl]="famillePlatControl.famillePlatQuantiteControl" [class.effectif-hidden]="!isCurrent"
            type="number" class="prestation-estimation-familleplat">
        </div>
      </div>
      <!-- PORTIONS -->
      <div *ngFor="let portion of famillePlatControl.portionsControls" [formGroup]="portion" class="prestation-portion">
        <span tabindex="-1" class="prestation-row-span" title="{{portion.tooltip}}">{{portion.platName}}</span>
        <!-- Not displayed at all if mode standard -->
        <div *ngIf="!isModeStandard" class="prestation-estimation-container">
          <!-- % -->
          <span tabindex="-1" *ngIf="this.prestation.autoriserSaisieTauxPrise" class="percentage">
            <input [formControl]="portion.effectifPortionPourcentageControl" selectAllOnFocus
            (blur)="handlePortionPercentageFocusOut(portion)" type="number" [inputNumberRange]="{'fc':portion.effectifPortionPourcentageControl,'max':100}" class="prestation-estimation">
          </span>
          <!-- Portion quantité -->
          <input [formControl]="portion.effectifPortionQuantiteControl" [linkFormControl]="portion.effectifPortionQuantiteControl"
            [class.effectif-hidden]="!isCurrent" type="number" class="prestation-estimation" [placeholder]="portion.effectifPortionQuantitePreco"
            (blur)="handlePortionFocusOut(portion)" selectAllOnFocus [inputNumberRange]="{'fc':portion.effectifPortionQuantiteControl,'max':inputMaxValue}">
        </div>
      </div>
    </div>
  </div>
  <!--<pre>
    {{prestation | json}}
    </pre>-->
</div>
