import { Routes } from '@angular/router';
import { BonLivraisonComponent } from './bon-livraison.component';
import { AuthGuard } from '../shared/guards/auth-guard';
import { DetailsBonLivraisonComponent } from './details-bon-livraison/details-bon-livraison.component';
import { LivraisonBonLivraisonComponent } from './livraison-bon-livraison/livraison-bon-livraison.component';
const ɵ0 = { title: 'Réceptions' }, ɵ1 = { title: 'Livraison' }, ɵ2 = { title: 'Réception' };
const routes = [
    {
        path: 'bonlivraison',
        component: BonLivraisonComponent,
        canActivate: [AuthGuard],
        data: ɵ0
    },
    {
        path: 'bonlivraison/livraison/:codeBonLivraison',
        component: LivraisonBonLivraisonComponent,
        canActivate: [AuthGuard],
        data: ɵ1
    },
    {
        path: 'bonlivraison/details/:codeBonLivraison',
        redirectTo: 'bonlivraison/reception/:codeBonLivraison',
    },
    {
        path: 'bonlivraison/reception/:codeBonLivraison',
        component: DetailsBonLivraisonComponent,
        canActivate: [AuthGuard],
        data: ɵ2
    }
];
export class BonLivraisonRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
