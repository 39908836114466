
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiSettings } from '../../../models/api/api-settings';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ParametreApiService {
  private controllerUrl: string = 'parametre';

  constructor(private apiSettings: ApiSettings, private http: HttpClient) {
  }

  getParametre(code: string): Observable<string> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/${code}`;
    return this.http.get(url, { responseType: 'text' });
  }
}
