import { GoogleAnalyticsProvider } from './providers/google-analytics-provider';
import { SessionStorageService } from '../shared/services/browser-storage.service';
import { CorrelationTokenService } from '../shared/services/correlationToken.service';
import { AnalyticsService } from './analytics-service';

import {
  NgModule,
  ModuleWithProviders,
  Inject,
  Optional,
  SkipSelf
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { HttpClientModule } from '@angular/common/http';
import { CommonModule, Location } from '@angular/common';
import { Router } from '@angular/router';
import { AppInsightsAnalyticsProvider } from './providers/appinsights-analytics-provider';

export function analyticsServiceFactory(
  location: Location,
  router: Router,
  appInsights: AppInsightsAnalyticsProvider,
  google: GoogleAnalyticsProvider) {
    google.dimensionPropertyMappings['cuisine'] = 'dimension1';
    google.dimensionPropertyMappings['contrat'] = 'dimension2';
    google.dimensionPropertyMappings['restaurant'] = 'dimension3';
    return new AnalyticsService(location, router, appInsights, google);
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    // HttpClientModule
  ],
  providers: [
    {
      provide: AnalyticsService,
      useFactory: analyticsServiceFactory,
      deps: [Location, Router, AppInsightsAnalyticsProvider, GoogleAnalyticsProvider]
    },
    AppInsightsAnalyticsProvider,
    GoogleAnalyticsProvider
  ]
})
export class AnalyticsModule {
}
