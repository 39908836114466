<form class="main-container" #form="ngForm">
  <div class="loader-container" *ngIf="isLoading">
    <app-loader message="Chargement..."></app-loader>
  </div>
  <div class="container-reception" *ngIf="allowReception">
    <div class="container-manques" *ngIf="hasManques">
      <h3>MANQUES</h3>
      <div *ngFor="let composant of manques">
        <div class="presentation-manque">
          <div class="quantite-manque">{{ composant.quantiteLivreeRelative > 0 ? '+' + composant.quantiteLivreeRelative : composant.quantiteLivreeRelative }}</div>
          <div class="contenance-manque">x{{ composant.contenance }}</div>
          <div class="unite-manque">{{ composant.unite }}</div>
          <div class="libelle-manque">{{ composant.libelle }}</div>
        </div>
      </div>
    </div>
    <div class="container-excedents" *ngIf="hasExcedents">
      <h3>EXCEDENTS</h3>
      <div *ngFor="let composant of excedents">
        <div class="presentation-manque">
          <div class="quantite-manque">{{ composant.quantiteLivreeRelative > 0 ? '+' + composant.quantiteLivreeRelative : composant.quantiteLivreeRelative }}</div>
          <div class="contenance-manque">x{{ composant.contenance }}</div>
          <div class="unite-manque">{{ composant.unite }}</div>
          <div class="libelle-manque">{{ composant.libelle }}</div>
        </div>
      </div>
    </div>
    <div class="container-reception-nofault" *ngIf="!hasComposantFaults">
      <h3>Vous n'avez pas déclaré de manque sur cette livraison.</h3>
    </div>
  </div>

  <div class="container-conformite" *ngIf="allowConformite && !showNonConformePrompt">
    <h3>Veuillez indiquer la conformité de la livraison :</h3>
  </div>

  <div class="container-conformite-non-conforme" *ngIf="showNonConformePrompt">
    <h3>Vous déclarez cette livraison non-conforme.<br>Veuillez préciser le type de non-conformité :</h3>
    <!-- <div class="container-non-conformites" [class.invalid]="(form.submitted || form.dirty || form.touched ) && !form.controls['nonConformiteControl'].valid">
      <label for="nc-{{selectItem.nonConformite.bonLivraisonTypeNonConformiteId}}" *ngFor="let selectItem of nonConformites" class="non-conformite-item">
        <input type="checkbox" [checked]="selectItem.selected" (change)="checkNonConformite($event)" [value]="selectItem.nonConformite.bonLivraisonTypeNonConformiteId" name="nonConformiteControl" [required]="showNonConformePrompt" id="nc-{{selectItem.nonConformite.bonLivraisonTypeNonConformiteId}}" /><span>{{ selectItem.nonConformite.libelle }}</span>
      </label>
    </div>
    <div *ngIf="(form.submitted || form.dirty || form.touched ) && !form.controls['nonConformiteControl'].valid && form.controls['nonConformiteControl'].errors['required']" class="error-message">Une non-conformité est requise.</div> -->
    <!-- <div class="container-non-conformites" [class.invalid]="(form.submitted || form.dirty || form.touched ) && !hasNonConformite">
      <label for="nc-{{selectItem.nonConformite.bonLivraisonTypeNonConformiteId}}" *ngFor="let selectItem of nonConformites" class="non-conformite-item">
        <input type="checkbox" [(ngModel)]="selectItem.selected" id="nc-{{selectItem.nonConformite.bonLivraisonTypeNonConformiteId}}" /><span>{{ selectItem.nonConformite.libelle }}</span>
      </label>
    </div>
    <div *ngIf="(form.submitted || form.dirty || form.touched ) && !hasNonConformite" class="error-message">Une non-conformité est requise.</div> -->
  <div class="container-non-conformites restaurant-type-background secondary" [class.invalid]="(form.submitted || form.dirty || form.touched ) && !hasNonConformite"  >
      <label for="nc-{{selectItem.nonConformite.bonLivraisonTypeNonConformiteId}}" *ngFor="let selectItem of nonConformites" class="non-conformite-item" [class.selected]="selectItem.selected">
        <input type="checkbox" [checked]="selectItem.selected" (change)="toggleNonConformite($event)" [value]="selectItem.nonConformite.bonLivraisonTypeNonConformiteId" id="nc-{{selectItem.nonConformite.bonLivraisonTypeNonConformiteId}}" /><span>{{ selectItem.nonConformite.libelle }}</span>
      </label>
    </div>
    <div *ngIf="(form.submitted || form.dirty || form.touched ) && !hasNonConformite" class="error-message">Une non-conformité est requise.</div>

  </div>

  <div class="commentaire-container" *ngIf="showNonConformePrompt || (allowReception && hasManques)">
    <h3 *ngIf="!showNonConformePrompt && hasManques"><label for="commentaire">COMMENTAIRE</label></h3>
    <textarea [(ngModel)]="commentaire"
              name="commentaireControl"
              #commentaireControl="ngModel"
              placeholder="Veuillez indiquer un commentaire"
              [required]="isCommentaireRequired"
              minlength="3"
              autocomplete="off"
              (focus)="scrollToElement(buttonContainer)"
              (input)="scrollToElement(buttonContainer)">
            </textarea>
    <!-- <div *ngIf="!commentaireControl.valid && (commentaireControl.dirty || commentaireControl.touched)"> -->
    <div *ngIf="(form.submitted || form.dirty || form.touched ) && !commentaireControl.valid">
      <div *ngIf="commentaireControl.errors['required']" class="error-message">Le commentaire est requis.</div>
      <div *ngIf="commentaireControl.errors['minlength']" class="error-message">Le commentaire doit contenir au moins 3 caractères.</div>
    </div>
  </div>

  <div class="error-container" *ngIf="erreurValidationMessage != ''">
    {{ erreurValidationMessage }}
  </div>

  <div class="button-container" #buttonContainer>
    <!-- <button class="generic-button" (click)="hide()">Annuler</button> -->
    <button class="generic-button revoke-button red-button" (click)="showNonConformePrompt = true" *ngIf="allowConformite && !showNonConformePrompt">Non conforme</button>
    <button class="generic-button revoke-button red-button" (click)="DeclarerConformite(false)" *ngIf="showNonConformePrompt">Non conforme</button>
    <button class="generic-button validate-button green-button" (click)="DeclarerConformite(true)" *ngIf="allowConformite && !showNonConformePrompt">Conforme</button>
    <button class="generic-button restaurant-type-background" (click)="DeclarerManque()" *ngIf="forceReceptionOnly">Déclarer le manque</button>
  </div>
</form>
