import { FileUploader } from 'ng2-file-upload';
import { Directive, ElementRef, Input, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
    selector: '[takePhoto]',
    host: {
        '(click)': 'onClick($event)'
    }
})
export class TakePhotoDirective {
    private _uploader: FileUploader;

    @Input()
    set uploader(uploader: FileUploader) {
        this._uploader = uploader;
        this._updateView();

    }
    constructor(private _viewContainer: ViewContainerRef,
                private _changeDetectorRef: ChangeDetectorRef) {
        this._updateView();
    }

    onClick(event: Event) {
        if (window.cordova)
            this._takePicture();
    }

    private _updateView() {
        if (!window.cordova) {
            this._viewContainer.clear(); //hide element
        }
    }
    /**
   * Prise de photo avec le plugin cordova-plugin-camera
   */
    private _takePicture() {
        const uploader = this._uploader;
        const changeDetectorRef = this._changeDetectorRef;
        navigator.camera.getPicture(
            (imageURI) => {
                // console.log(imageURI);
                window.resolveLocalFileSystemURL(imageURI,
                    (fileEntry: FileEntry) => {
                        // console.log("fileEntry is file? " + fileEntry.isFile.toString());
                        // console.log("fileEntry nativeURL: " + fileEntry.nativeURL);
                        fileEntry.file((file: File) => {
                            // console.log("file: " + file.name);
                            // console.log("file type: " + file.type);

                            //HACK le File n'est pas la classe native File (écrasée par Cordova), il faut donc lire manuellement le fichier avant le charger en tant que File natif (window.NativeFile)
                            let reader = new FileReader();
                            reader.onloadend = function () {
                                // console.log("Successful load write: " + this.result);
                                var nativeFile = new window.NativeFile([<ArrayBuffer>this.result], file.name, { type: file.type });
                                // console.log(nativeFile);
                                uploader.addToQueue([nativeFile]);
                                //force angular to detect changes (reader.onloadend is out of angular zone)
                                changeDetectorRef.detectChanges();
                            };
                            reader.readAsArrayBuffer(file);
                        });
                    });

            },
            err => { },
            {
                quality: 70,
                destinationType: Camera.DestinationType.FILE_URI
            }
        );

    }

}