import { DocumenthequeComponent } from './documentheque.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/guards/auth-guard';


const documenthequeRoutes: Routes = [
  {
    path: 'documentheque',
    component: DocumenthequeComponent,
    canActivate: [AuthGuard],
    data: { title: "Documenthèque" }
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(documenthequeRoutes)
  ],
  exports: [
    RouterModule
    ]
})

export class DocumenthequeRoutingModule { }
