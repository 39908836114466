
import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, finalize, first } from 'rxjs/operators';
import { DateSelectorComponent } from '../date-selector/date-selector.component';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { ErrorPopupComponent } from '../popup/error-popup/error-popup.component';
import { WarningPopupComponent } from '../popup/warning-popup/warning-popup.component';
import { ReportingService } from '../reporting/services/reporting.service';
import { RythmeContratEnum } from '../shared/enums/rythme-contrat.enum';
import { DateHelper } from '../shared/helpers/date.helper';
import { FileHelper } from '../shared/helpers/file.helper';
import { FormControlHelper } from '../shared/helpers/form-control.helper';
import { ErrorApiModel } from '../shared/models/api/error/error.apimodel';
import { PostCommandeRepasWarningApiResponse } from '../shared/models/api/warning/post-commande-repas-warning.apiresponse';
import { BaseJourModel } from '../shared/models/base-jour.model';
import { BaseErrorModel, CommandeRepasErrorModel, CommandeRepasErrorTypeEnum, CommandeRepasUpdateErrorTypeEnum, FamillePlatErrorModel, PortionErrorModel, PrestationErrorModel } from '../shared/models/commande-repas-error.model';
import { BaseComponentNav } from '../shared/models/component-layout/base-component-nav';
import { FileResultModel } from '../shared/models/fileresult.model';
import { NotificationCustomModel, NotificationModel } from '../shared/models/notification.model';
import { RestaurantModel } from '../shared/models/restaurant.model';
import { SemaineModel } from '../shared/models/semaine.model';
import { LogoRouteService } from '../shared/services/api/logo/logo-route.service';
import { ApplicationDataService } from '../shared/services/application-data.service';
import { InteractionService } from '../shared/services/interaction.service';
import { LogService } from '../shared/services/log.service';
import { NavigationService } from '../shared/services/navigation.service';
import { CommandeRepasModel, ConviveModel, JourModel, LieuCommandeModel, PeriodeContratTypeEnum, PrestationModel, SemaineInfosModel } from './models/commande-repas.model';
import { PrestationComponent } from './prestation/prestation.component';
import { CommandeRepasService } from './services/commande-repas.service';

const DEBOUNCE_CMD_REPAS_MS = 1000;

@Component({
  selector: 'app-repas',
  templateUrl: './repas.component.html',
  styleUrls: ['./repas.component.scss']
})
export class RepasComponent extends BaseComponentNav implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  /** View Child */
  @ViewChild(DateSelectorComponent, { static: true }) dateSelector: DateSelectorComponent;
  @ViewChild('repasContainer', { static: false }) repasContainer: ElementRef;
  @ViewChild('semaineDropdown', { static: true }) semaineDropdown: DropdownComponent;

  @ViewChildren(PrestationComponent) prestationComponents: QueryList<PrestationComponent>;
  /** Emitters / Subscriptions */
  getCommandeEmitter: EventEmitter<{ refreshList: boolean, newRest: boolean, selectNewDay: boolean }>;
  cmdRepasSubscription: Subscription;

  /** fields */
  isPiqueNique: boolean = false;
  askForNavigation: boolean;
  alignRequested: boolean;
  isLoading: boolean = true;
  conviveList: ConviveModel[];
  lieuCmdList: LieuCommandeModel[];
  isRestaurantDisplay: boolean = true;
  isLieuCommandeDisplay: boolean = true;
  isRegle1ButtonDisplay: boolean = false;
  isConviveDisplay: boolean = true;
  isEditInfosDisplay: boolean; true;
  cmdRepas: CommandeRepasModel = undefined;
  contratId: number;
  cuisineCentraleId: number;
  contratRythme: RythmeContratEnum;

  isPeriodeTooltipInfo: boolean;

  isMatriceDeChoixDownloading = false;
  isMenuDownloading = false;
  isSendingCommandeRepas = false;
  /** Props */

  /** - LieuCommande */
  _selectedLieuCommande: LieuCommandeModel = undefined;
  get selectedLieuCommande(): LieuCommandeModel {
    return this._selectedLieuCommande;
  }

  set selectedLieuCommande(newLieuCmd: LieuCommandeModel) {
    if (this._selectedLieuCommande == newLieuCmd) {
      return;
    }

    if (this.checkIsDirty()) {
      const oldLieuCmd = this._selectedLieuCommande;
      this._selectedLieuCommande = newLieuCmd;
      this.interactionService.showConfirmNavigationDialog().subscribe(confirm => {
        if (confirm) {
          this.handleNewLieuCommande(newLieuCmd);
        } else {
          this._selectedLieuCommande = oldLieuCmd;
        }
      });
    } else {
      this._selectedLieuCommande = newLieuCmd;
      this.handleNewLieuCommande(newLieuCmd);
    }
  }

  /** - Convives */
  _selectedConvive: ConviveModel = undefined;
  get selectedConvive(): ConviveModel {
    return this._selectedConvive;
  }
  set selectedConvive(newConvive: ConviveModel) {
    if (this._selectedConvive == newConvive) {
      return;
    }

    if (this.checkIsDirty()) {
      const oldConvive = this._selectedConvive;
      this._selectedConvive = newConvive;
      this.interactionService.showConfirmNavigationDialog().subscribe(confirm => {
        if (confirm) {
          this.handleNewConvive(newConvive);
        } else {
          this._selectedConvive = oldConvive;
        }
      });
    } else {

      this._selectedConvive = newConvive;
      this.handleNewConvive(newConvive);
    }
  }

  constructor(private appDataService: ApplicationDataService,
    private logService: LogService,
    private logoService: LogoRouteService,
    private commandeRepasService: CommandeRepasService,
    private interactionService: InteractionService,
    private navService: NavigationService,
    private reportingService: ReportingService,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute) {
    super(appDataService, logoService, interactionService);
  }

  /** Start interface implementations  */

  ngOnInit() {
    this.handleRoute();
    this.handleSubscription();

    super.initComponent();

    this.navService.registerComponent(this);
  }

  ngAfterViewInit() {
    super.subscribeDateSelectorEvents();
  }

  ngOnDestroy() {
    super.destroy();

    this.navService.unregisterComponent(this);
  }

  handleRoute() {
    // get route params
    this.activatedRoute.data.pipe(first()).subscribe(data => {
      this.isPiqueNique = data.isPiqueNique || false;
    });
  }

  checkIsDirty(): boolean {
    if (this.prestationComponents == null) {
      return false;
    }

    return this.prestationComponents.some(pc => {
      return pc.isDirty;
    });
  }


  /** End interface implementations */

  selectedRangeChanged(dateRange: SemaineModel) {
    if (dateRange == this.selectedRange) {
      return;
    }

    if (this.checkIsDirty()) {
      this.interactionService.showConfirmNavigationDialog().subscribe(confirm => {
        if (confirm) {
          this.selectedRange = dateRange;
          this.selectedDate = dateRange.firstDay;
          this.handleNewDate(dateRange.firstDay);
        } else {
          // On force le refresh de la dropdown pour remettre la valeur de base comme l"utilisateur a refusé
          // Il ne faut pas assigner le selectedRange puis le remettre à la valeur par défaut car cela va rafraichir la vue
          this.semaineDropdown.refreshCurrent(this.selectedRange);
        }
      },
        err => {
          this.semaineDropdown.refreshCurrent(this.selectedRange);
        });
    } else {
      this.selectedRange = dateRange;
      this.selectedDate = dateRange.firstDay;
      this.handleNewDate(dateRange.firstDay);
    }
  }

  handleSubscription() {
    // set up debouncing for commande repas
    this.getCommandeEmitter = new EventEmitter<{ refreshList: boolean, newRest: boolean, selectNewDay: boolean }>();
    this.getCommandeEmitter.pipe(
      debounceTime(DEBOUNCE_CMD_REPAS_MS))
      .subscribe((res) => {
        this.getCommandeRepas(res.refreshList, res.newRest, res.selectNewDay);
      });

    this.contratSubscription = this.appDataService.contrat$.subscribe(
      contrat => {
        if (contrat != null) {
          this.contratId = contrat.id;
          if (this.contratRythme != contrat.rythme) {
            this.contratRythme = contrat.rythme;
          }
          this.cuisineCentraleId = contrat.cuisineCentraleId;

          this.restaurantList = contrat.restaurants;
          this.isRestaurantDisplay = this.restaurantList.length > 1;
          this.logoContratUrl = this.logoService.GetPicture2Url(contrat.id, contrat.cuisineCentraleId);
        }
      });
  }

  ngAfterViewChecked() {
    if (this.alignRequested) {
      this.alignPrestations();
      this.alignRequested = false;
    }
  }

  /** Align all the famillePlat and prestation by their index (no check on content currently) */
  alignPrestations() {
    if (!this.repasContainer) {
      return;
    }

    // Dictionnaire qui va permettre d"aligner les familles de plats
    const famillePlatsDic: { [key: string]: { fpList: { el: Element, jourIndex: number }[], maxHeight: number, prestaIndex: number, order: number } } = {};
    const daysArray: number[] = [];
    // Une liste qui va permettre d"aligner les prestations entre elles (une fois que les familles de plats seront calculées)
    const prestaArray: { prestaList: Element[], maxHeight: number }[] = [];
    const jours = this.repasContainer.nativeElement.querySelectorAll('.jour');
    const joursLength = jours.length;
    for (let i = 0; i < joursLength; i++) {
      daysArray.push(i);
      // Pour un jour :
      const jour = jours[i] as Element;
      // 1. On récupère les prestations d"un jour
      const prestas = jour.querySelectorAll('app-prestation');
      const prestasLength = prestas.length;
      for (let j = 0; j < prestasLength; j++) {
        // 2. On regroupe les prestations ensemble (basé sur l"index) // Amélio : utiliser le presta ID comme clé avec un dico ?
        const presta = prestas[j];
        if (prestaArray[j]) {
          prestaArray[j].prestaList.push(presta);
        } else {
          // On a la liste en premier, qui va être populée, puis le maxHeight qui est égal à 0
          // et qui sera calculé par l"incrément des height des familles de plat
          prestaArray[j] = { prestaList: [presta], maxHeight: 0 };
        }
        const famillePlats = presta.querySelectorAll('.famille-plat');
        const famillePlatsLength = famillePlats.length;
        for (let k = 0; k < famillePlatsLength; k++) {
          // 3. On regroupe les familles de plats ensembles avec une clé du style presta0_fp1   0 -> index presta / 1 -> ordre famille plat
          const famillePlat = famillePlats[k];
          const order: number = +famillePlat.getAttribute('data-order');
          const key = `presta${j}_fp${order}`;
          if (famillePlatsDic[key]) {
            const item = famillePlatsDic[key];
            item.fpList.push({ el: famillePlat, jourIndex: i });
            // 3.1. On set la maxheight à chaque fois qu"on en trouve une plus grande pour un groupe donné
            if (famillePlat.clientHeight > item.maxHeight) {
              item.maxHeight = famillePlat.clientHeight;
            }
          } else {
            famillePlatsDic[key] = {
              fpList: [{ el: famillePlat, jourIndex: i }],
              maxHeight: famillePlat.clientHeight,
              prestaIndex: j,
              order
            };
          }
        }
      }
    }

    const joursMissingFpHeight: { [key: string]: { missingDay: number, missingHeight: number }[] } = {};

    const sortedKeys: string[] = [];

    for (const key in famillePlatsDic) {
      // Pour chaque famille plat par ordre, on set la height vis à vis du plus grand
      sortedKeys[sortedKeys.length] = key;
    }

    sortedKeys.sort();
    sortedKeys.forEach(key => {
      // Pour chaque famille plat par ordre, on set la height vis à vis du plus grand
      const fpLine = famillePlatsDic[key];
      const fpLength = fpLine.fpList.length;
      // On récupère les jours qui ne possèdent pas cette ordre
      const missingDays = daysArray.filter(d => {
        return fpLine.fpList.map(fp => fp.jourIndex).indexOf(d) < 0;
      });

      const missingKey = this.getMissingKey(fpLine.prestaIndex, fpLine.order);
      // Les derniers missings (pour incérmenter si il manque plusieurs FP de suite)
      const previousMissingFp = this.tryGetPreviousMissingFp(joursMissingFpHeight, fpLine.prestaIndex, fpLine.order);

      joursMissingFpHeight[missingKey] = missingDays.map(day => {
        let height = fpLine.maxHeight;
        if (previousMissingFp != null) {
          // On check si la FP précédente était aussi absente pour ce jour
          const thisDayPreviousFp = previousMissingFp.missingData.find(pmfp => {
            return pmfp.missingDay == day;
          });

          if (thisDayPreviousFp != null) {
            // Ce jour manquait déjà, donc on incrémente la height
            height += thisDayPreviousFp.missingHeight;
            // On delete l"ancienne clé
            joursMissingFpHeight[previousMissingFp.key] = null;
          }
        }
        return { missingDay: day, missingHeight: height };
      });

      for (let i = 0; i < fpLength; i++) {
        const fpList = fpLine.fpList[i];
        this.renderer.setStyle(fpList.el, 'height', fpLine.maxHeight + 'px');
        if (previousMissingFp != null) {
          const missing = previousMissingFp.missingData.find(pmfp => pmfp.missingDay == fpList.jourIndex);
          if (missing != null) {
            this.renderer.setStyle(fpList.el, 'margin-top', missing.missingHeight + 'px');
            missing.missingHeight = 0;
          }
        }
      }

      // add the line maxHeight to get the presta.MaxHeight
      prestaArray[fpLine.prestaIndex].maxHeight += fpLine.maxHeight;
    });

    for (const prestaKey in prestaArray) {
      // set the presta max height
      const prestaLine = prestaArray[prestaKey];
      prestaLine.prestaList.forEach(p => {
        this.renderer.setStyle(p.querySelector('.collapsable'), 'max-height', prestaLine.maxHeight + 'px');
        this.renderer.setStyle(p.querySelector('.collapsable'), 'height', prestaLine.maxHeight + 'px');
      });
    }
  }

  tryGetPreviousMissingFp(dictionary: { [key: string]: { missingDay: number, missingHeight: number }[] }, prestaIndex: number, currentOrder: number): { missingData: { missingDay: number, missingHeight: number }[], key: string } {
    // Tant que supérieur à 0, on decremente et on check si on peut trouver une clé
    while (currentOrder > 0) {
      currentOrder--;
      const key = this.getMissingKey(prestaIndex, currentOrder);
      const data = dictionary[key];
      if (data != null) {
        return { missingData: data, key };
      }
    }

    // Rien trouvé, on renvoit null
    return null;
  }

  getMissingKey(prestaIndex: number, order: number): string {
    return `${prestaIndex}-${order}`;
  }

  /** Handle new */
  handleNewRestaurant(rest: RestaurantModel) {
    this._selectedConvive = undefined;
    this._selectedLieuCommande = undefined;
    // refresh list + is new week true to select the date
    this.getCommandeRepas(true, true, true);
  }

  handleNewConvive(conv: ConviveModel) {
    this.logService.Debug('New convive selected : ' + JSON.stringify(conv), 'RepasComponent');
    this.askGetCommandeRepas(false, false, false);
  }

  handleNewLieuCommande(lieuCmd: LieuCommandeModel) {
    this.logService.Debug('New LieuCommande selected : ' + JSON.stringify(lieuCmd), 'RepasComponent');
    this.askGetCommandeRepas(true, false, false);
  }

  handleNewDate(date: Date) {
    this.askGetCommandeRepas(true, false, true);
  }

  /** Get CMD Repas */
  askGetCommandeRepas(refreshList: boolean, newRest: boolean, selectNewDay: boolean) {
    if (this.cmdRepasSubscription) {
      this.cmdRepasSubscription.unsubscribe();
    }
    this.isLoading = true;
    this.getCommandeEmitter.next({ refreshList, newRest, selectNewDay });
  }

  getCommandeRepas(refreshList: boolean, newRest: boolean, selectNewDay: boolean) {
    this.isLoading = true;
    const conviveId = (this.selectedConvive) ? this.selectedConvive.id : undefined;
    const lieuCmdId = (this.selectedLieuCommande) ? this.selectedLieuCommande.id : undefined;
    this.cmdRepasSubscription = this.commandeRepasService.getCommandeRepas(this.isPiqueNique, this.selectedRestaurant.cuisineCentraleId, this.selectedRestaurant.id, conviveId, lieuCmdId, this.selectedDate)
      .subscribe(cmdRepasModel => {
        this.isNoContent = cmdRepasModel.jours.length == 0;
        this.handleNewCommandeRepas(cmdRepasModel, refreshList, newRest, selectNewDay);
      },
        (error: ErrorApiModel) => {
          // Clear convive & lieu cmd
          // TODO : ne pas vider les listes et laisser l"état précédent si erreur (ie:pas de convive)
          this.conviveList = [];
          this._selectedConvive = null;
          this.isConviveDisplay = false;
          this.lieuCmdList = [];
          this._selectedLieuCommande = null;
          this.isLieuCommandeDisplay = false;

          // suite TODO : On laisse le isNoContent + popup modale qui affiche l"erreur
          this.isNoContent = true;
          this.isLoading = false;

          const notifModel = new NotificationModel();
          notifModel.title = 'Erreur';
          notifModel.message = 'Une erreur est survenue lors du chargement des données : \n' + error.Message;

          this.interactionService.showNotification(notifModel);
        });
  }

  handleNewCommandeRepas(cmdRepasModel: CommandeRepasModel, refreshList: boolean, newRest: boolean, selectNewDay: boolean) {
    this.computeSemaine(cmdRepasModel.semaines, cmdRepasModel.jours);
    this.computeDisplayRules(cmdRepasModel);
    if (selectNewDay) {
      const now = new Date();
      if (DateHelper.isBetweenDates(this.selectedRange.firstDay, this.selectedRange.lastDay, now)) {
        this.dateSelector.selectedJour = this.selectedRange.jours.find(j => DateHelper.areSameDayOfYear(now, j.date));
      } else {
        this.dateSelector.selectedJour = this.selectedRange.jours[0];
      }
    } else {
      // the list is refreshed so we refresh the current too, we should check if we can avoid refreshing the whole list
      if (this.dateSelector.selectedJour) {
        this.dateSelector.selectedJour = this.selectedRange.jours.find(j => DateHelper.areSameDayOfYear(this.dateSelector.selectedJour.date, j.date));
      } else {
        if (this.selectedRange.jours.length > 0) {
          this.dateSelector.selectedJour = this.selectedRange.jours[0];
        }
      }
    }

    this.cmdRepas = cmdRepasModel;

    if (refreshList) {
      this.conviveList = this.cmdRepas.convives;
      this.lieuCmdList = this.cmdRepas.lieuCommandes;
      // if new rest : refresh anyway
      if (newRest) {
        this._selectedConvive = this.conviveList[0];
        this._selectedLieuCommande = this.lieuCmdList[0];
      } else {
        // keep the same convive if in the new list
        if (this._selectedConvive) {
          const sameConvive = this.conviveList.find(c => c.id == this._selectedConvive.id);
          this._selectedConvive = sameConvive ? sameConvive : this.conviveList[0];
        } else {
          // if selectedConvive was null
          this._selectedConvive = this.conviveList[0];
        }

        // keep the same lieu de commande if in the new list
        if (this._selectedLieuCommande) {
          const sameLieuCmd = this.lieuCmdList.find(l => l.id == this._selectedLieuCommande.id);
          this._selectedLieuCommande = sameLieuCmd ? sameLieuCmd : this.lieuCmdList[0];
        } else {
          // if selectedLieuCmd was null
          this._selectedLieuCommande = this.lieuCmdList[0];
        }
      }

      this.isConviveDisplay = this.conviveList.length > 1;
      this.isLieuCommandeDisplay = this.lieuCmdList.length > 1;
    }

    this.alignRequested = true;
    this.isLoading = false;

    const prestationsToCollapse = this.commandeRepasService.getPrestationsToCollapse();
    if (prestationsToCollapse) {
      prestationsToCollapse.forEach(id => {
        this.onPrestaToggle_Imp(id, true);
      });
    }
  }

  computeSemaine(semaineInfos: SemaineInfosModel[], jours: JourModel[]) {
    // Convertir les infos de semaine en semaine avec data
    let preSelectSemaine: SemaineModel = null;
    this.dateRanges = semaineInfos.map((si, i) => {
      const days = DateHelper.getDaysBetween(moment(si.dateDebut), moment(si.dateFin));
      const newSemaine = new SemaineModel(days, si.libelle, si.transmis, this.contratRythme);

      if (this.selectedRange != null) {
        // On set la preSelectSemaine si c"est le meme lundi
        if (DateHelper.areSameDayOfYear(this.selectedRange.firstDay, newSemaine.firstDay)) {
          preSelectSemaine = newSemaine;
        }
      }

      return newSemaine;
    });

    // si la semaine actuelle n"existe pas dans la nouvelle liste, on l"ajoute
    if (this.selectedRange != null
      && !this.dateRanges.find(sm => DateHelper.areSameDayOfYear(this.selectedRange.firstDay, sm.firstDay))) {
      // on ajoute la semaine et on retrie la liste
      this.dateRanges.push(this.selectedRange);
      this.dateRanges = this.dateRanges.sort((a, b) => +a.firstDay - +b.firstDay); // convert date object into number to resolve issue in typescript
      preSelectSemaine = this.selectedRange;
      this.selectedRange.transmis = true; // semaine valide (car pas d"effectif)
    }

    // si pas de date dispo on crée la semaine actuelle
    if (this.dateRanges.length === 0) {
      const today = moment();
      this.dateRanges = SemaineModel.getSemainesFromDates(today, today);
      this.dateRanges.forEach(s => {
        s.transmis = true; // semaine valide (car pas d"effectif)
      });
    }

    // Si on en a une, on preselectionne la semaine, sinon on selectionne la première
    if (preSelectSemaine) {
      this.selectedRange = preSelectSemaine;
    } else {
      this.selectedRange = this.dateRanges[0];
    }

    // Avec plusieurs lieu de commande, l"api nous renvoie des jours mais sans prestations, on les filtres
    jours.forEach(j => {
      if (j.prestations.length === 0) {
        j.isEmpty = true;
      }
    });

    this.refreshSemaine(this.selectedRange, jours);

    this.refreshNavigationAllowed();
  }

  /** Map les jours venant de l"api/get avec l"existant */
  refreshSemaine(semaine: SemaineModel, joursToMap: BaseJourModel[]) {
    const length = semaine.jours.length;
    let indexCount = 0;
    for (let i = 0; i < length; i++) {
      const jour = semaine.jours[i];
      const jourMap = joursToMap.find(jToMap => {
        return moment(jToMap.date).isSame(jour.date, 'day');
      });

      // DDJ : A refaire, mais ne veux pas tout casser pour l"instant
      // Il faudrait enlever ça d"ici --> Spécifique CMD Repas
      if (!jour.isHorsRythme) {
        // Si on trouve une correspondance, on update
        if (jourMap) {
          jourMap.index = indexCount++;
          semaine.jours[i] = jourMap;
        } else {
          // Sinon, il faut clear et mettre un modele vide
          // Je ne sais pas pourquoi on passe par la base puis on cast en JourModel
          // A enlever ?
          jour.index = indexCount++;
          const jourModel = new JourModel(jour.date);
          jourModel.isEmpty = true;
          jourModel.periode = {
            dateReference: null,
            endDate: null,
            ratioModification: null,
            startDate: null,
            type: PeriodeContratTypeEnum.HorsPeriode
          };
          semaine.jours[i] = jourModel;
        }
      }

    }
  }

  computeDisplayRules(cmdRepas: CommandeRepasModel) {
    this.isEditInfosDisplay = cmdRepas.modifiePar != null && cmdRepas.dateModification != null;

    // Lundi en préco -> Semaine en préco -> On affiche le bouton règle 1
    const lundi = cmdRepas.jours[0];

    let shouldDisplayRegle1Button = false;
    if (lundi != null && lundi.dayOfWeek == 1) {
      if (lundi.periode.type == PeriodeContratTypeEnum.Precommande) {
        // On affiche le bouton si il n"y a pas de taux de prise
        shouldDisplayRegle1Button = !cmdRepas.jours.every(j => {
          return j.prestations.every(p => {
            return p.autoriserSaisieTauxPrise;
          });
        });
      }
    }

    this.isRegle1ButtonDisplay = shouldDisplayRegle1Button;
  }


  validateCommand() {

    if (this.isLoading || this.isNoContent) {
      return;
    }

    this.isSendingCommandeRepas = true;
    this.interactionService.blockUI('Validation en cours');
    // clean server validators
    FormControlHelper.cleanAllServerValidators();

    const prestaData = this.prestationComponents.map(
      pc => {
        return pc.getData();
      });

    this.commandeRepasService.postCommandeRepas(
      this.contratId,
      this._selectedRestaurant.id,
      this._selectedLieuCommande.id,
      this._selectedConvive.id,
      this.cuisineCentraleId,
      this.selectedDate,
      prestaData).pipe(
        first(),
        finalize(() => {
          this.interactionService.releaseUI();
          this.isSendingCommandeRepas = false;
        }))
      .subscribe((res: PostCommandeRepasWarningApiResponse.Warning) => {
        if (res.jours.length > 0) {
          // Il y a des erreurs
          const notif: NotificationCustomModel = new NotificationCustomModel();
          notif.title = 'Avertissement';
          notif.type = WarningPopupComponent;
          notif.data = res;
          notif.fullScreen = false;

          this.interactionService.showNotification(notif);
          this.cmdRepas.jours.forEach(j => {
            j.hasError = false;
          });

          this.getCommandeRepas(true, false, false);
        } else {
          const notifModel = new NotificationModel();
          notifModel.title = 'Commande transmise';
          notifModel.message = 'Les effectifs ont été transmis à la cuisine.';

          this.cmdRepas.jours.forEach(j => {
            j.hasError = false;
          });

          this.interactionService.showNotification(notifModel);

          this.getCommandeRepas(true, false, false);
        }
      },
        (error: ErrorApiModel) => {
          const contentError = error.Content as CommandeRepasErrorModel;
          switch (contentError && contentError.errorType) {
            case CommandeRepasUpdateErrorTypeEnum.Validation:
              {
                const notif: NotificationCustomModel = new NotificationCustomModel();
                notif.title = 'Erreur de validation';
                notif.type = ErrorPopupComponent;
                notif.data = contentError;
                notif.fullScreen = false;

                this.interactionService.showNotification(notif);
                contentError.setCallBack((errorModel => this.onErrorClicked(errorModel))); // TODO il manque un handler sur le close de la popup pour désenregistrer les callback
                const dates = contentError.jours.map(j => {
                  return new Date(j.date);
                });

                this.cmdRepas.jours.map(jourModel => {
                  jourModel.hasError = dates.some(d => DateHelper.areSameDayOfYear(d, jourModel.date));
                });
              }
              break;
            case CommandeRepasUpdateErrorTypeEnum.TransmissionElixir:
              {
                const notifModel = new NotificationModel();
                notifModel.title = 'Erreur de transmission';
                notifModel.message = error.Message;

                this.interactionService.showNotification(notifModel);

                // c"est une erreur gérée, les effectifs sont enregistrés => on recharge
                this.getCommandeRepas(true, false, false);
              }
              break;
            default:
              {
                const notifModel = new NotificationModel();
                notifModel.title = 'Erreur';
                notifModel.message = error.Message;

                this.interactionService.showNotification(notifModel);
              }
              break;
          }
        });
  }

  onErrorClicked(err: BaseErrorModel) {
    const oldJour = this.dateSelector.selectedJour;
    this.dateSelector.selectedJour = this.selectedRange.jours.find(j => DateHelper.areSameDayOfYear(err.date, j.date));

    if (err.type === CommandeRepasErrorTypeEnum.FamillePlat) {
      const fpError = err as FamillePlatErrorModel;
      const fpControl = this.commandeRepasService.getEffectifPortionControlsByEffectifAndFamillePlats(fpError.effectifId, fpError.name);
      if (fpControl) {
        this.prestationComponents.forEach(pc => pc.tryFocusControl(fpControl.effectifPortionControls[0], oldJour !== this.dateSelector.selectedJour));
      }
    } else if (err.type === CommandeRepasErrorTypeEnum.Portion) {
      const portionError = err as PortionErrorModel;
      const portionControl = this.commandeRepasService.effectifPortionControls[portionError.id];
      if (portionControl) {
        this.prestationComponents.forEach(pc => pc.tryFocusControl(portionControl, oldJour !== this.dateSelector.selectedJour));
      }
    } else if (err.type === CommandeRepasErrorTypeEnum.Prestation) {
      const prestaError = err as PrestationErrorModel;
      const prestaControl = this.commandeRepasService.effectifControls[prestaError.id];
      if (prestaControl) {
        this.prestationComponents.forEach(pc => pc.tryFocusControl(prestaControl, oldJour !== this.dateSelector.selectedJour, !!prestaError.regimeId));
      }
    }
  }

  applyRule1() {
    this.prestationComponents.map(p => {
      p.applyRule1();
    });
  }

  expandAllPrestations() {
    this.cmdRepas.jours.forEach((jour: JourModel) => {
      jour.prestations.forEach((prestation: PrestationModel) => {
        prestation.isCollapsed = false;
      });
    });
  }

  /** Events */
  onPrestaToggle(toggleData: { id: number, shouldCollapse: boolean }) {
    this.onPrestaToggle_Imp(toggleData.id, toggleData.shouldCollapse);
    this.commandeRepasService.handleToggle(toggleData.id, toggleData.shouldCollapse);
  }

  onPrestaToggle_Imp(id: number, shouldCollapse: boolean) {
    this.cmdRepas.jours.forEach((jour: JourModel) => {
      jour.prestations.forEach((prestation: PrestationModel) => {
        if (prestation.prestationId === id) {
          prestation.isCollapsed = shouldCollapse;
        }
      });
    });
  }

  /** Function style of custom dropdown */
  dateFnStyle(semaine: SemaineModel | LieuCommandeModel): string {
    let classes = 'circle';
    if (semaine.transmis) {
      classes += ' green';
    }

    return `<div class="${classes}" ></div>${semaine.libelle}`;
  }

  // ** Print methods */
  printCommande() {
    if (this.isMatriceDeChoixDownloading || !this.cmdRepas) {
      return;
    }

    this.isMatriceDeChoixDownloading = true;
    const report$ = this.reportingService.getCommandeRepasMatriceDeChoix(this.selectedRestaurant.cuisineCentraleId, this.selectedRestaurant.id, this.selectedLieuCommande.id, this.selectedDate, this.isPiqueNique).pipe(
      finalize(() => {
        this.isMatriceDeChoixDownloading = false;
      }),
      first());

    this.reportingService.handleReportResponse(report$);
  }

  printMenu() {
    if (this.isMenuDownloading || !this.cmdRepas) {
      return;
    }

    this.isMenuDownloading = true;
    const report$ = this.reportingService.getCommandeRepasMenu(this.selectedRestaurant.cuisineCentraleId, this.selectedRestaurant.id, this.selectedLieuCommande.id, this.selectedDate, this.isPiqueNique).pipe(
      finalize(() => {
        this.isMenuDownloading = false;
      }),
      first());

    this.reportingService.handleReportResponse(report$);
  }

}
