<div id="overlay-container" class="pageload-overlay">
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 80 60" preserveAspectRatio="xMidYMid slice">
    <path d="M40,30 c 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 Z" />
    <defs></defs>
  </svg>
  <div class="loader-content">
    <app-loader></app-loader>
    <div *ngIf="customMessage" class="message">{{customMessage}}</div>
    <div *ngIf="!customMessage" class="message">Chargement en cours...</div>
  </div>
</div>
