
import { AfterViewChecked, Component, ElementRef, EventEmitter, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime, finalize, first } from 'rxjs/operators';
import { DateSelectorComponent } from '../date-selector/date-selector.component';
import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { DateHelper } from '../shared/helpers/date.helper';
import { ErrorApiModel } from '../shared/models/api/error/error.apimodel';
import { BaseJourModel } from '../shared/models/base-jour.model';
import { BaseComponentNav } from '../shared/models/component-layout/base-component-nav';
import { NotificationModel } from '../shared/models/notification.model';
import { RestaurantModel } from '../shared/models/restaurant.model';
import { SemaineModel } from '../shared/models/semaine.model';
import { LogoRouteService } from '../shared/services/api/logo/logo-route.service';
import { ApplicationDataService } from '../shared/services/application-data.service';
import { InteractionService } from '../shared/services/interaction.service';
import { LogService } from '../shared/services/log.service';
import { OdgConviveModel, OdgJourModel, OdgModel, OdgPrestationModel } from './models/odg.model';
import { OdgService } from './services/odg.service';

const DEBOUNCE_ODG_MS = 1000;
const allowWeeksBefore = 2;
const allowWeeksAfter = 2;

@Component({
  selector: 'app-odg',
  templateUrl: './odg.component.html',
  styleUrls: ['./odg.component.scss']
})
export class OdgComponent extends BaseComponentNav implements OnInit, AfterViewChecked {
  get selectedRestaurant() {
    return this._selectedRestaurant;
  }
  set selectedRestaurant(val: RestaurantModel) {
    this._selectedRestaurant = val;
    // notify app that we changed the restaurant in this view,
    // it will trigger the handleNewRestaurant subscription
    this.appDataService
      .changeRestaurant(val).pipe(
        first())
      .subscribe();
  }
  get selectedConvive(): OdgConviveModel {
    return this._selectedConvive;
  }
  set selectedConvive(val: OdgConviveModel) {
    this._selectedConvive = val;
    this.handleNewConvive(val);
  }

  /** Ctor */
  constructor(private appDataService: ApplicationDataService,
              private interactionService: InteractionService,
              private logService: LogService,
              private logoService: LogoRouteService,
              private odgService: OdgService,
              private renderer: Renderer2) {
    super(appDataService, logoService, interactionService);
  }
  /** View Child */
  @ViewChild(DateSelectorComponent, { static: true }) dateSelector: DateSelectorComponent;
  @ViewChild('odgRepasContainer', { static: false }) odgRepasContainer: ElementRef;

  /** Emitters / Subscriptions */
  getOdgEmitter: EventEmitter<{ refreshList: boolean; newRest: boolean; selectNewDay: boolean }>;
  odgSubscription: Subscription;
  badEvaluationSubscription: Subscription;

  /** fields */
  alignRequested: boolean;
  isLoading: boolean = true;
  currentType: RestaurantTypeEnum;
  conviveList: OdgConviveModel[];
  isConviveDisplay: boolean = true;
  odg: OdgModel = undefined;
  isSendingOdg = false;

  /** Props */
  /** - Restaurant */
  _selectedRestaurant: RestaurantModel;

  /** - Convives */
  _selectedConvive: OdgConviveModel = undefined;

  checkIsDirty(): boolean {
    // Todo ajouter la logique dirty sur le odgComponent
    return false;
  }

  ngOnInit() {
    // allow 2 week before
    super.initComponent();

    const startDate = moment().subtract(allowWeeksBefore, 'week');
    const endDate = moment().add(allowWeeksAfter, 'week');

    this.dateRanges = SemaineModel.getSemainesFromDates(startDate, endDate);
    // on masque tous les jours par défaut
    this.dateRanges.forEach(r => r.jours.forEach(j => (j.hidden = true)));

    // select current week for init
    const now = new Date();
    this.selectedRange = this.dateRanges.find(dr => DateHelper.isBetweenDates(dr.firstDay, dr.lastDay, now));
    if (!this.selectedRange) {
      this.selectedRange = this.dateRanges[0];
    }

    this.refreshNavigationAllowed();
    this.handleSubscription();
  }

  ngAfterViewChecked() {
    if (this.alignRequested) {
      this.alignOdgPrestations();
      this.alignRequested = false;
    }
  }

  ngAfterViewInit() {
    super.subscribeDateSelectorEvents();
  }

  ngOnDestroy() {
    super.destroy();
  }

  handleSubscription() {
    // set up debouncing for commande repas
    this.getOdgEmitter = new EventEmitter<{ refreshList: boolean; newRest: boolean; selectNewDay: boolean }>();
    this.getOdgEmitter.pipe(debounceTime(DEBOUNCE_ODG_MS)).subscribe(res => {
      this.getOdg(res.refreshList, res.newRest, res.selectNewDay);
    });

    this.contratSubscription = this.appDataService.contrat$.subscribe(contrat => {
      if (contrat != null) {
        this.restaurantList = contrat.restaurants;
        this.isRestaurantDisplay = this.restaurantList.length > 1;
        this.logoContratUrl = this.logoService.GetPicture2Url(contrat.id, contrat.cuisineCentraleId);
      }
    });

    this.badEvaluationSubscription = this.appDataService.getCloseBadEvaluation().subscribe(odg => {
      this.alignOdgPrestations();
    });
  }

  selectedRangeChanged(dateRange: SemaineModel) {
    this.selectedRange = dateRange;
    this.selectedDate = dateRange.firstDay;
    this.handleNewDate(dateRange.firstDay);
  }

  /** event from view */
  newConvive(conv: OdgConviveModel) {
    this.selectedConvive = conv;
  }

  /** Handle new */
  handleNewRestaurant(rest: RestaurantModel) {
    this.currentType = rest.type;
    this._selectedConvive = undefined;

    // refresh list + is new week true to select the date
    this.getOdg(true, true, true);
  }

  handleNewConvive(conv: OdgConviveModel) {
    this.logService.Debug('New convive selected : ' + JSON.stringify(conv), 'RepasComponent');
    this.askOdg(false, false, false);
  }

  handleNewDate(date: Date) {
    this.selectedRange = this.getRangeForDate(date);
    this.refreshNavigationAllowed();
    this.askOdg(true, false, true);
  }

  /** align */
  alignOdgPrestations() {
    if (!this.odgRepasContainer) {
      return;
    }

    const prestaArray: { prestaList: Element[]; maxHeight: number }[] = [];
    const jours = this.odgRepasContainer.nativeElement.querySelectorAll('.jour');
    const joursLength = jours.length;

    const collapsibles = this.odgRepasContainer.nativeElement.querySelectorAll('.collapsable');

    for (let i = 0; i < collapsibles.length; i++) {
      const collapsible = collapsibles[i];
      collapsible.style.removeProperty('max-height');
      collapsible.style.removeProperty('height');
    }

    for (let i = 0; i < joursLength; i++) {
      const jour = jours[i] as Element;
      const prestas = jour.querySelectorAll('odg-prestation');
      const prestasLength = prestas.length;
      for (let j = 0; j < prestasLength; j++) {
        const presta = prestas[j];
        if (prestaArray[j]) {
          prestaArray[j].prestaList.push(presta);
          if (presta.clientHeight > prestaArray[j].maxHeight) {
            prestaArray[j].maxHeight = presta.clientHeight;
          }
        } else {
          prestaArray[j] = { prestaList: [presta], maxHeight: presta.clientHeight };
        }
      }
    }

    for (const prestaKey in prestaArray) {
      // set the presta max height
      const prestaLine = prestaArray[prestaKey];
      prestaLine.prestaList.forEach(p => {
        this.renderer.setStyle(p.querySelector('.collapsable'), 'max-height', prestaLine.maxHeight + 'px');
        this.renderer.setStyle(p.querySelector('.collapsable'), 'height', prestaLine.maxHeight + 'px');
      });
    }
  }

  /** Get odg */
  askOdg(refreshList: boolean, newRest, selectNewDay) {
    if (this.odgSubscription) {
      this.odgSubscription.unsubscribe();
    }

    this.isLoading = true;
    this.getOdgEmitter.next({ refreshList, newRest, selectNewDay });
  }

  getOdg(refreshList: boolean, newRest, selectNewDay) {
    this.isLoading = true;
    const conviveId = this.selectedConvive ? this.selectedConvive.id : undefined;
    this.odgSubscription = this.odgService.getOdg(this.selectedRestaurant.cuisineCentraleId, this.selectedRestaurant.id, conviveId, this.selectedDate).subscribe(
      odgModel => {
        this.isNoContent = odgModel.odgJours.length == 0;
        // if (this.isNoContent) {
        //   //this.dateSelector.selectedJour = undefined;
        //   this.isLoading = false;
        // }
        this.handleNewOdg(odgModel, refreshList, newRest, selectNewDay);
      },
      error => {
        // TODO Gestion des erreurs sur ODG
        this.isNoContent = true;
        this.isLoading = false;
      }
    );
  }

  private refreshSemaine(semaine: SemaineModel, joursToMap: BaseJourModel[]) {
    const length = semaine.jours.length;
    let indexCount = 0;
    for (let i = 0; i < length; i++) {
      const jour = semaine.jours[i];
      const jourMap = joursToMap.find(jToMap => {
        return moment(jToMap.date).isSame(jour.date, 'day');
      });

      // Si on trouve une correspondance, on update
      if (jourMap) {
        jourMap.index = indexCount++;
        semaine.jours[i] = jourMap;
      } else {
        // Sinon, il faut clear et mettre un modele vide
        // Je ne sais pas pourquoi on passe par la base puis on cast en JourModel
        // A enlever ?
        jour.index = indexCount++;
        semaine.jours[i] = new OdgJourModel(jour.date);
      }

      // on affiche/masque le jour s'il n'est pas dans la liste
      semaine.jours[i].hidden = jourMap == null;
    }
  }

  handleNewOdg(odgModel: OdgModel, refreshList: boolean, newRest: boolean, selectNewDay: boolean) {
    this.refreshSemaine(this.selectedRange, odgModel.odgJours);
    if (selectNewDay) {
      const now = new Date();
      if (DateHelper.isBetweenDates(this.selectedRange.firstDay, this.selectedRange.lastDay, now)) {
        this.dateSelector.selectedJour = this.selectedRange.jours.find(j => DateHelper.areSameDayOfYear(now, j.date));
      } else {
        this.dateSelector.selectedJour = this.selectedRange.jours[0];
      }
    } else {
      // the list is refreshed so we refresh the current too, we should check if we can avoid refreshing the whole list
      if (this.dateSelector.selectedJour) { this.dateSelector.selectedJour = this.selectedRange.jours.find(j => DateHelper.areSameDayOfYear(this.dateSelector.selectedJour.date, j.date)); } else {
        if (this.selectedRange.jours.length > 0) { this.dateSelector.selectedJour = this.selectedRange.jours[0]; }
      }
    }

    this.odg = odgModel;

    if (refreshList) {
      // if new rest : refresh anyway
      this.conviveList = this.odg.odgConvives;
      if (newRest) {
        this._selectedConvive = this.conviveList[0];
      } else if (this.areSameList(this.odg.odgConvives, this.conviveList, 'id')) {
        // keep the convive selected if it's the same list
        if (this._selectedConvive) {
          const sameConvive = this.conviveList.find(c => c.id == this._selectedConvive.id);
          this._selectedConvive = sameConvive ? sameConvive : this.conviveList[0];
        } else {
          // if selectedConvive was null (shouldn't happened)
          this._selectedConvive = this.conviveList[0];
        }

        this.isConviveDisplay = this.conviveList.length > 1;
      }
    }

    this.alignRequested = true;
    this.isLoading = false;
  }

  validateOdg() {
    if (this.isLoading || this.isNoContent) {
      return;
    }

    this.isSendingOdg = true;
    this.interactionService.blockUI('Validation en cours');

    this.odgService
      .postOdg(this.odg, this._selectedConvive.id).pipe(
        first(),
        finalize(() => {
          this.interactionService.releaseUI();
          this.isSendingOdg = false;
        }))
      .subscribe(
        res => {
          if (res) {
            const notifModel = new NotificationModel();
            notifModel.title = 'Validation';
            notifModel.message = 'Validation Réussie';

            this.interactionService.showNotification(notifModel);
          }
        },
        (error: ErrorApiModel) => {
          const notifModel = new NotificationModel();
          notifModel.title = 'Handle l\'erreur';
          notifModel.message = error.Message;

          this.interactionService.showNotification(notifModel);
        }
      );
  }

  expandAllPrestations() {
    this.odg.odgJours.forEach((jour: OdgJourModel) => {
      jour.odgPrestations.forEach((prestation: OdgPrestationModel) => {
        prestation.isCollapsed = false;
      });
    });
  }

  onPrestaToggle(id: number) {
    this.odg.odgJours.forEach((jour: OdgJourModel) => {
      jour.odgPrestations.forEach((prestation: OdgPrestationModel) => {
        if (prestation.id == id) {
          prestation.isCollapsed = !prestation.isCollapsed;
        }
      });
    });
  }
}
