<div class="prestation-header pointerHover" [restaurantColor]="{'type': currentType, 'background':true}">
  <div class="nowrap header-libelle" (click)="toggle()">
    <span class="prestation-row-span chevron" [class.down]="odgPresta.isCollapsed"></span>
    <span class="prestation-row-span">{{odgPresta.libelle}}</span>
  </div>
</div>

<div class="collapsable" [class.collapsed]="odgPresta.isCollapsed">
  <odg-evaluation *ngFor="let odgEval of odgPresta.odgEvaluations" [odgEval]="odgEval">
  </odg-evaluation>
</div>