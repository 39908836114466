
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as HttpStatus from 'http-status-codes';
import { includes } from 'lodash';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ErrorApiModel } from '../../models/api/error/error.apimodel';
import { AuthenticationService } from '../authentication/authentication.service';
import { BaseHttpInterceptor } from '../base-http-interceptor';
import { LogService } from '../log.service';
import { ApiSettings } from '../../models/api/api-settings';
import { StringHelper } from '../../helpers/string.helper';

const SKIP_REFRESH_TOKEN_HEADERNAME = 'ng-skip-refreshToken';

// Erreurs serveurs en cas de token expiré
// https://github.com/aspnet/AspNetCore/blob/f4972dc6b61968ab244f6e030535974376298ba1/src/Security/Authentication/JwtBearer/src/JwtBearerHandler.cs#L286
// https://github.com/aspnet/AspNetCore/blob/f4972dc6b61968ab244f6e030535974376298ba1/src/Security/Authentication/JwtBearer/src/JwtBearerHandler.cs#L222
const TOKEN_EXPIRATION_SERVER_ERRORS = [
  'token expired', // SecurityTokenExpiredException
  'token lifetime is invalid' // SecurityTokenInvalidLifetimeException
];

@Injectable()
export class AuthHttpInterceptor extends BaseHttpInterceptor {

  constructor(
    private _httpClient: HttpClient,
    private _authenticationService: AuthenticationService,
    private _apiSettings: ApiSettings,
    logService: LogService) {
    super(
      logService.getLogger('AuthHttpInterceptor'),
      {
        whitelistedDomains: [null, /localhost(:\d+)/i],
        blacklistedRoutes: [
          /oauth\/refresh/,
          /oauth\/sendpassword/,
          /oauth$/, // login
          /// (?!\/(api|api-internal)\/)/i // on bypass tout ce qui n'est pas API
          RegExp(`^(?!${StringHelper.escapeRegExp(_apiSettings.apiUrl)})`, 'gi')
        ]
      }
    );
  }

  protected handleInterception(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const shouldRefreshToken = this.shouldRefreshToken(request);

    return this._authenticationService.getToken()
      .pipe(
        switchMap((token) => {
          if (token != null) {
            const authenticatedRequest = this.applyBearerToken(request, token.accessToken);
            return next.handle(authenticatedRequest);
          }
          // sinon on tente tout de même l'appel
          return next.handle(request);
        }),
        catchError((error) => {
          // on vérifie si l'erreur ne serait pas un token expiré, auquel cas on fait un refresh et on relance le pipe
          if (error instanceof HttpErrorResponse) {
            const response = error;

            if (this.isTokenExpirationServerErrors(response)) {
              if (shouldRefreshToken) {
                // on retente un refresh et on relance le pipe complet
                return this._authenticationService.refreshToken()
                  .pipe(
                    switchMap(token => {
                      // on réapplique le token "rafraichi"...
                      let relaunchRequest = this.applyBearerToken(request, token.accessToken);
                      // ...avec l'ajout d'un flag pour skipper le refresh auto lors du relancement dans le pipe http (pour éviter de boucler s'il y a de nouveau un autre problème en 401)
                      relaunchRequest = this.applySkipRefreshTokenHeader(relaunchRequest);
                      return this._httpClient.request(relaunchRequest);
                    })
                  );
              } else {
                // erreur générique
                return this.throwError('Problème d\'authentification, veuillez vous réauthentifier ou contacter un administrateur.', response.error);
              }
            }

          }

          // par défault on rethrow
          return observableThrowError(error);
        })
      );

  }


  private applyBearerToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        // ajout du token d'authentification
        Authorization: `Bearer ${token}`
      }
    });
  }

  private applySkipRefreshTokenHeader(request: HttpRequest<any>, skip: boolean = true): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        // ajout du token d'authentification
        [SKIP_REFRESH_TOKEN_HEADERNAME]: String(skip)
      }
    });
  }

  private shouldRefreshToken(request: HttpRequest<any>): boolean {
    return !request.headers.get(SKIP_REFRESH_TOKEN_HEADERNAME);
  }

  private throwError(error: string, extraInfo: any = null): Observable<never> {
    const errorModel: ErrorApiModel = {
      Code: 0,
      Message: error,
      Content: extraInfo
    };
    return this.throwErrorFromModel(errorModel);

  }
  private throwErrorFromModel(errorModel: ErrorApiModel): Observable<never> {
    this._log.error(JSON.stringify(errorModel));
    return observableThrowError(errorModel);
  }

  private isTokenExpirationServerErrors(response: HttpErrorResponse) {
    if (response.status === HttpStatus.UNAUTHORIZED) { // 401 (Unauthorized)
      // on check la présence de headers laissés par le JwtBearerHandler explicitant que le token a expiré, auquel cas fait un refresh et on relance le pipe
      // ex: www-authenticate: Bearer error="invalid_token", error_description="The token lifetime is invalid"
      // refresh si unauthorized
      const tokenError = response.headers.get('www-authenticate');

      return (tokenError
        && TOKEN_EXPIRATION_SERVER_ERRORS.some(search => includes(tokenError, search)));
    }
  }
}
