import { DailyMenuPrestationModel } from '../shared/models/daily-menu/daily-menu-prestation.model';
import { Component, Input, OnInit } from '@angular/core';

import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';

@Component({
  selector: 'app-meal-resume',
  templateUrl: './meal-resume.component.html',
  styleUrls: ['./meal-resume.component.scss']
})

export class MealResumeComponent implements OnInit {
  @Input() dailyPrestation: DailyMenuPrestationModel;
  @Input() currentType: RestaurantTypeEnum;

  constructor() {
  }

  ngOnInit() {
  }

}
