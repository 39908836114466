<app-contact-card *ngFor="let contact of contacts" [contact]="contact">
</app-contact-card>

<!--<table>
  <thead>
    <tr [restaurantColor]="{'type': restType, 'background':true, 'isSecondary': true}">
      <th>Nom</th>
      <th>Prénom</th>
      <th>Fonction</th>
      <th>E-mail</th>
      <th>Téléphone</th>
      <th>Mobile</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let contact of contacts" class="pointerHover" [restaurantColor]="{'type': restType, 'background':true, 'hover':true, 'isSecondary': true}">
      <td>
        {{contact.nom}}
      </td>
      <td>
        {{contact.prenom}}
      </td>
      <td>
        {{contact.fonction}}
      </td>
      <td>
        {{contact.email}}
      </td>
      <td>
        {{contact.telephone}}
      </td>
      <td>
        {{contact.mobile}}
      </td>
    </tr>
  </tbody>
</table>-->