import { PopupCustomComponentBase } from "../../popup-custom/popup-custom-content.component";
import { Type, Component, EventEmitter } from "@angular/core"
import { SafeValue } from "@angular/platform-browser";
// TODO
// Refacto d"une vraie popup custom...

// les notifications doivent être des interfaces !
export abstract class NotificationModelBase {
    id: number;
    isNew: boolean;
    title: string;
    isPrintable: boolean;
    fullScreen: boolean;
    closeOnBackdropClick: boolean = true;
    hasNoOverflow: boolean;
    onClose: () => void = () => { };
}

export class NotificationModel extends NotificationModelBase {
    message: string | SafeValue;

    checkboxText: string;
    isChecked: boolean;
    onCheck: (isChecked: boolean) => void;
}

export class NotificationCustomModel extends NotificationModelBase {
    type: Type<PopupCustomComponentBase>;
    data: any;
}

export class NotificationConfirmModel extends NotificationModelBase {
    message: string;

    constructor(
        public showOk: boolean = true,
        public showCancel: boolean = true
    ) {
        super();
    }

    onOk: () => void = () => { };
    onCancel: () => void = () => { };
}
