/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contrat-notification-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./contrat-notification-popup.component";
var styles_ContratNotificationPopupComponent = [i0.styles];
var RenderType_ContratNotificationPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContratNotificationPopupComponent, data: {} });
export { RenderType_ContratNotificationPopupComponent as RenderType_ContratNotificationPopupComponent };
export function View_ContratNotificationPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "contrat-notification-popup-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" contrat\n"]))], null, null); }
export function View_ContratNotificationPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "legal-info", [], null, null, null, View_ContratNotificationPopupComponent_0, RenderType_ContratNotificationPopupComponent)), i1.ɵdid(1, 180224, null, 0, i2.ContratNotificationPopupComponent, [], null, null)], null, null); }
var ContratNotificationPopupComponentNgFactory = i1.ɵccf("legal-info", i2.ContratNotificationPopupComponent, View_ContratNotificationPopupComponent_Host_0, {}, {}, []);
export { ContratNotificationPopupComponentNgFactory as ContratNotificationPopupComponentNgFactory };
