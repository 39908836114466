import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AlertesModule } from '../alertes/alertes.module';
import { BonLivraisonModule } from '../bon-livraison/bon-livraison.module';
import { DiscussionModule } from '../discussion/discussion.module';
import { RepasModule } from '../repas/repas.module';
import { SharedModule } from '../shared/shared.module';
import { ReportingRouteModule } from './reporting-routing.module';
import { ReportingComponent } from './reporting.component';
import { ReportingApiService } from './services/api/reporting.apiservice';
import { ReportingService } from './services/reporting.service';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReportingRouteModule,
    BonLivraisonModule,
    RepasModule,
    AlertesModule,
    DiscussionModule
  ],
  providers: [
    ReportingService,
    ReportingApiService
  ],
  declarations: [
    ReportingComponent
  ]
})
export class ReportingModule { }
