
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileHelper } from 'src/app/shared/helpers/file.helper';
import { ApiSettings } from '../../../shared/models/api/api-settings';
import { FileResultModel } from '../../../shared/models/fileresult.model';
import { DocumenthequeApiResponse } from '../../models/api/documentheque.apiresponse';

@Injectable()
export class DocumenthequeApiService {
  private controllerUrl: string = 'documentheque';

  constructor(private apiSettings: ApiSettings, private http: HttpClient) { }

  getDocument(documentationId: number): Observable<FileResultModel> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/download/${documentationId}`;
    return FileHelper.getFile(this.http, url, null);
  }

  getDocuments(cuisineCentraleId: number, contratId: number, searchWords: string[]): Observable<DocumenthequeApiResponse.Documentation[]> {
    let httpParams = new HttpParams()
      .set('cuisineCentraleId', cuisineCentraleId.toString())
      .set('contratId', contratId.toString());

    if (searchWords && searchWords.length !== 0) {
      for (const w of searchWords) {
        httpParams = httpParams.append('words', w);
      }
    }

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/search`;
    return this.http.get<DocumenthequeApiResponse.Documentation[]>(url, { params: httpParams });
  }
}
