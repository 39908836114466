<header class="color-animation"
        [restaurantColor]="{'type': currentType, 'background':true}">
  <div class="header-container">
    <a title="v{{appVersion}}"
       (click)="navigateToHome()">
      <img class="brand-logo"
           src="/assets/images/brand-logo.png" />
    </a>
    <app-hamburger class="pointerHover"
                   (click)="showDrawer()"></app-hamburger>
    <div class="title">{{pageTitle}}</div>
    <div class="right">
      <div class="user-info button-dropdown">
        <input #menuCheckInput
               id="menu-check"
               type="checkbox"
               name="menu" />
        <label for="menu-check"
               class="pointerHover">
          <img src="/assets/images/layout-header/user-logo.png" />
          <span *ngIf="user">{{user.fullName}}</span>
        </label>
        <ul class="submenu submenu-style1"
            [restaurantColor]="{'type': currentType, 'background':true, 'border':true}">
          <li *ngIf="hasAccessToAdmin"
              class="pointerHover"
              (click)="redirectToAdmin()">Administration</li>
          <li class="pointerHover"
              (click)="logOff()">Se déconnecter</li>
        </ul>
      </div>
      <app-alertes-tile [class.conditional-display]="cartIsVisible"
                        hideIfNoAlerte="true"
                        title="Notifications"></app-alertes-tile>
      <app-cart-tile [class.conditional-display]="cartIsVisible"
                     *ngIf="cartIsVisible"></app-cart-tile>
      <app-notif-tile [class.conditional-display]="cartIsVisible"
                      title="Demandes clients"
                      *ngIf="hasAccessToDiscussion"></app-notif-tile>
      <div *ngIf="hasAccessToContact"
           (click)="navigateToContact()"
           class="contact-icon pointerHover"
           title="Contacts">
        <img src="assets/images/mobile-circle.png" />
      </div>
      <!-- <div class="dropdown" title="{{ currentContrat && currentContrat.libelle }} ({{ currentContrat && currentContrat.id }})">
        <img src="/assets/images/layout-header/home.png" />
        <select-autocomplete [(entity)]="currentContrat" [entities]="contrats" entityDisplayProp="libelle"></select-autocomplete>
      </div>
      <div class="dropdown" title="{{ currentRestaurant && currentRestaurant.name }} ({{ currentRestaurant && currentRestaurant.id}})">
        <img src="/assets/images/layout-header/home.png" />
        <select-autocomplete [(entity)]="currentRestaurant" [entities]="restaurants" entityDisplayProp="name"></select-autocomplete>
      </div> -->
      <app-restaurant-selector [contrats]="contrats"
                               [selectedRestaurant]="restaurant"
                               (selectedRestaurantContextChange)="selectedRestaurantContextChanged($event)"
                               [showDebugInfo]="showDebugInfo"
                               [displayGrandCompteSelector]="hasAccessToAdmin"
                               ></app-restaurant-selector>
    </div>
  </div>
</header>
<!-- mix this in one header tag -->
<header class="sub-header-container">
  <div class="contrat-bar">
    <!--<app-logo class="logo-icon" [restaurantType]="currentType"></app-logo>-->
    <img class="contrat-logo"
         [src]="logoContratUrl"
         onerror="this.src='/assets/images/blank.png'" />
  </div>
  <div class="links">
    <app-menu-item class="pointerHover"
                   *ngFor="let item of menuList"
                   [item]="item"
                   [restaurantType]="currentType">
    </app-menu-item>
  </div>
</header>
