import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as HttpStatus from 'http-status-codes';
import { Observable, Subscriber, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CheckIsErrorApiModel, ErrorApiModel } from '../../models/api/error/error.apimodel';
import { BaseHttpInterceptor } from '../base-http-interceptor';
import { LogService } from '../log.service';
import { ApiSettings } from '../../models/api/api-settings';
import { StringHelper } from '../../helpers/string.helper';

@Injectable()
export class ErrorHandlerHttpInterceptor extends BaseHttpInterceptor {

  constructor(logService: LogService,
              private _apiSettings: ApiSettings,
  ) {
    super(
      logService.getLogger('ErrorHandlerHttpInterceptor'),
      {
        whitelistedDomains: [null, /localhost(:\d+)/i],
        blacklistedRoutes: [
          /// (?!\/(api|api-internal)\/)/i // on bypass tout ce qui n'est pas API
          RegExp(`^(?!${StringHelper.escapeRegExp(_apiSettings.apiUrl)})`, 'gi')
        ]
      }
    );
  }

  protected handleInterception(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((errorResponse) => {
          return this.handleError(errorResponse, request);
        })
      );
  }


  private handleError(response: HttpErrorResponse, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (response.status === HttpStatus.BAD_REQUEST) {
      // les BAD_REQUEST sont à gérer manuellement
      // TODO voir s'il n'y aurait pas une convention pour définir un modèle d'erreur générique sur les BAD_REQUEST
      const errorModel = ErrorApiModel.FromHttpErrorResponse(response);
      if (errorModel) {
        return this.throwErrorFromModel(errorModel);
      } else {
        return observableThrowError(response); // rethrow
      }
    } else if (response.status === HttpStatus.UNAUTHORIZED) { // 401 (Unauthorized)
      const errorModel = ErrorApiModel.FromHttpErrorResponse(response);
      if (errorModel) {
        return this.throwErrorFromModel(errorModel);
      } else {
        // catché par AuthHttpInterceptor
        return observableThrowError(response); // rethrow
      }
    } else if (response.status === HttpStatus.FORBIDDEN) { // 403 (Forbidden)
      // erreur générique => Accès refusé
      return this.throwError('Accès refusé, veuillez vous réauthentifier ou contacter un administrateur.');
    } else if (response.status === 0
      || response.status === HttpStatus.GATEWAY_TIMEOUT) { // net::ERR_CONNECTION_REFUSED
      // erreur générique => serveur injoignable
      return this.throwError('Le serveur n\'est pas joignable, veuillez réessayer dans quelques instants.', { error: response.error, message: response.message });
    } else {
      // check if blob readable content
      let responseContent: any;
      responseContent = response.error || response.message; // TODO à vérifier

      //   // Client Side Error //see http://www.advancesharp.com/blog/1234/global-error-handling-in-angular-6-with-httpclient-interceptor
      // if (error.error instanceof ErrorEvent) {
      //   errMsg = `Error: ${error.error.message}`;
      // }

      // fallback to throw generic error from http status
      if (responseContent === undefined) {
        responseContent = `${response.status}-${response.statusText}`;
      }
      // response can be a blob if fetch has been done using "responseType: ResponseContentType.Blob"
      if ((responseContent instanceof Blob)
        && (responseContent as Blob).type === 'application/json') {

        return new Observable((obs: Subscriber<HttpEvent<any>>) => {
          // extract blob Content
          const reader: FileReader = new FileReader();
          reader.onloadend = (e) => {
            const errorModel = JSON.parse(reader.result as string) as ErrorApiModel;
            this._log.error(JSON.stringify(errorModel));
            obs.error(errorModel);
          };
          reader.onerror = (e) => {
            const errorContent = JSON.stringify(reader.error);
            const errorModel: ErrorApiModel = {
              Code: 0,
              Message: errorContent
            };
            this._log.error(errorContent);
            obs.error(errorModel);
          };
          reader.readAsText(responseContent);
        });

      } else {
        // si la réponse est déjà un error model on la retourne
        if (CheckIsErrorApiModel(responseContent)) {
          return this.throwErrorFromModel(responseContent);
        }
        // else Erreur générique
        const errorContent = JSON.stringify(responseContent);
        const errorMessage = 'Incident technique, veuillez réessayer dans quelques instants. ' + (errorContent ? `\n(${errorContent})` : '');
        return this.throwError(errorMessage,
          {
            request: request && request.toString(),
            responseStatus: response.status,
            responseStatusText: response.statusText
          });
      }
    }
  }

  private throwError(error: string, extraInfo: any = null): Observable<never> {
    const errorModel: ErrorApiModel = {
      Code: 0,
      Message: error,
      Content: extraInfo
    };
    return this.throwErrorFromModel(errorModel);

  }
  private throwErrorFromModel(errorModel: ErrorApiModel): Observable<never> {
    this._log.error(JSON.stringify(errorModel));
    return observableThrowError(errorModel);
  }

}
