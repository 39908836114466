import * as moment from 'moment';
import { RythmeContratEnum } from '../../shared/enums/rythme-contrat.enum';
import { BaseJourModel } from '../../shared/models/base-jour.model';

export class SemaineModel {
  jours: BaseJourModel[];
  libelle: string;
  transmis: boolean;

  static getSemainesFromDates(dateMin: moment.Moment, dateMax: moment.Moment): SemaineModel[] {
    const array = new Array<SemaineModel>();
    dateMax = dateMax.startOf('day');
    const monday = dateMin.clone().startOf('isoWeek');
    const sunday = dateMin.clone().endOf('isoWeek').startOf('day');

    let week: SemaineModel;
    const i = 0;
    do {
      week = this.getRangeFromDates(monday, sunday);
      array.push(week);

      monday.add(1, 'week');
      sunday.add(1, 'week');
    }
    while (!this.isBetweenDates(week.firstDay, week.lastDay, dateMax.toDate()));

    return array;
  }

  private static getWithoutHours(date: Date): number {
    const newDate = moment(date).startOf('day');
    return newDate.valueOf();
  }

  private static isBetweenDates(dateMin: Date, dateMax: Date, dateToCheck: Date): boolean {
    return this.getWithoutHours(dateMin) <= this.getWithoutHours(dateToCheck)
      && this.getWithoutHours(dateMax) >= this.getWithoutHours(dateToCheck);
  }
  private static getRangeFromDates(monday: moment.Moment, sunday: moment.Moment): SemaineModel {
    const days = new Array<Date>();
    const day = monday.clone();
    while (day <= sunday) {
      days.push(day.clone().toDate());
      day.add(1, 'day');
    }

    const areSameMonth = monday.month() == sunday.month();
    const libelle = `Semaine du ${monday.date()}${areSameMonth ? '' : ' ' + monday.format('MMMM')} au ${sunday.date()} ${sunday.format('MMMM')}`;
    return new SemaineModel(days, libelle);
  }

  get validJours(): BaseJourModel[] {
    return this.jours.filter(j => !j.isHorsRythme && !j.hidden);
  }

  constructor(days: Date[], libelle: string, transmis: boolean = true, contratRythme: RythmeContratEnum = RythmeContratEnum.SeptSurSept) {
    this.jours = days.map(d => {
      const model = new BaseJourModel(d);
      model.isEmpty = true;
      this.refreshJourIsHorsRythme(model, contratRythme);
      return model;
    });

    this.transmis = transmis;
    this.libelle = libelle;
  }

  refreshIsHorsRythme(contratRythme: RythmeContratEnum) {
    this.jours.forEach(j => this.refreshJourIsHorsRythme(j, contratRythme));
  }

  private refreshJourIsHorsRythme(j: BaseJourModel, contratRythme: RythmeContratEnum) {
    if (contratRythme == RythmeContratEnum.SeptSurSept) {
      j.isHorsRythme = false;
    } else {
      // Hide week end
      if (j.dayOfWeek == 0 || j.dayOfWeek == 6) {
        j.isHorsRythme = true;
      } else if (contratRythme == RythmeContratEnum.QuatreSurSept && j.dayOfWeek == 3) {
        // hide wednesday
        j.isHorsRythme = true;
      } else {
        j.isHorsRythme = false;
      }
    }
  }

  get firstDay(): Date {
    return this.jours[0].date;
  }

  get lastDay(): Date {
    return this.jours[this.jours.length - 1].date;
  }

}
