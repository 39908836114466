/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-autocomplete.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngui/auto-complete/dist/auto-complete.directive";
import * as i3 from "@angular/forms";
import * as i4 from "./select-autocomplete.component";
var styles_SelectAutocompleteComponent = [i0.styles];
var RenderType_SelectAutocompleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectAutocompleteComponent, data: {} });
export { RenderType_SelectAutocompleteComponent as RenderType_SelectAutocompleteComponent };
export function View_SelectAutocompleteComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { input: 0 }), (_l()(), i1.ɵeld(1, 16777216, null, null, 8, "div", [["class", "autocomplete-container"], ["match-formatted", "true"], ["ngui-auto-complete", ""], ["no-match-found-text", "Aucun r\u00E9sultat"], ["z-index", "10"]], [[8, "title", 0]], [[null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChanged" === en)) {
        var pd_0 = (_co.entityChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 4931584, null, 0, i2.NguiAutoCompleteDirective, [i1.ComponentFactoryResolver, i1.ViewContainerRef, [8, null]], { source: [0, "source"], acceptUserInput: [1, "acceptUserInput"], listFormatter: [2, "listFormatter"], noMatchFoundText: [3, "noMatchFoundText"], matchFormatted: [4, "matchFormatted"], reFocusAfterSelect: [5, "reFocusAfterSelect"], zIndex: [6, "zIndex"] }, { valueChanged: "valueChanged" }), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["dropdownInput", 1]], null, 5, "input", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "focus"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("focus" === en)) {
        var pd_4 = (_co.onInputFocus($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(6, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, null), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(8, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["class", "pointerHover"], ["src", "/assets/images/dropdown-arrow.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onArrowClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getEntities; var currVal_2 = false; var currVal_3 = _co.entityFormatter.bind(_co); var currVal_4 = "Aucun r\u00E9sultat"; var currVal_5 = "true"; var currVal_6 = false; var currVal_7 = "10"; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_15 = _co.entityFormatter(_co.entity); _ck(_v, 6, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showTooltip && i1.ɵnov(_v, 3).value); _ck(_v, 1, 0, currVal_0); var currVal_8 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 8).ngClassValid; var currVal_13 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }); }
export function View_SelectAutocompleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "select-autocomplete", [], null, null, null, View_SelectAutocompleteComponent_0, RenderType_SelectAutocompleteComponent)), i1.ɵdid(1, 49152, null, 0, i4.SelectAutocompleteComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var SelectAutocompleteComponentNgFactory = i1.ɵccf("select-autocomplete", i4.SelectAutocompleteComponent, View_SelectAutocompleteComponent_Host_0, { entityDisplayProp: "entityDisplayProp", entityDisplayFunc: "entityDisplayFunc", entity: "entity", entities: "entities", showTooltip: "showTooltip" }, { entityChange: "entityChange" }, []);
export { SelectAutocompleteComponentNgFactory as SelectAutocompleteComponentNgFactory };
