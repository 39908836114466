<div class="main-container">
  <div class="ribbon" [style.background-image]="'url(' + logoContratUrl + ')'">
  </div>

  <div class="page-container">
    <h1>Documenthèque</h1>

    <div class="left-side">
      <span class="title">Recherche</span>

      <form class="content-container search-form" (submit)="searchDocuments($event)">

        <div class="sub-container text-container">
          <input type="text" id="search-text" name="search-text" [(ngModel)]="searchText" placeholder="Rechercher" />
        </div>

        <div class="sub-container buttons-container">
          <button type="submit" class="generic-button gray-tile-button search-button">Rechercher</button>
        </div>
      </form>
    </div>

    <div class="results-container right-side">
      <span class="title">Derniers documents</span>

      <div class="results-list content-container">
        <div *ngIf="isLoadingDocuments" class="info-container">
          <app-loader message="Chargement des documents..."></app-loader>
        </div>
        <!-- Data -->

        <div class="container-fluid" *ngIf="!isLoadingDocuments">

          <div class="table-row table-header">
            <div class="text">Documents</div>
          </div>

          <div class="table-row" *ngIf="!documents || documents.length == 0">
            Aucun document trouvé
          </div>

          <div class="table-row table-body" *ngFor="let document of displayedDocuments" (click)="getDocument(document)" [restaurantColor]="{'type': currentType, 'isSecondary':true, 'background':true, 'hover':true}">
            <div class="document-icon">
              <img src="/assets/images/files/file-outline.png" />
            </div>
            <div class="document text">
              <div class="document-header">
                <div class="document-name">{{ document.nom }}</div>
                <div class="document-date">{{ document.createdAtString }}</div>
              </div>

              <div class="document-description">{{document.isDownloading? 'Téléchargement...' : document.description}}</div>
            </div>
          </div>

        </div>


        <div class="pagination" *ngIf="!isLoadingDocuments && documents && documents.length > 0 && totalPages > 1">
          <button class="generic-button prev-button" (click)="prev()" [class.hidden]="page == 1"></button>
          <button class="generic-button page-button" (click)="changePage(1)" [class.selected-page-button]="page == 1" [restaurantColor]="{'type': currentType, 'background':page == 1}">1</button>
          <span *ngIf="page > 3">...</span>
          <button class="generic-button page-button" (click)="changePage(p)" *ngFor="let p of pages" [class.hidden]="!isPageDisplayed(p)" [class.selected-page-button]="p == page" [restaurantColor]="{'type': currentType, 'background':page == p}">{{p}}</button>
          <span *ngIf="page < totalPages - 2">...</span>
          <button class="generic-button page-button" (click)="changePage(totalPages)" [class.hidden]="totalPages == 1" [class.selected-page-button]="page == totalPages" [restaurantColor]="{'type': currentType, 'background':page == totalPages}">{{totalPages}}</button>
          <button class="generic-button next-button" (click)="next()" [class.hidden]="page == totalPages"></button>
        </div>

      </div>
    </div>

  </div>

</div>
