import { ContactModel } from '../models/contact.model';
import { GetContactsApiResponse } from '../models/api/get-contacts.apiresponse';

export class ContactProjector {

    public static projectContacts(apiContacts: GetContactsApiResponse.Contact[]): ContactModel[] {
        return apiContacts.map(apiContact => {
            const contact: ContactModel = {
                contratId: apiContact.contratId,
                email: apiContact.email,
                estPiqueNique: apiContact.estPiqueNique,
                estUrgence: apiContact.estUrgence,
                fax: apiContact.fax,
                fonction: apiContact.fonction,
                id: apiContact.id,
                mobile: apiContact.mobile,
                nom: apiContact.nom,
                prenom: apiContact.prenom,
                telephone: apiContact.telephone
            };

            return contact;
        });

    }
}
