/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./meal-resume.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../shared/directives/restaurant-type-color.directive";
import * as i4 from "../shared/pipes/dateFormat.pipe";
import * as i5 from "./meal-resume.component";
var styles_MealResumeComponent = [i0.styles];
var RenderType_MealResumeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MealResumeComponent, data: {} });
export { RenderType_MealResumeComponent as RenderType_MealResumeComponent };
function View_MealResumeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "daily-plat"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.libelle; _ck(_v, 1, 0, currVal_0); }); }
function View_MealResumeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MealResumeComponent_3)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "hr", [["class", "bar"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i3.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpod(4, { "type": 0, "background": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.plats; _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 4, 0, _co.currentType, true); _ck(_v, 3, 0, currVal_1); }, null); }
function View_MealResumeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MealResumeComponent_2)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dailyPrestation.familles; _ck(_v, 1, 0, currVal_0); }, null); }
function View_MealResumeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "no-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Pas de service pour cette journ\u00E9e. "]))], null, null); }
export function View_MealResumeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DateFormatPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "tile-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "h3", [["class", "title"]], [[8, "title", 0]], null, null, null, null)), i1.ɵppd(3, 2), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "daily-prestation-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "daily-prestation"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MealResumeComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MealResumeComponent_4)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = !_co.dailyPrestation.isEmpty; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.dailyPrestation.isEmpty; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), _co.dailyPrestation.date, "DD/MM/YYYY")), ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.dailyPrestation.date, "dddd")); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.dailyPrestation.prestation; _ck(_v, 7, 0, currVal_2); }); }
export function View_MealResumeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-meal-resume", [], null, null, null, View_MealResumeComponent_0, RenderType_MealResumeComponent)), i1.ɵdid(1, 114688, null, 0, i5.MealResumeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MealResumeComponentNgFactory = i1.ɵccf("app-meal-resume", i5.MealResumeComponent, View_MealResumeComponent_Host_0, { dailyPrestation: "dailyPrestation", currentType: "currentType" }, {}, []);
export { MealResumeComponentNgFactory as MealResumeComponentNgFactory };
