import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, EventEmitter, Output, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { RythmeContratEnum } from '../shared/enums/rythme-contrat.enum';
import { ContratModel } from '../shared/models/contrat.model';
import { RestaurantModel } from '../shared/models/restaurant.model';
import { InteractionService } from '../shared/services/interaction.service';
import { RestaurantContext } from './model/restaurant-context.model';
import { uniqBy, sortBy } from 'lodash';

export interface GrandCompte {
  id: number;
  libelle: string
}

@Component({
  selector: 'app-restaurant-selector',
  templateUrl: './restaurant-selector.component.html',
  styleUrls: ['./restaurant-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // encapsulation: ViewEncapsulation.None
})
export class RestaurantSelectorComponent implements OnInit, OnChanges {

  @Input()
  displayGrandCompteSelector: false;

  @Input()
  contrats: ContratModel[] = [];

  // @Input()
  // selectedContrat: ContratModel | null;
  // @Output()
  // selectedContratChange: EventEmitter<ContratModel | null> = new EventEmitter<ContratModel | null>();

  @Input()
  selectedRestaurant: RestaurantModel | null;
  private _oldSelectedRestaurant: RestaurantModel | null;

  @Input()
  showDebugInfo: boolean = false;

  @Output()
  selectedRestaurantContextChange: EventEmitter<RestaurantContext | null> = new EventEmitter<RestaurantContext | null>();

  internalSelectableGrandComptes: GrandCompte[];
  internalSelectedGrandCompte: GrandCompte | null;
  internalSelectableContrats: ContratModel[];
  internalSelectedContrat: ContratModel | null;
  internalSelectedRestaurantContext: RestaurantContext | null;

  restaurants: RestaurantModel[] = [];

  // private _allRestaurants: Array<RestaurantModel> | null;
  // private get allRestaurants(): Array<RestaurantModel> | null {
  //   if (!this._allRestaurants) {
  //     // generate the whole combination of all restaurants
  //     this._allRestaurants = [];
  //     for (const contrat of this.contrats) {
  //       this._allRestaurants.push(...contrat.restaurants);
  //     }
  //     this._allRestaurants.sort((a, b) => a.name.localeCompare(b.name));
  //   }
  //   return this._allRestaurants;
  // }

  selectedRestaurantTooltip: string = '';
  selectedContratTooltip: string = '';

  constructor(
    // private _appDataService: ApplicationDataService,
    private _interactionService: InteractionService,
    private _cd: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contrats']) {
      // // reset _allRestaurants
      // this._allRestaurants = null;

      if (this.displayGrandCompteSelector) {
        this.loadGrandComptes(this.contrats);
      } else {
        this.loadContrats(this.contrats);
      }
    }
    if (changes['selectedRestaurant']) {
      this.selectRestaurant(this.selectedRestaurant);
    }
  }

  ngOnInit() {
  }

  private loadContrats(contrats: ContratModel[] | null) {
    contrats = contrats || [];
    if (contrats.length > 1 || contrats.length === 0) {
      this.internalSelectableContrats = [
        // Append dummy contrat 'TOUS'
        {
          id: 0,
          libelle: 'TOUS',
          cuisineCentraleId: 0,
          cuisineCentraleCode: '',
          cuisineCentrale: '',
          // grandCompteId?: null;
          // grandCompteLibelle?: null;
          restaurants: [],
          rythme: RythmeContratEnum.None
        }
        , ...contrats];
    } else {
      this.internalSelectableContrats = contrats;
    }

    //check if contrat exists in new list else take first
    const newContrat = (this.internalSelectedContrat && this.internalSelectableContrats.find(c => c.id === this.internalSelectedContrat.id))
      || (this.internalSelectableContrats && this.internalSelectableContrats[0]);

    this.selectContrat(newContrat);
  }

  private loadGrandComptes(contrats: ContratModel[] | null) {
    let grandComptes = uniqBy(contrats || [], 'grandCompteId')
      .filter(c => c.grandCompteId != null)
      .map<GrandCompte>(c => ({ id: c.grandCompteId, libelle: c.grandCompteLibelle }));

    grandComptes.sort((a, b) => a.libelle.localeCompare(b.libelle)); //= sortBy(grandComptes, 'libelle');

    if (grandComptes.length > 1 || grandComptes.length === 0) {
      this.internalSelectableGrandComptes = [
        // Append dummy grandCompte 'TOUS'
        {
          id: 0,
          libelle: 'TOUS'
        }
        , ...grandComptes];
    } else {
      this.internalSelectableGrandComptes = grandComptes;
    }

    const newGrandCompte = (this.internalSelectedGrandCompte && this.internalSelectableGrandComptes.find(gc => gc.id === this.internalSelectedGrandCompte.id))
      || (this.internalSelectableGrandComptes && this.internalSelectableGrandComptes[0]);

    this.selectGrandCompte(newGrandCompte);
  }

  private getRestaurantContrat(restaurant: RestaurantModel | null): ContratModel | null {
    if (!restaurant) {
      return null;
    }
    const cuisineId = restaurant.cuisineCentraleId;
    const contratId = restaurant.contratId;
    const contrat = this.contrats.find(c => c.id === contratId && c.cuisineCentraleId === cuisineId);
    return contrat;
  }

  private selectRestaurant(newSelectedRestaurant: RestaurantModel) {
    if (newSelectedRestaurant !== this._oldSelectedRestaurant) {
      this.internalSelectedRestaurantContext = {
        restaurant: newSelectedRestaurant,
        contrat: this.getRestaurantContrat(newSelectedRestaurant)
      };

      this.selectedRestaurant = newSelectedRestaurant;
      this.selectedRestaurantContextChange.emit(this.internalSelectedRestaurantContext);

      // update internalSelectedContrat
      if (!this.internalSelectedContrat
        || (this.internalSelectedContrat.id != 0 // not 'Tous'
          // different du Contrat actuel du restaurant
          && this.internalSelectedContrat != this.internalSelectedRestaurantContext.contrat)
      ) {
        //this.internalSelectedContrat = this.internalSelectedRestaurantContext.contrat;
        this.selectContrat(this.internalSelectedRestaurantContext.contrat);
      }

      // update tooltips
      const newContrat = this.internalSelectedRestaurantContext.contrat;
      this.selectedContratTooltip = newContrat && `👨‍🍳 Cuisine : ${newContrat.cuisineCentrale} (${newContrat.cuisineCentraleCode})` || '';
      if (this.showDebugInfo) {
        this.selectedContratTooltip += newContrat && ` - id:${newContrat.cuisineCentraleId}` || '';
      }
      this.selectedContratTooltip += newContrat && `\n💼 Contrat : ${newContrat.libelle}` || '';
      if (this.showDebugInfo) {
        this.selectedContratTooltip += newContrat && ` - id:${newContrat.id}` || '';
      }
      if (newContrat.grandCompteId) {
        this.selectedContratTooltip += newContrat && `\n🏢 Grand compte : ${newContrat.grandCompteLibelle}` || '';
        if (this.showDebugInfo) {
          this.selectedContratTooltip += newContrat && ` - id:${newContrat.grandCompteId}` || '';
        }
      }

      const restaurantLabel = this.formatRestaurantLabel(newSelectedRestaurant);
      this.selectedRestaurantTooltip = newSelectedRestaurant && this.selectedContratTooltip + (newSelectedRestaurant && `\n🏠 Restaurant : ${restaurantLabel}` || '');
      if (this.showDebugInfo) {
        this.selectedRestaurantTooltip += newSelectedRestaurant && ` - id:${newSelectedRestaurant.id}` || '';
      }

    }
  }

  private selectContrat(newSelectedContrat: ContratModel) {
    this.internalSelectedContrat = newSelectedContrat;

    if (this.internalSelectedContrat) {
      if (this.internalSelectedContrat.id == 0) {
        const allRestaurants: Array<RestaurantModel> = [];
        for (const contrat of this.internalSelectableContrats) {
          allRestaurants.push(...contrat.restaurants);
        }
        allRestaurants.sort((a, b) => a.name.localeCompare(b.name));
        this.restaurants = allRestaurants;
      } else {
        this.restaurants = this.internalSelectedContrat.restaurants;
      }
    }
    else {
      // selectedContrat is null
      this.restaurants = [];
    }

    //check if restaurant exists in new list else take first
    const newRestaurant = (this.selectedRestaurant && this.restaurants.find(r => r.cuisineCentraleId === this.selectedRestaurant.cuisineCentraleId && r.id === this.selectedRestaurant.id))
      || (this.restaurants && this.restaurants[0]);

    this.selectRestaurant(newRestaurant);
    this._cd.markForCheck();

  }

  private selectGrandCompte(newSelectedGrandCompte: GrandCompte) {
    this.internalSelectedGrandCompte = newSelectedGrandCompte;

    if (this.internalSelectedGrandCompte) {
      if (this.internalSelectedGrandCompte.id == 0) {
        this.loadContrats(this.contrats)
      } else {
        const grandCompteContrats = this.contrats.filter(c => c.grandCompteId === this.internalSelectedGrandCompte.id);
        this.loadContrats(grandCompteContrats)
      }
    }

    this._cd.markForCheck();
  }

  onSelectedGrandCompteChanged(newSelectedGrandCompte: GrandCompte) {
    this.selectGrandCompte(newSelectedGrandCompte);
  }

  onSelectedContratChanged(newSelectedContrat: ContratModel) {
    this.selectContrat(newSelectedContrat);
  }

  onSelectedRestaurantChanged(newSelectedRestaurant: RestaurantModel) {
    this.selectRestaurant(newSelectedRestaurant);
  }

  formatGrandCompteLabel(gc: GrandCompte) {
    if (gc == null) return null;
    return gc.libelle;
  }
  formatRestaurantLabel(entity: any) {
    const restaurant = entity as RestaurantModel;
    if (restaurant == null) return null;

    const code = restaurant.code && restaurant.code.trim();
    const codeSearchString = code.toLowerCase();
    const nameHasAlreadyCode = restaurant.name.toLowerCase().indexOf(codeSearchString) > -1;
    return nameHasAlreadyCode
      ? restaurant.name
      : `${restaurant.name}${code ? (' (' + code + ')') : ''}`
  }

  displayDebugInfo(info: string) {
    this._interactionService.showSimpleMessage('🛈 Informations', info);
  }

}
