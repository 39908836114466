<div class="main-container">
  <div class="ribbon" [style.background-image]="'url(' + contratPicture + ')'">
  </div>
  <div class="buttons-container">
    <div class="float-button" title="Valider" (click)="OpenAjouterBL()">
      <div></div>
    </div>
  </div>
  <div class="page-container">
    <h1>Réceptions</h1>
    <div class="left-side">
      <span class="title">Recherche</span>
      <form class="content-container search-form" (submit)="searchBonsLivraison($event)">
        <label>
          Date de consommation
        </label>
        <div class="sub-container dates-container">
          <app-date-input id="start-date" name="start-date" [(ngModel)]="startDate" (ngModelChange)="askLoadListeBLs()" [calendarInfo]="dateCalendarData" placeholder="Début"></app-date-input>
          <app-date-input id="end-date" name="end-date" [(ngModel)]="endDate" (ngModelChange)="askLoadListeBLs()" [calendarInfo]="dateCalendarData" placeholder="Fin"></app-date-input>
        </div>
        <div class="sub-container buttons-container">
          <!-- <button type="submit" class="generic-button gray-tile-button search-button">Rechercher</button> -->
          <button [restaurantColor]="{'type': currentType, 'background':true}" type="button" class="generic-button plus-button" (click)="OpenAjouterBL()"><span class="plus"></span>Réceptionner</button>
        </div>
      </form>
    </div>
    <div class="results-container right-side">
      <span class="title">Liste des réceptions</span>

      <div class="results-list content-container">
        <div *ngIf="isLoadingBonsLivraison" class="info-container">
          <app-loader message="Chargement..."></app-loader>
        </div>
        <table *ngIf="!isLoadingBonsLivraison">
          <thead *ngIf="listeBonsLivraison && listeBonsLivraison.length != 0">
            <tr>
              <th class="codeBarre">Code</th>
              <th class="dateConsommation">Consommation</th>
              <th class="etatReception">Etat</th>
            </tr>
          </thead>
          <tbody>
            <!-- Affichage sans commandes -->
            <tr *ngIf="!listeBonsLivraison || listeBonsLivraison.length == 0">
              <td colspan="3" class="container-no-result"> Aucune réception trouvée sur cette plage de dates </td>
            </tr>
            <tr class="pointerHover" [restaurantColor]="{'type': currentType, 'background':true, 'hover':true, 'isSecondary': true}" (click)="navigateToDetails(bonLivraison.code)" *ngFor="let bonLivraison of listeBonsLivraison">
              <!-- 1. codeBarre -->
              <td class="codeBarre-container">
                <div>{{ bonLivraison.code }}</div>
              </td>
              <!-- 2. Date de consommation -->
              <td class="dateConsommation-container">
                <div>{{ bonLivraison.dateConsommation | dateFormat }}</div>
              </td>

              <!-- 3. Etat Reception -->
              <td class="etat-container">
                <span class="statut-text-bon-livraison statut-dot statut-{{ BonLivraisonStatutEnum[bonLivraison.statut] }}"> {{ bonLivraison.statut | formatBonLivraisonStatut }} </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
