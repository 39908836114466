
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { PostCommandeRepasApiRequest } from '../../repas/models/api/requests/post-commande-repas.apirequest';
import { FormControlHelper } from '../../shared/helpers/form-control.helper';
import { ErrorApiModel } from '../../shared/models/api/error/error.apimodel';
import { PostCommandeRepasErrorApiResponse } from '../../shared/models/api/error/post-commande-repas-error.apiresponse';
import { PostCommandeRepasWarningApiResponse } from '../../shared/models/api/warning/post-commande-repas-warning.apiresponse';
import { CommandeRepasErrorModel, CommandeRepasErrorNumber } from '../../shared/models/commande-repas-error.model';
import { DailyMenuPrestationModel } from '../../shared/models/daily-menu/daily-menu-prestation.model';
import { FileResultModel } from '../../shared/models/fileresult.model';
import { SessionStorageService } from '../../shared/services/browser-storage.service';
import { ExportBilanRepasRequest } from '../models/api/requests/export-bilan-repas-request.apirequest';
import { ExportEcartsRequest } from '../models/api/requests/export-ecarts-request.apirequest';
import { CommandeRepasModel } from '../models/commande-repas.model';
import { PrestationFormModel } from '../models/prestation-form.model';
import { CommandeRepasProjector } from '../projectors/commande-repas.projector';
import { CommandeRepasApiService } from './api/commande-repas.apiservice';
import { ExportCommandeValoriseeRequest } from '../models/api/requests/export-commande-valorisee-request.apirequest';

const PRESTACLOSED_KEY = 'PRESTACLOSED';

@Injectable()
export class CommandeRepasService {
  lastFamillePlatByEffectifId: { [effectifId: string]: string } = {};
  effectifPortionControls: { [effectifPortionId: string]: FormControl } = {};
  effectifControls: { [effectifId: string]: FormControl; } = {};

  private effectifPortionsFamillePlats: { [key: string]: { effectifPortionControls: FormControl[], famillePlatControl: FormControl } } = {};

  constructor(
    private cmdRepasApiService: CommandeRepasApiService,
    private sessionStorageService: SessionStorageService) { }

  /** Form dictionaries */
  addPortionToDictionary(fcControlList: FormControl[], famillePlatControl: FormControl, effectifId: number, famillePlat: string) {
    this.effectifPortionsFamillePlats[`${effectifId}${famillePlat}`] = { effectifPortionControls: fcControlList, famillePlatControl };
  }

  getEffectifPortionControlsByEffectifAndFamillePlats(effectifId: number, famillePlat: string): { effectifPortionControls: FormControl[], famillePlatControl: FormControl } {
    return this.effectifPortionsFamillePlats[`${effectifId}${famillePlat}`];
  }

  private clearDictionaries() {
    this.effectifControls = {};
    this.effectifPortionControls = {};
    this.effectifPortionsFamillePlats = {};
  }

  /** Api queries */

  getMenu(cuisineId: number, restId: number, date: Date): Observable<DailyMenuPrestationModel[]> {
    return this.cmdRepasApiService.getMenu(cuisineId, restId, date).pipe(
      map(res => {
        return CommandeRepasProjector.projectDailyMenus(res);
      }));
  }

  getCommandeRepas(isPiqueNique: boolean, cuisineId: number, restaurantId: number, conviveId: number = undefined, lieuCommandeId: number = undefined, day: Date = undefined): Observable<CommandeRepasModel> {
    return this.cmdRepasApiService.getCommandeRepas(isPiqueNique, cuisineId, restaurantId, conviveId, lieuCommandeId, day).pipe(
      map(res => {
        this.clearDictionaries();
        return CommandeRepasProjector.projectCmdRepas(res);
      }));
  }

  postCommandeRepas(
    contratId: number,
    restaurantId: number,
    lieuCommandeId: number,
    conviveId: number,
    cuisineCentraleId: number,
    date: Date,
    prestaDataList: PrestationFormModel[]): Observable<PostCommandeRepasWarningApiResponse.Warning> {
    const commandeRepasUpdate: PostCommandeRepasApiRequest.CommandeRepasUpdate = CommandeRepasProjector.projectCmdRepasUpdate(
      contratId,
      restaurantId,
      lieuCommandeId,
      conviveId,
      cuisineCentraleId,
      date,
      prestaDataList);
    return new Observable((obs: Subscriber<PostCommandeRepasWarningApiResponse.Warning>) => {
      this.cmdRepasApiService.postCommandeRepas(commandeRepasUpdate)
        .subscribe(res => {
          obs.next(res);
          obs.complete();
        },
          (error: ErrorApiModel) => {
            const contentError = error.Content as PostCommandeRepasErrorApiResponse.ErrorContent;
            if (contentError != null && contentError.ErrorType != null) {
              const errorProjected = CommandeRepasProjector.projectCmdRepasError(contentError);
              error.Content = errorProjected;
              // handle this specific error in the service because of dictionaries
              if (error.Code != CommandeRepasErrorNumber.InvalidModelState) {
                this.handlePostCommandeRepasErrors(errorProjected);
              }
            }

            obs.error(error);
            obs.complete();
          });
    });
  }

  handlePostCommandeRepasErrors(errContent: CommandeRepasErrorModel) {
    errContent.jours.forEach(
      err => {
        err.portions.forEach(p => {
          const ctrl = this.effectifPortionControls[p.id];
          if (ctrl) {
            FormControlHelper.addServerValidator(ctrl);
          }
        });

        // We aggregate the key effectif Id + plat name here, and we give a boolean 'updateFamillePlat' which indicate
        // that the famillePlat validation should be refreshed too on portion update
        const effectifDico: { [key: string]: { effectifId: number, fpName: string, updateFamillePlat: boolean } } = {};

        err.prestations.forEach(p => {
          if (p.code == CommandeRepasErrorNumber.Regle11) {
            // get the last prestation name of this effectif/prestation
            const prestaName = this.lastFamillePlatByEffectifId[p.id];
            if (prestaName) {
              effectifDico[p.id + prestaName] = { effectifId: p.id, fpName: prestaName, updateFamillePlat: true };
            }
          }
        });

        err.famillePlats.forEach(fp => {
          if (fp.code == CommandeRepasErrorNumber.Regle9
            || fp.code == CommandeRepasErrorNumber.Regle10
            || fp.code == CommandeRepasErrorNumber.Regle12) {
            if (!effectifDico[fp.effectifId + fp.name]) {
              effectifDico[fp.effectifId + fp.name] = { effectifId: fp.effectifId, fpName: fp.name, updateFamillePlat: false };
            }
          }
        });

        for (const key in effectifDico) {
          const item = effectifDico[key];
          const ctrls = this.getEffectifPortionControlsByEffectifAndFamillePlats(item.effectifId, item.fpName);
          if (item.updateFamillePlat) {
            FormControlHelper.addFamillePlatServerValidator(key, ctrls.effectifPortionControls, ctrls.famillePlatControl);
          } else {
            FormControlHelper.addFamillePlatServerValidator(key, ctrls.effectifPortionControls);

          }
        }
      });
  }

  exportEcarts(cuisineCentraleId?: number, grandCompteId?:number, contratId?: number, date?: Date): Observable<FileResultModel> {

    const request: ExportEcartsRequest = {
      cuisineCentraleId,
      grandCompteId,
      contratId,
      date,
    };

    return this.cmdRepasApiService.exportEcarts(request);
  }

  exportBilanRepas(grandCompteId?: number, cuisineCentraleId?: number, contratId?: number, dateDebut?: Date, dateFin?: Date, isPiqueNique?: boolean): Observable<FileResultModel> {

    const request: ExportBilanRepasRequest = {
      grandCompteId,
      cuisineCentraleId,
      contratId,
      dateDebut,
      dateFin,
      isPiqueNique
    };

    return this.cmdRepasApiService.exportBilanRepas(request);
  }

  exportCommandeValorisee(grandCompteId?: number, cuisineCentraleId?: number, contratId?: number, dateDebut?: Date, dateFin?: Date, isPiqueNique?: boolean): Observable<FileResultModel> {

    const request: ExportCommandeValoriseeRequest = {
      grandCompteId,
      cuisineCentraleId,
      contratId,
      dateDebut,
      dateFin,
      isPiqueNique
    };

    return this.cmdRepasApiService.exportCommandeValorisee(request);
  }

  /** Toggle Handle */
  handleToggle(prestaId: number, shouldCollapse: boolean) {
    if (shouldCollapse) {
      this.sessionStorageService.addToList(PRESTACLOSED_KEY, prestaId);
    } else {
      this.sessionStorageService.deleteFromList(PRESTACLOSED_KEY, prestaId);
    }
  }

  getPrestationsToCollapse(): number[] {
    return this.sessionStorageService.getObject<number[]>(PRESTACLOSED_KEY);
  }
}
