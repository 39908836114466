import { Component, ComponentFactoryResolver, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { NotificationCustomModel } from '../shared/models/notification.model';
import { ApplicationDataService } from '../shared/services/application-data.service';

@Component({
  selector: 'app-popup-custom',
  templateUrl: './popup-custom.component.html',
  styleUrls: ['./popup-custom.component.scss']
})
export class PopupCustomComponent implements OnInit, OnDestroy {
  @HostBinding('class.visible')
  public showPopup: boolean;

  public withButton: boolean = false;

  public currentType: RestaurantTypeEnum;

  public currentNotification: NotificationCustomModel;

  constructor(private appDataService: ApplicationDataService, private componentFactoryResolver: ComponentFactoryResolver) {

  }

  ngOnInit() {
    this.appDataService.restaurantType$
    .pipe(untilDestroyed(this))
    .subscribe(type => {
      this.currentType = type;
    });
  }

  show(notif: NotificationCustomModel) {
    this.currentNotification = notif;
    this.showPopup = true;
  }

  hide() {
    this.showPopup = false;
    if (this.currentNotification) {
      this.currentNotification.onClose();
      this.currentNotification = null;
    }
  }


  ngOnDestroy() {

  }

}
