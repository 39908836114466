
import { observeOn, filter, distinctUntilChanged } from 'rxjs/operators';
import { GoogleAnalyticsProvider } from './providers/google-analytics-provider';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsDimensions } from './models/analytics-dimensions.model';
import { AppInsightsAnalyticsProvider } from './providers/appinsights-analytics-provider';
import { ReplaySubject, Scheduler, asapScheduler } from 'rxjs';
import { Location } from '@angular/common';

@Injectable()
export class AnalyticsService {
  private dimensions: AnalyticsDimensions = {};

  // private subjects
  private setUserIdSubject: ReplaySubject<string> = new ReplaySubject(10);
  private setCorrelationIdSubject: ReplaySubject<string> = new ReplaySubject(10);

  private pageTrackSubject: ReplaySubject<{ path: string, dimensions: AnalyticsDimensions }> = new ReplaySubject(10);
  private eventTrackSubject: ReplaySubject<{ action: string, properties: any, dimensions: AnalyticsDimensions }> = new ReplaySubject(10);

  // Exposed subscribable events
  public pageTrack$ = this.pageTrackSubject.asObservable().pipe(distinctUntilChanged(this.deepCompare));
  public eventTrack$ = this.eventTrackSubject.asObservable().pipe(distinctUntilChanged(this.deepCompare));
  public setUserId$ = this.setUserIdSubject.asObservable().pipe(distinctUntilChanged());
  public setCorrelationId$ = this.setCorrelationIdSubject.asObservable().pipe(distinctUntilChanged());

  constructor(
    private location: Location,
    private router: Router,
    private appInsights: AppInsightsAnalyticsProvider,
    private google: GoogleAnalyticsProvider) {

    // On link les childrens aux events (méthode générique, il suffit de fournir l'instance courante du service)
    this.appInsights.subscribeToMainService(this);
    this.google.subscribeToMainService(this);

    // On s'abonne au router pour les pageView
    this.subscribeToRouter();
  }


  /** Public methods */

  public eventTrack(action: string, properties: any, trackUrlChange: boolean = false) {
    this.eventTrackSubject.next({
      action: action,
      properties: properties,
      dimensions: this.dimensions
    });

    if (trackUrlChange) {
      this.trackUrlChange(this.location.path());
    }
  }

  public setUserId(userId: string) {
    this.dimensions.userId = userId;
    this.setUserIdSubject.next(userId);
  }

  public setCorrelationId(correlationId: string) {
    this.dimensions.correlationId = correlationId;
    this.setCorrelationIdSubject.next(correlationId);
  }

  public setDimensionProperties(dimensionValues: any) {
    if (dimensionValues != null) {
      for (const dimensionProp in dimensionValues) {
        this.dimensions[dimensionProp] = dimensionValues[dimensionProp];
      }
    }
  }
  clearDimensionProperties() {
    this.dimensions = {};
  }

  private subscribeToRouter() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      observeOn(asapScheduler)
    )
      .subscribe((event: NavigationEnd) => {
        this.trackUrlChange(event.urlAfterRedirects);
      });
  }

  private trackUrlChange(url: string) {
    this.pageTrackSubject.next(
      {
        path: this.location.prepareExternalUrl(url),
        dimensions: this.dimensions
      });
  }


  private deepCompare(object: any, base: any): boolean {
    // cheap deep-comparison. see:https://stackoverflow.com/a/44446930/590741
    return JSON.stringify(object) === JSON.stringify(base);
  }

}
