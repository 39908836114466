function getHashCode(text:string) {
    var hash = 0;
    if (text.length == 0) return hash;
    for (var i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
};

function intToHSL(val : number) {
    var shortened = val % 360;
    return "hsl(" + shortened + ",40%,60%)";
};

export function stringToColor(text : string){
    return intToHSL(getHashCode(text));
}