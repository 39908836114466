/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./drawer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../restaurant-selector/restaurant-selector.component.ngfactory";
import * as i3 from "../restaurant-selector/restaurant-selector.component";
import * as i4 from "../shared/services/interaction.service";
import * as i5 from "../menu-item/menu-item.component.ngfactory";
import * as i6 from "../menu-item/menu-item.component";
import * as i7 from "@angular/router";
import * as i8 from "../shared/services/log.service";
import * as i9 from "@angular/common";
import * as i10 from "../user-info/user-info.component.ngfactory";
import * as i11 from "../user-info/user-info.component";
import * as i12 from "../shared/services/authentication/authentication.service";
import * as i13 from "../hamburger/hamburger.component.ngfactory";
import * as i14 from "../hamburger/hamburger.component";
import * as i15 from "../shared/directives/restaurant-type-color.directive";
import * as i16 from "./drawer.component";
import * as i17 from "../shared/services/application-data.service";
var styles_DrawerComponent = [i0.styles];
var RenderType_DrawerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DrawerComponent, data: {} });
export { RenderType_DrawerComponent as RenderType_DrawerComponent };
function View_DrawerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dropdown-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-restaurant-selector", [], null, [[null, "selectedRestaurantContextChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedRestaurantContextChange" === en)) {
        var pd_0 = (_co.selectedRestaurantContextChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RestaurantSelectorComponent_0, i2.RenderType_RestaurantSelectorComponent)), i1.ɵdid(2, 638976, null, 0, i3.RestaurantSelectorComponent, [i4.InteractionService, i1.ChangeDetectorRef], { displayGrandCompteSelector: [0, "displayGrandCompteSelector"], contrats: [1, "contrats"], selectedRestaurant: [2, "selectedRestaurant"], showDebugInfo: [3, "showDebugInfo"] }, { selectedRestaurantContextChange: "selectedRestaurantContextChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayGrandCompteSelector; var currVal_1 = _co.contrats; var currVal_2 = _co.restaurant; var currVal_3 = _co.showDebugInfo; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_DrawerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-item", [["class", "pointerHover"]], null, [[null, "onNavigated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onNavigated" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MenuItemComponent_0, i5.RenderType_MenuItemComponent)), i1.ɵdid(1, 114688, null, 0, i6.MenuItemComponent, [i7.Router, i8.LogService], { item: [0, "item"], restaurantType: [1, "restaurantType"] }, { onNavigated: "onNavigated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.currentType; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DrawerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "contact pointerHover"], ["src", "assets/images/mobile-circle.png"], ["title", "Contacts"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToContact() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_DrawerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "container"]], null, null, null, null, null)), i1.ɵprd(512, null, i9.ɵNgClassImpl, i9.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i9.NgClass, [i9.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "visible": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["id", "behind-layout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 17, "nav", [["id", "drawer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-user-info", [], null, null, null, i10.View_UserInfoComponent_0, i10.RenderType_UserInfoComponent)), i1.ɵdid(7, 49152, null, 0, i11.UserInfoComponent, [i7.Router, i12.AuthenticationService], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-hamburger", [["class", "pointerHover"], ["color", "#000"], ["isRight", "true"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_HamburgerComponent_0, i13.RenderType_HamburgerComponent)), i1.ɵdid(9, 114688, null, 0, i14.HamburgerComponent, [], { isRight: [0, "isRight"], color: [1, "color"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "clear"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DrawerComponent_1)), i1.ɵdid(12, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "menu-item-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DrawerComponent_2)), i1.ɵdid(15, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 5, "div", [["class", "company-bar color-animation"]], null, null, null, null, null)), i1.ɵdid(17, 16384, null, 0, i15.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpod(18, { "type": 0, "background": 1 }), (_l()(), i1.ɵeld(19, 0, null, null, 0, "img", [["class", "brand"], ["src", "/assets/images/brand-logo.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DrawerComponent_3)), i1.ɵdid(21, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 0, "div", [["class", "contrat-bar"]], [[4, "background-image", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "container"; var currVal_1 = _ck(_v, 3, 0, _co.showDrawer); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "true"; var currVal_3 = "#000"; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = _co.showDrawer; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.menuList; _ck(_v, 15, 0, currVal_5); var currVal_6 = _ck(_v, 18, 0, _co.currentType, true); _ck(_v, 17, 0, currVal_6); var currVal_7 = _co.hasAccessToContact; _ck(_v, 21, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_8 = (("url(" + _co.logoContratUrl) + ")"); _ck(_v, 22, 0, currVal_8); }); }
export function View_DrawerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-drawer", [], [[2, "visible", null]], null, null, View_DrawerComponent_0, RenderType_DrawerComponent)), i1.ɵdid(1, 245760, null, 0, i16.DrawerComponent, [i17.ApplicationDataService, i12.AuthenticationService, i7.Router, i9.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).showDrawer; _ck(_v, 0, 0, currVal_0); }); }
var DrawerComponentNgFactory = i1.ɵccf("app-drawer", i16.DrawerComponent, View_DrawerComponent_Host_0, { contrats: "contrats", restaurant: "restaurant", showDebugInfo: "showDebugInfo", menuList: "menuList", logoContratUrl: "logoContratUrl" }, { selectedRestaurantContextChange: "selectedRestaurantContextChange" }, []);
export { DrawerComponentNgFactory as DrawerComponentNgFactory };
