import { Injectable } from '@angular/core';
import { ApiSettings } from 'src/app/shared/models/api/api-settings';
import { LogService } from 'src/app/shared/services/log.service';
import { AlerteModel } from '../models/alertes.model';
import { HttpClient } from '@angular/common/http';

const CONTROLLER_URL = 'alerte';

@Injectable({
  providedIn: 'root'
})
export class AlertesApiService {

  constructor(private _apiSettings: ApiSettings,
              private _http: HttpClient,
              private _logService: LogService) {

  }

  getRestaurantAlertes(cuisineCentraleId: number, restaurantId: number) {
    const url = `${this._apiSettings.apiUrl}/alertes`;
    return this._http.get<AlerteModel[]>(url,
      {
        params: {
          cuisineCentraleId: cuisineCentraleId.toString(),
          restaurantId: restaurantId.toString()
        }
      });
  }

}
