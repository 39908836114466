import { Component, OnInit, Input } from '@angular/core';
import { BonLivraisonModel, BonLivraisonStatut, ReceptionStatut, MiseEnConformiteStatut, LivraisonStatut } from '../models/bon-livraison.model';

@Component({
  selector: 'app-bon-livraison-info-header',
  templateUrl: './bon-livraison-info-header.component.html',
  styleUrls: ['./bon-livraison-info-header.component.scss']
})
export class BonLivraisonInfoHeaderComponent implements OnInit {

  @Input()
  public bonLivraison: BonLivraisonModel;

  public BonLivraisonStatutEnum: typeof BonLivraisonStatut = BonLivraisonStatut; // declare enum property to allow use in component template. see: http://stackoverflow.com/a/40034109/590741
  public LivraisonStatutEnum: typeof LivraisonStatut = LivraisonStatut; // declare enum property to allow use in component template. see: http://stackoverflow.com/a/40034109/590741
  public ReceptionStatutEnum: typeof ReceptionStatut = ReceptionStatut; // declare enum property to allow use in component template. see: http://stackoverflow.com/a/40034109/590741
  public MiseEnConformiteStatutEnum: typeof MiseEnConformiteStatut = MiseEnConformiteStatut; // declare enum property to allow use in component template. see: http://stackoverflow.com/a/40034109/590741

  public isInfoBonCollapsed: boolean = true;

  public get notifyDateFinReceptionExpired() {
    return this.bonLivraison
      && this.bonLivraison.dateFinReceptionExpired
      && !this.bonLivraison.dateClotureExpired;
  }

  public get notifyDateClotureExpired() {
    // on met en évidence la Clotûre lorsque le bon a été cloturé automatiquement (statut ConformeCloture)
    return this.bonLivraison
      && this.bonLivraison.dateClotureExpired
      && (this.bonLivraison.statut === BonLivraisonStatut.AReceptionnerCloture
        || this.bonLivraison.statut === BonLivraisonStatut.ManqueCloture);
  }

  constructor() { }

  ngOnInit() {
  }


  toggleInfoBon() {
    this.isInfoBonCollapsed = !this.isInfoBonCollapsed;
  }

}
