import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewEncapsulation, SimpleChanges, SimpleChange } from '@angular/core';
import { EnqueteModel } from '../../models/enquete.model';
import { ReceivedEnqueteModel } from '../../models/received-enquete.model';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { RatingModel } from '../rating/rating.model';
import { InteractionService } from 'src/app/shared/services/interaction.service';

@Component({
  selector: 'app-enquete',
  templateUrl: './enquete.component.html',
  styleUrls: ['./enquete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnqueteComponent implements OnChanges {

  @Input()
  public enquete: EnqueteModel;

  @Input()
  public showTitle: boolean = true;

  @Input()
  public receivedEnquete: ReceivedEnqueteModel;

  @Output()
  public enqueteDismissed: EventEmitter<ReceivedEnqueteModel> = new EventEmitter<ReceivedEnqueteModel>();

  public restaurantType$ = this._appDataService.restaurantType$;

  public note?: number;
  public commentaire?: string;

  public get safeMessage(): SafeHtml {
    return this.enquete && this._domSanitizer.bypassSecurityTrustHtml(this.enquete.question);
  }

  public get commentaireRequired(): boolean {
    return this.note && (this.note <= this.enquete.seuilCommentaireRequis);
  }

  constructor(
    private _domSanitizer: DomSanitizer,
    private _appDataService: ApplicationDataService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // tslint:disable-next-line: no-string-literal
    const change = changes['receivedEnquete'];
    if (change && change.currentValue) {
      const receivedEnquete = change.currentValue as ReceivedEnqueteModel;
      this.note = receivedEnquete.note;
      this.commentaire = receivedEnquete.commentaire;
    }
  }

  repondreEnquete() {
    if (this.note) {
      this.receivedEnquete.note = this.note;
      this.receivedEnquete.commentaire = this.commentaire;
    }
    this.enqueteDismissed.next(this.receivedEnquete);
  }

  ratingComponentClick(rating: RatingModel) {
    this.note = rating.rating;
  }
}
