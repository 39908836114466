import { Component, OnInit, HostBinding } from "@angular/core";
declare var SVGLoader: any;
declare var mina: any;

// TODO : Revoir les animations (utiliser les observables) --> le cercle ne convient pas donc en QUICK FIX on a mis des durées très courtes
const ANIMATION_DURATION_MS: number = 1;
const KEEP_OPEN_MIN_MS: number = 0;
// Sans un délai supplémentaire, le script n'arrive pas à le réouvrir de suite
const SAFE_LOADER_RELAUNCH_MS: number = 100;

@Component({
  selector: "app-loading-overlay",
  templateUrl: "./loading-overlay.component.html",
  styleUrls: ["./loading-overlay.component.scss"]
})
export class LoadingOverlayComponent {

  @HostBinding("class.show")
  public isDisplay: boolean = false;
  public customMessage: string;

  constructor() { }

  public open(message: string) {
    this.customMessage = message;
    this.isDisplay = true;
  }

  public hide() {
    this.isDisplay = false;
  }

}
