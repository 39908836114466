import { DocumentModel, DocumentationTypeEnum } from './models/documentheque.model';
import { DocumenthequeApiResponse } from './models/api/documentheque.apiresponse';

const documentDateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

export class DocumenthequeProjector {

  public static projectDocuments(documentsResponse: DocumenthequeApiResponse.Documentation[]): DocumentModel[] {
    return documentsResponse.map(documentResp => {

      const createdAt = new Date(documentResp.createdAt);
      let document: DocumentModel = {
        nom: documentResp.nom,
        fileName: documentResp.fileName != null ? documentResp.fileName : documentResp.nom + documentResp.extension,
        description: documentResp.description,
        documentationId: documentResp.documentationId,
        createdAt: createdAt,
        createdAtString: createdAt.toLocaleDateString(undefined, documentDateOptions),
        isDownloading: false,
        type: this.projectDocumentType(documentResp.type)
      };

      return document;
    });
  }

  private static projectDocumentType(docType: string): DocumentationTypeEnum {
    return DocumentationTypeEnum[docType];
  }

}
