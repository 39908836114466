import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { NotificationConfirmModel, NotificationCustomModel, NotificationModel, NotificationModelBase } from '../models/notification.model';
import { LogService } from './log.service';

@Injectable()
export class InteractionService {
  public toggleEmitter: EventEmitter<{ toggle: boolean, message?: string }> = new EventEmitter<{ toggle: boolean, message?: string }>();


  private blockCount: number = 0;
  private notificationSubject = new BehaviorSubject<NotificationModelBase>(undefined);

  /** asObservable pour cacher le notificationSubject (on ne peut que subscribe dessus publiquement) */
  public notification$ = this.notificationSubject.asObservable();

  constructor(private logService: LogService) {
  }

  public showNotification(notif: NotificationModel | NotificationCustomModel | NotificationConfirmModel) {
    this.logService.Debug('showNotification requested : ' + JSON.stringify(notif), 'InteractionService');
    this.notificationSubject.next(notif);
  }

  public showSimpleMessage(title: string, message: string) {
    const notifModel = new NotificationModel();
    notifModel.title = title;
    notifModel.message = message;

    this.showNotification(notifModel);
  }

  /** Renvoie un boolean qui définit si on peut effectuer une action de navigation (navigate ou changement de semaine,convive (cmdRepas),etc...)
   *  Si la page en cours est "dirty" -> Ouvre une popup et renvoie true/false en fonction de la réponse de l'utilisateur
   *  Si la page n'est pas dirty -> Renvoie true directement
  */
  public showConfirmNavigationDialog(): Observable<boolean> {
    return new Observable((subscriber: Subscriber<boolean>) => {
      const notifModel = new NotificationConfirmModel();
      notifModel.title = 'Alerte';
      notifModel.message = 'Êtes vous sur de vouloir effectuer cette action ? Toutes les données non sauvegardées seront perdues.';
      notifModel.onOk = () => {
        subscriber.next(true);
        subscriber.complete();
      };
      notifModel.onCancel = () => {
        subscriber.next(false);
        subscriber.complete();
      };

      this.showNotification(notifModel);
    });
  }

  public showConfirmDialog(message: string, title: string = ''): Observable<boolean> {
    return new Observable((subscriber: Subscriber<boolean>) => {
      const notifModel = new NotificationConfirmModel();
      notifModel.title = title;
      notifModel.message = message;
      notifModel.onOk = () => {
        subscriber.next(true);
        subscriber.complete();
      };
      notifModel.onCancel = () => {
        subscriber.next(false);
        subscriber.complete();
      };

      this.showNotification(notifModel);
    });
  }

  /** Generic Loader */
  public blockUI(message: string) {
    this.blockCount++;
    // On a déja affiché l'overlay
    if (this.blockCount > 1) {
      return;
    }
    this.toggleEmitter.next({ toggle: true, message });
  }

  public releaseUI() {
    // L'overlay n'est pas affiché
    if (this.blockCount === 0) {
      return;
    }

    this.blockCount--;
    // On ferme seulement si le blockCount == 0 après décrémentation
    if (this.blockCount === 0) {
      this.toggleEmitter.next({ toggle: false });
    }
  }


}
