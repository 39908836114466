import { Component, OnInit, HostListener, Input, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AlertesService } from '../../services/alertes.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { NotificationsModel } from '../../models/notifications.model';

@Component({
  selector: 'app-alertes-tile',
  templateUrl: './alertes-tile.component.html',
  styleUrls: ['./alertes-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertesTileComponent implements OnInit {

  notifications$ = this._alertesService.notifications$;

  @Input()
  hideIfNoAlerte?: boolean;

  constructor(
    private _alertesService: AlertesService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  showNotifications(notifications: NotificationsModel) {
    if (notifications) {
      this._alertesService.showNotifications(notifications)
        .subscribe(() => {
          this._changeDetectorRef.markForCheck();
        });
    }
  }

}
