<div class="main-container">
  <div class="loader-container" *ngIf="isLoading">
    <app-loader message="Chargement..."></app-loader>
  </div>
  <form (ngSubmit)="searchBonLivraison()" style="text-align: center;" ngNativeValidate>
    <h4>Veuillez scanner ou saisir le code-barre du bon de livraison :</h4>
    <div #barcodeScanner class="scanner"></div>
    <div class="input-section">
      <span class="code-cuisine">{{ data.cuisineCode }}</span>
      <span class="union-barre">-</span>
      <input type="text" placeholder="Code Barre" [(ngModel)]="codeBarre" name="codeBarre" class="input-code-barre" #inputCodeBarre required autocomplete="off">
    </div>
    <div class="button-section">
      <button class="generic-button btn-large" type="submit" [restaurantColor]="{'type': data.currentType, 'background':true}">Rechercher</button>
    </div>
  </form>

</div>
