
import { of as observableOf, from, concat, interval } from 'rxjs';
import { map, filter, first, switchMap } from 'rxjs/operators';

import { Location, DOCUMENT, PlatformLocation } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, ViewChild, ViewEncapsulation, Inject, ApplicationRef, HostBinding, Input, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { DrawerComponent } from './drawer/drawer.component';
import { PopupComponent } from './popup/popup.component';
import { PopupCustomComponent } from './popup-custom/popup-custom.component';
import { PopupConfirmComponent } from './popup-confirm/popup-confirm.component';

import { ApplicationDataService } from './shared/services/application-data.service';
import { SessionStorageService } from './shared/services/browser-storage.service';
import { LogService } from './shared/services/log.service';
import { LogoRouteService } from './shared/services/api/logo/logo-route.service';
import { CorrelationTokenService } from './shared/services/correlationToken.service';
import { AnalyticsService } from './analytics/analytics-service';
import { NavigationService } from './shared/services/navigation.service';
import { InteractionService } from './shared/services/interaction.service';

import { UserInfoModel } from './shared/models/user-info.model';
import { ContratModel } from './shared/models/contrat.model';
import { RestaurantModel } from './shared/models/restaurant.model';
import { MenuLinkModel } from './shared/models/menu-link.model';
import { NotificationConfirmModel, NotificationCustomModel, NotificationModel } from './shared/models/notification.model';
import { OdgEvaluationModel } from './odg/models/odg.model';
import { RestaurantTypeEnum } from './shared/enums/restaurant-type.enum';
import { SwUpdate } from '@angular/service-worker';
import { RestaurantTypeHelper } from './shared/helpers/restaurant-type.helper';
import { AuthenticationService } from './shared/services/authentication/authentication.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { RestaurantContext } from './restaurant-selector/model/restaurant-context.model';

const { version: appVersion } = require('../../package.json');

const SelectedRestaurantIdStorageKey = 'SelectedRestaurantId';
const SelectedContratKeyStorageKey = 'SelectedContratKey';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(DrawerComponent, { static: false })
  private drawerComponent: DrawerComponent;
  @ViewChild(PopupComponent, { static: true })
  private popupComponent: PopupComponent;
  @ViewChild(PopupCustomComponent, { static: true })
  private popupCustomComponent: PopupCustomComponent;
  @ViewChild(PopupConfirmComponent, { static: true })
  private popupConfirmComponent: PopupConfirmComponent;

  @ViewChild('mainLoadingOverlay', { static: true })
  private loaderOverlayComponent: LoadingOverlayComponent;

  @Input() class: string; // override the standard class attr with a new one. see: https://github.com/angular/angular/issues/7289#issuecomment-390415535
  @HostBinding('class')
  get hostClasses(): string {
    return [this.class, RestaurantTypeHelper.getThemeClass(this.currentType) || ''].join(' ');
  }

  menuList: MenuLinkModel[];
  headerMenuList: MenuLinkModel[];

  contrats: ContratModel[];
  currentRestaurant: RestaurantModel;
  currentContrat: ContratModel;

  isUserLogged: boolean = false;
  isUserAdmin: boolean = false;
  isLoginPage: boolean = false;
  logoContratUrl: string = '';
  badOdgEvaluation: OdgEvaluationModel;
  currentType: RestaurantTypeEnum;

  constructor(
    private appDataService: ApplicationDataService,
    private logService: LogService,
    private authService: AuthenticationService,
    private location: Location,
    @Inject(DOCUMENT) private document: Document,
    // private domAdapter: ɵDomAdapter,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private logoService: LogoRouteService,
    private navigationService: NavigationService,
    private interactionService: InteractionService,
    private correlationTokenService: CorrelationTokenService,
    private analytics: AnalyticsService,
    private sessionStorageService: SessionStorageService,
    private swUpdate: SwUpdate,
    private platformLocation: PlatformLocation,
    private changeDetectorRef: ChangeDetectorRef,
    private appRef: ApplicationRef,
  ) {
    router.events.subscribe(event => {
      // make this in a better way ?
      if (event instanceof NavigationEnd) {
        this.isLoginPage = event.url === '/login';
      }
    });

    // update page title automaticaly. see: https://toddmotto.com/dynamic-page-titles-angular-2-router-events
    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      switchMap((route: ActivatedRoute) => route.data)
    )
      .subscribe(routeData => {
        this.appDataService.changePageTitle(routeData.title);
      });

    /* FORCE APP UPDATE via service worker */
    // on recherche régulièrement s'il y a une mise à jour disponible (important pour les tablettes où l'app n'est jamais rechargée)
    // https://angular.io/guide/service-worker-communications#available-and-activated-updates
    // also https://medium.com/@arjenbrandenburgh/angulars-pwa-swpush-and-swupdate-15a7e5c154ac
    if (swUpdate.isEnabled) {

      swUpdate.available
        .pipe(
          switchMap((updateEvent) => from(this.swUpdate.activateUpdate())),
          switchMap(() => this.interactionService.showConfirmDialog('Une mise à jour de l\'application est disponible, l\'application va redémarrer.', 'Mise à jour disponible')),
          first(confirmation => confirmation === true)
        )
        .subscribe(() => {
          // document.location.href = (this.platformLocation as any).location.origin;
          document.location.reload();
        });

      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
      const everyHours$ = interval(1 * 60 * 60 * 1000);
      const everyHoursOnceAppIsStable$ = concat(appIsStable$, everyHours$);
      everyHoursOnceAppIsStable$.subscribe(() => swUpdate.checkForUpdate());

    }

  }

  ngOnDestroy(): void {
    // mandatory for untildestroyed
  }

  ngOnInit() {
    // define correlationId to LogService instead of direct dependency on CorrelationService to prevent dependency loop
    this.logService.correlationId = this.correlationTokenService.correlationId;
    this.analytics.setCorrelationId(this.correlationTokenService.correlationId);
    this.analytics.setDimensionProperties({
      'client-version': appVersion,
      host: this.document.location.hostname
    });

    // The app component subscribes once to the events to notify the layout-header and the drawer (to avoid doing the same job in both components)

    this.interactionService.notification$.subscribe(notif => {
      if (notif) {
        if (notif instanceof NotificationModel) {
          this.showNewNotification(notif);
        } else if (notif instanceof NotificationCustomModel) {
          this.showNewCustomNotification(notif);
        } else if (notif instanceof NotificationConfirmModel) {
          this.showNewConfirmNotification(notif);
        } else {
          throw new Error('Bad notification type');
        }
      }
    });

    this.authService.currentUser$
      .pipe(untilDestroyed(this))
      .subscribe((user: UserInfoModel) => {
        this.analytics.setUserId(user && user.id || null);
        this.isUserLogged = user != null;
        if (this.isUserLogged) {
          this.handleContrats(user);
        }
        this.isUserAdmin = user && user.isAdmin;
      });

    this.appDataService.contrat$
      .pipe(untilDestroyed(this))
      .subscribe(contrat => {
        if (contrat) {
          this.currentContrat = contrat;
          this.analytics.setDimensionProperties({
            cuisine: contrat && contrat.cuisineCentrale,
            contrat: contrat && contrat.libelle,
            grandCompte: contrat && contrat.grandCompteLibelle,
            restaurant: contrat && contrat.restaurants[0] ? contrat.restaurants[0].name : null
          });
          this.analytics.eventTrack('Changement contrat', { contrat: contrat && contrat.libelle }); // pas besoin de faire un pageTrack, il est déclanché sur le changement de restaurant qui vient ensuite
          this.logoContratUrl = contrat && this.logoService.GetLogoUrl(contrat.id, contrat.cuisineCentraleId);
          this.handleRestaurants(contrat);
        }
      });

    this.appDataService.restaurant$
      .pipe(untilDestroyed(this))
      .subscribe(restaurant => {
        this.currentRestaurant = restaurant;
        if (restaurant) {
          this.currentType = restaurant.type;
        }

        // on persite le contrat & restaurant sélectionné
        this.sessionStorageService.setString(SelectedRestaurantIdStorageKey, restaurant && restaurant.id.toString());
        this.sessionStorageService.setString(SelectedContratKeyStorageKey, restaurant && this.getContratKey(restaurant.cuisineCentraleId, restaurant.contratId));

        if (restaurant) {
          this.analytics.setDimensionProperties({ restaurant: restaurant.name });
          this.analytics.eventTrack('Changement restaurant', { restaurant: restaurant.name }, true); // déclenchement d"un pageTrack explicite
        }
      });

    this.appDataService.showBadEval.subscribe(badEval => {
      this.badOdgEvaluation = badEval;
    });

    this.interactionService.toggleEmitter.subscribe((toggleData: { toggle: boolean; message?: string }) => {
      toggleData.toggle ? this.loaderOverlayComponent.open(toggleData.message) : this.loaderOverlayComponent.hide();
    });

    // Menus
    this.authService.getAvailableMenuLinks()
      .pipe(untilDestroyed(this))
      .subscribe(menuList => {
        this.menuList = menuList;
        this.headerMenuList = this.filterMenuForHeader(menuList);
      });

    this.hideCordovaSplashScreen();
  }

  private hideCordovaSplashScreen() {
    if (!window.cordova) {
      // console.log("hideCordovaSplashScreen : cordova not loaded, hook to deviceready event")
      const hideSplash = this.hideCordovaSplashScreen.bind(this);
      window.document.addEventListener('deviceready', () => setTimeout(hideSplash, 10), false);

      return; // exit
    }

    // console.log("hideCordovaSplashScreen : cordova loaded => close SplashScreen");
    if (window.navigator.splashscreen) {
      window.navigator.splashscreen.hide();
    } else {
      this.logService.getLogger('AppComponent').warn('window.navigator.splashscreen not found');
    }

    // launch changeDection for components depedent on window.cordova
    this.changeDetectorRef.detectChanges();
  }

  onBadEvaluationClose() {
    this.badOdgEvaluation = null;
  }

  private handleRestaurants(contrat: ContratModel) {
    // get restaurants
    const restaurants = contrat && contrat.restaurants;
    // on essaye de restaurer le restaurant précédemment sélectionné
    const selectedRestaurantId = this.sessionStorageService.getString(SelectedRestaurantIdStorageKey);
    this.currentRestaurant = (selectedRestaurantId && restaurants && restaurants.find(r => r.id.toString() === selectedRestaurantId)) || (restaurants && restaurants[0]);
    // Si on arrive là, c'est que le contrat a pu être modifié (donc plus besoin de check le dirty)
    this.appDataService
      .changeRestaurant(this.currentRestaurant, true)
      .pipe(first())
      .subscribe();
  }

  private getContratKey(cuisineCentraleId: number, contratId: number): string {
    return `${cuisineCentraleId}-${contratId}`;
  }
  private handleContrats(user: UserInfoModel) {
    // get contrats
    this.contrats = user.allContrats;
    this.contrats.sort((a, b) => a.libelle.localeCompare(b.libelle));
    // on essaye de restaurer le contrat précédemment sélectionné
    const selectedContratKey = this.sessionStorageService.getString(SelectedContratKeyStorageKey);
    this.currentContrat = (selectedContratKey && this.contrats.find(c => this.getContratKey(c.cuisineCentraleId, c.id) === selectedContratKey)) || this.contrats[0];

    // dont need a callback on this one
    this.handleRestaurants(this.currentContrat);
    this.appDataService
      .changeContrat(this.currentContrat)
      .pipe(first())
      .subscribe();
  }

  selectedRestaurantContextChanged(selectedRestaurantContext: RestaurantContext) {
    // On essaye de changer le contrat, si l'utilisateur refuse (ou erreur) on remet le contrat d'avant
    const oldContrat = this.currentContrat;
    this.currentContrat = selectedRestaurantContext.contrat;
    this.appDataService.changeContrat(selectedRestaurantContext.contrat).subscribe(canExecute => {
      if (!canExecute) {
        // L'utilisateur a refusé, on remet le contrat précédent
        this.currentContrat = oldContrat;
      } else {
        const oldRestaurant = this.currentRestaurant;
        this.currentRestaurant = selectedRestaurantContext.restaurant;

        this.appDataService.changeRestaurant(selectedRestaurantContext.restaurant).subscribe(canExecute => {
          if (!canExecute) {
            // L'utilisateur a refusé, on remet le restaurant précédent
            this.currentRestaurant = oldRestaurant;
          }
        },
          err => {
            // Une erreur est arrivée : on remet le restaurant précédent
            this.currentRestaurant = oldRestaurant;
          });
      }
    },
      err => {
        // Une erreur est arrivée : on remet le contrat précédent
        this.currentContrat = oldContrat;
      });
  }

  filterMenuForHeader(menus: MenuLinkModel[]): MenuLinkModel[] {
    return menus.filter(m => m.isInHeader);
  }

  onShowDrawer() {
    this.drawerComponent.show();
  }

  showNewNotification(notif: NotificationModel) {
    if (notif) {
      this.popupComponent.show(notif);
    }
  }

  showNewCustomNotification(notif: NotificationCustomModel) {
    if (notif) {
      this.popupCustomComponent.show(notif);
    }
  }

  showNewConfirmNotification(notif: NotificationConfirmModel) {
    if (notif) {
      this.popupConfirmComponent.show(notif);
    }
  }
}
