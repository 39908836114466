import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { StringHelper } from '../shared/helpers/string.helper';

@Component({
  selector: 'app-pretty-price',
  templateUrl: './pretty-price.component.html',
  styleUrls: ['./pretty-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrettyPriceComponent implements OnChanges {
  public integerPart: string;
  public decimalPart: string;
  @Input() price: number;
  constructor() { }

  ngOnChanges() {
    if (this.price) {
      const formatted = StringHelper.formatDecimal(this.price);
      const split = formatted.split(',');
      [this.integerPart, this.decimalPart] = split;
    } else {
      this.integerPart = null;
      this.decimalPart = null;
    }
  }
}
