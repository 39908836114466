import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})

export class UserInfoComponent {
  @Input()
  public user$ = this._authService.currentUser$;

  constructor(
    private _router: Router,
    private _authService: AuthenticationService) {
  }

  logOff() {
    this._authService.signOut(true, this._router.routerState.snapshot.url);
  }
}
