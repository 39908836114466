import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";

@Directive({ selector: '[translateYSlider]' })
export class TranslateYSliderDirective {

  constructor(private renderer: Renderer2, private el: ElementRef) {
  }

  @Input()
  set translateYSlider(index: number) {
    const translateY = `translateY(${index * -70}px)`;
    this.renderer.setStyle(this.el.nativeElement, "transform", translateY);
  }
}
