
import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { asapScheduler, combineLatest, of as observableOf, Subscription } from 'rxjs';
import { debounceTime, finalize, first, map, mergeMap, observeOn } from 'rxjs/operators';
import { RestaurantModel } from 'src/app/shared/models/restaurant.model';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { DateInputComponent, DatePickerInfo } from '../../date-input/date-input.component';
import { RestaurantTypeEnum } from '../../shared/enums/restaurant-type.enum';
import { DateHelper } from '../../shared/helpers/date.helper';
import { IPromptForNavigation } from '../../shared/interfaces/iprompt-for-navigation';
import { ErrorApiModel } from '../../shared/models/api/error/error.apimodel';
import { NotificationConfirmModel, NotificationModel } from '../../shared/models/notification.model';
import { LogoRouteService } from '../../shared/services/api/logo/logo-route.service';
import { ApplicationDataService } from '../../shared/services/application-data.service';
import { InteractionService } from '../../shared/services/interaction.service';
import { LogService } from '../../shared/services/log.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { ArticleModel, CartArticleModel } from '../models/article.model';
import { CommandeModel } from '../models/commande.model';
import { DateLivraisonModel } from '../models/date-livraison.model';
import { TypeListArticleFrequenceEnum, TypeListeArticleModel, TypeListeEnum } from '../models/type-liste-articles.model';
import { EpicerieInventaireService } from '../services/epicerie-inventaire.service';

const DEBOUNCE_FILTER = 300;
const CREATION_WARNING_HOUR_LIMIT = 10;
@Component({
  selector: 'app-epicerie-inventaire-create',
  templateUrl: './epicerie-inventaire-create.component.html',
  styleUrls: ['./epicerie-inventaire-create.component.scss']
})
export class EpicerieInventaireCreateComponent implements OnInit, OnDestroy, IPromptForNavigation {
  // temp because ngModel two way binding doesn't work on the date input component
  @ViewChild('desktopDate', { static: false }) dateLivraisonComponent: DateInputComponent; // cette ligne fonctinne aussi pour mobile-date
  @ViewChild('searchInput', { static: true }) searchInput;

  /** Fields */
  private isDateDirty: boolean;
  private isCommandeJustCreated: boolean;
  typeListName: string;
  typeListType: string;
  restaurantType: RestaurantTypeEnum;
  articles: ArticleModel[] = [];
  filteredArticles: ArticleModel[];
  commande: CommandeModel;
  logoContratUrl: string = '';
  dateLivraisonCalendarData: DatePickerInfo;
  cartArticles: CartArticleModel[] = [];
  cartIsVisible: boolean;
  dateCommande: Date = new Date();
  searchIsVisible: boolean;
  searchText: string;
  isInventaire: boolean;
  consult: boolean;
  isSendingCommand: boolean = false;
  availableDateLivraisons: DateLivraisonModel[];

  get validationButtonLabel() {
    const validationRequise = (this.commande && this.commande.validationRequise);
    const isUpdate = (this.commande && this.commande.id > 0);

    return this.isSendingCommand
      ? 'Validation...'
      : (validationRequise && this.isAdmin)
        ? 'Valider'
        : isUpdate
          ? 'Modifier'
          : this.isInventaire
            ? 'Valider'
            : 'Commander';
  }

  get dateLivraison(): Date {
    return this.isInventaire
      ? this.dateCommande
      : this._dateLivraison;
  }

  set dateLivraison(value: Date) {
    if (this.isInventaire) {
      return;
    }

    if (!this.availableDateLivraisons) {
      // Les dates de livraison disponibles ne sont pas encore chargées
      return;
    }
    const dateLivraison: DateLivraisonModel = this.availableDateLivraisons.filter(date => DateHelper.areSameDayOfYear(date.date, value))[0];
    if (!dateLivraison) {
      if (this._dateLivraison && this.availableDateLivraisons.filter(date => DateHelper.areSameDayOfYear(date.date, this._dateLivraison)).length === 1) {
        this.dateLivraisonComponent.setNewDate(this._dateLivraison);
      }
      return;
    }

    if (dateLivraison.commandeId == null || dateLivraison.commandeId === this.commande.id) {
      this.isDateDirty = true;
      this._dateLivraison = value;
    } else {
      const notifModel = new NotificationConfirmModel();
      notifModel.title = 'Commande';
      notifModel.message = `Il existe déjà une commande pour la date de livraison ${DateHelper.toShortDateString(dateLivraison.date)}. Souhaitez vous l'éditer ?`;
      notifModel.onOk = () => {
        this.router.navigate(['/epicerieinventaire/create'], { queryParams: { commandeId: dateLivraison.commandeId } });
      };
      notifModel.onCancel = () => {
        this.dateLivraisonComponent.setNewDate(this._dateLivraison);
      };
      this.interactionService.showNotification(notifModel);
    }
  }

  /** Emitters / Subscriptions  */
  public filterArticlesEmitter: EventEmitter<void>;
  private focusEmitter: EventEmitter<void>;

  /** private Fields */
  private _typeListeArticleId: number;
  private _contratId: number;
  private _cuisineCentraleId: number;
  private _restaurantId: number;
  private _dateLivraison: Date;
  public isAdmin: boolean;

  /** Ctor & lifecycle */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appDataService: ApplicationDataService,
    private logService: LogService,
    private logoService: LogoRouteService,
    private authService: AuthenticationService,
    private epicerieInventaireService: EpicerieInventaireService,
    private navService: NavigationService,
    private interactionService: InteractionService) {
  }

  ngOnInit() {
    this.isAdmin = false;

    this.navService.registerComponent(this);
    this.epicerieInventaireService.clearCartArticles();
    this.subscribe();
    this.searchIsVisible = false;
    this.filterArticlesEmitter = new EventEmitter<void>();
    this.filterArticlesEmitter.pipe(
      debounceTime(DEBOUNCE_FILTER))
      .subscribe((res) => {
        this.filteredArticles = this.articles.filter(article => this.searchText == '' || article.libelle.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1);
      });
    this.focusEmitter = new EventEmitter<void>();
    this.focusEmitter.pipe(
      debounceTime(100))
      .subscribe((res) => {
        this.searchInput.nativeElement.focus();
      });

  }

  tryPromptMessageAfterHourLimit(hour: number, typeListe: TypeListArticleFrequenceEnum) {
    if (hour >= CREATION_WARNING_HOUR_LIMIT
      && typeListe === TypeListArticleFrequenceEnum.Quotidienne
      && !this.consult) {
      this.interactionService.showSimpleMessage('Avertissement', `Attention, vous commandez après ${CREATION_WARNING_HOUR_LIMIT}h, une journée supplémentaire a été implicitement ajoutée dans le calcul de la première date de livraison disponible.`);
    }
  }

  checkIsDirty(): boolean {
    return !this.isCommandeJustCreated && (this.isDateDirty || this.epicerieInventaireService.isDirty);
  }

  showSearch(value: boolean) {
    this.searchIsVisible = value;
    this.searchText = '';
    if (this.focusEmitter) {
      this.focusEmitter.next();
    }
    if (this.filterArticlesEmitter) {
      this.filterArticlesEmitter.next();
    }
  }

  /** Logic  */
  handleGetOrCreateCommande(commandeId: number, duplicate: boolean) {
    this.epicerieInventaireService.getTypeListeArticleInfo(this._cuisineCentraleId, this._restaurantId, this._typeListeArticleId)
    .pipe(
      mergeMap((typeListe: TypeListeArticleModel) => {
        this.typeListName = typeListe.libelle;
        this.typeListType = typeListe.type === TypeListeEnum.Epicerie
          ? 'Epicerie'
          : 'Inventaire';
        // On affiche un message d'avertissement si l'heure est dépassée sur une liste quotidienne
        this.tryPromptMessageAfterHourLimit(moment().hours(), typeListe.frequence);
        if (commandeId) {
          // On a un commandeId, donc on retourne le getCommande
          return this.epicerieInventaireService.getCommande(commandeId);
        } else {
          // Création d'une commande -> Nouvelle commande avec l'information validationRequise
          const newCommande = new CommandeModel();
          newCommande.validationRequise = typeListe.validationRequise;
          newCommande.dateCommande = new Date();
          return observableOf(newCommande);
        }
      })
      ).subscribe(cmd => {
        // On set la commande (new ou edit)
        this.commande = cmd;

        // On remplit le panier avec les articles de la commande (s'il y en a)
        if (cmd.articles && cmd.articles.length) {
          this.epicerieInventaireService.currentCartArticles = cmd.articles;
        }

        // On prend la typeListArticle de la commande récupérée (s'il y en a)
        if (cmd.typeListeArticleId != null) {
          this._typeListeArticleId = cmd.typeListeArticleId;
        }

        // Si duplicate, on remove l'id de la commande
        if (duplicate) {
          // reset commande id on duplicate mode
          this.commande.id = null;
        } else {
          if (this.commande.id != null) {
            this.dateCommande = this.commande.dateCommande;
          }
        }

        // On récup les dates de livraisons + articles
        this.handleGetArticles();
        this.handleDateLivraisons();
      });
  }

  handleGetArticles() {
    // TODO faire un loader pour les articles
    this.epicerieInventaireService
      .getArticles(this._typeListeArticleId, this._cuisineCentraleId, this._restaurantId, this._contratId)
      .subscribe(res => {
        this.articles = res;
        this.filteredArticles = this.articles;
      },
        err => {
          // TODO handle errors
        });
  }

  handleDateLivraisons() {
    if (!this.isInventaire) {
      this.epicerieInventaireService.getAvailableDateLivraison(this._cuisineCentraleId, this._restaurantId, this._contratId, this._typeListeArticleId, this.dateCommande)
        .subscribe(res => {
          this.availableDateLivraisons = res;
          this.dateLivraisonCalendarData = {
            availableDays: this.availableDateLivraisons.map(date => date.date),
            maxDate: DateHelper.addMonth(new Date(), 6),
            enableDayFilter: true
          };
          if (this.commande.id) {
            // set date livraison only on edit mode
            this._dateLivraison = this.commande.dateLivraison;
          } else {
            // set first available date
            const dateLivraison = this.availableDateLivraisons.filter(date => date.commandeId == null)[0];
            if (dateLivraison) {
              this._dateLivraison = dateLivraison.date;
            }
          }
        });
    }

    this.isDateDirty = false;
  }

  /** Events */
  validate() {
    if (this.isSendingCommand) {
      return;
    }

    if (this.epicerieInventaireService.currentCartLength === 0) {
      this.interactionService.showSimpleMessage('Panier vide', 'Veuillez remplir le panier avant de valider la commande');
      return;
    }

    this.isSendingCommand = true;
    this.epicerieInventaireService.saveCommande(this._cuisineCentraleId, this._restaurantId, this._typeListeArticleId, this._contratId, this.dateLivraison, this.epicerieInventaireService.currentCartArticles, this.commande.id).pipe(
      first(),
      finalize(() => {
        this.isSendingCommand = false;
      }))
      .subscribe(message => {
        this.isCommandeJustCreated = true;
        const notifModel = new NotificationModel();
        notifModel.title = 'Commande';
        notifModel.message = message;
        notifModel.onClose = () => {
          this.router.navigate(['/epicerieinventaire']);
        };

        this.interactionService.showNotification(notifModel);

      },
        (error: ErrorApiModel) => {
          const notifModel = new NotificationModel();
          notifModel.title = 'Erreur';
          notifModel.message = error.Message;

          this.interactionService.showNotification(notifModel);
        });
  }

  /** Subscriptions */
  subscribe() {
    this.route.queryParams
      .pipe(
        observeOn(asapScheduler), // TODO: à voir : le changement de title doit être fait de façon synchrone pour ne pas planter le ChangeDetector
        first()
      )
      .subscribe(params => {
        this.isInventaire = params.isInventaire === 'true';
        const commandeId: number = params.commandeId || null;
        const newTitle = `${this.isInventaire ? 'Inventaire' : 'Épicerie'} - ${commandeId ? 'Édition' : 'Création'}`;
        this.appDataService.changePageTitle(newTitle);
      });

    // on prends le dernier restaurant et on complete le stream que l'on combine ensuite aux infos user pour en tirer le context actuel
    combineLatest([
      this.appDataService.restaurant$,
      this.route.queryParams]
    )
      .pipe(
        untilDestroyed(this),
        map(([restaurant, params]) => ({ restaurant, params }))
      )
      .subscribe((tuple: { restaurant: RestaurantModel, params: Params }) => {
        const restaurant = tuple.restaurant;
        const params = tuple.params || {};
        if (restaurant != null) {
          this._restaurantId = restaurant.id;
          this.restaurantType = restaurant.type;
          this._cuisineCentraleId = restaurant.cuisineCentraleId;
          this._contratId = restaurant.contratId;
          this.logoContratUrl = this.logoService.GetPicture2Url(restaurant.contratId, restaurant.cuisineCentraleId);

          this.isInventaire = params.isInventaire === 'true';
          this._typeListeArticleId = params.typeListeArticleId || null;
          const commandeId: number = params.commandeId || null;
          const duplicate: boolean = params.duplicate || false;
          this.consult = params.consult || false;
          this.handleGetOrCreateCommande(commandeId, duplicate);
        }
      });

    this.epicerieInventaireService.showCartEmitter
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.cartIsVisible = value;
      });

    this.authService.currentUser$
      .pipe(untilDestroyed(this))
      .subscribe(user => this.isAdmin = user && user.isAdmin);
  }

  hideCart() {
    this.epicerieInventaireService.showCart(false);
  }

  ngOnDestroy() {
    this.epicerieInventaireService.clearCartArticles();
    this.navService.unregisterComponent(this);
    this.isCommandeJustCreated = false;
  }
}
