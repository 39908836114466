import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PopupCustomComponentGeneric } from '../../../popup-custom/popup-custom-content.component';
import { ErrorApiModel } from '../../../shared/models/api/error/error.apimodel';
import { InteractionService } from '../../../shared/services/interaction.service';
import { BonLivraisonComposantReceptionModel } from '../../models/bon-livraison-composant-reception.model';
import { BonLivraisonComposantModel } from '../../models/bon-livraison-composant.model';
import { BonLivraisonStatut } from '../../models/bon-livraison.model';
import { BonLivraisonService } from '../../services/bon-livraison.service';
import { NonConformiteSelectItemModel } from './non-conformite-select-item.model';
import { PopupValidationModel } from './popup-validation.model';


@Component({
  selector: 'app-popup-validation',
  templateUrl: './popup-validation.component.html',
  styleUrls: ['./popup-validation.component.scss']
})
export class PopupValidationComponent extends PopupCustomComponentGeneric<PopupValidationModel> implements OnInit, OnDestroy {

  @ViewChild('form', { static: true })
  public form: NgForm; // FormGroup

  public isLoading: boolean = false;
  public erreurValidationMessage: string;

  //#region data property override
  private _data: any;
  public get data(): any {
    return this._data;
  }
  public set data(data: any) {
    this._data = data;
    this.loadModel();
  }
  //#endregion

  public manques: BonLivraisonComposantModel[];
  public excedents: BonLivraisonComposantModel[];
  public nonConformites: NonConformiteSelectItemModel[];
  public commentaire: string;

  public get hasManques(): boolean {
    return this.manques && this.manques.length > 0;
  }

  public get hasExcedents(): boolean {
    return this.excedents && this.excedents.length > 0;
  }

  public get hasComposantFaults(): boolean {
    // Manque ou Excédent
    return this.hasManques || this.hasExcedents;
  }

  public get hasNonConformite(): boolean {
    return this.nonConformites.some(nc => nc.selected);
  }

  public get allowReception(): boolean {
    if (!this.model.bonLivraison.peutReceptionner) {
      return false;
    }
    // on affiche le détail de reception dès lors qu'il y a eu une modification de quantité où que le bon doit être réceptionné
    return this.model.hasModification
      || this.model.bonLivraison.statut === BonLivraisonStatut.AReceptionner;
  }

  public get forceReceptionOnly(): boolean {
    // on force la réception seule lorsqu'il y a la précense d'un manque
    return this.allowReception && this.hasManques;
  }

  public get allowConformite(): boolean {
    if (!this.model.bonLivraison.peutMettreEnConformite
      || this.forceReceptionOnly) {
      return false;
    }

    // on demande la déclaration de conformité du bon dès lors:
    return !this.model.hasModification // qu'il n'y a pas eu de modification de quantité
      || (!this.hasComposantFaults // qu'il n'y a pas eu de défaut de quantité
        || !this.hasManques // ou que le défaut n'est pas un manque (le manque nécessite une relivraison et donc une confirmation ultérieure)
      );
  }

  public get isCommentaireRequired() {
    // le commentaire est requis seulement en cas de non-conformite et qu'un type de non-conformité sélectionné soit en commentaireRequis
    return this.showNonConformePrompt && this.nonConformites.some(nc => nc.selected && nc.nonConformite.commentaireRequis);
  }

  public showNonConformePrompt: boolean = false;

  constructor(
    private bonLivraisonService: BonLivraisonService,
    private router: Router,
    private interactionService: InteractionService
  ) {
    super();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  private loadModel() {

    this.manques = [];
    this.excedents = [];
    this.model.bonLivraison.bonLivraisonPrestations
      .forEach(p => p.bonLivraisonFamillePlats
        .forEach(fp => fp.bonLivraisonPlats
          .forEach(pl => pl.bonLivraisonComposants
            .forEach(c => {
              // tslint:disable-next-line: triple-equals
              if (c.quantiteLivreeRelative != 0) {
                const composant = { ...c }; // simple copy
                composant.libelle = composant.libelle || pl.libelle; // on redéfini au libellé plat s'il n'y a pas de libellé composant
                if (composant.quantiteLivreeRelative < 0) {
                  this.manques.push(composant);
                }
                if (composant.quantiteLivreeRelative > 0) {
                  this.excedents.push(composant);
                }
              }
            })
          )
        )
      );

    this.commentaire = this.model.bonLivraison.miseEnConformiteCommentaire || this.model.bonLivraison.receptionCommentaire;

    this.nonConformites = this.model.bonLivraison.settings.typeNonConformites.map(tnc => ({
      selected: this.model.bonLivraison.nonConformites.some(nc => nc.bonLivraisonTypeNonConformiteId === tnc.bonLivraisonTypeNonConformiteId),
      nonConformite: tnc
    }));
  }

  DeclarerManque() {
    if (!this.validateForm()) {
      return;
    }

    this.isLoading = true;
    this.receptionnerBon().subscribe(success => {
      // this.isValidatedReception = true;
      this.erreurValidationMessage = '';
      this.isLoading = false;

      this.hide();
      this.interactionService.showSimpleMessage('Manque', 'Votre déclaration de manque(s) a été prise en compte.');
      this.router.navigate(['/bonlivraison'], { queryParams: {} });
    },
      (error: ErrorApiModel) => {
        // this.isValidatedReception = false;
        this.isLoading = false;
        this.erreurValidationMessage = error.Message;
      }
    );
  }

  private receptionnerBon() {

    // on transforme les Plats en BonLivraisonComposantReceptionModel[]
    const manques: BonLivraisonComposantReceptionModel[]
      = (this.manques)
        .map(c => ({
          bonLivraisonComposantId: c.bonLivraisonComposantId,
          quantiteLivreeRelative: c.quantiteLivreeRelative || 0
        }));

    const excedents: BonLivraisonComposantReceptionModel[]
      = (this.excedents)
        .map(c => ({
          bonLivraisonComposantId: c.bonLivraisonComposantId,
          quantiteLivreeRelative: c.quantiteLivreeRelative || 0
        }));

    const defauts = manques.concat(excedents);

    return this.bonLivraisonService.receptionnerBon(this.model.bonLivraison.code, defauts, this.commentaire);

  }

  DeclarerConformite(isConforme: boolean) {
    if (!this.validateForm()) {
      return;
    }

    this.isLoading = true;

    // si la réception est autorisée, on effectue une réception au préalable
    const reception$ = this.allowReception
      ? this.receptionnerBon()
      : observableOf(true);

    const nonConformiteIds = this.nonConformites
      .filter(nc => !isConforme && nc.selected) // on prends les non-conformite sélectionnées (seulement si on est en non-conformite)
      .map(nc => nc.nonConformite.bonLivraisonTypeNonConformiteId);

    // on fait ensuite la conformité
    reception$.pipe(
      switchMap(() => // https://medium.com/@juliapassynkova/switchmap-in-details-b98d34145311
        this.bonLivraisonService.miseEnConformiteBon(this.model.bonLivraison.code, isConforme, this.commentaire, nonConformiteIds)
      )
    )
      .subscribe(
        success => {
          // this.isValidatedMiseEnConformite = true;
          this.erreurValidationMessage = '';
          this.isLoading = false;

          this.hide();

          this.interactionService.showSimpleMessage(isConforme ? 'Conformité' : 'Non conformité',
            `Votre déclaration de ${isConforme ? 'conformité' : 'non conformité'} a été prise en compte.`);

          this.router.navigate(['/bonlivraison'], { queryParams: {} });
        },
        (error: ErrorApiModel) => {
          // this.isValidatedMiseEnConformite = false;
          this.isLoading = false;
          this.erreurValidationMessage = error.Message;
        }
      );
  }

  hide() {
    this.emitter.next();
  }

  private validateForm(): boolean {
    this.validateAllFormFields(this.form.form);
    return this.form.valid
      && this.checkNonConformite();
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }



  // HACK : Workaround pour gérer la liste de checkbox, il semble qu'il n'y ai pas d'autre moyen à par de passer par du ReactiveForm
  toggleNonConformite(event: Event) {
    const checkBox = event.target as HTMLInputElement;
    const nonConformiteId = parseInt(checkBox.value, 10);
    const selected = !!checkBox.checked;
    const nonConformite = this.nonConformites.find(nc => nc.nonConformite.bonLivraisonTypeNonConformiteId === nonConformiteId);
    nonConformite.selected = selected;
    // permet de déclencher les affichage de validation
    this.form.control.markAsTouched();
  }
  private checkNonConformite(): boolean {
    return !this.showNonConformePrompt || this.hasNonConformite;
  }

  scrollToElement(targetElement: HTMLElement) {
    targetElement.scrollIntoView();
  }
}
