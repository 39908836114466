
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, finalize, first } from 'rxjs/operators';
import { DatePickerInfo } from '../date-input/date-input.component';
import { EpicerieInventaireService } from '../epicerie-inventaire/services/epicerie-inventaire.service';
import { CommandeRepasModel } from '../repas/models/commande-repas.model';
import { CommandeRepasService } from '../repas/services/commande-repas.service';
import { DateHelper } from '../shared/helpers/date.helper';
import { FileHelper } from '../shared/helpers/file.helper';
import { ErrorApiModel } from '../shared/models/api/error/error.apimodel';
import { ContratModel } from '../shared/models/contrat.model';
import { FileResultModel } from '../shared/models/fileresult.model';
import { NotificationModel } from '../shared/models/notification.model';
import { LogoRouteService } from '../shared/services/api/logo/logo-route.service';
import { ApplicationDataService } from '../shared/services/application-data.service';
import { InteractionService } from '../shared/services/interaction.service';
import { CommandeRepasFilterModel } from './models/commande-repas-filter.model';
import { EpicerieInventaireFilterModel } from './models/epicerie-inventaire-filter.model';
import { MatriceDeChoixFilterModel } from './models/matrice-choix-filter.model';
import { ReportingService } from './services/reporting.service';

const DEBOUNCE_EMITTER = 300;
const EPICERIE_LABEL = 'Epicerie';
const INVENTAIRE_LABEL = 'Inventaire';

export interface ReportSemaineModel {
  libelle: string;
  dateDebut: Date;
  dateFin: Date;
}
@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})

export class ReportingComponent implements OnInit, OnDestroy {
  /** Subscriptions */
  private contratSubscription: Subscription;
  private restaurantSubscription: Subscription;

  /** fields */
  logoContratUrl: string = '';
  dateCalendarData: DatePickerInfo;
  epicerieInventaireFilter: EpicerieInventaireFilterModel;
  matriceDeChoixFilter: MatriceDeChoixFilterModel;
  commandeRepasFilter: CommandeRepasFilterModel;

  /** private fields */
  private contrat: ContratModel;
  private restaurantId: number;
  private cuisineCentraleId: number;

  constructor(
    private appDataService: ApplicationDataService,
    private logoService: LogoRouteService,
    private epicerieInventaireService: EpicerieInventaireService,
    private cmdRepasService: CommandeRepasService,
    private interactionService: InteractionService,
    private reportingService: ReportingService) { }

  ngOnInit() {
    // calendar
    this.dateCalendarData = {
      minDate: moment().add(-2, 'year').startOf('year').toDate(),
      maxDate: moment().add(1, 'year').endOf('year').toDate()
    };
    // filters
    const startDate: Date = moment().startOf('month').toDate();
    const endDate: Date = moment().endOf('month').toDate();
    const today: Date = moment().toDate();
    // epicerie filter
    this.epicerieInventaireFilter = new EpicerieInventaireFilterModel();
    this.epicerieInventaireFilter.startDate = startDate;
    this.epicerieInventaireFilter.endDate = endDate;
    this.epicerieInventaireFilter.type = EPICERIE_LABEL;
    this.epicerieInventaireFilter.epicerieInventaireEmitter = new EventEmitter<void>();
    this.epicerieInventaireFilter.epicerieInventaireEmitter.pipe(
      debounceTime(DEBOUNCE_EMITTER))
      .subscribe(() => {
        this.selectTypeListeArticle(this.epicerieInventaireFilter.type);
      });
    // repas filter
    this.commandeRepasFilter = new CommandeRepasFilterModel();
    this.commandeRepasFilter.startDate = startDate;
    this.commandeRepasFilter.endDate = endDate;
    this.commandeRepasFilter.isPiqueNique = false;

    // subscription
    this.handleSubscribe();
  }

  ngOnDestroy() {
    this.contratSubscription.unsubscribe();
    this.restaurantSubscription.unsubscribe();
  }

  initMatriceDeChoixFilter() {
    this.matriceDeChoixFilter = null;
    const startOfWeek = moment().startOf('week').toDate();
    // #46735 : on affiche jusqu'à 4 semaines précédentes
    const startDate = moment(startOfWeek).subtract(4, 'weeks').toDate();
    this.cmdRepasService.getCommandeRepas(false, this.cuisineCentraleId, this.restaurantId, null, null, startDate).pipe(
      debounceTime(1000))
      .subscribe((cmdRepas: CommandeRepasModel) => {
        this.matriceDeChoixFilter = new MatriceDeChoixFilterModel(cmdRepas.lieuCommandes, cmdRepas.semaines);
        // par défaut, sélection de la semaine courante
        this.matriceDeChoixFilter.selectedSemaine = this.matriceDeChoixFilter.semaineList.find(s => new Date(s.dateDebut) >= startOfWeek);
      });
  }

  /** END Matrice de choix */
  handleSubscribe() {
    this.contratSubscription = this.appDataService.contrat$.subscribe(
      contrat => {
        if (contrat != null) {
          this.contrat = contrat;
          this.cuisineCentraleId = contrat.cuisineCentraleId;
          this.logoContratUrl = this.logoService.GetPicture2Url(contrat.id, contrat.cuisineCentraleId);
          if (this.restaurantId) {
            this.getTypeListeArticles();
          }
          // this.getPrestations();
        }
      });

    this.restaurantSubscription = this.appDataService.restaurant$.subscribe(
      restaurant => {
        if (restaurant != null) {
          this.restaurantId = restaurant.id;
          this.initMatriceDeChoixFilter();
          this.getTypeListeArticles();
        }
      });
  }

  private getTypeListeArticles() {
    this.epicerieInventaireFilter.loadingData = true;
    this.epicerieInventaireService.getTypeListeArticles(this.cuisineCentraleId, this.restaurantId, this.contrat.id).pipe(
      debounceTime(1000))
      .subscribe(typeListeArticles => {
        this.epicerieInventaireFilter.typeListeArticlesEpicerie = typeListeArticles.typesListeArticleEpicerie;
        this.epicerieInventaireFilter.typeListeArticlesInventaire = typeListeArticles.typesListeArticleInventaire;
        // set epicerie by default
        this.epicerieInventaireFilter.type = EPICERIE_LABEL;
        this.selectTypeListeArticle(this.epicerieInventaireFilter.type);
        this.epicerieInventaireFilter.loadingData = false;
      });
  }

  private selectTypeListeArticle(type: string) {
    switch (type) {
      case EPICERIE_LABEL:
        this.epicerieInventaireFilter.selectedTypeListeArticles = this.epicerieInventaireFilter.typeListeArticlesEpicerie;
        this.epicerieInventaireFilter.selectedTypeListeArticle = this.epicerieInventaireFilter.selectedTypeListeArticles[0];
        break;
      case INVENTAIRE_LABEL:
        this.epicerieInventaireFilter.selectedTypeListeArticles = this.epicerieInventaireFilter.typeListeArticlesInventaire;
        this.epicerieInventaireFilter.selectedTypeListeArticle = this.epicerieInventaireFilter.selectedTypeListeArticles[0];
        break;
    }
  }

  // Print methods
  getCommandeRepasMatriceDeChoix() {
    this.matriceDeChoixFilter.downloading = true;
    const report$ = this.reportingService
      .getCommandeRepasMatriceDeChoix(this.cuisineCentraleId, this.restaurantId, this.matriceDeChoixFilter.selectedLieuCommande.id, this.matriceDeChoixFilter.getDate(), false).pipe(
        debounceTime(1000),
        first(),
        finalize(() => {
          this.matriceDeChoixFilter.downloading = false;
        }));

    this.reportingService.handleReportResponse(report$);
  }
  getEtatCommandeInventaireReport() {
    const partName = this.epicerieInventaireFilter.type.toLowerCase();
    this.epicerieInventaireFilter.downloading = true;
    const report$ = this.reportingService
      .getEtatCommandeInventaireReport(this.cuisineCentraleId, this.contrat.id, this.epicerieInventaireFilter.selectedTypeListeArticle.id, this.epicerieInventaireFilter.startDate, this.epicerieInventaireFilter.endDate).pipe(
        debounceTime(1000),
        first(),
        finalize(() => {
          this.epicerieInventaireFilter.downloading = false;
        }));

    this.reportingService.handleReportResponse(report$);
  }

  getBilanCommandeRepasOuPiqueNiqueReport() {
    const namePart = this.commandeRepasFilter.isPiqueNique ? 'Bilan_commande_piquenique' : 'Bilan_commande_repas';
    const fileName = `${namePart}_${DateHelper.toShortDateString(this.commandeRepasFilter.startDate)}_${DateHelper.toShortDateString(this.commandeRepasFilter.endDate)}.xlsx`;
    this.commandeRepasFilter.downloading = true;
    const report$ = this.reportingService.getBilanCommandeRepasOuPiqueNiqueReportForAllRestaurant(this.cuisineCentraleId, this.contrat, this.commandeRepasFilter.startDate, this.commandeRepasFilter.endDate, this.commandeRepasFilter.isPiqueNique).pipe(
      debounceTime(1000), // TODO Pourquoi un Debounce en aval du téléchargement??
      finalize(() => {
        this.commandeRepasFilter.downloading = false;
      }),
      first());

    this.reportingService.handleReportResponse(report$);
  }


  downloadButtonText(isDownloading: boolean): string {
    return isDownloading ? 'Téléchargement...' : 'Extraire';
  }
}
