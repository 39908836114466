/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-tag-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./file-tag-list.component";
var styles_FileTagListComponent = [i0.styles];
var RenderType_FileTagListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileTagListComponent, data: {} });
export { RenderType_FileTagListComponent as RenderType_FileTagListComponent };
function View_FileTagListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "tag-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "tag-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "filename"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "delete"], ["src", "/assets/images/circle-delete.png"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.file.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.file.name; _ck(_v, 3, 0, currVal_1); }); }
export function View_FileTagListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileTagListComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploader.queue; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FileTagListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-tag-list", [], null, null, null, View_FileTagListComponent_0, RenderType_FileTagListComponent)), i1.ɵdid(1, 114688, null, 0, i3.FileTagListComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileTagListComponentNgFactory = i1.ɵccf("app-file-tag-list", i3.FileTagListComponent, View_FileTagListComponent_Host_0, { readonly: "readonly", uploader: "uploader" }, {}, []);
export { FileTagListComponentNgFactory as FileTagListComponentNgFactory };
