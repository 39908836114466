
import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { debounceTime, finalize, first } from 'rxjs/operators';
import { InteractionService } from '../../shared/services/interaction.service';
import { TypeInterventionModel, TypeMaintenanceModel, TypeInterventionGroup } from '../models/type-intervention.model';
import { DiscussionService } from '../services/discussion.service';
import { RestaurantModel } from 'src/app/shared/models/restaurant.model';
import { ErrorApiModel } from 'src/app/shared/models/api/error/error.apimodel';
import { FormGroup, NgForm, FormControl } from '@angular/forms';

@Component({
  selector: 'new-discussion',
  templateUrl: './new-discussion.component.html',
  styleUrls: ['./new-discussion.component.scss'],
})
export class NewDiscussionComponent implements OnInit, OnDestroy {

  @Input()
  public typeInterventionGroups: TypeInterventionGroup[];

  @Input()
  public isLoadingTypeInterventions: boolean;

  @Input()
  public restaurant: RestaurantModel;

  @Output()
  public newDiscussionCreated: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('class.new-discussion-visible') public isOpen: boolean = false;

  @ViewChild('form', { static: true })
  public form: NgForm; // FormGroup

  /** Form values */
  private _selectedTypeIntervention: TypeInterventionModel;
  public get selectedTypeIntervention(): TypeInterventionModel {
    return this._selectedTypeIntervention;
  }
  public set selectedTypeIntervention(value: TypeInterventionModel) {
    this._selectedTypeIntervention = value;
    this.selectedTypeMaintenance = null;

    // Si il n'y a qu'une seule TypeMaintenance, on la sélectionne automatiquement
    if (this._selectedTypeIntervention
      && this._selectedTypeIntervention.typeMaintenances
      && this._selectedTypeIntervention.typeMaintenances.length === 1
      && this._selectedTypeIntervention.typeMaintenances[0].typeMaintenances
      && this._selectedTypeIntervention.typeMaintenances[0].typeMaintenances.length === 1) {
      this.selectedTypeMaintenance = this._selectedTypeIntervention.typeMaintenances[0].typeMaintenances[0];
    }
  }

  private _oldSelectedTypeMaintenance: TypeMaintenanceModel

  private _selectedTypeMaintenance: TypeMaintenanceModel;
  public get selectedTypeMaintenance(): TypeMaintenanceModel {
    return this._selectedTypeMaintenance;
  }
  public set selectedTypeMaintenance(v: TypeMaintenanceModel) {
    this._selectedTypeMaintenance = v;

    // Initialisation par défaut de l'objet / message
    if (this._selectedTypeMaintenance) {
      const hasChangedDefaultObjet = (this._oldSelectedTypeMaintenance && this._oldSelectedTypeMaintenance.libelle) !== this.objet;
      const hasChangedDefaultMessage = (this._oldSelectedTypeMaintenance && this._oldSelectedTypeMaintenance.templateMessage) !== this.description;

      if (!this.objet || !hasChangedDefaultObjet) {
        this.objet = this._selectedTypeMaintenance.libelle;
      }
      if (!this.description || !hasChangedDefaultMessage) {
        this.description = this._selectedTypeMaintenance.templateMessage;
      }
    }

    if (v != null) {
      this._oldSelectedTypeMaintenance = v;
    }
  }

  public objet: string = '';
  public description: string = '';
  public uploader: FileUploader = new FileUploader({});

  public get showTakePictureButton() {
    return window.cordova != null;
  }
  /** Display  */
  public get isTypeInterventionsVisible(): boolean {
    return this.typeInterventionGroups && this.typeInterventionGroups.length > 0;
  }

  public get isTypeMaintenancesVisible(): boolean {
    return this.selectedTypeIntervention
      && this.selectedTypeIntervention.typeMaintenances
      && this.selectedTypeIntervention.typeMaintenances.length > 0;
  }

  public get isDescriptionObligatoire(): boolean {
    if (this.selectedTypeMaintenance) {
      return this.selectedTypeMaintenance.descriptionObligatoire;
    }

    return this.selectedTypeIntervention && !!this.selectedTypeIntervention.descriptionObligatoire;
  }

  public isSending: boolean;

  constructor(private discussionService: DiscussionService, private interactionService: InteractionService) { }

  /** Implementations */
  ngOnInit() {
  }

  ngOnDestroy() {
  }

  /** Methods */
  cleanForm() {
    this.selectedTypeIntervention = null;
    this.selectedTypeMaintenance = null;
    this.objet = '';
    this.description = '';
    this.uploader.cancelAll();
    this.uploader.clearQueue();
  }

  sendNewDiscussion() {
    if (!this.validateForm() || this.isSending) {
      return;
    }
    this.isSending = true;

    const files = this.uploader.queue.map(i => {
      // console.log(i._file);
      return i._file;
    });

    const maintenanceId = this.selectedTypeMaintenance != null ? this.selectedTypeMaintenance.id : null;
    this.discussionService
      .sendNewDiscussion(this.restaurant.cuisineCentraleId, this.restaurant.id, this.selectedTypeIntervention.id, this.objet, this.description, files, maintenanceId).pipe(
        debounceTime(1000),
        first(),
        finalize(() => {
          this.isSending = false;
        }))
      .subscribe((success) => {
        this.cleanForm();
        this.newDiscussionCreated.next();
        this.close();
      },
        (err: ErrorApiModel) => {
          this.interactionService.showSimpleMessage('Erreur', `Une erreur est survenue lors de la création d\'une nouvelle demande : ${err.Message}`);
        });
  }

  /** Open/Close */
  open() {
    this.isOpen = true;
  }

  close(event: any = null) {
    if (event) {
      event.preventDefault();
    }

    this.cleanForm();
    this.isOpen = false;
  }

  private validateForm(): boolean {
    this.validateAllFormFields(this.form.form);
    return this.form.valid;
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
