import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';


@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @ViewChild("logo", { static: true })
  private logo: ElementRef;

  @Input()
  restaurantType: RestaurantTypeEnum;

  private _class: string = '';
  @Input()
  set class(customclass: string) {
    if (this._class != customclass) {
      if (this._class)
        this.renderer.removeClass(this.logo.nativeElement, this._class);

      this._class = customclass;

      if (this._class)
        this.renderer.addClass(this.logo.nativeElement, this._class);
    }
  }
  get class(): string { return this._class; }

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }

}
