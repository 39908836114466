import { AppInsights } from 'applicationinsights-js';
import { ToastrModule } from 'ngx-toastr';

import { NguiAutoCompleteModule } from '@ngui/auto-complete';

import { OdgModule } from './odg/odg.module';
import { DocumenthequeModule } from './documentheque/documentheque.module';
import { DiscussionModule } from './discussion/discussion.module';
import { ReportingModule } from './reporting/reporting.module';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// A privilégier
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ServiceWorkerModule } from '@angular/service-worker';

// Feature module
import { EpicerieInventaireModule } from './epicerie-inventaire/epicerie-inventaire.module';
import { SharedModule } from './shared/shared.module';
import { ContactModule } from './contact/contact.module';
import { routing } from './app-routing.module';
import { AnalyticsModule } from './analytics/analytics.module';
import { RepasModule } from './repas/repas.module';

// Components
import { PopupComponent } from './popup/popup.component';
import { AppComponent } from './app.component';
import { DrawerComponent } from './drawer/drawer.component';
import { HomeComponent } from './home/home.component';
import { MealResumeComponent } from './meal-resume/meal-resume.component';
import { MealSliderComponent } from './meal-slider/meal-slider.component';
import { MealTileComponent } from './meal-tile/meal-tile.component';
import { NotifTileComponent } from './notif-tile/notif-tile.component';
import { LayoutHeaderComponent } from './layout-header/layout-header.component';
import { LoginComponent } from './login/login.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { HamburgerComponent } from './hamburger/hamburger.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { PopupCustomComponent } from './popup-custom/popup-custom.component';
import { PopupCustomContentComponent } from './popup-custom/popup-custom-content.component';
import { SandboxComponent } from './sandbox/sandbox.component';
import { PopupConfirmComponent } from './popup-confirm/popup-confirm.component';
import { CartTileComponent } from './cart-tile/cart-tile.component';
import { LogoComponent } from './logo/logo.component';
import { SelectAutocompleteComponent } from './select-autocomplete/select-autocomplete.component';
import { RestaurantSelectorComponent } from './restaurant-selector/restaurant-selector.component';

// Services
// Angular DI does not work well with Barrels (so only file declaration)
// Api services
import { AuthenticationApiService } from './shared/services/api/authentication/authentication.apiservice';
import { ParametreApiService } from './shared/services/api/parametre/parametre.apiservice';
import { PromptForNavGuard } from './shared/guards/prompt-for-nav-guard';
import { NavigationService } from './shared/services/navigation.service';
import { InteractionService } from './shared/services/interaction.service';

// Normal services
import { ApplicationDataService } from './shared/services/application-data.service';
import { LocalStorageService } from './shared/services/browser-storage.service';
import { SessionStorageService } from './shared/services/browser-storage.service';
import { LogService } from './shared/services/log.service';
import { LogoRouteService } from './shared/services/api/logo/logo-route.service';
import { ParametreService } from './shared/services/parametre.service';
import { CommandeRepasService } from './repas/services/commande-repas.service';
import { CommandeRepasApiService } from './repas/services/api/commande-repas.apiservice';
import { AppSettingsService } from './shared/services/app-settings.service';
// Directives

// Guards
import { AuthGuard } from './shared/guards/auth-guard';

// Error handler
import { EliorErrorHandler } from './shared/elior-error-handler';

// const
import { environment } from '../environments/environment';
import { AppSettings } from './shared/models/app-settings.model';
import { ApiSettings } from './shared/models/api/api-settings';
import { BonLivraisonModule } from './bon-livraison/bon-livraison.module';
import { AppHttpInterceptor } from './shared/services/http/app-http-interceptor';
import { AuthHttpInterceptor } from './shared/services/http/auth-http-interceptor';
import { ErrorHandlerHttpInterceptor } from './shared/services/http/error-handler-http-interceptor';
import { AuthenticationService } from './shared/services/authentication/authentication.service';
import { AlertesModule } from './alertes/alertes.module';

const { version: appVersion } = require('../../package.json');

// HACK pour éviter un cyclic-dependency à cause du CustomHttpService référencé en Htpp, on injecte la classe (non initialisée) pour l'initialiser ensuite
export function onAppInit(appSettingsService: AppSettingsService,
                          apiSettings: ApiSettings)
  : () => Promise<any> {
  // see https://hackernoon.com/hook-into-angular-initialization-process-add41a6b7e
  // also (+) https://www.intertech.com/Blog/angular-4-tutorial-run-code-during-app-initialization/
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      appSettingsService.load()
        .then(() => {
          // init appSettings
          initApiSettings(apiSettings, appSettingsService.appSettings);
          // App Initialization code (fetch&load settings ...)
          initAppInsight(appSettingsService.appSettings);

          resolve();
        })
        .catch(() => reject());
    });
  };
}

function initAppInsight(appSettings: AppSettings) {
  if (!appSettings.appInsightsInstrumentationKey) {
    console.warn('Pas d\'instrumentation key : pas d\'application Insights');
    return;
  }
  /* Call downloadAndSetup to download full ApplicationInsights script from CDN and initialize it with instrumentation key */
  AppInsights.downloadAndSetup({ instrumentationKey: appSettings.appInsightsInstrumentationKey });

  AppInsights.queue.push(() => {
    // paramétrage de l'applicationContext (version)
    AppInsights.context.application.ver = appVersion;
    AppInsights.context.application.build = appSettings.environment;
  });
}

function initApiSettings(apiSettings: ApiSettings, appSettings: AppSettings) {
  apiSettings.apiUrl = appSettings.apiUrl;
  apiSettings.subscriberKey = appSettings.apiSubscriberKey;
  apiSettings.mockOdgService = environment.mockOdgService;
}

export function appSettingsFactory(appSettingsService: AppSettingsService) {
  if (!appSettingsService.appSettings) {
    throw new Error('appSettingsService.appSettings not loaded');
  }

  return appSettingsService.appSettings;
}

@NgModule({
  declarations: [
    // pipes
    // directives
    // components
    PopupComponent,
    AppComponent,
    HomeComponent,
    MealResumeComponent,
    MealSliderComponent,
    MealTileComponent,
    LoginComponent,
    LayoutHeaderComponent,
    NotifTileComponent,
    DrawerComponent,
    UserInfoComponent,
    HamburgerComponent,
    MenuItemComponent,
    PopupCustomComponent,
    PopupCustomContentComponent,
    SandboxComponent,
    PopupConfirmComponent,
    CartTileComponent,
    LogoComponent,
    SelectAutocompleteComponent,
    RestaurantSelectorComponent,
    // SelectContratComponent
  ],
  imports: [
    NguiAutoCompleteModule,
    SharedModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    // NoopAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule, // A privilegier
    routing,
    // features
    AlertesModule,
    RepasModule,
    ContactModule,
    EpicerieInventaireModule,
    ReportingModule,
    OdgModule,
    DocumenthequeModule,
    DiscussionModule,
    BonLivraisonModule,
    // analytics
    AnalyticsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ToastrModule.forRoot({
      maxOpened: 4,
      closeButton: true,
      tapToDismiss: true,
      countDuplicates: true,
      positionClass: 'toast-bottom-center',
      disableTimeOut: false,
      onActivateTick: true,
      enableHtml: true,
      preventDuplicates: true
    }),
  ],
  providers: [
    AppSettingsService,
    SessionStorageService,
    LocalStorageService,
    {
      provide: ApiSettings,
      useClass: ApiSettings // on construit une classe vide qui sera définie dan le onAppInit
    },
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit,
      multi: true,
      deps: [AppSettingsService, ApiSettings]
    },
    {
      provide: ErrorHandler,
      useClass: EliorErrorHandler
    },
    {
      provide: AppSettings,
      useFactory: appSettingsFactory,
      deps: [AppSettingsService]
    },
    // guards
    AuthGuard,
    PromptForNavGuard,
    // services
    AuthenticationApiService, // TODO déplacer dans le providers du SharedModule https://angular-2-training-book.rangle.io/handout/modules/feature-modules.html
    AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerHttpInterceptor, multi: true },
    CommandeRepasApiService,
    CommandeRepasService,
    ParametreApiService,
    LogoRouteService,
    ApplicationDataService,
    ParametreService,
    InteractionService,
    NavigationService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor() {
  }
}
