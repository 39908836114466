import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { asapScheduler, from } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { ROUTE_URLS } from '../shared/constants/route_urls';
import { AllowedFeatureEnum } from '../shared/enums/allowed-feature.enum';
import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { AppSettings } from '../shared/models/app-settings.model';
import { ContratModel } from '../shared/models/contrat.model';
import { MenuLinkModel } from '../shared/models/menu-link.model';
import { RestaurantModel } from '../shared/models/restaurant.model';
import { UserInfoModel } from '../shared/models/user-info.model';
import { ApplicationDataService } from '../shared/services/application-data.service';
import { InteractionService } from '../shared/services/interaction.service';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { DOCUMENT } from '@angular/common';
import { RestaurantContext } from '../restaurant-selector/model/restaurant-context.model';

const { version: appVersion } = require('../../../package.json');

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./styles/layout-header.component.scss', './styles/layout-subheader.component.scss'],
  host: {
    '(document:click)': 'onDocumentClick($event)',
  }
})

export class LayoutHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('menuCheckInput', { static: true })
  menuCheckInput: ElementRef;

  // events
  @Output()
  onShowDrawer = new EventEmitter<void>();

  @Input()
  menuList: MenuLinkModel[];

  // @Input()
  // restaurants: RestaurantModel[] = [];

  @Input()
  contrats: ContratModel[] = [];

  @Input()
  restaurant: RestaurantModel[];

  @Output()
  selectedRestaurantContextChange: EventEmitter<RestaurantContext | null> = new EventEmitter<RestaurantContext | null>();

  @Input()
  showDebugInfo: boolean = false;

  @Input()
  logoContratUrl: string = '';

  hasAccessToAdmin: boolean;
  user: UserInfoModel;
  pageTitle: string = '';
  currentType: RestaurantTypeEnum;
  appVersion: string;

  public hasAccessToContact: boolean;
  public hasAccessToDiscussion: boolean;

  constructor(
    private _router: Router,
    private _appDataService: ApplicationDataService,
    private _authService: AuthenticationService,
    private _interactionService: InteractionService,
    private _appSettings: AppSettings,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {

    this.appVersion = appVersion;
  }

  onDocumentClick(event: any) {
    if (this.menuCheckInput.nativeElement != null) {
      const isChecked = this.menuCheckInput.nativeElement.checked === true;
      if (!this.menuCheckInput.nativeElement.contains(event.target) && isChecked) {
        this.menuCheckInput.nativeElement.checked = false;
      }
    }
  }

  showDrawer() {
    this.onShowDrawer.emit();
  }

  get cartIsVisible(): boolean {
    return this._router.url.indexOf('epicerieinventaire/create') !== -1;
  }

  logOff() {
    this._authService.signOut(true, this._router.routerState.snapshot.url);
  }

  redirectToAdmin() {
    if (this._appSettings.administrationPageUrl != null && this._appSettings.administrationPageUrl !== '') {
      window.open(this._appSettings.administrationPageUrl, '_blank');
    } else {
      this._interactionService.showSimpleMessage('Erreur', 'L\'adresse du backoffice n\'est pas configurée.');
    }
  }

  navigateToContact() {
    this._router.navigate([ROUTE_URLS.Contact]);
  }

  // public goHome(event: Event) {
  //   event.preventDefault();
  //   this.router.navigate([ROUTE_URLS.Home]);
  //   this.swUpdate.available
  //     .pipe(first())
  //     .subscribe(e => {
  //       this.swUpdate.activateUpdate().then(() => document.location.href = (this.platformLocation as any).location.origin);
  //     });
  // }

  ngOnInit() {

    this._authService.currentUser$
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.user = user;

        this.hasAccessToAdmin = user && user.isAdmin;
        this.hasAccessToContact = user && user.hasAccessToFeature(AllowedFeatureEnum.Contacts);
        this.hasAccessToDiscussion = user && user.hasAccessToFeature(AllowedFeatureEnum.Discussion);
      });

    this._appDataService.restaurant$
      .pipe(untilDestroyed(this))
      .subscribe(rest => {
        if (rest) {
          this.currentType = rest.type;
        }
      });

    this._appDataService.pageTitle$
      .pipe(untilDestroyed(this))
      .pipe(observeOn(asapScheduler))
      .subscribe(title => this.pageTitle = title);
  }

  navigateToHome() {
    from(this.router.navigate([ROUTE_URLS.Home]))
      .pipe(untilDestroyed(this))
      .subscribe(navigated => {
        // Force reload
        this.document.location.reload();
      });
  }

  ngOnDestroy() {
    // mandatory for untilDestroyed
  }

  selectedRestaurantContextChanged(selectedRestaurantContext: RestaurantContext) {
   this.selectedRestaurantContextChange.emit(selectedRestaurantContext);
  }
}
