import { RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SandboxComponent } from './sandbox/sandbox.component';
import { AuthGuard } from './shared/guards/auth-guard';
const ɵ0 = { title: 'Connexion' }, ɵ1 = { title: 'Bienvenue' }, ɵ2 = { title: 'Sandbox' };
const routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        component: LoginComponent,
        data: ɵ0
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
        data: ɵ1
    },
    {
        path: 'sandbox',
        component: SandboxComponent,
        data: ɵ2
    },
];
export const routing = RouterModule.forRoot(routes);
export { ɵ0, ɵ1, ɵ2 };
