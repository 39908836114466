import { DiscussionMessageDocumentModel } from './discussion-message-document.model';
import { FileTypeEnum } from './file-type.enum';
import { DiscussionMessageModel } from './discussion-message.model';
import { DiscussionStatusEnum } from './discussion-status.enum';
import { DiscussionModel } from './discussion.model';

import { TypeInterventionApiResponse } from './api/type-intervention.apiresponse';
import { GetDiscussionsApiResponse } from './api/get-discussions.apiresponse';
import { GetDiscussionMessagesApiResponse } from './api/get-discussion-messages.apiresponse';
import { groupBy, min, orderBy } from 'lodash';
import { TypeInterventionGroup, TypeInterventionModel, TypeMaintenanceModel, TypeMaintenanceGroup } from './type-intervention.model';


const FILE_PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
const FILE_PPT = 'application/vnd.ms-powerpoint';

const FILE_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const FILE_DOC = 'application/msword';

const FILE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const FILE_XLS = 'application/vnd.ms-excel';
const nameof = <T>(name: keyof T) => name;

export class DiscussionProjector {

  /** Type Maintenance */
  public static projectTypeInterventions(apiTypes: TypeInterventionApiResponse.TypeIntervention[]): TypeInterventionGroup[] {
    // return apiTypes.map(apiType => {
    //   const type: TypeInterventionModel = {
    //     id: apiType.id,
    //     libelle: apiType.libelle,
    //     groupe: apiType.groupe,
    //     typeNonConformite: apiType.typeNonConformite,
    //     templateMessage: apiType.templateMessage,
    //     descriptionObligatoire: apiType.descriptionObligatoire,
    //     ordre: apiType.ordre,
    //     typeMaintenances: apiType.typeMaintenances.map<TypeMaintenanceModel>(apiTm => ({
    //       id: apiTm.id,
    //       libelle: apiTm.libelle,
    //       groupe: apiTm.groupe,
    //       typeNonConformite: apiTm.typeNonConformite,
    //       templateMessage: apiTm.templateMessage,
    //       descriptionObligatoire: apiTm.descriptionObligatoire,
    //       ordre: apiTm.ordre
    //     }))
    //   };

    //   return type;
    // });
    return this.BuildTypeInterventionGroups(apiTypes);
  }

  private static BuildTypeInterventionGroups(typeInterventions: TypeInterventionApiResponse.TypeIntervention[]): TypeInterventionGroup[] {
    if (!typeInterventions) {
      return [];
    }

    // on groupe les typeIntervention par leur groupe

    // TypeInterventionGroup[]
    //      TypeIntervention[]
    //            TypeMaintenanceGroup[]
    //                  TypeMaintenance[]

    const typeInterventionsByGroupe = groupBy(typeInterventions, ti => ti.groupe || '');
    let typeInterventionGroups: TypeInterventionGroup[] = [];
    // tslint:disable-next-line: forin
    // for (const groupe in typeInterventionsByGroupe) {
    // const groupeTypeInterventions = typeInterventionsByGroupe[groupe];
    for (const [tiGroupe, groupeTypeInterventions] of Object.entries(typeInterventionsByGroupe)) {
      const group: TypeInterventionGroup = {
        groupe: tiGroupe,
        ordre: min(groupeTypeInterventions.map(ti => ti.ordre)),
        typeInterventions: orderBy(groupeTypeInterventions, nameof<TypeInterventionModel>('ordre'))
          .map(ti => {
            const typeMaintenancesByGroupe = groupBy(ti.typeMaintenances, tm => tm.groupe || ti.libelle);
            let typeMaintenanceGroups: TypeMaintenanceGroup[] = Object.entries(typeMaintenancesByGroupe)
              .map(entry => {
                const [tmGroupe, groupeTypeMaintenances] = entry;
                return {
                  groupe: tmGroupe,
                  ordre: min(groupeTypeMaintenances.map(tm => tm.ordre)),
                  typeMaintenances: orderBy(groupeTypeMaintenances, nameof<TypeMaintenanceModel>('ordre'))
                    .map(tm => ({
                      id: tm.id,
                      libelle: tm.libelle,
                      groupe: tm.groupe,
                      typeNonConformite: tm.typeNonConformite,
                      templateMessage: tm.templateMessage || ti.templateMessage, // on hérite du template du type d'intervention
                      descriptionObligatoire: tm.descriptionObligatoire,
                      ordre: tm.ordre
                    })
                    )
                };
              });
            typeMaintenanceGroups = orderBy(typeMaintenanceGroups, nameof<TypeMaintenanceGroup>('ordre'));

            const typeIntervention: TypeInterventionModel = {
              id: ti.id,
              libelle: ti.libelle,
              groupe: ti.groupe,
              typeNonConformite: ti.typeNonConformite,
              templateMessage: ti.templateMessage,
              descriptionObligatoire: ti.descriptionObligatoire,
              ordre: ti.ordre,
              typeMaintenances: typeMaintenanceGroups
            };
            return typeIntervention;
          })
      };
      typeInterventionGroups.push(group);
    }

    typeInterventionGroups = orderBy(typeInterventionGroups, nameof<TypeInterventionGroup>('ordre'));

    return typeInterventionGroups;
  }


  public static projectMessages(userId: string, apiMessages: GetDiscussionMessagesApiResponse.Message[])
    : DiscussionMessageModel[] {
    return apiMessages.map(apiMsg => this.projectMessage(userId, apiMsg));
  }

  public static projectMessage(userId: string, apiMsg: GetDiscussionMessagesApiResponse.Message): DiscussionMessageModel {
    const model: DiscussionMessageModel = {
      dateCreation: apiMsg.dateCreation,
      id: apiMsg.id,
      texte: apiMsg.texte,
      utilisateur: apiMsg.utilisateur,
      fromOtherUser: userId !== apiMsg.utilisateurId,
      documents: this.projectDocuments(apiMsg.documents)
    };
    return model;
  }

  public static projectDocuments(apiDocuments: GetDiscussionMessagesApiResponse.Document[]): DiscussionMessageDocumentModel[] {
    return apiDocuments.map(apiDoc => {
      const model: DiscussionMessageDocumentModel = {
        id: apiDoc.id,
        nomFichier: apiDoc.nomFichier,
        typeMime: apiDoc.typeMime,
        type: this.getTypeFromTypeMime(apiDoc.typeMime),
        extension: this.getExtension(apiDoc.nomFichier),
        hasMiniature: apiDoc.hasMiniature
      };
      return model;
    });
  }

  private static getExtension(fileName: string): string {
    const splitted = fileName.split('.');
    return splitted[splitted.length - 1];
  }

  private static getTypeFromTypeMime(typeMime: string): FileTypeEnum {
    if (!typeMime) {
      return FileTypeEnum.Other;
    } else if (typeMime.startsWith('image/')) {
      return FileTypeEnum.Image;
    } else if (typeMime === FILE_DOC || typeMime === FILE_DOCX) {
      return FileTypeEnum.Word;
    } else if (typeMime === FILE_XLS || typeMime === FILE_XLSX) {
      return FileTypeEnum.Excel;
    } else if (typeMime === FILE_PPT || typeMime === FILE_PPTX) {
      return FileTypeEnum.Powerpoint;
    } else {
      return FileTypeEnum.Other;
    }
  }


  public static projectDiscussions(apiDiscussions: GetDiscussionsApiResponse.Discussion[])
    : DiscussionModel[] {
    return apiDiscussions.map(apiDisc => {
      const disc: DiscussionModel = {
        id: apiDisc.id,
        dateModification: new Date(apiDisc.dateModification),
        dateCreation: new Date(apiDisc.dateCreation),
        lu: apiDisc.lu,
        contratId: apiDisc.contratId,
        contrat: apiDisc.contrat,
        restaurantId: apiDisc.restaurantId,
        restaurant: apiDisc.restaurant,
        sujet: apiDisc.sujet,
        typeInterventionId: apiDisc.typeInterventionId,
        typeIntervention: apiDisc.typeIntervention,
        typeMaintenanceId: apiDisc.typeMaintenanceId,
        typeMaintenance: apiDisc.typeMaintenance,
        typeNonConformite: apiDisc.typeNonConformite,
        statut: this.projectDiscussionStatut(apiDisc.statut),
        peutCloturer: apiDisc.peutCloturer,
        peutRequalifier: apiDisc.peutRequalifier
      };
      return disc;
    });
  }

  private static projectDiscussionStatut(apiStatut: string): DiscussionStatusEnum {
    return DiscussionStatusEnum[apiStatut];
  }

}
