import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map } from 'rxjs/operators';
import { NotificationsModel } from '../alertes/models/notifications.model';
import { AlertesService } from '../alertes/services/alertes.service';
import { MealSliderComponent } from '../meal-slider/meal-slider.component';
import { CommandeRepasService } from '../repas/services/commande-repas.service';
import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { DateHelper } from '../shared/helpers/date.helper';
import { DailyMenuPrestationModel } from '../shared/models/daily-menu/daily-menu-prestation.model';
import { RestaurantModel } from '../shared/models/restaurant.model';
import { LogoRouteService } from '../shared/services/api/logo/logo-route.service';
import { ApplicationDataService } from '../shared/services/application-data.service';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { InteractionService } from '../shared/services/interaction.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild(MealSliderComponent, { static: true })
  mealSlider: MealSliderComponent;

  private _notifications: NotificationsModel;
  dailyQuote: string;
  dailyQuoteTitle: string;
  dailyPrestations: DailyMenuPrestationModel[] = null;
  currentType: RestaurantTypeEnum;
  logoContratUrl: string = '';

  menuList$ = this.authService.getAvailableMenuLinks().pipe(map(menuList => menuList.filter(m => m.isInHomeTile)));

  constructor(
    private cmdRepasService: CommandeRepasService,
    private appDataService: ApplicationDataService,
    private logoService: LogoRouteService,
    private authService: AuthenticationService,
    private interactionService: InteractionService,
    private _alertesService: AlertesService
  ) { }

  subscribeContrat() {
    this.appDataService.contrat$
      .pipe(untilDestroyed(this))
      .subscribe(contrat => {
        if (contrat) {
          this.logoContratUrl = this.logoService.GetPicture1Url(contrat.id, contrat.cuisineCentraleId);
        }
      });
  }

  openAlerte() {
    this._alertesService.showNotifications(this._notifications).subscribe();
  }

  ngOnInit() {
    this.subscribeRestaurant();
    this.subscribeContrat();
    this.subscribeAlertes();
  }

  private subscribeAlertes() {
    this._alertesService.notifications$.pipe(
      untilDestroyed(this),
    ).subscribe(notifications => {
      this._notifications = notifications;
      if (notifications) {
        if (notifications.hasDisplayableNotification) {
          this.dailyQuote = notifications.alerteShortMessage;
          this.dailyQuoteTitle = notifications.titre;
        } else {
          this.dailyQuote = null;
          this.dailyQuoteTitle = null;
        }
        if (notifications.shouldAutoDisplayNotifications) {
          this._alertesService.showNotifications(notifications).subscribe();
        }
      }
    });
  }

  private subscribeRestaurant() {
    this.appDataService.restaurant$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(restaurant => {
        this.currentType = restaurant && restaurant.type;
        this.getDailyMenu(restaurant);
      });
  }

  getDailyMenu(restaurant: RestaurantModel) {
    if (!restaurant) {
      this.dailyPrestations = null;
      return;
    }
    const date = new Date();
    this.cmdRepasService.getMenu(restaurant.cuisineCentraleId, restaurant.id, date)
      .subscribe((prestaModel: DailyMenuPrestationModel[]) => {
        if (prestaModel.length === 0) {
          this.dailyPrestations = null;
          this.interactionService.showSimpleMessage('Erreur', 'Aucun menu n\'a été récupéré pour cette semaine.');
        } else {
          this.dailyPrestations = prestaModel;
          if (this.mealSlider) {
            this.handleSliderInitialIndex();
          }
        }
      },
        err => {
          this.interactionService.showSimpleMessage('Erreur', 'Une erreur est survenue lors de la récupération des menus de la semaine.');
          this.dailyPrestations = null;
        }
      );
  }

  private handleSliderInitialIndex() {
    const now = new Date();
    let firstCurrentSlideIndex: number = null;
    for (let i = 0; i < this.dailyPrestations.length; i++) {
      const presta = this.dailyPrestations[i];
      if (DateHelper.areSameDayOfYear(presta.date, now)) {
        firstCurrentSlideIndex = i;
      }
    }

    if (firstCurrentSlideIndex != null) {
      this.mealSlider.setInitialIndex(firstCurrentSlideIndex);
    }
  }

  ngOnDestroy() {
    // required (untilDestroyed)
  }
}
