
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileResultModel } from '../../shared/models/fileresult.model';
import { DiscussionProjector } from '../models/discussion.projector';
import { DiscussionMessageModel } from '../models/discussion-message.model';
import { DiscussionStatusEnum } from '../models/discussion-status.enum';
import { TypeInterventionGroup } from '../models/type-intervention.model';
import { DiscussionApiService } from './api/discussion.apiservice';
import { ExportSuiviQualiteRequest } from '../models/export-suivi-qualite-request.model';
import { DiscussionUnreadCount } from '../models/discussion-unread-count';

@Injectable()
export class DiscussionService {
  constructor(private discussionApiService: DiscussionApiService) { }

  /** Type Interventions */
  public getTypeInterventionsForContrat(cuisineCentraleId: number, contratId: number): Observable<TypeInterventionGroup[]> {
    return this.discussionApiService.getTypeInterventionsForContrat(cuisineCentraleId, contratId)
      .pipe(
        map(types => DiscussionProjector.projectTypeInterventions(types))
      );
  }

  /** Send Message */
  public sendNewDiscussion(cuisineCentraleId: number, restaurantId: number, typeInterventionId: number, sujet: string, message: string, files: File[], typeMaintenanceId?: number): Observable<boolean> {
    return this.discussionApiService.sendNewDiscussion(cuisineCentraleId, restaurantId, typeInterventionId, sujet, message, files, typeMaintenanceId);
  }

  public requalifierDiscussion(discussionId: number, typeInterventionId: number, typeMaintenanceId?: number) {
    return this.discussionApiService.requalifierDiscussion(discussionId, typeInterventionId, typeMaintenanceId);
  }

  public getDiscussions(isDiscussionsOuvertes: boolean, cuisineCentraleId: number, contratId: number, restaurantId: number, dateDebut: Date, dateFin: Date, searchFilter: string) {
    const statut: DiscussionStatusEnum = isDiscussionsOuvertes ? DiscussionStatusEnum.Ouvert : DiscussionStatusEnum.Ferme;

    return this.discussionApiService.getDiscussions(statut, cuisineCentraleId, contratId, restaurantId, dateDebut, dateFin, searchFilter).pipe(map(discussions => {
      return DiscussionProjector.projectDiscussions(discussions);
    }));
  }

  public getDiscussionMessages(userId: string, discussionId: number): Observable<DiscussionMessageModel[]> {
    return this.discussionApiService.getDiscussionMessages(discussionId).pipe(map(messages => {
      return DiscussionProjector.projectMessages(userId, messages);
    }));
  }

  public sendNewMessage(userId: string, discussionId: number, texte: string, cloturerDiscussion: boolean, files: File[]): Observable<DiscussionMessageModel> {
    // Si succès, on renvoie un faux message pour éviter de reload les messages via l'api
    return this.discussionApiService.sendNewMessage(discussionId, texte, cloturerDiscussion, files).pipe(map(msg => {
      return DiscussionProjector.projectMessage(userId, msg);
    }));
  }

  public lireDiscussion(discussionId: number): Observable<boolean> {
    return this.discussionApiService.lireDiscussion(discussionId);
  }

  public getUnreadCount(): Observable<DiscussionUnreadCount> {
    return this.discussionApiService.getUnreadCount()
      .pipe(
        map(res => {
          return {
            unreadCount: res.unreadCount,
            minDate: new Date(res.minDate),
            maxDate: new Date(res.maxDate)
          };
        })
      );
  }

  public getDocument(documentId: number): Observable<FileResultModel> {
    return this.discussionApiService.getDocument(documentId);
  }
  public getThumbnailSrc(documentId: number) {
    return this.discussionApiService.getThumbnailSrc(documentId);
  }

  public exportEnquetesSatisfaction(request: ExportSuiviQualiteRequest): Observable<FileResultModel> {
    return this.discussionApiService.exportEnquetesSatisfaction(request);
  }
}
