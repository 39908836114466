import { Injectable } from '@angular/core';

import { LogService } from './log.service';
import { StringHelper } from '../helpers/string.helper';
// import { compress, decompress } from "lz-string";

export enum StorageType {
  Local,
  Session
}

export abstract class StorageServiceBase {
  private _storage: Storage;
  private _serviceName: string;

  /**
   * Prefix pour définir un scope sur toutes les clés de stocage
   */
  public keyPrefix: string;

  constructor(storageType: StorageType, private logService: LogService) {
    if (storageType === StorageType.Local) {
      this._storage = localStorage;
      // tslint:disable-next-line: no-use-before-declare
      this._serviceName = LocalStorageService.name;
    } else if (storageType === StorageType.Session) {
      this._storage = sessionStorage;
      // tslint:disable-next-line: no-use-before-declare
      this._serviceName = SessionStorageService.name;
    } else {
      this.logService.Debug(`Bad storageType: ${storageType}`, 'StorageServiceBase');
      throw new Error(`Bad storageType: ${storageType}`);
    }
  }

  public setString(key: string, val: string): void {
    try {
      // this.logService.Debug("Storage key '" + key + "' set to : " + val, this.serviceName);
      // val = compress(val);
      if (val !== null) {
        key = this.buildStorageKey(key);
        this._storage.setItem(key, val);
      } else {
        this.clearItem(key);
      }
    } catch (error) {
      throw error;
    }
  }

  public getString(key: string): string {
    let data = this._storage.getItem(key);
    if (!data && this.keyPrefix) {
      key = this.buildStorageKey(key);
      data = this._storage.getItem(key);
    }
    if (data === 'null') {
      return null;
    } // FIX gestion des relicats pour n problème sur la méthode SetObject
    // on essaie de décompresser la donnée
    try {
      // data = decompress(data);
    } catch (error) {
      // silent fail
      this.logService.Debug('Uncompressed storage key \'' + key + '\' : ' + data, this._serviceName);
    }
    return data;
  }

  public setObject(key: string, val: any): void {
    if (val !== null) {
      const data = JSON.stringify(val);
      this.setString(key, data);
    } else {
      this.clearItem(key);
    }
  }

  public getObject<T>(key: string): T {
    const data = this.getString(key);
    const value: T = StringHelper.jsonParseWithDates(data); // JSON.parse(data);
    return value;
  }

  public clearItem(key: string): void {
    // this.logService.Debug("Clear key : '" + key + "'", this.serviceName);
    this._storage.removeItem(key);
    if (this.keyPrefix) {
      key = this.buildStorageKey(key);
      this._storage.removeItem(key);
    }
  }

  public clear() {
    this._storage.clear();
  }

  /** List helpers */
  public addToList(key: string, itemToAdd: any) {
    // this.logService.Debug(`Adding item ${itemToAdd} in key item ${key}`);
    const existing = this.getObject<any[]>(key);
    if (existing) {
      existing.push(itemToAdd);
      this.setObject(key, existing);
    } else {
      this.setObject(key, [itemToAdd]);
    }
  }

  public deleteFromList(key: string, itemToDelete: any) {
    // this.logService.Debug(`Deleting item ${itemToDelete} in key item ${key}`);
    const existing = this.getObject<any[]>(key);
    if (existing) {
      const index = existing.indexOf(itemToDelete);
      existing.splice(index, 1);
      this.setObject(key, existing);
    }

    // else should not happen
  }

  /**
   * Calcule la clé de storage en tenant compte d'un préfix
   */
  private buildStorageKey(key: string) {
    return (this.keyPrefix || '').length > 0 ? `${this.keyPrefix}-${key}` : key;
  }

}

@Injectable()
export class LocalStorageService extends StorageServiceBase {
  constructor(logService: LogService) {
    super(StorageType.Local, logService);
  }
}

@Injectable()
export class SessionStorageService extends StorageServiceBase {
  constructor(logService: LogService) {
    super(StorageType.Session, logService);
  }
}
