import { AuthTokenModel } from '../models/auth-token.model';
import { AuthTokenApiResponse } from '../models/api/authentication';

export class AuthTokenProjector {
    static projectFromApi(apiModel: AuthTokenApiResponse.AuthData): AuthTokenModel {
        if (!apiModel.refreshToken || !apiModel.refreshToken) {
            throw new Error("RefreshToken or Bearer is missing");
        }
        return {
            refreshToken: apiModel.refreshToken,
            accessToken: apiModel.accessToken
        }
    }
}