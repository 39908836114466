import { ApiSettings } from '../../../models/api/api-settings';
import { Injectable } from '@angular/core';

@Injectable()
export class LogoRouteService {
    private contratControllerRoute: string = "contrats";

    constructor(private apiSettings: ApiSettings) {
    }

    GetLogoUrl(contratId: number, cuisineCentraleId: number) {
        return `${this.apiSettings.apiUrl}/${this.contratControllerRoute}/${contratId}/logo?cuisineCentraleId=${cuisineCentraleId}`; // cuisineCentraleId ajouté en querystring pour le rendre faculatif et gérer simplement la montée de version
    }

    GetPicture1Url(contratId: number, cuisineCentraleId: number) {
        return `${this.apiSettings.apiUrl}/${this.contratControllerRoute}/${contratId}/photo1?cuisineCentraleId=${cuisineCentraleId}`;
    }

    GetPicture2Url(contratId: number, cuisineCentraleId: number) {
        return `${this.apiSettings.apiUrl}/${this.contratControllerRoute}/${contratId}/photo2?cuisineCentraleId=${cuisineCentraleId}`;
    }
}
