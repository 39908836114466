<form class="form-new-discussion form-discussion" (submit)="sendNewDiscussion()" ngNativeValidate autocomplete="off" #form="ngForm">

  <span class="close" (click)="close()">&times;</span>

  <h2>Nouvelle demande / réclamation</h2>
  <div class="form-group">
    <label>Restaurant : </label><span class="form-value">{{ restaurant.name }}</span>
  </div>
  <div class="form-group">
    <label>Type</label>
    <select [disabled]="isSending" *ngIf="isTypeInterventionsVisible" [(ngModel)]="selectedTypeIntervention" name="type-intervention" required>
      <optgroup *ngFor='let grp of typeInterventionGroups' label="{{grp.groupe}}">
        <option *ngFor='let typeIntervention of grp.typeInterventions' [ngValue]="typeIntervention">{{typeIntervention.libelle}}</option>
      </optgroup>
    </select>
    <app-loader *ngIf="isLoadingTypeInterventions" message="Chargement des types..."></app-loader>
    <p *ngIf="!isTypeInterventionsVisible && !isLoadingTypeInterventions" class="error-message">Aucun type n'est disponible pour ce contrat, veuillez contacter un administrateur.</p>
  </div>
  <ng-container [ngSwitch]="isTypeInterventionsVisible && selectedTypeIntervention != null">
    <ng-container *ngSwitchCase="true">
      <div *ngIf="isTypeMaintenancesVisible" class="form-group">
        <label>Catégorie</label>
        <select [disabled]="isSending" [(ngModel)]="selectedTypeMaintenance" name="selectedTypeMaintenance" required #typeMaintenanceCtrl="ngModel">
          <!-- <option [ngValue]="null">- Veuillez sélectionner une catégorie</option> -->
          <optgroup *ngFor='let grp of selectedTypeIntervention.typeMaintenances' label="{{grp.groupe}}">
            <option *ngFor='let typeMaintenance of grp.typeMaintenances' [ngValue]="typeMaintenance">{{typeMaintenance.libelle}}</option>
          </optgroup>
        </select>
        <div *ngIf="(form.submitted || form.dirty || form.touched || form.invalid ) && typeMaintenanceCtrl.invalid && (typeMaintenanceCtrl.dirty || typeMaintenanceCtrl.touched)" class="error-message">La catégorie est requise.</div>
      </div>

      <div class="form-group">
        <label>Objet</label>
        <input [disabled]="isSending" type="text" placeholder="Objet" [(ngModel)]="objet" name="objet" required />
      </div>

      <div class="form-group">
        <textarea [disabled]="isSending" placeholder="Description" [(ngModel)]="description" name="description" [required]="isDescriptionObligatoire ? true: null"></textarea>
      </div>
      <div *ngIf="uploader.queue.length==1 ">1 fichier</div>
      <div *ngIf="uploader.queue.length> 1">{{uploader.queue.length}} fichiers</div>
      <app-file-tag-list [uploader]="uploader">
      </app-file-tag-list>
      <div class="btn-row">
        <!--<button [disabled]="isSending" [restaurantColor]="{'type': restaurantType, 'background':true}" class="generic-button camera-button"
    type="button" (click)="takePicture()" *ngIf="showTakePictureButton"></button>-->
        <button [disabled]="isSending" class="generic-button camera-button restaurant-type-background" type="button" takePhoto [uploader]="uploader" *ngIf="showTakePictureButton"></button>
        <!--<button [disabled]="isSending" [restaurantColor]="{'type': restaurantType, 'background':true}" class="generic-button camera-button"
    type="button" (click)="cameraInput.click()"></button>-->
        <button [disabled]="isSending" class="generic-button upload-button restaurant-type-background" type="button" (click)="fileInput.click()"></button>

        <button [disabled]="isSending" class="generic-button red-tile-button cancel-button" (click)="close($event)"><span>Annuler</span></button>
        <button [disabled]="isSending" type="submit" class="generic-button green-tile-button send-button restaurant-type-background"><span>{{ isSending? 'Envoi...' : 'Envoyer' }}</span></button>
        <!-- not displayed -->
        <input #fileInput name="file-input" type="file" ng2FileSelect [uploader]="uploader" multiple />
        <!--<input #cameraInput name="camera-input" type="file" ng2FileSelect [uploader]="uploader" capture="camera" accept="image/*"/>-->
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <div class="btn-row">
        <button [disabled]="isSending" class="generic-button red-tile-button cancel-button" (click)="close($event)"><span>Annuler</span></button>
      </div>
    </ng-container>
  </ng-container>
</form>
