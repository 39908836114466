import { EventEmitter } from '@angular/core';
import { TypeListeArticleModel } from '../../epicerie-inventaire/models/type-liste-articles.model';
import { FilterBaseModel } from './filter-base.model';

export class EpicerieInventaireFilterModel extends FilterBaseModel {
    loadingData: boolean = true;
    type: string;
    typeListeArticlesEpicerie: TypeListeArticleModel[];
    typeListeArticlesInventaire: TypeListeArticleModel[];
    selectedTypeListeArticles: TypeListeArticleModel[];
    selectedTypeListeArticle: TypeListeArticleModel;
    epicerieInventaireEmitter: EventEmitter<void>;
}