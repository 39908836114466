import { HttpClient, HttpParams } from '@angular/common/http';
import { DateHelper } from 'src/app/shared/helpers/date.helper';
import { FileHelper } from 'src/app/shared/helpers/file.helper';
import { ApiSettings } from 'src/app/shared/models/api/api-settings';
import { LogService } from 'src/app/shared/services/log.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/models/api/api-settings";
import * as i2 from "@angular/common/http";
import * as i3 from "../../shared/services/log.service";
const CONTROLLER_URL = 'enquete';
export class EnquetesApiService {
    constructor(_apiSettings, _http, _logService) {
        this._apiSettings = _apiSettings;
        this._http = _http;
        this._logService = _logService;
    }
    getRestaurantEnquetes(cuisineCentraleId, restaurantId) {
        const url = `${this._apiSettings.apiUrl}/enquetes`;
        return this._http.get(url, {
            params: {
                cuisineCentraleId: cuisineCentraleId.toString(),
                restaurantId: restaurantId.toString()
            }
        });
    }
    repondreEnquete(reponse) {
        const url = `${this._apiSettings.apiUrl}/${CONTROLLER_URL}`;
        return this._http.post(url, reponse);
    }
    exportEnquetesSatisfaction(request) {
        let params = new HttpParams();
        if (request.grandCompteId) {
            params = params.append('grandCompteId', String(request.grandCompteId));
        }
        if (request.cuisineCentraleId) {
            params = params.append('cuisineCentraleId', String(request.cuisineCentraleId));
        }
        if (request.contratId) {
            params = params.append('contratId', String(request.contratId));
        }
        if (request.dateDebut) {
            params = params.append('dateDebut', DateHelper.toISODateString(request.dateDebut));
        }
        if (request.dateFin) {
            params = params.append('dateFin', DateHelper.toISODateString(request.dateFin));
        }
        const url = `${this._apiSettings.apiUrl}/${CONTROLLER_URL}/ExportEnquetesSatisfaction`;
        return FileHelper.getFile(this._http, url, params);
    }
}
EnquetesApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnquetesApiService_Factory() { return new EnquetesApiService(i0.ɵɵinject(i1.ApiSettings), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.LogService)); }, token: EnquetesApiService, providedIn: "root" });
