import { ValidatorFn, AbstractControl } from '@angular/forms';
import { ValidationResult } from '../models/validators/validation-result';
import { NumberValidator } from './number.validator';

export class PrestationValidator {
    static rule6(func: () => number): ValidatorFn {
        if (!func) {
            throw Error('The function given to PrestationValidator.rule6 is undefined');
        }
        return (control: AbstractControl): ValidationResult => {
            let val = <number>control.value;
            if (func() != val) {
                return {
                    rule6: {
                        isValid: false, //TODO revoir la validation, que ce soit IsValid
                        error: "Le nombre de portions de la famille de plat est différent du nombre de convives."
                    }
                }
            }
        };
    }

    static rule5(func: () => number): ValidatorFn {
        if (!func) {
            throw Error('The function given to PrestationValidator.rule5 is undefined');
        }
        return (control: AbstractControl): ValidationResult => {
            let val = <number>control.value;
            if (func() > 0) {
                //s'il y a de l'effectif on lance la validation min=1
                return NumberValidator.range(1)(control);
            }
        };
    }

    static serverRule(errorMsg?: string): ValidatorFn {
        return (control: AbstractControl): ValidationResult => {
            return {
                serverRule: {
                    isValid: true,
                    error: errorMsg
                }
            }
        };
    }

}