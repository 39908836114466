import { Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth-guard';
import { DiscussionComponent } from './discussion.component';
const ɵ0 = { title: 'Demande / Réclamation' };
const DiscussionRoutes = [
    {
        path: 'demande',
        component: DiscussionComponent,
        canActivate: [AuthGuard],
        data: ɵ0
    }
];
export class DiscussionRoutingModule {
}
export { ɵ0 };
