import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FileItem, FileUploader } from "ng2-file-upload";

@Component({
  selector: 'app-file-tag-list',
  templateUrl: './file-tag-list.component.html',
  styleUrls: ['./file-tag-list.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileTagListComponent implements OnInit {
  @Input()
  readonly: boolean;

  @Input()
  uploader: FileUploader;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => this.onAfterAddingFile(fileItem);
  }

  onAfterAddingFile(addedItem: FileItem) {
    let matchingItem: FileItem = null;
    this.cd.markForCheck();
    this.uploader.queue.forEach(item => {
      if (addedItem.file.size == item.file.size
        && addedItem.file.name == item.file.name
        && addedItem.file.type == item.file.type) {
        if (matchingItem) {
          // C'est la deuxième fois qu'on retrouve le fichier, c'est le dernier ajouté donc on enlève l'ancien
          matchingItem.remove();
          return;
        }

        matchingItem = item;
      }
    });
  }

  deleteItem(item: FileItem) {
    if (this.readonly) {
      return;
    }

    item.remove();
  }
}
