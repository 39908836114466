
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { debounceTime, finalize, first } from 'rxjs/operators';
import { RestaurantTypeEnum } from "../shared/enums/restaurant-type.enum";
import { LogoRouteService } from "../shared/services/api/logo/logo-route.service";
import { ApplicationDataService } from "../shared/services/application-data.service";
import { ContactModel } from "./models/contact.model";
import { ContactService } from "./services/contact.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"]
})
export class ContactComponent implements OnInit, OnDestroy {
  /** Subs */
  contratSubscription: Subscription;
  restSubscription: Subscription;

  /** Transverse */
  contratId: number;
  cuisineCentraleId: number;
  logoContratUrl: string = "";
  restType: RestaurantTypeEnum;

  /** Contacts */
  urgenceContacts: ContactModel[];
  contacts: ContactModel[];
  isLoadingContacts: boolean;
  hasContacts: boolean;

  constructor(private appDataService: ApplicationDataService,
    private logoService: LogoRouteService,
    private contactService: ContactService) { }

  /** Ng Lifecycle  */
  ngOnInit() {
    this.handleSubs();
  }

  ngOnDestroy() {
    if (this.contratSubscription)
      this.contratSubscription.unsubscribe();
    if (this.restSubscription)
      this.restSubscription.unsubscribe();
  }

  getContacts() {
    if (!this.contratId) {
      return;
    }

    this.isLoadingContacts = true;
    this.contacts = [];
    this.urgenceContacts = [];

    this.contactService.getContacts(this.contratId, this.cuisineCentraleId).pipe(
      debounceTime(1000),
      first(),
      finalize(() => {
        this.hasContacts = this.contacts.length + this.urgenceContacts.length > 0;
        this.isLoadingContacts = false;
      })).subscribe((contacts: ContactModel[]) => {
        contacts.forEach(c => {
          if (c.estUrgence) {
            this.urgenceContacts.push(c);
          }
          else {
            this.contacts.push(c);
          }
        });
      });
  }

  handleSubs() {
    this.contratSubscription = this.appDataService.contrat$.subscribe(
      contrat => {
        if (contrat != null) {
          this.contratId = contrat.id;
          this.cuisineCentraleId = contrat.cuisineCentraleId;
          this.logoContratUrl = this.logoService.GetPicture2Url(contrat.id, contrat.cuisineCentraleId);

          // Get contacts
          this.getContacts();
        }
      });

    this.restSubscription = this.appDataService.restaurant$.subscribe(
      rest => {
        if (rest != null) {
          this.restType = rest.type;
        }
      });
  }
}
