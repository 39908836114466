
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetContactsApiResponse } from '../models/api/get-contacts.apiresponse';
import { ContactModel } from '../models/contact.model';
import { ContactProjector } from '../projectors/contact.projector';
import { ContactApiService } from './api/contact.apiservice';

@Injectable()
export class ContactService {
  constructor(private contactApiService: ContactApiService) {
  }

  getContacts(contratId: number, cuisineCentraleId: number): Observable<ContactModel[]> {
    return this.contactApiService.getContacts(contratId, cuisineCentraleId).pipe(map((contacts: GetContactsApiResponse.Contact[]) => {
      return ContactProjector.projectContacts(contacts);
    }));
  }
}
