import { BaseJourModel } from '../../shared/models/base-jour.model';
import { GetCommandeRepasApiResponse } from './api/responses/get-commande-repas.apiresponse';

export class PeriodeModel {
  dateReference: Date;
  type: PeriodeContratTypeEnum;
  startDate: Date;
  endDate: Date;
  ratioModification: number;
}

export class PortionModel {
  effectifPortionId: number;
  platId: number;
  plat: string;
  quantitePrecommande?: number;
  quantite: number;
  modeSaisie: ModeSaisiePortionEnum;
  dateModification: string;
  dateExport: string;
  dateImport: string;
  exportRequis: boolean;

  portionTooltipInfo: string;

  historique: GetCommandeRepasApiResponse.HistoriquePortion[];

}

export class FamillePlatModel {
  famillePlatId: number;
  famillePlatOrdre: number;
  famillePlat: string;
  nombreLimitePlats: number;
  portions: PortionModel[];
  isComposanteRestreinte: boolean;
  autoriserEffectifZero: boolean;
}

export class PrestationModel {
  modeSaisie: ModeSaisieEnum;
  autoriserSaisieTauxPrise: boolean;
  prestationOrdre: number;
  effectifId: number;
  prestationId: number;
  prestation: string;
  regimeId?: any;
  regimeLibelle?: any;
  nombreLimiteFamillePlats?: number;
  quantitePrecommande: number;
  quantiteJour: number;
  quantiteJourMin: number;
  quantiteJourMax?: number;
  autoriserSaisie: boolean;
  valide: boolean;
  famillePlats: FamillePlatModel[];
  isCollapsed: boolean;
  regimeData: RegimeDataModel;
  prestationTooltipInfo: string;

  dateModification: string;
  dateExport: string;
  dateImport: string;
  exportRequis: boolean;

  historique: GetCommandeRepasApiResponse.HistoriquePrestation[];
}

export class JourModel extends BaseJourModel {
  periode: PeriodeModel;
  quantitePiqueNique: number;
  prestations: PrestationModel[];
  hasError: boolean;
  periodeTooltipInfo: string = '';

  setIfEmpty(): void {
    this.isEmpty = this.prestations.length === 0;
  }
}

export class RegimeDataModel {
  regimeId: number;
  regimeLibelle: string;
  quantiteJour: number;
  quantiteJourMin: number;
  quantiteJourMax: number;
  quantitePrecommande: number;
  effectifId: number;

  dateModification: string;
  dateExport: string;
  dateImport: string;
  exportRequis: boolean;
}

export class ConviveModel {
  id: number;
  libelle: string;
  abrege: string;
  ordre: number;
  transmis: boolean;
}

export class LieuCommandeModel {
  transmis: boolean;
  id: number;
  libelle: string;
  restaurantId: number;
}

export class SemaineInfosModel {
  libelle: string;
  dateDebut: Date;
  dateFin: Date;
  transmis: boolean;
}

export class CommandeRepasModel {
  transmis: boolean;
  semaines: SemaineInfosModel[];
  autoriserDepassementEffectif: boolean;
  autoriserInsuffisanceEffectif: boolean;
  jourFinSemaine: string;
  jours: JourModel[];
  convives: ConviveModel[];
  lieuCommandes: LieuCommandeModel[];
  modifiePar: string;
  dateModification: string;
  exportRequis: boolean;

}

/** enums  */
export enum ModeSaisieEnum {
  Standard,
  Portion
}

export enum ModeSaisiePortionEnum {
  LectureSeule,
  Quantite,
  TauxPrise,
}

export enum PeriodeContratTypeEnum {
  Precommande,
  Commande,
  Reajustement,
  Reel,
  HorsPeriode
}
