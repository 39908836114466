export class ArticleModel {
    id: string;
    libelle: string;
    unite: string;
    unitPrice: number;
    qteLimite: number | null;
}

export class CartArticleModel extends ArticleModel {
    quantity: number;
}
