import { Pipe, PipeTransform } from '@angular/core';
import { ReceptionStatut } from '../models/bon-livraison.model';

@Pipe({ name: 'formatReceptionStatut' })
export class FormatReceptionStatutPipe implements PipeTransform {
  transform(value: ReceptionStatut): string {
    return this.format(value);
  }

  private format(statut: ReceptionStatut): string {
    switch (statut) {
      case ReceptionStatut.NA: return 'Non réceptionné';
      case ReceptionStatut.Valide: return 'Valide';
      case ReceptionStatut.Manque: return 'Manque';
      case ReceptionStatut.Excedent: return 'Excédent';
    }
    return status;
  }

}
