import { BonLivraisonResponseModel } from './api/bon-livraison-response.model';
import { BonLivraisonModel, LivraisonStatut, ReceptionStatut, MiseEnConformiteStatut, BonLivraisonStatut } from './bon-livraison.model';
import { BonLivraisonListModel } from './bon-livraison-list.model';
import { BonLivraisonPrestationModel } from './bon-livraison-prestation.model';
import { BonLivraisonPrestationEffectifModel } from './bon-livraison-prestation-effectif.model';
import { BonLivraisonFamillePlatModel } from './bon-livraison-famille-plat.model';
import { BonLivraisonComposantModel } from './bon-livraison-composant.model';
import { BonLivraisonPlatModel } from './bon-livraison-plat.model';
import * as moment from 'moment';
import { BonLivraisonTypeNonConformiteModel } from './bon-livraison-type-non-conformite.model';
import { BonLivraisonSettingsModel } from './bon-livraison-settings.model';

function parseDate(dateString?: string): Date | null {
  return dateString != null ? moment(dateString).toDate() : null;
}

export class BonLivraisonProjector {

  public static projectBonLivraison(apiModel: BonLivraisonResponseModel.BonLivraisonApiModel)
    : BonLivraisonModel {
    const model: BonLivraisonModel = {
      ...apiModel,
      ...{
        bonLivraisonPrestations: apiModel.bonLivraisonPrestations.map(BonLivraisonProjector.projectBonLivraisonPrestation),
        nonConformites: apiModel.nonConformites.map(BonLivraisonProjector.projectBonLivraisonTypeNonConformite),
        settings: BonLivraisonProjector.projectBonLivraisonSettings(apiModel.settings),
        // Parse Dates
        dateConsommation: parseDate(apiModel.dateConsommation),
        dateCreation: parseDate(apiModel.dateCreation),
        livraisonDate: parseDate(apiModel.livraisonDate),
        receptionDate: parseDate(apiModel.receptionDate),
        miseEnConformiteDate: parseDate(apiModel.miseEnConformiteDate),
        dateCloture: parseDate(apiModel.dateCloture),
        dateFinReception: parseDate(apiModel.dateFinReception),
        dateDLCMin: parseDate(apiModel.dateDLCMin),
        // Parse Enums
        livraisonStatut: LivraisonStatut[apiModel.livraisonStatut],
        receptionStatut: ReceptionStatut[apiModel.receptionStatut],
        miseEnConformiteStatut: MiseEnConformiteStatut[apiModel.miseEnConformiteStatut],
        statut: BonLivraisonStatut[apiModel.statut]
      }
    } as any;

    return model;
  }

  public static projectBonLivraisonSettings(apiModel: BonLivraisonResponseModel.BonLivraisonSettingsApiModel)
    : BonLivraisonSettingsModel {
    const model: BonLivraisonSettingsModel = {
      ...apiModel,
      ...{
        typeNonConformites: apiModel.typeNonConformites.map(BonLivraisonProjector.projectBonLivraisonTypeNonConformite),
      }
    } as any;

    return model;
  }

  public static projectBonLivraisonListe(apiModel: BonLivraisonResponseModel.BonLivraisonApiListModel)
    : BonLivraisonListModel {
    const model: BonLivraisonListModel = {
      ...apiModel,
      ...{
        // ParseDates
        dateConsommation: parseDate(apiModel.dateConsommation),
        livraisonDate: parseDate(apiModel.livraisonDate),
        receptionDate: parseDate(apiModel.receptionDate),
        miseEnConformiteDate: parseDate(apiModel.miseEnConformiteDate),
        dateCloture: parseDate(apiModel.dateCloture),
        dateFinReception: parseDate(apiModel.dateFinReception),

        // Parse Enums
        livraisonStatut: LivraisonStatut[apiModel.livraisonStatut],
        receptionStatut: ReceptionStatut[apiModel.receptionStatut],
        miseEnConformiteStatut: MiseEnConformiteStatut[apiModel.miseEnConformiteStatut],
        statut: BonLivraisonStatut[apiModel.statut]
      }
    } as any;

    return model;
  }


  public static projectBonLivraisonPrestation(apiModel: BonLivraisonResponseModel.BonLivraisonPrestationApiModel)
    : BonLivraisonPrestationModel {
    const model: BonLivraisonPrestationModel = {
      ...apiModel,
      ...{
        bonLivraisonPrestationEffectifs: apiModel.bonLivraisonPrestationEffectifs.map(BonLivraisonProjector.projectBonLivraisonPrestationEffectif),
        bonLivraisonFamillePlats: apiModel.bonLivraisonFamillePlats.map(BonLivraisonProjector.projectBonLivraisonFamillePlat)
      }
    } as any;

    return model;

  }

  public static projectBonLivraisonPrestationEffectif(apiModel: BonLivraisonResponseModel.BonLivraisonPrestationEffectifApiModel)
    : BonLivraisonPrestationEffectifModel {
    const model: BonLivraisonPrestationEffectifModel = {
      ...apiModel
    } as any;

    return model;
  }

  public static projectBonLivraisonFamillePlat(apiModel: BonLivraisonResponseModel.BonLivraisonFamillePlatApiModel)
    : BonLivraisonFamillePlatModel {
    const model: BonLivraisonFamillePlatModel = {
      ...apiModel,
      ...{ bonLivraisonPlats: apiModel.bonLivraisonPlats.map(BonLivraisonProjector.projectBonLivraisonPlat) }
    } as any;

    return model;
  }

  public static projectBonLivraisonPlat(apiModel: BonLivraisonResponseModel.BonLivraisonPlatApiModel)
    : BonLivraisonPlatModel {
    const model: BonLivraisonPlatModel = {
      ...apiModel,
      ...{
        // ParseDates
        livraisonDLC: parseDate(apiModel.livraisonDLC)
      },
      ...{ bonLivraisonComposants: apiModel.bonLivraisonComposants.map(BonLivraisonProjector.projectBonLivraisonComposant) }
    } as any;

    return model;
  }

  public static projectBonLivraisonComposant(apiModel: BonLivraisonResponseModel.BonLivraisonComposantApiModel)
    : BonLivraisonComposantModel {
    const model: BonLivraisonComposantModel = {
      ...apiModel
    } as any;

    return model;
  }

  public static projectBonLivraisonTypeNonConformite(apiModel: BonLivraisonResponseModel.BonLivraisonTypeNonConformiteApiModel)
    : BonLivraisonTypeNonConformiteModel {
    const model: BonLivraisonTypeNonConformiteModel = {
      ...apiModel
    } as any;

    return model;
  }


}
