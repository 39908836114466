
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateHelper } from '../../../shared/helpers/date.helper';
import { ApiSettings } from '../../../shared/models/api/api-settings';
import { LogService } from '../../../shared/services/log.service';
import { PostOdgApiRequest } from '../../models/api/requests/post-odg.apirequest';
import { GetOdgApiResponse } from '../../models/api/responses/get-odg.apiresponse';

@Injectable()
export class OdgApiService {
  private controllerUrl: string = 'odg';

  constructor(private apiSettings: ApiSettings, private http: HttpClient, private logService: LogService) { }

  getOdg(cuisineCentraleId: number, restaurantId: number, odgConviveId: number, date: Date): Observable<GetOdgApiResponse.Odg> {
    let httpParams = new HttpParams()
      .set('cuisineCentraleId', cuisineCentraleId.toString())
      .set('restaurantId', restaurantId.toString())
      .set('date', DateHelper.toISODateString(date));

    if (odgConviveId) { httpParams = httpParams.set('odgConviveId', odgConviveId.toString()); }

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/get` + (this.apiSettings.mockOdgService ? 'Mock' : '');
    return this.http.get<GetOdgApiResponse.Odg>(url, { params: httpParams });
  }

  postOdg(odg: PostOdgApiRequest.Odg): Observable<boolean> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/post` + (this.apiSettings.mockOdgService ? 'Mock' : '');
    return this.http.post(url, odg).pipe(map(res => {
      return true;
    }));
  }
}
