import { Injectable } from '@angular/core';
import { AppSettings } from '../models/app-settings.model';
import { environment } from '../../../environments/environment';
@Injectable()
export class AppSettingsService {

  appSettings: AppSettings;

  constructor() {
    // console.log("constructing config service");
  }

  /**
   * Charge la configuration à partir d'un fichier sur le serveur
   */
  public load(): Promise<void | AppSettings> {
    const url = 'client-settings.json';
    // On utilise un XmlHttpRequest (ou fetch) plutôt que HttpClient pour éviter l'injection des dépendances des interceptors
    // see: https://sebastienollivier.fr/blog/angular/pourquoi-il-ne-faut-pas-utiliser-httpclient-dans-appinitializer
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url);

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            this.appSettings = this.appSettings || new AppSettings();
            Object.assign(this.appSettings, JSON.parse(xhr.responseText));
            resolve(this.appSettings);
          } else if (environment.FallBackAppSettings) {
            const err = xhr.statusText;
            console.log(`appsettings endpoint not reachable (${err}) => fallback to local environnement settings`);
            this.appSettings = environment.FallBackAppSettings;
            resolve(this.appSettings);
          } else {
            const err = 'appsettings endpoint not reachable';
            console.log(err);
            reject(err);
          }
        }
      });

      xhr.send(null);
    });
  }

}
