import { BonLivraisonPrestationModel } from './bon-livraison-prestation.model';
import { BonLivraisonTypeNonConformiteModel } from './bon-livraison-type-non-conformite.model';
import { BonLivraisonSettingsModel } from './bon-livraison-settings.model';

export interface BonLivraisonModel {
  bonLivraisonId?: number; // int32
  code?: string;
  tournee?: string;
  dateConsommation?: Date; // date-time
  dateCreation?: Date; // date-time
  utilisateurCreation?: string;
  cuisineId?: number; // int32
  societeJuridique?: string;
  numeroAgrement?: string;
  contratId?: number; // int32
  contratLibelle?: string;
  restaurantId?: number; // int32
  restaurantLibelle?: string;
  restaurantCode?: string;
  restaurantAdresse?: string;
  restaurantCodePostal?: string;
  restaurantVille?: string;
  restaurantTel?: string;
  restaurantOrdre?: number; // int32
  informations?: string;
  livraisonStatut?: LivraisonStatut;
  livraisonDate?: Date; // date-time
  livraisonUtilisateur?: string;
  livraisonCommentaire?: string;
  livraisonSignature?: string;
  receptionStatut?: ReceptionStatut;
  receptionDate?: Date; // date-time
  receptionCommentaire?: string;
  receptionUtilisateur?: string;
  miseEnConformiteStatut?: MiseEnConformiteStatut;
  miseEnConformiteDate?: Date; // date-time
  miseEnConformiteCommentaire?: string;
  miseEnConformiteUtilisateur?: string;
  nonConformites: BonLivraisonTypeNonConformiteModel[];

  bonLivraisonPrestations?: BonLivraisonPrestationModel[];

  statut: BonLivraisonStatut;

  peutLivrer: boolean;
  peutReceptionner: boolean;
  peutMettreEnConformite: boolean;

  dateCloture: Date;
  dateClotureExpired: boolean;

  dateFinReception: Date;
  dateFinReceptionExpired: boolean;

  dateDLCMin: Date;

  settings: BonLivraisonSettingsModel;
}

/** enums  */
export enum LivraisonStatut {
  NonLivre,
  Conforme,
  NonConforme
}

export enum ReceptionStatut {
  NA,
  Valide,
  Manque,
  Excedent
}

export enum MiseEnConformiteStatut {
  NA,
  ARelivrer,
  NonConforme,
  Conforme
}

export enum BonLivraisonStatut {
  ALivrer,
  AReceptionner, // Orange
  Manque, // Orange
  Conforme, // Vert
  AReceptionnerCloture, // Vert clair
  ManqueCloture, // Vert clair
  NonConforme // Rouge
}
