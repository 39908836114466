<div class="infos-container">
  <h2>Synthèse</h2>

  <div class="info-container header">
    <div class="info-group">
      <div class="info-item info-code">
        <label class="info-label">Code</label><span class="info-value">{{ bonLivraison.code }}</span>
      </div>
      <div class="info-item info-dateConsommation">
        <label class="info-label">Consommation</label><span class="info-value">{{ bonLivraison.dateConsommation | dateFormat}}</span>
      </div>
      <div class="info-item info-statut">
        <label class="info-label">Statut</label><span class="info-value statut-dot statut-{{ BonLivraisonStatutEnum[bonLivraison.statut] }}">{{ bonLivraison.statut | formatBonLivraisonStatut }}</span>
      </div>
    </div>
  </div>

  <div class="info-container livraison" *ngIf="bonLivraison.livraisonStatut != LivraisonStatutEnum.NonLivre">
    <div class="info-group">
      <div class="info-item">
        <label class="info-label">Livraison</label><span class="info-value">{{ bonLivraison | formatLivraisonStatut }}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Le</label><span class="info-value" >{{ bonLivraison.livraisonDate | dateFormat:'DD/MM/YYYY à HH:mm'}}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Par</label><span class="info-value">{{ bonLivraison.livraisonUtilisateur}}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Limite de réception<span [class.date-expired]="notifyDateFinReceptionExpired">{{notifyDateFinReceptionExpired ? " (expirée)": ""}}</span></label><span class="info-value" [class.date-expired]="notifyDateFinReceptionExpired">{{ bonLivraison.dateFinReception | dateFormat:'DD/MM/YYYY HH:mm'}}</span>
      </div>
    </div>
    <div class="commentaire-container" *ngIf="bonLivraison.livraisonCommentaire">
      <h5>COMMENTAIRE</h5>
      <div class="commentaire">{{ bonLivraison.livraisonCommentaire }}</div>
    </div>
    <div class="container-releve-livraison">
      <div class="container-temperature" *ngIf="releveLivraison && releveLivraison.temperaturePlats.length > 0">
        <h5>TEMPERATURE</h5>
        <div class="temperature-item" *ngFor="let rPlat of releveLivraison.temperaturePlats">
          <div class="temperature temperature-color-{{ TemperatureStatusEnum[rPlat.status] }}">{{ rPlat.plat.livraisonTemperature | formatTemperature:'°C' }}</div>
          <div class="plat">{{ rPlat.plat.libelle }}</div>
        </div>
      </div>
      <div class="container-dlc" *ngIf="releveLivraison && releveLivraison.dlcPlats.length > 0">
        <h5>DLC/DDM</h5>
        <div class="dlc-item" *ngFor="let rPlat of releveLivraison.dlcPlats">
          <div class="dlc dlc-color-{{ DLCStatusEnum[rPlat.status] }}">{{ rPlat.plat.livraisonDLC | dateFormat }}</div>
          <div class="plat">{{ rPlat.plat.libelle }}</div>
        </div>
      </div>
    </div>
    <div class="signature-image-container" *ngIf="bonLivraison.livraisonSignature">
      <img src="{{bonLivraison.livraisonSignature}}" width="50%" />
    </div>
  </div>

  <div class="info-container reception" *ngIf="bonLivraison.receptionStatut != ReceptionStatutEnum.NA">
    <div class="info-group">
      <div class="info-item">
        <label class="info-label">Réception</label><span class="info-value">{{ bonLivraison.receptionStatut | formatReceptionStatut }}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Le</label><span class="info-value" >{{ bonLivraison.receptionDate | dateFormat:'DD/MM/YYYY à HH:mm'}}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Par</label><span class="info-value">{{ bonLivraison.receptionUtilisateur}}</span>
      </div>
    </div>
    <div class="commentaire-container" *ngIf="bonLivraison.receptionCommentaire">
      <h5>COMMENTAIRE</h5>
      <div class="commentaire">{{ bonLivraison.receptionCommentaire }}</div>
    </div>
  </div>

  <div class="info-container miseenconformite" *ngIf="bonLivraison.miseEnConformiteStatut != MiseEnConformiteStatutEnum.NA">
    <div class="info-group">
      <div class="info-item">
        <label class="info-label">Conformité</label><span class="info-value">{{ bonLivraison.miseEnConformiteStatut | formatMiseEnConformiteStatut }}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Le</label><span class="info-value" >{{ bonLivraison.miseEnConformiteDate | dateFormat:'DD/MM/YYYY à HH:mm'}}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Par</label><span class="info-value">{{ bonLivraison.miseEnConformiteUtilisateur}}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Clôture <span [class.date-expired]="notifyDateClotureExpired">{{notifyDateClotureExpired ? " (expirée)": ""}}</span></label><span class="info-value" [class.date-expired]="bonLivraison.dateClotureExpired">{{ bonLivraison.dateCloture | dateFormat:'DD/MM/YYYY à HH:mm'}}</span>
      </div>
      <div class="info-item container-non-conformites" *ngIf="bonLivraison.nonConformites.length > 0">
        <label class="info-label">Non conformité{{ bonLivraison.nonConformites.length > 1 ? 's' : '' }} :</label>
        <span *ngFor="let nonConformite of bonLivraison.nonConformites">- {{nonConformite.libelle}}</span>
      </div>
    </div>
    <div class="commentaire-container" *ngIf="bonLivraison.miseEnConformiteCommentaire">
      <h5>COMMENTAIRE</h5>
      <div class="commentaire">{{ bonLivraison.miseEnConformiteCommentaire }}</div>
    </div>
  </div>
</div>
