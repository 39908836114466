import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { BonLivraisonModel, BonLivraisonStatut, ReceptionStatut, MiseEnConformiteStatut, LivraisonStatut } from '../models/bon-livraison.model';
import { ReleveLivraison, BonLivraisonService } from '../services/bon-livraison.service';
import { BonLivraisonTemperatureStatus } from '../models/bon-livraison-temperature-status.model';
import { BonLivraisonDLCStatus } from '../models/bon-livraison-dlc-status.model';

@Component({
  selector: 'app-bonlivraison-info-panel',
  templateUrl: './bonlivraison-info-panel.component.html',
  styleUrls: ['./bonlivraison-info-panel.component.scss']
})
export class BonlivraisonInfoPanelComponent implements OnInit, OnChanges {

  @Input() public bonLivraison: BonLivraisonModel;

  // declare enum property to allow use in component template. see: http://stackoverflow.com/a/40034109/590741
  public BonLivraisonStatutEnum: typeof BonLivraisonStatut = BonLivraisonStatut; // tslint:disable-line: variable-name
  public LivraisonStatutEnum: typeof LivraisonStatut = LivraisonStatut;  // tslint:disable-line: variable-name
  public ReceptionStatutEnum: typeof ReceptionStatut = ReceptionStatut;  // tslint:disable-line: variable-name
  public MiseEnConformiteStatutEnum: typeof MiseEnConformiteStatut = MiseEnConformiteStatut;  // tslint:disable-line: variable-name
  public TemperatureStatusEnum: typeof BonLivraisonTemperatureStatus = BonLivraisonTemperatureStatus; // tslint:disable-line: variable-name
  public DLCStatusEnum: typeof BonLivraisonDLCStatus = BonLivraisonDLCStatus; // tslint:disable-line: variable-name

  public releveLivraison: ReleveLivraison;

  public get notifyDateFinReceptionExpired() {
    return this.bonLivraison
      && this.bonLivraison.dateFinReceptionExpired
      && !this.bonLivraison.dateClotureExpired;
  }

  public get notifyDateClotureExpired() {
    // on met en évidence la Clotûre lorsque le bon a été cloturé automatiquement (statut ConformeCloture)
    return this.bonLivraison
      && this.bonLivraison.dateClotureExpired
      && (this.bonLivraison.statut === BonLivraisonStatut.AReceptionnerCloture
        || this.bonLivraison.statut === BonLivraisonStatut.ManqueCloture);
  }


  constructor(private _bonLivraisonService: BonLivraisonService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.releveLivraison = this.bonLivraison && this._bonLivraisonService.getReleveLivraison(this.bonLivraison);
  }
}
