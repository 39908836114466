import { Injectable } from '@angular/core';
import { includes } from 'lodash';
import * as moment from 'moment';
import { EMPTY, forkJoin, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { FileResultModel } from 'src/app/shared/models/fileresult.model';
import { NotificationCustomModel } from 'src/app/shared/models/notification.model';
import { RestaurantModel } from 'src/app/shared/models/restaurant.model';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { LocalStorageService } from 'src/app/shared/services/browser-storage.service';
import { InteractionService } from 'src/app/shared/services/interaction.service';
import { LogService } from 'src/app/shared/services/log.service';
import { PopupNotificationsComponent } from '../components/popup-notifications/popup-notifications.component';
import { AlerteModel } from '../models/alertes.model';
import { EnqueteModel } from '../models/enquete.model';
import { ExportEnquetesSatisfactionRequest } from '../models/export-enquetes-satisfaction-request.model';
import { NotificationsModel } from '../models/notifications.model';
import { PopupNotificationsModel } from '../models/popup-notifications.model';
import { ReceivedAlerteModel } from '../models/received-alert.model';
import { ReceivedEnqueteModel } from '../models/received-enquete.model';
import { AlertesApiService } from './alertes-api.service';
import { EnquetesApiService } from './enquetes-api.service';


const RECEIVEDALERTES_KEY = 'received_Alertes';
const RECEIVEDENQUETES_KEY = 'received_Enquetes';

@Injectable({
  providedIn: 'root'
})
export class AlertesService {

  private _notificationUpdatedSubject = new Subject<NotificationsModel>();

  // notifications$ = this.notificationsSubject.asObservable();
  // notifications$ = combineLatest([
  //   this._appDataService.restaurant$
  //     .pipe(
  //       distinctUntilChanged(),
  //       debounceTime(300)
  //     ),
  //   this._notificationUpdatedSubject
  // ])
  //   .pipe(
  //     map(([restaurant, dummyRefreshToken]) => restaurant),
  //     switchMap(restaurant => this.getNotifications(restaurant)),
  //     shareReplay(1)
  //   );
  notifications$: Observable<NotificationsModel> = merge(
    this._appDataService.restaurant$
      .pipe(
        distinctUntilChanged(),
        debounceTime(300),
        switchMap(restaurant => this.getNotifications(restaurant))
      ),
    this._notificationUpdatedSubject.asObservable()
  )
    .pipe(
      shareReplay(1)
    );

  constructor(private _alertesApi: AlertesApiService,
              private _enquetesApi: EnquetesApiService,
              private _logService: LogService,
              private _localStorageService: LocalStorageService,
              private _interactionService: InteractionService,
              private _appDataService: ApplicationDataService

  ) { }

  private getRestaurantAlertes(cuisineCentraleId: number, restaurantId: number): Observable<AlerteModel[]> {
    return this._alertesApi.getRestaurantAlertes(cuisineCentraleId, restaurantId);
  }

  private getRestaurantEnquetes(cuisineCentraleId: number, restaurantId: number): Observable<EnqueteModel[]> {
    return this._enquetesApi.getRestaurantEnquetes(cuisineCentraleId, restaurantId);
  }

  getNotifications(restaurant: RestaurantModel): Observable<NotificationsModel> {
    if (!restaurant) {
      return null;
    }

    return forkJoin([
      this.getRestaurantAlertes(restaurant.cuisineCentraleId, restaurant.id),
      this.getRestaurantEnquetes(restaurant.cuisineCentraleId, restaurant.id)
    ])
      .pipe(
        map(([alertes, enquetes]) => {
          const { receivedAlertes, receivedEnquetes } = this.loadReceivedNotifications(restaurant, alertes, enquetes);
          return new NotificationsModel(restaurant, alertes, receivedAlertes, enquetes, receivedEnquetes);
        })
      );
  }

  showNotifications(notifications: NotificationsModel): Observable<boolean> {
    if (!notifications.hasDisplayableNotification) {
      return EMPTY;
    }

    return new Observable<boolean>(subscriber => {
      const notif: NotificationCustomModel = new NotificationCustomModel();
      notif.title = notifications.titre;
      notif.id = 1;
      notif.type = PopupNotificationsComponent;
      notif.fullScreen = true;
      notif.closeOnBackdropClick = true;
      const popupModel: PopupNotificationsModel = { notifications };
      notif.data = popupModel;
      notif.hasNoOverflow = true;
      notif.onClose = () => {
        this.pushEnqueteReponses(notifications)
          .pipe(
            finalize(() => {
              // dans tous les cas (erreur compris) on stocke et notifie la fin du traitement
              this.storeReceivedNotification(notifications);
              this.notifyNotificationsUpdated(notifications);
              subscriber.next(true);
              subscriber.complete();
            })
          ).subscribe();
      };

      this._interactionService.showNotification(notif);
    });
  }

  private loadReceivedNotifications(restaurant: RestaurantModel,
                                    alertes: AlerteModel[],
                                    enquetes: EnqueteModel[]): { receivedAlertes: ReceivedAlerteModel[], receivedEnquetes: ReceivedEnqueteModel[] } {

    let receivedAlertes = this._localStorageService.getObject<ReceivedAlerteModel[]>(RECEIVEDALERTES_KEY) || [];
    let receivedEnquetes = this._localStorageService.getObject<ReceivedEnqueteModel[]>(RECEIVEDENQUETES_KEY) || [];

    // purge des élements expirés
    const now = moment();
    receivedAlertes = receivedAlertes.filter(r => !r.expirationDate || moment(r.expirationDate).isAfter(now));
    receivedEnquetes = receivedEnquetes.filter(r => !r.expirationDate || moment(r.expirationDate).isAfter(now));

    // ajout des nouveau éléments
    // - Alertes
    const newReceivedAlertes = alertes
      .filter(a => !includes(receivedAlertes.map(ra => ra.alerteId), a.alerteId))
      .map((a) => {
        return {
          alerteId: a.alerteId,
          showAfterDate: now.startOf('day').toDate(), // on affiche
          expirationDate: this.getExpirationDate(a.dateFin, a.recurrence),
          isRead: false
        } as ReceivedAlerteModel;
      });
    receivedAlertes.push(...newReceivedAlertes);
    // - Enquetes
    const newReceivedEnquetes = enquetes
      .filter(e => !receivedEnquetes.some(re => re.enqueteId === e.enqueteId
        && re.cuisineCentraleId === restaurant.cuisineCentraleId
        && re.restaurantId === restaurant.id))
      .map(e => {
        return {
          enqueteId: e.enqueteId,
          cuisineCentraleId: restaurant.cuisineCentraleId,
          restaurantId: restaurant.id,
          showAfterDate: now.startOf('day').toDate(), // on affiche
          expirationDate: this.getExpirationDate(e.dateFin, e.recurrence)
        } as ReceivedEnqueteModel;
      });
    receivedEnquetes.push(...newReceivedEnquetes);

    return { receivedAlertes, receivedEnquetes };
  }

  private storeReceivedNotification(notifications: NotificationsModel) {
    // on recalcule les prochaines dates d'affichage
    const now = moment();
    notifications.alertesWithState
      .forEach(a => {
        a.state.showAfterDate = (a.state.isRead)
          ? null // this.getNextPromptDate(a.alerte.recurrence)
          : now.endOf('day').toDate(); // si pas lue => on réaffiche le lendemain
      });
    notifications.enquetesWithState
      .forEach(e => {
        e.state.showAfterDate = (e.state.note)
          ? null // this.getNextPromptDate(e.enquete.recurrence)
          : now.endOf('day').toDate(); // si pas répondu => on réaffiche le lendemain
      });

    // on persiste les states des notifications
    this._localStorageService.setObject(RECEIVEDALERTES_KEY, notifications.receivedAlertes);
    this._localStorageService.setObject(RECEIVEDENQUETES_KEY, notifications.receivedEnquetes);
  }

  // envoie au serveur la/les réponses aux enquetes
  private pushEnqueteReponses(notifications: NotificationsModel): Observable<any> {
    return forkJoin(notifications.receivedEnquetes
      .filter(re => re.note && !re.serverPersisted) // dès qu'une note est présente et non transmise
      .map(re => this.repondreEnquete(re))
    );
  }

  private repondreEnquete(receivedEnquete: ReceivedEnqueteModel): Observable<any> {
    if (!receivedEnquete.note) {
      throw new Error(`(RepondreEnquete) Erreur : L'enquete ${receivedEnquete.enqueteId} n'a pas été notée.`);
    }

    return this._enquetesApi.repondreEnquete({
      enqueteId: receivedEnquete.enqueteId,
      cuisineCentraleId: receivedEnquete.cuisineCentraleId,
      restaurantId: receivedEnquete.restaurantId,
      note: receivedEnquete.note,
      commentaire: receivedEnquete.commentaire
    }).pipe(
      tap(() => receivedEnquete.serverPersisted = true)
    );
  }

  // private getNextPromptDate(recurrence: 'Unique' | 'Hebdomadaire' | 'Mensuelle'): Date {
  //   const now = moment();
  //   switch (recurrence) {
  //     case 'Hebdomadaire':
  //       // on réaffiche la semaine suivante
  //       return now.startOf('day').add(1, 'week').startOf('week').toDate();
  //     case 'Mensuelle':
  //       // on réaffiche le mois suivant
  //       return now.startOf('day').startOf('month').add(1, 'month').toDate();
  //     default: // "Unique"
  //       return null; // on ne réaffiche pas
  //   }
  // }

  /**
   *  Calcul la date d'expiration en fonction de la récurrence de l'élément et de sa date de fin
   *  on expire dès que l'élément n'a plus lieu (dateFin) ou avant la prochaine récurrence
   */
  private getExpirationDate(dateFin: Date, recurrence: 'Unique' | 'Hebdomadaire' | 'Mensuelle'): Date {
    // inner function (pour pas polluler le service)
    function getNextExpirationDate(r: 'Unique' | 'Hebdomadaire' | 'Mensuelle'): Date {
      const now = moment();
      switch (r) {
        case 'Hebdomadaire':
          // on réaffiche la semaine suivante
          return now.endOf('week').toDate();
        case 'Mensuelle':
          // on réaffiche le mois suivant
          return now.endOf('month').toDate();
        default: // "Unique"
          return null; // on ne réaffiche pas
      }
    }

    const expirationDate = getNextExpirationDate(recurrence);
    if (expirationDate === null) {
      // si pas d'expiration selon la récurrence (Unique) alors date de fin de l'alerte/enquete
      return dateFin;
    } else if (dateFin) {
      // sinon on prends la date minimum
      return moment(dateFin).isBefore(expirationDate) ? dateFin : expirationDate;
    }

    return expirationDate;
  }

  private notifyNotificationsUpdated(notifications: NotificationsModel) {
    this._notificationUpdatedSubject.next(notifications);
  }


  exportEnquetesSatisfaction(request: ExportEnquetesSatisfactionRequest): Observable<FileResultModel> {
    return this._enquetesApi.exportEnquetesSatisfaction(request);
  }

}
