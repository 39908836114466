import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { PopupCustomComponentGeneric } from 'src/app/popup-custom/popup-custom-content.component';
import { ErrorApiModel } from 'src/app/shared/models/api/error/error.apimodel';
import { InteractionService } from 'src/app/shared/services/interaction.service';
import { BonLivraisonDLCStatus } from '../../models/bon-livraison-dlc-status.model';
import { BonLivraisonPlatLivraisonModel } from '../../models/bon-livraison-plat-livraison.model';
import { BonLivraisonTemperatureStatus } from '../../models/bon-livraison-temperature-status.model';
import { BonLivraisonStatut } from '../../models/bon-livraison.model';
import { BonLivraisonService, PlatDlcStatus, PlatTemperatureStatus, ReleveLivraison } from '../../services/bon-livraison.service';
import { LivraisonSignatureComponent } from '../livraison-signature/livraison-signature.component';
import { LivraisonPopupValidationModel } from './livraison-popup-validation.model';

@Component({
  selector: 'app-livraison-popup-validation',
  templateUrl: './livraison-popup-validation.component.html',
  styleUrls: ['./livraison-popup-validation.component.scss']
})
export class LivraisonPopupValidationComponent extends PopupCustomComponentGeneric<LivraisonPopupValidationModel> implements OnInit, OnDestroy {

  @ViewChild('form', { static: true })
  public form: NgForm; // FormGroup

  @ViewChild(LivraisonSignatureComponent, { static: false })
  public signatureControl: LivraisonSignatureComponent;

  public isLoading: boolean = false;
  public erreurValidationMessage: string;

  //#region data property override
  private _data: any;
  public get data(): any {
    return this._data;
  }
  public set data(data: any) {
    this._data = data;
    this.loadModel();
  }
  //#endregion

  private _relevesLivraison: ReleveLivraison;
  public get nonConformiteDlcPlats(): PlatDlcStatus[] { return this._relevesLivraison && this._relevesLivraison.nonConformiteDlcPlats; }
  public get nonConformiteTemperaturePlats(): PlatTemperatureStatus[] { return this._relevesLivraison && this._relevesLivraison.nonConformiteTemperaturePlats; }

  public commentaire: string;
  public tournee: string;
  public livraisonDate: Date = new Date();

  // declare enum property to allow use in component template. see: http://stackoverflow.com/a/40034109/590741
  public TemperatureStatusEnum: typeof BonLivraisonTemperatureStatus = BonLivraisonTemperatureStatus; // tslint:disable-line: variable-name
  public DLCStatusEnum: typeof BonLivraisonDLCStatus = BonLivraisonDLCStatus; // tslint:disable-line: variable-name


  public get hasNonConformiteTemperature(): boolean {
    return this.nonConformiteTemperaturePlats && this.nonConformiteTemperaturePlats.length > 0;
  }

  public get hasNonConformiteDlc(): boolean {
    return this.nonConformiteDlcPlats && this.nonConformiteDlcPlats.length > 0;
  }

  public get hasPlatFaults(): boolean {
    // Manque ou Excédent
    return this.hasNonConformiteTemperature || this.hasNonConformiteDlc;
  }

  public get allowLivraison(): boolean {
    if (!this.model.bonLivraison.peutLivrer) {
      return false;
    }
    // on affiche le détail de livraison dès lors qu'il y a eu une modification où que le bon doit être déclaré livré
    return this.model.hasModification
      || this.model.bonLivraison.statut === BonLivraisonStatut.ALivrer;
  }

  public get isCommentaireRequired() {
    // le commentaire est requis seulement en cas de non-conformite
    return this.hasPlatFaults;
  }

  public showNonConformePrompt: boolean = false;

  constructor(
    private _bonLivraisonService: BonLivraisonService,
    private _router: Router,
    private _interactionService: InteractionService
  ) {
    super();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  private loadModel() {
    this._relevesLivraison = this._bonLivraisonService.getReleveLivraison(this.model.bonLivraison);
    this.commentaire = this.model.bonLivraison.livraisonCommentaire;
    this.tournee = this.model.bonLivraison.tournee;
  }

  DeclarerLivraison() {
    this.erreurValidationMessage = '';

    if (!this.validateForm()) {
      if (!this.checkSignature()) {
        this.erreurValidationMessage = 'Signature requise';
      }
      return;
    }

    this.isLoading = true;
    this.livrerBon()
      .subscribe(success => {
        // this.isValidatedReception = true;
        this.isLoading = false;

        this.hide();

        const remainingDuration = moment.duration(this.model.bonLivraison.settings.delaisReception).locale('fr').humanize();
        this._interactionService.showSimpleMessage('Déclaration de livraison',
        `Votre déclaration de livraison a été prise en compte. \nVous avez ${remainingDuration} pour faire la réception.`);
        this._router.navigate(['/bonlivraison/details/' + this.model.bonLivraison.code], { queryParams: {} });
      },
        (error: ErrorApiModel) => {
          // this.isValidatedReception = false;
          this.isLoading = false;
          this.erreurValidationMessage = error.Message;
        }
      );
  }

  private livrerBon() {

    const releves: BonLivraisonPlatLivraisonModel[]
      = Array.from(
        new Set(this._relevesLivraison.dlcPlats.map(n => n.plat)
          .concat(this._relevesLivraison.temperaturePlats.map(n => n.plat)))
      )
        .map(p => ({
          bonLivraisonPlatId: p.bonLivraisonPlatId,
          livraisonTemperature: p.livraisonTemperature,
          livraisonDLC: p.livraisonDLC
        }));

    const signatureImage = this.signatureControl.toDataURL();
    return this._bonLivraisonService.declarerBonLivre(this.model.bonLivraison.code, releves, this.commentaire, signatureImage, this.tournee);

  }

  hide() {
    this.emitter.next();
  }

  private validateForm(): boolean {
    this.validateAllFormFields(this.form.form);
    return this.form.valid
      && this.checkSignature();
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  scrollToElement(targetElement: HTMLElement) {
    targetElement.scrollIntoView();
  }

  private checkSignature(): boolean {
    return (!!this.model.bonLivraison.livraisonSignature) || (this.signatureControl && !this.signatureControl.isEmpty());
  }
}
