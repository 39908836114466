import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { CartComponent } from './cart/cart.component';
import { ArticleComponent } from './create/article/article.component';
import { EpicerieInventaireCreateComponent } from './create/epicerie-inventaire-create.component';
import { EpicerieInventaireRoutingModule } from './epicerie-inventaire-routing.module';
import { EpicerieInventaireComponent } from './epicerie-inventaire.component';
import { EpicerieInventaireApiService } from './services/api/epicerie-inventaire.apiservice';
import { EpicerieInventaireService } from './services/epicerie-inventaire.service';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    EpicerieInventaireRoutingModule
  ],
  providers: [
    EpicerieInventaireApiService,
    EpicerieInventaireService,
  ],
  declarations: [
    EpicerieInventaireComponent,
    EpicerieInventaireCreateComponent,
    ArticleComponent,
    CartComponent]
})
export class EpicerieInventaireModule { }
