<!-- mobile -->
<div class="container">
	<!-- mobile header -->
	<div class="mobile-header">
		<div *ngIf="isRestaurantDisplay">
			<app-item-selector id="restaurant-selector" [items]="restaurantList" [(selectedItem)]="selectedRestaurant" propName="name"
			 propComparer="id" class="color-animation" [restaurantColor]="{'type': currentType, 'isSecondary': true, 'background':true}">
			</app-item-selector>
			<hr class="separator" />
		</div>
		<div *ngIf="cmdRepas && isLieuCommandeDisplay">
			<app-item-selector id="lieu-commande-selector" [items]="lieuCmdList" [(selectedItem)]="selectedLieuCommande" propName="libelle"
			 propComparer="id" class="color-animation" [restaurantColor]="{'type': currentType, 'isSecondary': true, 'background':true}">
			</app-item-selector>
			<hr class="separator" />
		</div>
		<app-item-selector *ngIf="cmdRepas && isConviveDisplay" id="convive-selector" [items]="conviveList" [(selectedItem)]="selectedConvive"
		 propName="libelle" propComparer="id" class="color-animation" [restaurantColor]="{'type': currentType, 'isSecondary': true, 'background':true}"></app-item-selector>
		<app-date-selector [selectedRange]="selectedRange" [dateRanges]="dateRanges" [currentType]="currentType" id="date-selector"
		 [(selectedJour)]="selectedJour" [isSwipePrevAllowed]="isPrevWeekAllowed" [isSwipeNextAllowed]="isNextWeekAllowed">
		</app-date-selector>
	</div>
	<!-- desktop header -->
	<div class="sm-header">
		<div class="convives-container" [style.background-image]="'url(' + logoContratUrl + ')'">
			<div class="convive-tab pointerHover" [class.selected]="selectedConvive == convive" (click)="selectedConvive = convive" *ngFor="let convive of conviveList">
				<div class="circle" [class.green]="convive.transmis"></div>
				<span class="convive-libelle">{{convive.libelle}}</span>
			</div>
		</div>
		<div class="middle-container">
			<div class="actions-container">
				<div class="actions-left">
					<!--<select [ngModel]="selectedLieuCommande" (ngModelChange)="selectedLieuCommandeChanged($event)">
						<option *ngFor="let lieuCmd of lieuCmdList" [ngValue]="lieuCmd">{{lieuCmd.libelle}}</option>
					</select>-->
					<app-dropdown *ngIf="cmdRepas && isLieuCommandeDisplay" [items]="lieuCmdList" [(selectedItem)]="selectedLieuCommande" [fnStyle]="dateFnStyle">
					</app-dropdown>
					<div class="range-selector">
						<button [class.disabled]="!isPrevWeekAllowed" class="generic-button navWeek prevWeek pointerHover" (click)="handleNavigation(-1)">
							<div class="arrow"></div>
						</button>
						<!--<select [ngModel]="selectedRange" (ngModelChange)="selectedRangeChanged($event)">
							<option *ngFor="let range of dateRanges" [ngValue]="range">{{range.libelle}}</option>
						</select>-->
						<app-dropdown #semaineDropdown [items]="dateRanges" [selectedItem]="selectedRange" (selectedItemChange)="selectedRangeChanged($event)"
						 [fnStyle]="dateFnStyle">
						</app-dropdown>
						<button [class.disabled]="!isNextWeekAllowed" class="generic-button navWeek nextWeek pointerHover" (click)="handleNavigation(+1)">
							<div class="arrow"></div>
						</button>
					</div>
				</div>

				<div class="actions-right">
					<div class="action-infos">
						<span *ngIf="cmdRepas && cmdRepas.dateModification && !isNoContent">Commande validée le {{cmdRepas.dateModification}} par {{cmdRepas.modifiePar}}</span>&nbsp;
					</div>

					<div class="right-buttons">
						<button (click)="printMenu()" class="generic-button gray-button print-button" [disabled]="isMenuDownloading">{{isMenuDownloading?'Téléchargement...':'Menu'}}</button>
						<button (click)="printCommande()" class="generic-button gray-button print-button" [disabled]="isMatriceDeChoixDownloading">{{isMatriceDeChoixDownloading?'Téléchargement...':'Commande'}}</button>
						<button (click)="validateCommand()" class="generic-button validate-button" [class.red-button]="!cmdRepas || !cmdRepas.transmis"
						 [class.green-button]="cmdRepas && cmdRepas.transmis">{{isSendingCommandeRepas? 'Validation...' : 'Valider la commande'}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- commande repas display -->
	<div *ngIf="isLoading || isNoContent" class="info-container">
		<app-loader *ngIf="isLoading" message="Chargement des effectifs..."></app-loader>
		<div class="no-content" *ngIf="!isLoading && isNoContent"><span>Aucun effectif</span></div>
	</div>
	<div class="main-container">
		<div #repasContainer class="repas-container" *ngIf="!isLoading && selectedJour && !isNoContent">
			<div class="buttons-container">
				<div class="save-button" (click)="validateCommand()" [class.red-button]="!cmdRepas || !cmdRepas.transmis" [class.green-button]="cmdRepas && cmdRepas.transmis">
					<div></div>
				</div>
			</div>
			<div [translateSlider]="selectedRange.validJours.indexOf(selectedJour)" class="jours-container" [style.width]="selectedRange.validJours.length*100 + 'vw'">
				<div class="jour" *ngFor="let jour of selectedRange.validJours">
					<div [jourType]="jour.periode" class="day-head" [restaurantColor]="{'type': currentType, 'isSecondary':true, 'background':true}"
					 [title]="jour.periodeTooltipInfo">
						<img *ngIf="jour.hasError" class="invalid" src="/assets/images/repas/warning.png">
						<span>{{jour.dayString + ' ' +jour.day+' ' +jour.monthShort}}</span>
						<img class="has-piquenique" *ngIf="jour.quantitePiqueNique > 0" src="/assets/images/repas/piquenique.png" />
					</div>
					<div *ngIf="!jour.isEmpty" class="prestations">
						<app-prestation (prestaToggle)="onPrestaToggle($event)" [isCurrent]="selectedJour == jour" [jour]="jour" [currentType]="currentType"
						 *ngFor="let presta of jour.prestations" [prestation]="presta">
						</app-prestation>
					</div>
					<div class="presta-nodata" *ngIf="jour.isEmpty" [restaurantColor]="{'type': currentType,'border':true}">
						Aucune donnée pour ce jour
					</div>
				</div>
				<button *ngIf="isRegle1ButtonDisplay" (click)="applyRule1()" class="generic-button refresh-button" title="Réinitialiser les donneés de la semaine"
				 [restaurantColor]="{'type': currentType, 'isSecondary':true, 'background':true}"></button>
			</div>

			<div class="mobile-repas-infos" *ngIf="isEditInfosDisplay">
				Commande validée le {{cmdRepas.dateModification}} par {{cmdRepas.modifiePar}}
			</div>
		</div>
	</div>
</div>
