import { Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth-guard';
import { ContactComponent } from './contact.component';
const ɵ0 = { title: 'Contacts' };
const contactRoutes = [
    {
        path: 'contact',
        component: ContactComponent,
        canActivate: [AuthGuard],
        data: ɵ0
    }
];
export class ContactRoutingModule {
}
export { ɵ0 };
