<div class="login-container">
  <div class="subContainer">
    <div class="logo">
      <div class="portail" title="v{{appVersion}}">
        <div class="app-version">v{{appVersion}}</div>
        <app-logo class="logo-icon" [restaurantType]="RestaurantTypeEnum.Creche"></app-logo>
      </div>
    </div>

    <div class="forms-container" [ngClass]="activeFormClass">

      <div class="forms-container-slider">
        <div class="login-block">
          <form [formGroup]="loginForm" (ngSubmit)="tryLogin()" ngNativeValidate>
            <input placeholder="Identifiant" formControlName="login" type="text" name="login" required />
            <div [hidden]="loginForm.controls.login.valid || loginForm.controls.login.pristine" class="error">L'identifiant est requis.</div>
            <input placeholder="Mot de passe" formControlName="password" type="password" name="password" required />
            <div [hidden]="loginForm.controls.password.valid || loginForm.controls.password.pristine" class="error">Le mot de passe est requis.</div>
            <div class="error" [hidden]="errorMessage == undefined">{{errorMessage}}</div>
            <button type="submit" class="btn-valider creche-background" [disabled]="isLoading ">
              <span class="loading-wrap" [hidden]="!isLoading">
                <span class="loading">
                  <span class="loading-bar"></span>
                  <span class="loading-bar"></span>
                  <span class="loading-bar"></span>
                  <span class="loading-bar"></span>
                </span>
              </span>
              <span>Se connecter</span>
            </button>

            <a class="switch-form" (click)="showForgottenPwd()">Mot de passe oublié</a>
          </form>
        </div>

        <div class="forgotten-pwd-block">

          <form (ngSubmit)="getPassword()">
            <input placeholder="Nom d'utilisateur" [(ngModel)]="username" name="username" type="text" id="username" required />

            <div class="error" *ngIf="isPasswordFail">Nous n'avons pas réussi à récupérer vos informations d'utilisateur. {{passwordFailError}}</div>
            <div class="confirm" *ngIf="isPasswordSent">Un email a été envoyé avec votre nouveau mot de passe.</div>

            <button class="btn-valider creche-background">
              <span>Valider</span>
            </button>

            <a class="switch-form" (click)="showLogin()">Retour à l'écran de connexion</a>
          </form>
        </div>
      </div>
    </div>

    <div id="ie-warning" *ngIf="showInternetExplorerWarning">
      <i class="material-icons">warning</i>
      <span>Vous utilisez Internet Explorer. Cette application est optimisée sur Chrome et Firefox</span>
    </div>

    <a class="legal-info" (click)="showLegalInfos()">Mentions légales</a>
  </div>
</div>
