/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./smiley.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./odg-smiley-pbc.component";
var styles_OdgSmileyPlutotBienConsommeComponent = [i0.styles];
var RenderType_OdgSmileyPlutotBienConsommeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OdgSmileyPlutotBienConsommeComponent, data: {} });
export { RenderType_OdgSmileyPlutotBienConsommeComponent as RenderType_OdgSmileyPlutotBienConsommeComponent };
function View_OdgSmileyPlutotBienConsommeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "/assets/images/odg/pbc.png"]], null, null, null, null, null))], null, null); }
function View_OdgSmileyPlutotBienConsommeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "/assets/images/odg/pbc-active.png"]], null, null, null, null, null))], null, null); }
export function View_OdgSmileyPlutotBienConsommeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "smiley-img"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSmileyClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OdgSmileyPlutotBienConsommeComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OdgSmileyPlutotBienConsommeComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isActive; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isActive; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_OdgSmileyPlutotBienConsommeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "odg-smiley-pbc", [], null, null, null, View_OdgSmileyPlutotBienConsommeComponent_0, RenderType_OdgSmileyPlutotBienConsommeComponent)), i1.ɵdid(1, 49152, null, 0, i3.OdgSmileyPlutotBienConsommeComponent, [], null, null)], null, null); }
var OdgSmileyPlutotBienConsommeComponentNgFactory = i1.ɵccf("odg-smiley-pbc", i3.OdgSmileyPlutotBienConsommeComponent, View_OdgSmileyPlutotBienConsommeComponent_Host_0, { activeNote: "activeNote" }, { smileyClick: "smileyClick" }, []);
export { OdgSmileyPlutotBienConsommeComponentNgFactory as OdgSmileyPlutotBienConsommeComponentNgFactory };
