
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileResultModel } from '../../shared/models/fileresult.model';
import { BonLivraisonRequestModel } from '../models/api/bon-livraison-request.model';
import { BonLivraisonComposantReceptionModel } from '../models/bon-livraison-composant-reception.model';
import { BonLivraisonComposantModel } from '../models/bon-livraison-composant.model';
import { BonLivraisonListModel } from '../models/bon-livraison-list.model';
import { BonLivraisonModel } from '../models/bon-livraison.model';
import { BonLivraisonProjector } from '../models/bon-livraison.projector';
import { BonLivraisonApiService } from './api/bon-livraison-api.service';
import { BonLivraisonPlatLivraisonModel } from '../models/bon-livraison-plat-livraison.model';
import { BonLivraisonPlatModel } from '../models/bon-livraison-plat.model';
import { BonLivraisonTemperatureStatus, InvalidTemperatureStatuses } from '../models/bon-livraison-temperature-status.model';
import { BonLivraisonSettingsModel } from '../models/bon-livraison-settings.model';
import { BonLivraisonDLCStatus, InvalidDLCStatuses } from '../models/bon-livraison-dlc-status.model';
import * as moment from 'moment';
import { includes } from 'lodash';

export interface PlatDlcStatus { plat: BonLivraisonPlatModel; status: BonLivraisonDLCStatus; }
export interface PlatTemperatureStatus { plat: BonLivraisonPlatModel; status: BonLivraisonTemperatureStatus; }
export interface ReleveLivraison {
  dlcPlats: PlatDlcStatus[];
  temperaturePlats: PlatTemperatureStatus[];
  nonConformiteDlcPlats: PlatDlcStatus[];
  nonConformiteTemperaturePlats: PlatTemperatureStatus[];
}

@Injectable()
export class BonLivraisonService {

  constructor(private bonLivraisonApiService: BonLivraisonApiService) { }

  getBonsLivraisonListe(cuisineId: number, contratId: number, restaurantId: number, startDate: Date, endDate: Date): Observable<BonLivraisonListModel[]> {
    if (!(cuisineId && contratId && restaurantId)) {
      return observableOf([]);
    } // on retourne vide s'il les paramètres ne sont pas défini

    return this.bonLivraisonApiService.getBonsLivraisonListe(cuisineId, contratId, restaurantId, startDate, endDate).pipe(
      map(bonsLivraisonListModels => bonsLivraisonListModels.map(BonLivraisonProjector.projectBonLivraisonListe)));
  }

  getBonLivraison(codeBonLivraison: string): Observable<BonLivraisonModel> {
    return this.bonLivraisonApiService.getBonLivraison(codeBonLivraison)
      .pipe(
        map(bonLivraisonApiModel => BonLivraisonProjector.projectBonLivraison(bonLivraisonApiModel))
      );
  }

  declarerBonLivre(bonLivraisonCode: string,
                   plats: BonLivraisonPlatLivraisonModel[],
                   commentaire: string,
                   signature: string,
                   tournee: string): Observable<{}> {

    plats = plats || [];
    const request: BonLivraisonRequestModel.LivraisonRequest = {
      bonLivraisonCode,
      commentaire,
      signature,
      tournee,
      plats: plats.map(p => {
        return {
          bonLivraisonPlatId: p.bonLivraisonPlatId,
          livraisonDLC: p.livraisonDLC,
          livraisonTemperature: p.livraisonTemperature
        };
      })
    };
    return this.bonLivraisonApiService.declarerBonLivre(request);
  }

  receptionnerBon(bonLivraisonCode: string,
                  defauts: BonLivraisonComposantReceptionModel[],
                  commentaire: string): Observable<{}> {

    defauts = defauts || [];
    const request: BonLivraisonRequestModel.ReceptionRequest = {
      bonLivraisonCode,
      commentaire,
      composants: defauts.map(c => {
        return {
          bonLivraisonComposantId: c.bonLivraisonComposantId,
          quantiteLivreeRelative: c.quantiteLivreeRelative
        };
      })
    };
    return this.bonLivraisonApiService.receptionnerBon(request);
  }

  miseEnConformiteBon(bonLivraisonCode: string,
                      conforme: boolean,
                      commentaire: string,
                      nonConformiteIds: number[]): Observable<{}> {

    const request: BonLivraisonRequestModel.MiseEnConformiteRequest = {
      bonLivraisonCode,
      commentaire,
      conforme,
      nonConformiteIds
    };
    return this.bonLivraisonApiService.miseEnConformiteBon(request);
  }

  exportNonConformites(
    grandCompteId?: number,
    cuisineCentraleId?: number,
    contratId?: number,
    dateConsoDebut?: Date,
    dateConsoFin?: Date): Observable<FileResultModel> {

    const request: BonLivraisonRequestModel.ExportNonConformitesRequest = {
      grandCompteId,
      cuisineCentraleId,
      contratId,
      dateConsoDebut,
      dateConsoFin
    };

    return this.bonLivraisonApiService.exportNonConformites(request);
  }

  exportManques(grandCompteId?: number,
                cuisineCentraleId?: number,
                contratId?: number,
                dateConsoDebut?: Date,
                dateConsoFin?: Date): Observable<FileResultModel> {

    const request: BonLivraisonRequestModel.ExportManquesRequest = {
      grandCompteId,
      cuisineCentraleId,
      contratId,
      dateConsoDebut,
      dateConsoFin
    };

    return this.bonLivraisonApiService.exportManques(request);
  }

  private getPlats(bonLivraison: BonLivraisonModel) {
    const plats: BonLivraisonPlatModel[] = [];

    bonLivraison.bonLivraisonPrestations
      .forEach(p => p.bonLivraisonFamillePlats
        .forEach(fp => plats.push(...fp.bonLivraisonPlats))
      );

    return plats;
  }

  private getComposants(bonLivraison: BonLivraisonModel) {
    const composants: BonLivraisonComposantModel[] = [];

    this.getPlats(bonLivraison)
      .forEach(pl => composants.push(...pl.bonLivraisonComposants));

    return composants;
  }

  checkSumQteLivree(bonLivraison: BonLivraisonModel): string {
    return this.getComposants(bonLivraison)
      .reduce<string>((s, c) => s + `${c.bonLivraisonComposantId}|${c.quantiteLivreeRelative};`, '');
  }

  checkSumTemperatureDLC(bonLivraison: BonLivraisonModel): string {
    return this.getPlats(bonLivraison)
      .reduce<string>((s, p) => s + `${p.bonLivraisonPlatId}|${p.livraisonTemperature}|${p.livraisonDLC};`, '');
  }

  checkHasManque(bonLivraison: BonLivraisonModel): boolean {
    return this.getComposants(bonLivraison)
      .some(c => c.quantiteLivreeRelative < 0);
  }

  checkHasExcedent(bonLivraison: BonLivraisonModel): boolean {
    return this.getComposants(bonLivraison)
      .some(c => c.quantiteLivreeRelative < 0);
  }

  checkHasDlcFault(bonLivraison: BonLivraisonModel): boolean {
    return this.getPlats(bonLivraison)
      .some(p => includes(InvalidDLCStatuses, this.computeDLCStatus(p.livraisonDLC, bonLivraison.dateDLCMin)));
  }

  checkHasTemperatureFault(bonLivraison: BonLivraisonModel): boolean {
    return this.getPlats(bonLivraison)
      .some(p => includes(InvalidTemperatureStatuses, this.computeTemperatureStatus(p.livraisonTemperature, bonLivraison.settings)));
  }

  // Calcule la conformité de la température
  public computeTemperatureStatus(temperature: number, settings: BonLivraisonSettingsModel): BonLivraisonTemperatureStatus {
    if (!settings || temperature === null) { return BonLivraisonTemperatureStatus.NA; }

    if (temperature >= settings.temperatureConformeMin && temperature <= settings.temperatureConformeMax) { return BonLivraisonTemperatureStatus.Conforme; }
    if (temperature >= settings.toleranceTemperatureMin && temperature <= settings.toleranceTemperatureMax) { return BonLivraisonTemperatureStatus.Tolere; }

    return BonLivraisonTemperatureStatus.NonConforme;
  }

  // Calcule la conformité de la DLC
  public computeDLCStatus(dateDlc: Date, dateDLCMin: Date): BonLivraisonDLCStatus {
    if (!dateDlc || !dateDLCMin) {
      return BonLivraisonDLCStatus.NA;
    }

    if (moment(dateDlc).isSameOrAfter(dateDLCMin)) {
      return BonLivraisonDLCStatus.Conforme;
    }

    return BonLivraisonDLCStatus.NonConforme;
  }

  /**
   * Retourne les DLC et Temperatures relevées sur la livraison
   */
  public getReleveLivraison(bonLivraison: BonLivraisonModel): ReleveLivraison {
    const dlcPlats: PlatDlcStatus[] = [];
    const temperaturePlats: PlatTemperatureStatus[] = [];
    const nonConformiteDlcPlats = [];
    const nonConformiteTemperaturePlats = [];

    bonLivraison.bonLivraisonPrestations
      .forEach(p => p.bonLivraisonFamillePlats
        .forEach(fp => fp.bonLivraisonPlats
          .forEach(pl => {
            const dlcStatus = this.computeDLCStatus(pl.livraisonDLC, bonLivraison.dateDLCMin);
            if (dlcStatus !== BonLivraisonDLCStatus.NA) { // Seulement s'il y a eu un relevé de DLC
              const dlcPlat = { plat: pl, status: dlcStatus };
              dlcPlats.push(dlcPlat);
              if (dlcStatus === BonLivraisonDLCStatus.NonConforme) {
                nonConformiteDlcPlats.push(dlcPlat);
              }
            }
            const temperatureStatus = this.computeTemperatureStatus(pl.livraisonTemperature, bonLivraison.settings);
            if (temperatureStatus !== BonLivraisonTemperatureStatus.NA) { // Seulement s'il y a eu un relevé de température
              const temperaturePlat = { plat: pl, status: temperatureStatus };
              temperaturePlats.push(temperaturePlat);
              if (temperatureStatus === BonLivraisonTemperatureStatus.NonConforme
                || temperatureStatus === BonLivraisonTemperatureStatus.Tolere) {
                nonConformiteTemperaturePlats.push(temperaturePlat);
              }
            }
          }
          )
        )
      );

    return { dlcPlats, temperaturePlats, nonConformiteDlcPlats, nonConformiteTemperaturePlats };
  }
}
