<div class="restaurant-selector-content">
  <div
    class="restaurant-selector-dropdown"
    *ngIf="displayGrandCompteSelector"
  >
    <img
      src="/assets/images/layout-header/grandcompte.png"
    />
    <select-autocomplete
      [entityDisplayFunc]="formatGrandCompteLabel"
      [entity]="internalSelectedGrandCompte"
      [entities]="internalSelectableGrandComptes"
      (entityChange)="onSelectedGrandCompteChanged($event)"
    ></select-autocomplete>
  </div>
  <div
    class="restaurant-selector-dropdown"
    title="{{ selectedContratTooltip }}"
  >
    <img
      src="/assets/images/layout-header/contrat.png"
      (dblclick)="displayDebugInfo(selectedContratTooltip)"
    />
    <select-autocomplete
      [entity]="internalSelectedContrat"
      [entities]="internalSelectableContrats"
      (entityChange)="onSelectedContratChanged($event)"
      entityDisplayProp="libelle"
    ></select-autocomplete>
  </div>
  <div
    class="restaurant-selector-dropdown"
    title="{{ selectedRestaurantTooltip }}"
  >
    <img
      src="/assets/images/layout-header/home.png"
      (dblclick)="displayDebugInfo(selectedRestaurantTooltip)"
    />
    <select-autocomplete
      [entity]="selectedRestaurant"
      (entityChange)="onSelectedRestaurantChanged($event)"
      [entities]="restaurants"
      [entityDisplayFunc]="formatRestaurantLabel"
    ></select-autocomplete>
  </div>
</div>
