import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { BonLivraisonModel, BonLivraisonStatut, ReceptionStatut, MiseEnConformiteStatut } from '../models/bon-livraison.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BonLivraisonService } from '../services/bon-livraison.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { InteractionService } from 'src/app/shared/services/interaction.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, first, filter } from 'rxjs/operators';
import { ErrorApiModel } from 'src/app/shared/models/api/error/error.apimodel';
import { BonLivraisonApiErrorCodes } from '../models/api/bon-livraison-api-error-codes';
import { NotificationModel, NotificationCustomModel } from 'src/app/shared/models/notification.model';
import { LivraisonPopupValidationModel } from './livraison-popup-validation/livraison-popup-validation.model';
import { LivraisonPopupValidationComponent } from './livraison-popup-validation/livraison-popup-validation.component';

@Component({
  selector: 'app-livraison-bon-livraison',
  templateUrl: './livraison-bon-livraison.component.html',
  styleUrls: ['./livraison-bon-livraison.component.scss']
})
export class LivraisonBonLivraisonComponent implements OnInit, OnDestroy {

  private bonLivraisonCode: string;
  public detailsBonLivraison: BonLivraisonModel;
  private _originalBonLivraisonCheckSum: string;

  public get hasChanged(): boolean {
    return this.detailsBonLivraison && (this._originalBonLivraisonCheckSum != this.bonLivraisonService.checkSumTemperatureDLC(this.detailsBonLivraison));
  }

  public get canValidate(): boolean {
    if (!this.detailsBonLivraison) { return false; }
    if (!this.checkReleveCount()) { return false; }

    return true;
  }

  public isLoadingBonLivraison: boolean = false;

  private askLoadDetailsBLEmitter: EventEmitter<void> = new EventEmitter<void>();

  private _currentRestaurantId?: number;

  // declare enum property to allow use in component template. see: http://stackoverflow.com/a/40034109/590741
  public BonLivraisonStatutEnum: typeof BonLivraisonStatut = BonLivraisonStatut; // tslint:disable-line: variable-name
  public ReceptionStatutEnum: typeof ReceptionStatut = ReceptionStatut;  // tslint:disable-line: variable-name
  public MiseEnConformiteStatutEnum: typeof MiseEnConformiteStatut = MiseEnConformiteStatut;  // tslint:disable-line: variable-name

  constructor(private route: ActivatedRoute,
              private bonLivraisonService: BonLivraisonService,
              private appDataService: ApplicationDataService,
              private authService: AuthenticationService,
              private interactionService: InteractionService,
              private router: Router
  ) { }

  ngOnInit() {

    this.route.params
      .pipe(
        untilDestroyed(this)
      )
      // .observeOn(Scheduler.asap) // TODO: à voir : le changement de title doit être fait de façon synchrone pour ne pas planter le ChangeDetector
      .subscribe(params => {
        this.bonLivraisonCode = params.codeBonLivraison;
        if (this.bonLivraisonCode != null) {
          this.appDataService.changePageTitle(`Livraison ${this.bonLivraisonCode}`);
          this.askToGetDetails();
        }
      }
      );

    this.appDataService.restaurant$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(
        restaurant => {
          if (restaurant != null) {
            this._currentRestaurantId = restaurant.id;
          }
        }
      );

    this.askLoadDetailsBLEmitter
      .pipe(
        untilDestroyed(this),
        debounceTime(500))
      .subscribe(
        () => {
          // TODO devrait être fait dans un route resolver
          this.bonLivraisonService.getBonLivraison(this.bonLivraisonCode)
            .subscribe(
              success => {
                this.isLoadingBonLivraison = false;
                this.detailsBonLivraison = success;
                this._originalBonLivraisonCheckSum = this.bonLivraisonService.checkSumTemperatureDLC(this.detailsBonLivraison);
                // console.log(success);
                this.updateCurrentRestaurantIfNeeded();
              },
              (error: ErrorApiModel) => {
                this.isLoadingBonLivraison = false;
                if (error.Code === BonLivraisonApiErrorCodes.BonLivraisonNotFound) {
                  this.interactionService.showSimpleMessage(`Réception ${this.bonLivraisonCode} inconnu`, 'Veuillez saisir un autre code.');
                  this.router.navigate(['/bonlivraison'], { queryParams: {} });
                } else {
                  const notifModel = new NotificationModel();
                  notifModel.title = 'Erreur';
                  notifModel.message = 'Erreur de chargement du bon : \n' + error.Message;
                  this.interactionService.showNotification(notifModel);
                }
              });
        }
      );

    this.askToGetDetails();
  }

  ngOnDestroy(): void {
    // mandatory (untildestroy)
  }

  private askToGetDetails() {
    this.isLoadingBonLivraison = true;
    this.askLoadDetailsBLEmitter.emit();
  }

  private updateCurrentRestaurantIfNeeded() {
    if (this._currentRestaurantId != null
      && this._currentRestaurantId !== this.detailsBonLivraison.restaurantId) {
      this.authService.currentUser$
        .pipe(
          first(),
          filter(user => !!user),
          untilDestroyed(this)
        )
        .subscribe(user => {
          // find restaurantModel in user perimeter
          const bonLivraisonContrat = user.allContrats.find(c => c.id === this.detailsBonLivraison.contratId);
          const bonLivraisonResto = bonLivraisonContrat.restaurants.find(r => r.id === this.detailsBonLivraison.restaurantId);
          this.appDataService.changeContrat(bonLivraisonContrat).pipe(first()).subscribe();
          this.appDataService.changeRestaurant(bonLivraisonResto, true).pipe(first()).subscribe();
        });
    }
  }

  validate() {

    if (!this.checkReleveCount()) {
      this.interactionService.showSimpleMessage('Validation', `Veuillez relever au moins ${this.detailsBonLivraison.settings.releveTemperatureMin} températures et ${this.detailsBonLivraison.settings.releveDlcMin} dlc/ddm.`);
      return;
    }

    const notif: NotificationCustomModel = new NotificationCustomModel();
    notif.id = 1;
    notif.title = 'Valider la livraison';
    notif.type = LivraisonPopupValidationComponent;
    notif.fullScreen = false;
    notif.closeOnBackdropClick = false;
    const popupModel: LivraisonPopupValidationModel = {
      bonLivraison: this.detailsBonLivraison,
      hasModification: this.hasChanged
    };
    notif.data = popupModel;
    notif.hasNoOverflow = true;

    this.interactionService.showNotification(notif);

  }

  public get isModifiable() {
    return this.detailsBonLivraison.peutLivrer;
  }


  private checkReleveCount(): boolean {
    const releves = this.bonLivraisonService.getReleveLivraison(this.detailsBonLivraison);
    return releves.dlcPlats.length >= this.detailsBonLivraison.settings.releveDlcMin
      && releves.temperaturePlats.length >= this.detailsBonLivraison.settings.releveTemperatureMin;
  }
}

