export class HistoryCommandeModel {
    id: number;
    numberArticles: number;
    canEdit: boolean;
    canDuplicate: boolean;
    canDelete: boolean;
    restaurant: string;

    estValidee?: boolean;
    estTransmise: boolean;
    dateCommande: Date;
    dateLivraison: Date;
    etat: EtatCommandeEnum;
}

export enum EtatCommandeEnum {
    Transmise,
    ValideeNonTransmise,
    EnAttenteValidation
}
