/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./composants-livres.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./composants-livres.component";
var styles_ComposantsLivresComponent = [i0.styles];
var RenderType_ComposantsLivresComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ComposantsLivresComponent, data: {} });
export { RenderType_ComposantsLivresComponent as RenderType_ComposantsLivresComponent };
function View_ComposantsLivresComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "generic-button inputPlusMoins"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.manqueComposant((0 - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["-"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "input", [["class", "inputManqueReception"], ["disabled", "disabled"], ["type", "text"]], [[1, "value", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "rouge": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "generic-button inputPlusMoins"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.manqueComposant(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["+"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "inputManqueReception"; var currVal_2 = _ck(_v, 6, 0, (_co.composant.quantiteLivreeRelative != 0)); _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formattedQuantiteLivreeRelative; _ck(_v, 3, 0, currVal_0); }); }
function View_ComposantsLivresComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "affichage-non-modifiable"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "rouge": 0 }), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "affichage-non-modifiable"; var currVal_1 = _ck(_v, 4, 0, (_co.composant.quantiteLivreeRelative != 0)); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.formattedQuantiteLivreeRelative; _ck(_v, 5, 0, currVal_2); }); }
export function View_ComposantsLivresComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ComposantsLivresComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ComposantsLivresComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isModifiable; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isModifiable; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ComposantsLivresComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-composants-livres", [], null, null, null, View_ComposantsLivresComponent_0, RenderType_ComposantsLivresComponent)), i1.ɵdid(1, 638976, null, 0, i3.ComposantsLivresComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ComposantsLivresComponentNgFactory = i1.ɵccf("app-composants-livres", i3.ComposantsLivresComponent, View_ComposantsLivresComponent_Host_0, { composant: "composant", isModifiable: "isModifiable" }, {}, []);
export { ComposantsLivresComponentNgFactory as ComposantsLivresComponentNgFactory };
