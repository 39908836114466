export abstract class BaseErrorModel {

  abstract type: CommandeRepasErrorTypeEnum;
  callback: (BaseErrorModel) => void;
  constructor(public index: number, public code: number, public message: string, public date: Date) {
  }

  onClick() {
    this.callback(this);
  }
}

export class FamillePlatErrorModel extends BaseErrorModel {

  type = CommandeRepasErrorTypeEnum.FamillePlat;
  effectifId: number;
  name: string;

  constructor(effectifId: number, name: string, index: number, code: number, message: string, date: Date) {
    super(index, code, message, date);
    this.effectifId = effectifId;
    this.name = name;
  }
}

export class PortionErrorModel extends BaseErrorModel {

  type = CommandeRepasErrorTypeEnum.Portion;
  id: number;

  constructor(id: number, index: number, code: number, message: string, date: Date) {
    super(index, code, message, date);
    this.id = id;
  }

}

export class PrestationErrorModel extends BaseErrorModel {

  type = CommandeRepasErrorTypeEnum.Prestation;
  id: number;
  regimeId: number;

  constructor(id: number, regimeId: number, index: number, code: number, message: string, date: Date) {
    super(index, code, message, date);
    this.id = id;
    this.regimeId = regimeId;
  }
}

export interface JourErrorModel {
  date: Date;
  prestations: PrestationErrorModel[];
  famillePlats: FamillePlatErrorModel[];
  portions: PortionErrorModel[];
}

export class CommandeRepasErrorModel {
  constructor(public jours: JourErrorModel[], public errorType: CommandeRepasUpdateErrorTypeEnum) {
  }

  setCallBack(cb: (BaseErrorModel) => void) {
    if (this.jours) {
      this.jours.forEach(j => {
        j.famillePlats.forEach(fp => {
          fp.callback = cb;
        });
        j.portions.forEach(fp => {
          fp.callback = cb;
        });
        j.prestations.forEach(fp => {
          fp.callback = cb;
        });
      });
    }
  }

}

export enum CommandeRepasErrorNumber {
  Regle9 = 9,
  Regle10 = 10,
  Regle11 = 11,
  Regle12 = 12,
  InvalidModelState = 4000
}

export enum CommandeRepasErrorTypeEnum {
  Prestation = 0,
  Portion = 1,
  FamillePlat = 2
}

export enum CommandeRepasUpdateErrorTypeEnum {
  Unhandled,
  Validation,
  TransmissionElixir
}
