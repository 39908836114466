
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileResultModel } from '../../shared/models/fileresult.model';
import { DocumenthequeProjector } from '../documentheque.projector';
import { DocumentModel } from '../models/documentheque.model';
import { DocumenthequeApiService } from './api/documentheque.apiservice';

@Injectable()
export class DocumenthequeService {
  constructor(private docApiService: DocumenthequeApiService) { }

  public getDocuments(cuisineCentraleId: number, contratId: number, searchWords: string[]): Observable<DocumentModel[]> {
    return this.docApiService.getDocuments(cuisineCentraleId, contratId, searchWords).pipe(map(res => {
      return DocumenthequeProjector.projectDocuments(res);
    }));
  }

  public getDocument(id: number): Observable<FileResultModel> {
    return this.docApiService.getDocument(id);
  }
}
