import { Component, HostBinding, Input, OnDestroy, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ROUTE_URLS } from '../shared/constants/route_urls';
import { AllowedFeatureEnum } from '../shared/enums/allowed-feature.enum';
import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { ContratModel } from '../shared/models/contrat.model';
import { MenuLinkModel } from '../shared/models/menu-link.model';
import { RestaurantModel } from '../shared/models/restaurant.model';
import { ApplicationDataService } from '../shared/services/application-data.service';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { from } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { RestaurantContext } from '../restaurant-selector/model/restaurant-context.model';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit, OnDestroy {
  // @Input()
  // restaurants: RestaurantModel[];

  @Input()
  contrats: ContratModel[];

  @Input()
  restaurant: RestaurantModel[];

  @Output()
  selectedRestaurantContextChange: EventEmitter<RestaurantContext | null> = new EventEmitter<RestaurantContext | null>();

  @Input()
  showDebugInfo: boolean = false;

  @Input()
  menuList: MenuLinkModel[];

  @Input()
  logoContratUrl: string = '';

  @HostBinding('class.visible')
  public showDrawer: boolean;

  currentType: RestaurantTypeEnum;

  public hasAccessToDiscussion: boolean = false;
  public hasAccessToContact: boolean = false;
  public displayGrandCompteSelector: boolean = false;

  constructor(
    private _appDataService: ApplicationDataService,
    // private _logService: LogService,
    private _authService: AuthenticationService,
    // private logoService: LogoRouteService,
    private _router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit() {
    this._appDataService.restaurant$
      .pipe(untilDestroyed(this))
      .subscribe(rest => {
        if (rest) {
          this.currentType = rest.type;
        }
      });

    this._authService.currentUser$
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.hasAccessToContact = user && user.hasAccessToFeature(AllowedFeatureEnum.Contacts);
        this.displayGrandCompteSelector = user && user.isAdmin;
      });
  }

  navigateToContact() {
    this._router.navigate([ROUTE_URLS.Contact]);
    this.hide();
  }

  navigateToHome() {
    from(this._router.navigate([ROUTE_URLS.Home]))
      .pipe(untilDestroyed(this))
      .subscribe(navigated => {
        // Force reload
        this.document.location.reload();
      });

    this.hide();
  }

  show() {
    this.showDrawer = true;
  }

  hide() {
    this.showDrawer = false;
  }

  ngOnDestroy() {
    // mandatory for untildestroyed
  }

  selectedRestaurantContextChanged(selectedRestaurantContext: RestaurantContext) {
    this.selectedRestaurantContextChange.emit(selectedRestaurantContext);
  }

}
