<div class="infos-bon-livraison restaurant-type-background secondary">
  <span class="chevron" [class.down]="isInfoBonCollapsed"></span>
  <div class="info-header" (click)="toggleInfoBon()">
    <div class="info-group info-group-header">
      <div class="info-item info-code">
        <label class="info-label">Code</label><span class="info-value">{{ bonLivraison.code }}</span>
      </div>
      <div class="info-item info-dateConsommation">
        <label class="info-label">Consommation</label><span class="info-value">{{ bonLivraison.dateConsommation | dateFormat}}</span>
      </div>
      <div class="info-item info-statut">
        <label class="info-label">Statut</label><span class="info-value statut-dot statut-{{ BonLivraisonStatutEnum[bonLivraison.statut] }}">{{ bonLivraison.statut | formatBonLivraisonStatut }}</span>
      </div>
    </div>
  </div>

  <div class="info-details collapsable" [class.collapsed]="isInfoBonCollapsed">
    <!-- Informations complémentaires -->
    <div class="info-group info-goup-livraison" *ngIf="bonLivraison.livraisonStatut != LivraisonStatutEnum.NonLivre">
      <div class="info-item">
        <label class="info-label">Livraison</label><span class="info-value">{{ bonLivraison | formatLivraisonStatut }}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Le</label><span class="info-value" title="{{ bonLivraison.livraisonDate | dateFormat:'DD/MM/YYYY à HH:mm:ss'}}">{{ bonLivraison.livraisonDate | dateFormat:'DD/MM/YYYY HH:mm'}}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Par</label><span class="info-value">{{ bonLivraison.livraisonUtilisateur}}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Limite de réception<span [class.date-expired]="notifyDateFinReceptionExpired">{{notifyDateFinReceptionExpired ? " (expirée)": ""}}</span></label><span class="info-value" [class.date-expired]="notifyDateFinReceptionExpired">{{ bonLivraison.dateFinReception | dateFormat:'DD/MM/YYYY HH:mm'}}</span>
      </div>
    </div>

    <div class="info-group info-goup-reception" *ngIf="bonLivraison.receptionStatut != ReceptionStatutEnum.NA">
      <div class="info-item">
        <label class="info-label">Réception</label><span class="info-value">{{ bonLivraison.receptionStatut | formatReceptionStatut }}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Le</label><span class="info-value" title="{{ bonLivraison.receptionDate | dateFormat:'DD/MM/YYYY à HH:mm:ss'}}">{{ bonLivraison.receptionDate | dateFormat:'DD/MM/YYYY HH:mm'}}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Par</label><span class="info-value">{{ bonLivraison.receptionUtilisateur}}</span>
      </div>
    </div>

    <div class="info-group info-goup-conformite" *ngIf="bonLivraison.miseEnConformiteStatut != MiseEnConformiteStatutEnum.NA">
      <div class="info-item">
        <label class="info-label">Conformité</label><span class="info-value">{{ bonLivraison.miseEnConformiteStatut | formatMiseEnConformiteStatut }}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Le</label><span class="info-value" title="{{ bonLivraison.miseEnConformiteDate | dateFormat:'DD/MM/YYYY à HH:mm:ss'}}">{{ bonLivraison.miseEnConformiteDate | dateFormat:'DD/MM/YYYY HH:mm'}}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Par</label><span class="info-value">{{ bonLivraison.miseEnConformiteUtilisateur}}</span>
      </div>
      <div class="info-item">
        <label class="info-label">Clôture <span [class.date-expired]="notifyDateClotureExpired">{{notifyDateClotureExpired ? " (expirée)": ""}}</span></label><span class="info-value" [class.date-expired]="bonLivraison.dateClotureExpired">{{ bonLivraison.dateCloture | dateFormat:'DD/MM/YYYY à HH:mm'}}</span>
      </div>
      <div class="info-item container-non-conformites">
        <label class="info-label">Non conformité{{ bonLivraison.nonConformites.length > 1 ? 's' : '' }} :</label>
        <span *ngFor="let nonConformite of bonLivraison.nonConformites">- {{nonConformite.libelle}}</span>
      </div>
    </div>

  </div>
</div>
