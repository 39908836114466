import { AfterViewInit, Component, ElementRef, forwardRef, Input, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import * as Pikaday from 'pikaday';
import { DateHelper } from '../shared/helpers/date.helper';
import { ErrorPrettyDatePipe } from '../shared/pipes/error-pretty-date.pipe';

export interface DatePickerInfo {
  minDate?: Date;
  maxDate?: Date;
  enableDayFilter?: boolean;
  availableDays?: Date[];
  showClearButton?: boolean;
}

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true
    },
    ErrorPrettyDatePipe
  ]
})
export class DateInputComponent implements ControlValueAccessor, AfterViewInit {

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChange(v);
    }
  }

  private _disabled: boolean = false;
  @Input() public set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.applyPickaday();
  }
  public get disabled(): boolean { return this._disabled; }

  @Input() calendarInfo: DatePickerInfo;
  @Input() format: string = 'DD/MM/YYYY';
  @Input() placeholder: string = '';
  @Input() class: string = '';

  @ViewChild('dateInput', { static: true })
  private _inputRef: ElementRef;

  public get inputId(): string {
    return 'input-' + this._elementRef.nativeElement.id;
  }

  // The internal data model
  private innerValue: any = '';

  private minDate: Date;
  private maxDate: Date;
  private enableDayFilter: boolean;
  private availableDays: Date[];
  private yearRange: number[];
  private calendar: Pikaday;

  private i18n: any = {
    previousMonth: 'Mois précédent',
    nextMonth: 'Mois suivant',
    months: DateHelper.monthNames,
    weekdays: DateHelper.dayNames,
    weekdaysShort: DateHelper.dayNamesShort
  };

  private _viewInitialized = false;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) {
  }

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  onChange = (_) => { };
  onTouched = () => { };

  ngAfterViewInit() {
    const currentDate = moment();

    this.minDate = this.calendarInfo.minDate || currentDate.toDate();
    this.maxDate = this.calendarInfo.maxDate || currentDate.endOf('year').add(2, 'year').toDate();
    this.availableDays = this.calendarInfo.availableDays || [];

    this.enableDayFilter = this.calendarInfo.enableDayFilter || false;

    this.yearRange = [this.minDate.getFullYear(), this.maxDate.getFullYear()];

    this._viewInitialized = true;

    this.applyPickaday();
  }

  private applyPickaday() {
    if (!this.disabled) {
      this.attachPickaday();
    } else {
      this.removePickaday();
    }
  }

  private attachPickaday() {
    if (this._viewInitialized && !this.calendar) {
      this.calendar = new Pikaday({
        // field: this._elementRef.nativeElement,
        field: this._inputRef.nativeElement,
        firstDay: 1,
        minDate: this.minDate,
        maxDate: this.maxDate,
        format: this.format,
        formatStrict: true,
        yearRange: this.yearRange,
        disableDayFn: this.isItADisabledDay.bind(this),
        i18n: this.i18n,
        showWeekNumber: true,
        onSelect: (date: Date) => {
          this.value = date;
        }
      });
    }
  }
  private removePickaday() {
    if (this.calendar) {
      this.calendar.destroy();
      this.calendar = null;
    }
  }

  public setNewDate(d: Date) {
    this.calendar.setDate(d);
  }

  private isItADisabledDay(day: Date): boolean {
    if (!this.enableDayFilter) {
      return false;
    }
    if (this.availableDays) {
      for (let i = 0; i < this.availableDays.length; i++) {
        if (DateHelper.areSameDayOfYear(this.availableDays[i], day)) {
          return false;
        }
      }
    }
    return true;
  }

  public clear() {
    if (this.calendar) {
      // this.calendar.clear(); // BUG clear n'existe pas ??
      this.calendar.setDate(null);
      this.calendar.gotoToday();
    }
    this.value = null;
  }

  // Set touched on blur
  public onBlur() {
    this.onTouched();
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onModelChange(val: Date) {
    if (val instanceof Date) {
      this.value = val;
    } else {
      const newDate = moment(val, this.format);
      this.value = newDate.isValid() ? newDate.toDate() : null;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    // this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
    this.disabled = isDisabled;
  }
}
