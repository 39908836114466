import { ApplicationDataService } from '../../shared/services/application-data.service';

import { RestaurantTypeEnum } from '../../shared/enums/restaurant-type.enum';
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { OdgEvaluationModel } from '../models/odg.model';

@Component({
  selector: 'odg-bad-evaluation',
  templateUrl: './odg-bad-evaluation.component.html',
  styleUrls: ['./odg-bad-evaluation.component.scss']
})
export class OdgBadEvaluationComponent {
  @Input()
  badEvaluation: OdgEvaluationModel;
  @Input()
  currentType: RestaurantTypeEnum;

  @Output()
  badEvaluationClose: EventEmitter<void> = new EventEmitter<void>();

  get canValidate(): boolean {
    let bool = this.badEvaluation.aspect
      || this.badEvaluation.assaisonnement
      || this.badEvaluation.consistance
      || (this.badEvaluation.autreRaison != undefined && this.badEvaluation.autreRaison.length >= 3);
    return bool;
  }

  constructor(private appDataService: ApplicationDataService) { }

  validate() {
    if (this.canValidate)
    {
      this.badEvaluationClose.emit();
      this.appDataService.closeBadEvaluation(this.badEvaluation);
    }
    this.badEvaluation = null;
  }
}