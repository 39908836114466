import { AuthTokenModel as AuthToken } from '../../models/auth-token.model';
import { JwtHelper } from './jwt-helper.helper';

export class AuthenticationToken {
  public authToken: AuthToken;

  public get accessToken() { return this.authToken.accessToken; }
  public get refreshToken() { return this.authToken.refreshToken; }

  public accessTokenExpirationDate: Date;
  public refreshTokenExpirationDate: Date;

  constructor(token: AuthToken) {
    this.authToken = token;
    this.accessTokenExpirationDate = JwtHelper.getTokenExpirationDate(token.accessToken);
    this.refreshTokenExpirationDate = JwtHelper.getTokenExpirationDate(token.refreshToken);
  }

  public isAccessTokenExpired() {
    return this.isExpired(this.accessTokenExpirationDate);
  }

  public isRefreshTokenExpired() {
    return this.isExpired(this.refreshTokenExpirationDate);
  }

  private isExpired(expirationDate: Date, offsetSeconds?: number): boolean {
    offsetSeconds = offsetSeconds || 0;
    if (expirationDate === null) {
      return true;
    }
    return !(expirationDate.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }
}
