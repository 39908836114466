<div class="main-container">
  <div class="ribbon" [style.background-image]="'url(' + logoContratUrl + ')'"> </div>

  <div class="page-container" [class.messages-visible]="selectedDiscussion != null">
    <h1>Demandes / Réclamations</h1>

    <div class="actions-container">
      <ng-container *ngIf="isLoadingFilters; else displayFiltersTemplate">
        <app-loader message="Préparation du formulaire...">
        </app-loader>
      </ng-container>
      <ng-template #displayFiltersTemplate>

        <h2 class="title">Filtrer les sujets</h2>

        <div class="actions-filters">
          <div class="form-group search-text-filter">
            <input type="text" id="search-text" name="search-text" [(ngModel)]="searchText" placeholder="Rechercher" />
          </div>

          <div class="form-group dates-filter">

            <div class="form-group-date">
              <app-date-input id="start-date" name="start-date" [(ngModel)]="startDate" [calendarInfo]="dateCalendarData" placeholder="Début"></app-date-input>
            </div>

            <div class="form-group-date">
              <app-date-input id="end-date" name="end-date" [(ngModel)]="endDate" [calendarInfo]="dateCalendarData" placeholder="Fin"></app-date-input>
            </div>
          </div>

          <div class="form-group other filter-group">
            <div class="is-ouvert-filter">
              <label class="label-radio">
                <input type="radio" [(ngModel)]="isOuvertSelected" name="type" [value]="false" />
                <span class="custom-radio"></span>
                <span>Clôturés</span>
              </label>
              <label class="label-radio">
                <input type="radio" [(ngModel)]="isOuvertSelected" name="type" [value]="true" />
                <span class="custom-radio"></span>
                <span>Ouverts</span>
              </label>
            </div>

            <div class="filter-on-contrat-filter" [hidden]="!showFilterOnContrat">
              <input id="chkContratFilter" type="checkbox" [checked]="!filterOnContrat" (change)="filterOnContrat = !$event.target.checked; askLoadDiscussions()" />
              <label for="chkContratFilter" title="'Tous mes contrats' ou seulement le contrat sélectionné actuellement ({{ contrat.libelle }})">Tous mes contrats</label>
            </div>
          </div>

          <button (click)="askLoadDiscussions()" class="generic-button gray-tile-button search-button">Rechercher</button>
          <button class="generic-button red-button plus-button new-button restaurant-type-background" (click)="showNewDiscussion()" [hidden]="newDiscussionComponent.isOpen"><span class="plus"></span><span class="text">Nouveau</span></button>

        </div>

      </ng-template>

    </div>

    <div class="ie11-max-height-fix-container">
      <div class="discussion-container" *ngIf="!isLoadingFilters">

        <div class="topics-container">
          <ul>
            <div *ngIf="isLoadingDiscussions" class="info-container">
              <app-loader message="Chargement...">
              </app-loader>
            </div>
            <li *ngIf="!currentDiscussions || currentDiscussions.length == 0" class="no-discussion">
              Aucune demande
            </li>
            <li [ngClass]="{'restaurant-type-background': discussion==selectedDiscussion, 'secondary': true, 'restaurant-type-border': !discussion.lu}" (click)="onDiscussionClick(discussion)" *ngFor="let discussion of currentDiscussions" class="discussion-history">
              <div class="discussion-history-border" [ngClass]="{'restaurant-type-border': !discussion.lu}">
                <h2 class="topic-restaurant" [title]="discussion.restaurant + ' (' + discussion.contrat + ')'">{{ discussion.restaurant }}</h2>
                <p class="topic-type">{{ discussion.typeIntervention }}<small>{{ discussion.typeMaintenance ? (' - ' + discussion.typeMaintenance) : null }}</small></p>
                <p class="topic-sujet"> {{ discussion.sujet }}</p>
                <span class="date restaurant-type-color" [title]="'Création le '+ (discussion.dateCreation | dateFormat:'DD/MM/YYYY à HH:mm:ss' ) + '\n\rModification le ' + (discussion.dateModification | dateFormat:'DD/MM/YYYY à HH:mm:ss' ) +'\n\r'">{{ discussion.dateModification | dateFormat:'DD/MM/YYYY HH:mm' }}</span>
              </div>
            </li>
          </ul>
        </div>
        <discussion-details (discussionClosed)="onDiscussionClosed()" (refreshDiscussionsRequired)="onRefreshDiscussionsRequired($event)" (messageLuTriggered)="onMessageLuTriggered($event)" [discussion]="selectedDiscussion" [typeInterventionGroups$]="typeInterventionGroups$" [restaurant]="restaurant">
        </discussion-details>
      </div>
    </div>
    <new-discussion (newDiscussionCreated)="onNewDiscussionCreated()" [typeInterventionGroups]="typeInterventionGroups$ | async" [isLoadingTypeInterventions]="isLoadingTypeInterventions" [restaurant]="restaurant" #newDiscussionComponent>
    </new-discussion>
  </div>
</div>
