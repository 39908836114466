
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileHelper } from 'src/app/shared/helpers/file.helper';
import { DateHelper } from '../../../shared/helpers/date.helper';
import { ApiSettings } from '../../../shared/models/api/api-settings';
import { FileResultModel } from '../../../shared/models/fileresult.model';
import { BonLivraisonRequestModel } from '../../models/api/bon-livraison-request.model';
import { BonLivraisonResponseModel } from '../../models/api/bon-livraison-response.model';

@Injectable()
export class BonLivraisonApiService {
  private controllerUrl: string = 'bonlivraison';
  constructor(private apiSettings: ApiSettings, private http: HttpClient) { }

  getBonsLivraisonListe(cuisineId: number, contratId: number, restaurantId: number, startDate: Date, endDate: Date)
    : Observable<BonLivraisonResponseModel.BonLivraisonApiListModel[]> {

    const httpParams = new HttpParams()
      .set('cuisineId', cuisineId.toString())
      .set('contratId', contratId.toString())
      .set('restaurantId', restaurantId.toString())
      .set('dateConsoDebut', DateHelper.toISODateString(startDate))
      .set('dateConsoFin', DateHelper.toISODateString(endDate));

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/GetBonLivraisons`;
    return this.http.get<BonLivraisonResponseModel.BonLivraisonApiListModel[]>(url, { params: httpParams });
  }

  getBonLivraison(codeBonLivraison: string): Observable<BonLivraisonResponseModel.BonLivraisonApiModel> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/GetBonLivraison/${codeBonLivraison}`;
    return this.http.get<BonLivraisonResponseModel.BonLivraisonApiModel>(url);
  }

  declarerBonLivre(request: BonLivraisonRequestModel.LivraisonRequest) {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/LivrerBon`;
    return this.http.post(url, request);
  }

  receptionnerBon(request: BonLivraisonRequestModel.ReceptionRequest): Observable<{}> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/reception`;
    return this.http.post(url, request);
  }

  miseEnConformiteBon(request: BonLivraisonRequestModel.MiseEnConformiteRequest): Observable<{}> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/MiseEnConformite`;
    return this.http.post(url, request);
  }

  exportNonConformites(request: BonLivraisonRequestModel.ExportNonConformitesRequest): Observable<FileResultModel> {
    let httpParams = new HttpParams();
    if (request.grandCompteId) {
      httpParams = httpParams.set('grandCompteId', String(request.grandCompteId));
    }
    if (request.cuisineCentraleId) {
      httpParams = httpParams.set('cuisineCentraleId', String(request.cuisineCentraleId));
    }
    if (request.contratId) {
      httpParams = httpParams.set('contratId', String(request.contratId));
    }
    if (request.dateConsoDebut) {
      httpParams = httpParams.set('dateConsoDebut', DateHelper.toISODateString(request.dateConsoDebut));
    }
    if (request.dateConsoFin) {
      httpParams = httpParams.set('dateConsoFin', DateHelper.toISODateString(request.dateConsoFin));
    }

    const url = `${this.apiSettings.apiUrl}/bonlivraison/exportNonConformites`;
    return FileHelper.getFile(this.http, url, httpParams);
  }

  exportManques(request: BonLivraisonRequestModel.ExportManquesRequest): Observable<FileResultModel> {
    let httpParams = new HttpParams();
    if (request.grandCompteId) {
      httpParams = httpParams.set('grandCompteId', String(request.grandCompteId));
    }
    if (request.cuisineCentraleId) {
      httpParams = httpParams.set('cuisineCentraleId', String(request.cuisineCentraleId));
    }
    if (request.contratId) {
      httpParams = httpParams.set('contratId', String(request.contratId));
    }
    if (request.dateConsoDebut) {
      httpParams = httpParams.set('dateConsoDebut', DateHelper.toISODateString(request.dateConsoDebut));
    }
    if (request.dateConsoFin) {
      httpParams = httpParams.set('dateConsoFin', DateHelper.toISODateString(request.dateConsoFin));
    }

    const url = `${this.apiSettings.apiUrl}/bonlivraison/exportManques`;
    return FileHelper.getFile(this.http, url, httpParams);
  }
}
