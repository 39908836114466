
import { EventEmitter, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateHelper } from '../../shared/helpers/date.helper';
import { FileResultModel } from '../../shared/models/fileresult.model';
import { EpicerieInventaireProjector } from '../epicerie-inventaire.projector';
import { CreateCommandeApiModel } from '../models/api/create-commande.apimodel';
import { ArticleModel, CartArticleModel } from '../models/article.model';
import { CommandeModel } from '../models/commande.model';
import { DateLivraisonModel } from '../models/date-livraison.model';
import { HistoryCommandeModel } from '../models/history-commande.model';
import { TypeListeArticleModel, TypeListeArticlesModel } from '../models/type-liste-articles.model';
import { EpicerieInventaireApiService } from './api/epicerie-inventaire.apiservice';

@Injectable()
export class EpicerieInventaireService {
  // DDJ : la logique de gestion du cart est dans le service, donc on met le isDirty ici, il faudrait envisager de mettre tout ça dans le cart.component plutot ?
  public isDirty: boolean;
  private _displayCart: boolean;
  public showCartEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public readonly QuantiteMax = 999;
  public readonly CartMaxLength = 99;
  /** Fields */
  public currentCartArticles: CartArticleModel[] = [];

  /** get set */
  public get currentCartLength(): number {
    return this.currentCartArticles.length;
  }

  /** Ctor */
  constructor(
    private epicerieInventaireApiService: EpicerieInventaireApiService,
    private _toastrService: ToastrService
  ) { }

  /** Logic */
  public clearCartArticles() {
    this.currentCartArticles = [];
    this.isDirty = false;
  }

  /** Ajout d'un article dans le panier. Retourne True si l'article a été ajouté */
  public addArticle(articleModel: ArticleModel, quantity: number) {
    const cartArticle = EpicerieInventaireProjector.projectArticleToCartArticle(articleModel, quantity);
    return this.handleNewArticle(cartArticle);
  }

  /** Gère le add/append du nouvel article  */
  public handleNewArticle(newArticle: CartArticleModel) {
    this.isDirty = true;

    const maxQuantity = newArticle.qteLimite || this.QuantiteMax;
    const existing = this.currentCartArticles.find(a => a.id === newArticle.id);
    if (existing) {
      existing.quantity += newArticle.quantity;
      newArticle = existing;
    } else {
      if (this.currentCartArticles.length < this.CartMaxLength) {
        this.currentCartArticles.push(newArticle);
      }
      else {
        this.warnMaxCartReached();
        return false; // exit
      }
    }

    //ensure max quantity & notify user
    if (newArticle.quantity > maxQuantity) {
      newArticle.quantity = maxQuantity;
      this.warnMaxArticlesReached(newArticle);
      return false;
    }

    return true;
  }

  warnMaxArticlesReached(article: ArticleModel) {
    const maxQuantity = article.qteLimite || this.QuantiteMax;
    this._toastrService.warning('Vous avez atteint la quantité maximum commandable', `Quantité maximum : ${maxQuantity}`, { extendedTimeOut: 2000 });
  }

  warnMaxCartReached() {
    this._toastrService.warning('Votre panier a atteint la taille maximum', `Quantité maximum : ${this.CartMaxLength}`, { extendedTimeOut: 2000 });
  }

  public showCart(displayCart: boolean) {
    this._displayCart = displayCart;
    this.showCartEmitter.next(displayCart);
  }

  public toggleCart() {
    this.showCart(!this._displayCart);
  }

  public getArticles(typeListeArticleId: number, cuisineCentraleId: number, restaurantId: number, contratId: number): Observable<ArticleModel[]> {
    // On recup une nouvelle liste d'articles --> plus dirty
    this.isDirty = false;
    return this.epicerieInventaireApiService.getArticles(typeListeArticleId, cuisineCentraleId, restaurantId, contratId).pipe(map(res => {
      return EpicerieInventaireProjector.projectArticles(res);
    }));
  }

  public getArticlePicture(id: string): string {
    return this.epicerieInventaireApiService.getArticlePicture(id);
  }

  public getTypeListeArticles(cuisineCentraleId: number, restaurantId: number, contratId: number, dateDebut?: Date, dateFin?: Date): Observable<TypeListeArticlesModel> {
    return this.epicerieInventaireApiService.getTypeListeArticles(cuisineCentraleId, restaurantId, contratId, dateDebut, dateFin).pipe(map(res => {
      return EpicerieInventaireProjector.projectTypeListeArticles(res);
    }));
  }

  public getAvailableDateLivraison(cuisineCentraleId: number, restaurantId: number, contratId: number, typeListeArticleId: number, dateDuMois: Date): Observable<DateLivraisonModel[]> {
    return this.epicerieInventaireApiService.getAvailableDateLivraison(cuisineCentraleId, restaurantId, contratId, typeListeArticleId, dateDuMois).pipe(map(res => {
      return EpicerieInventaireProjector.projectDateLivraisons(res);
    }));
  }

  /** Search commande */
  public getCommandes(cuisineCentraleId: number, typeList: TypeListeArticleModel, estEnAttenteValidation: boolean, restaurantId: number, contratId: number, startDate: Date = null, endDate: Date = null): Observable<HistoryCommandeModel[]> {
    // On s'assure que le filtre reste cohérent
    if (!typeList.validationRequise) {
      estEnAttenteValidation = false;
    }

    return this.epicerieInventaireApiService.getCommandes(estEnAttenteValidation, cuisineCentraleId, typeList.id, restaurantId, contratId, startDate, endDate).pipe(map(res => {
      return EpicerieInventaireProjector.projectCommandesResponseToHistoryCommande(res.commandes);
    }));
  }

  public getCommande(id: number): Observable<CommandeModel> {
    return this.epicerieInventaireApiService.getCommande(id).pipe(map(res => {
      return EpicerieInventaireProjector.projectCommande(res);
    }));
  }

  public getTypeListeArticleInfo(cuisineCentraleId: number, restaurantId: number, typeListeArticleId: number): Observable<TypeListeArticleModel> {
    return this.epicerieInventaireApiService.getTypeListeArticle(cuisineCentraleId, restaurantId, typeListeArticleId).pipe(map(res => {
      return EpicerieInventaireProjector.projectTypeListArticle(res);
    }));
  }

  public saveCommande(cuisineCentraleId: number, restaurantId: number, typeListeArticleId: number, contratId: number, dateLivraison: Date, articles: CartArticleModel[], commandeId: number = null): Observable<string> {
    const createCommande: CreateCommandeApiModel = {
      cuisineCentraleId,
      restaurantId,
      typeListeArticleId,
      contratId,
      dateLivraison: dateLivraison && DateHelper.toISODateString(dateLivraison),
      articles: EpicerieInventaireProjector.projectArticleDetails(articles),
      commandeId
    };
    return this.epicerieInventaireApiService.saveCommande(createCommande).pipe(map(res => {
      return res.message;
    }));
  }

  public deleteCommande(id: number): Observable<boolean> {
    return this.epicerieInventaireApiService.deleteCommande(id);
  }

  public imprimerCommande(commandeId: number): Observable<FileResultModel> {
    return this.epicerieInventaireApiService.imprimerCommande(commandeId);
  }

  /** LocalStorage */

  // public storeCartLocalStorage(listId: number, dateLivraison: Date, articles: CartArticleModel[]) {
  //     var cart = {'listId': listId, 'dateLivraison': dateLivraison, 'articleObj': [ articles ]}
  //     localStorage.setItem("cart", JSON.stringify(cart));
  // }

  // public getCartLocalStorage(listId: number, dateLivraison: Date) {
  //     var storedCart = JSON.parse(localStorage.getItem("cart"));
  // }

  // public deleteCartLocalStorage(listId: number, dateLivraison: Date) {
  //     localStorage.removeItem("cart");
  //     return;
  // }
}
