
import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription, Observable } from 'rxjs';
import { throttleTime, finalize, first, skipUntil, filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { DocumentThumbnailComponent } from '../../document-thumbnail/document-thumbnail.component';
import { ApplicationDataService } from '../../shared/services/application-data.service';
import { InteractionService } from '../../shared/services/interaction.service';
import { DiscussionMessageModel } from '../models/discussion-message.model';
import { DiscussionStatusEnum } from '../models/discussion-status.enum';
import { DiscussionModel } from '../models/discussion.model';
import { DiscussionService } from '../services/discussion.service';
import { NotificationCustomModel } from 'src/app/shared/models/notification.model';
import { RequalifierDiscussionComponent } from '../requalifier-discussion/requalifier-discussion.component';
import { RequalifierDiscussionModel } from '../requalifier-discussion/requalifier-discussion.model';
import { TypeInterventionGroup } from '../models/type-intervention.model';
import { RestaurantModel } from 'src/app/shared/models/restaurant.model';

@Component({
  selector: 'discussion-details',
  templateUrl: './discussion-details.component.html',
  styleUrls: ['./discussion-details.component.scss']
})
export class DiscussionDetailsComponent implements OnInit, AfterViewChecked, OnDestroy {

  /** ViewChildren */
  @ViewChildren(DocumentThumbnailComponent) thumbnailComponents: QueryList<DocumentThumbnailComponent>;

  @ViewChild('messageList', { static: true }) messageListElement: ElementRef;

  /** Inputs Outputs  */

  @Input()
  public typeInterventionGroups$: Observable<TypeInterventionGroup[]>;

  @Input()
  public restaurant: RestaurantModel;

  private _discussion: DiscussionModel;
  @Input()
  set discussion(val: DiscussionModel) {
    this.clearData();
    this.clearThumbnailsData();
    this._discussion = val;
    if (val != null) {
      this.isClosed = val.statut == DiscussionStatusEnum.Ferme;
      this.initialIsClosed = this.isClosed;
      this.openDiscussion(val.id);
    }
  }
  get discussion() {
    return this._discussion;
  }

  @Output()
  discussionClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  messageLuTriggered: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  refreshDiscussionsRequired: EventEmitter<{ isClosed: boolean, discussionId: number }> = new EventEmitter<{ isClosed: boolean, discussionId: number }>();

  /** Props  */
  public discussionMessages: DiscussionMessageModel[] = [];
  public isLoadingMessages: boolean;
  public isSendingMessage: boolean;
  private userId: string;

  /** Form data */
  public text: string;
  public isClosed: boolean;
  private initialIsClosed: boolean;
  private isScrollBottomRequired: boolean;
  public uploader: FileUploader = new FileUploader({});

  /** image loaded  */
  imageLoadedSubs: Subscription[] = [];
  imageLoadedCount = 0;

  public get showTakePictureButton() {
    return window.cordova != null;
  }


  constructor(private authService: AuthenticationService,
              private appDataService: ApplicationDataService,
              private discussionService: DiscussionService,
              private interactionService: InteractionService) {
  }

  ngOnInit() {
    this.authService.currentUser$
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.userId = user && user.id;
      });
  }

  onThumbnailImageLoaded() {
    // lorsqu'une thumbnail est loadé, on incrémente le compteur, lorsque tous les ThumbnailComponent ont été loadé (image loaded venant de la balise img) on scroll en bas
    if (this.imageLoadedCount++ === this.thumbnailComponents.length - 1) {
      this.scrollBottomMessages();
      this.clearThumbnailsData();
    }
  }

  clearThumbnailsData() {
    this.imageLoadedCount = 0;
    this.imageLoadedSubs.forEach(sub => {
      sub.unsubscribe();
    });

    this.imageLoadedSubs = [];
  }

  ngAfterViewChecked() {
    if (this.isScrollBottomRequired) {
      this.scrollBottomMessages();
      this.isScrollBottomRequired = false;
      if (this.thumbnailComponents) {
        this.thumbnailComponents.forEach(thumbComponent => {
          this.imageLoadedSubs.push(thumbComponent.imageLoadedEmitter.subscribe(res => {
            this.onThumbnailImageLoaded();
          }));
        });
      }
    }
  }

  ngOnDestroy(): void {
    // required (untilDestroyed)
  }

  openDiscussion(discussionId: number) {
    this.isLoadingMessages = true;
    this.discussionService.getDiscussionMessages(this.userId, discussionId).pipe(
      throttleTime(1000),
      first(),
      // delay(100000), //debug
      finalize(() => {
        this.isLoadingMessages = false;
      }))
      .subscribe(messages => {
        this.discussionMessages = messages;
        this.isScrollBottomRequired = true;
        // Dans 2 secondes, si la discussion est toujours ouverte et non lue, on envoit la requete http lireDiscussion
        if (!this._discussion.lu) {
          setTimeout(() => {
            this.trySendLireDiscussion();
          }, 2000);
        }
      },
        err => {
          this.interactionService.showSimpleMessage('Erreur', 'Une erreur est survenue lors du chargement de la discussion.');
          this.clearData();
        });
  }

  private trySendLireDiscussion() {
    if (this._discussion != null) {
      this.discussionService.lireDiscussion(this._discussion.id)
        .subscribe(b => {
          this.appDataService.discussionLuDecreaseCount(1);
          this.messageLuTriggered.next(this._discussion.id);
        });
    }
  }

  sendMessage() {
    if (this._discussion == null || this.isSendingMessage) {
      return;
    }

    if (!this.text) {
      this.interactionService.showSimpleMessage('Erreur', 'Le texte du message n\'est pas renseigné !');
      return;
    }

    const files = this.uploader.queue.map(i => i._file);

    this.isSendingMessage = true;
    this.discussionService.sendNewMessage(this.userId, this._discussion.id, this.text, this.isClosed, files).pipe(
      throttleTime(1000),
      first(),
      finalize(() => {
        this.isSendingMessage = false;
      }))
      .subscribe(msg => {
        this.discussionMessages.push(msg);

        // le statut a changé donc on doit changer de liste en conséquence pour garder le selected
        if (this.initialIsClosed != this.isClosed) {
          this.refreshDiscussionsRequired.next({ isClosed: this.isClosed, discussionId: this._discussion.id });
        }

        this.text = '';
        this.uploader.clearQueue();
        this.isScrollBottomRequired = true;
      },
        err => {
          this.interactionService.showSimpleMessage('Erreur', 'Le message n\'a pas pu être envoyé.');
        });
  }

  clearData() {
    this.discussionMessages = null;
    this._discussion = null;
    this.text = '';
    this.uploader.clearQueue();
  }

  clearOnlyForm() {
    this.text = '';
    this.uploader.clearQueue();
    this.isClosed = false;
  }

  close(event: any = null) {
    if (event != null) {
      event.preventDefault();
    }

    this.clearOnlyForm();
    this.discussionClosed.next();
  }

  scrollBottomMessages() {
    if (this.messageListElement != null) {
      const element = this.messageListElement.nativeElement;
      if (element != null) {
        element.scrollTop = element.scrollHeight;
      }
    }
  }

  requalifierDemande() {
    // TODO loader

    this.typeInterventionGroups$
      .pipe(
        filter(tiGroups => tiGroups !== undefined),
        first()
      )
      .subscribe(tiGroups => {
        const notif: NotificationCustomModel = new NotificationCustomModel();
        notif.id = 1;
        notif.title = 'Requalifier la demande';
        notif.type = RequalifierDiscussionComponent;
        notif.fullScreen = false;
        notif.closeOnBackdropClick = false;
        const popupModel: RequalifierDiscussionModel = {
          discussion: this._discussion,
          typeInterventionGroups: tiGroups,
          restaurant: this.restaurant,
          onRequalificationSucceed: () => {
            this.refreshDiscussionsRequired.next({ isClosed: this.isClosed, discussionId: this._discussion.id });
          }
        };
        notif.data = popupModel;
        notif.hasNoOverflow = true;

        this.interactionService.showNotification(notif);
      });
  }
}
