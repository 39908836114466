import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RepasComponent } from '../repas/repas.component';
import { AuthGuard } from '../shared/guards/auth-guard';
import { PromptForNavGuard } from '../shared/guards/prompt-for-nav-guard';

const reportingRoutes: Routes = [
  {
    path: 'repas',
    component: RepasComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PromptForNavGuard],
    data: { title: 'Repas' }
  },
  // On utilise le composant Repas pour le piquenique, il sait qu'il est en piquenique grâce à l'url (voir le ngOnInit du repas.component)
  {
    path: 'piquenique',
    component: RepasComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PromptForNavGuard],
    data: { title: 'Pique-nique', isPiqueNique: true }
  },
];
@NgModule({
  imports: [
    RouterModule.forChild(reportingRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class RepasRoutingModule { }
