import { NotificationConfirmModel } from '../shared/models/notification.model';
import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { ApplicationDataService } from '../shared/services/application-data.service';
import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-popup-confirm',
  templateUrl: './popup-confirm.component.html',
  styleUrls: ['./popup-confirm.component.scss']
})
export class PopupConfirmComponent implements OnInit, OnDestroy {
  @HostBinding('class.visible')
  public showPopup: boolean;
  public currentNotification: NotificationConfirmModel;
  public currentType: RestaurantTypeEnum;
  private restTypeSubscription: Subscription;

  constructor(private appDataService: ApplicationDataService) { }

  ngOnInit() {
    this.restTypeSubscription = this.appDataService.restaurantType$
      .subscribe(type => {
        this.currentType = type;
      });
  }

  show(notif: NotificationConfirmModel) {
    this.currentNotification = notif;
    this.showPopup = true;
  }

  ok() {
    this.currentNotification.onOk();
    this.hide();
  }

  cancel() {
    this.currentNotification.onCancel();
    this.hide();
  }

  hide() {
    this.showPopup = false;
    if (this.currentNotification) {
      this.currentNotification.onClose();
      this.currentNotification = null;
    }
  }

  ngOnDestroy() {
    this.restTypeSubscription.unsubscribe();
  }

}
