import { OdgEvaluationNote } from '../models/odg.model';
import { SmileyBase } from './smiley-base';
import { Component, Input, Output, EventEmitter } from '@angular/core'


@Component({
    selector: 'odg-smiley-pcdt',
    template: `
    <div (click)="onSmileyClick()" class="smiley-img">
        <img *ngIf="!isActive" src="/assets/images/odg/pcdt.png" />
        <img *ngIf="isActive" src="/assets/images/odg/pcdt-active.png" />
    </div>
    `,
    styleUrls: ['./smiley.component.scss']
})
export class OdgSmileyPasConsommeDuToutComponent extends SmileyBase {
    @Input()
    activeNote: OdgEvaluationNote;
    @Output()
    smileyClick: EventEmitter<OdgEvaluationNote> = new EventEmitter<OdgEvaluationNote>();

    note: OdgEvaluationNote = OdgEvaluationNote.PasConsommeDuTout;
}