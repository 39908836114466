import { LieuCommandeModel, SemaineInfosModel } from '../../repas/models/commande-repas.model';

export class MatriceDeChoixFilterModel {
    selectedLieuCommande: LieuCommandeModel;

    selectedSemaine: SemaineInfosModel;
    downloading: boolean;

    constructor(public lieuCommandeList: LieuCommandeModel[], public semaineList: SemaineInfosModel[]) {
        this.selectedSemaine = semaineList[0];
        this.selectedLieuCommande = lieuCommandeList[0];
    }

    getDate() {
        return this.selectedSemaine.dateDebut;
    }
}
