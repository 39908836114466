
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { OdgApiService } from "./api/odg.apiservice";
import { OdgProjector } from "../projectors/odg.projector";

import { OdgEvaluationNote, OdgJourModel, OdgModel, OdgPrestationModel } from "../models/odg.model";
@Injectable()
export class OdgService {
  constructor(private odgApiService: OdgApiService) {}

  getOdg(cuisineCentraleId: number, restaurantId: number, odgConviveId: number = undefined, day: Date): Observable<OdgModel> {
    return this.odgApiService.getOdg(cuisineCentraleId, restaurantId, odgConviveId, day).pipe(map(res => {
      return OdgProjector.projectOdg(res);
    }));
  }

  postOdg(odg: OdgModel, odgConviveId: number): Observable<boolean> {
    const newOdg = this.removeUnmodifyEvaluation(odg);
    const request = OdgProjector.projectPostOdg(newOdg, odgConviveId);
    return this.odgApiService.postOdg(request).pipe(map(res => {
      return true;
    }));
  }

  private removeUnmodifyEvaluation(odg: OdgModel): OdgModel {
    const newOdg = new OdgModel();
    (newOdg.odgConvives = odg.odgConvives),
      (newOdg.restaurantId = odg.restaurantId),
      (newOdg.odgRestaurantId = odg.odgRestaurantId),
      (newOdg.odgContratId = odg.odgContratId),
      (newOdg.odgJours = odg.odgJours.map(jour => {
        const newOdgJour = new OdgJourModel(jour.date);
        (newOdgJour.observations = jour.observations),
          (newOdgJour.autoriserSaisie = jour.autoriserSaisie),
          (newOdgJour.odgPrestations = jour.odgPrestations.map(presta => {
            const newOdgPrestation = new OdgPrestationModel();
            (newOdgPrestation.id = presta.id),
              (newOdgPrestation.isCollapsed = presta.isCollapsed),
              (newOdgPrestation.libelle = presta.libelle),
              (newOdgPrestation.odgEvaluations = presta.odgEvaluations.filter(evaluation => {
                // on supprime les évaluations qui on comme id -1 et qui n'ont pas reçu de note, pour ne pas fausser les statistiques
                return evaluation.id != -1 || evaluation.note != OdgEvaluationNote.None;
              }));
            return newOdgPrestation;
          }));
        return newOdgJour;
      }));
    return newOdg;
  }
}
