import { deburr, includes, trimEnd, padEnd } from 'lodash';
export class StringHelper {
  static withoutAccent(str: string): string {
    // see https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript

    // return str && str.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); //need polyfill for es6 (ie11)
    // use lodash deburr instead
    return str && deburr(str);
  }

  static indexOf(text: string, search: string, caseSensitive: boolean = false, ignoreAccents = true)
    : number {
    if (!caseSensitive) {
      search = search.toLocaleLowerCase();
      text = text.toLocaleLowerCase();
    }

    if (ignoreAccents) {
      search = this.withoutAccent(search);
      text = this.withoutAccent(text);
    }

    return text.indexOf(search);
  }

  static includes(text: string, search: string, caseSensitive: boolean = false, ignoreAccents = true)
    : boolean {
    return StringHelper.indexOf(text, search, caseSensitive, ignoreAccents) > -1;
  }

  static jsonParseWithDates<T>(json: string, dateFields?: Array<string | RegExp>): T {
    return JSON.parse(json, dateFields ? StringHelper.buildJsonDateReviver(dateFields) : StringHelper._defaultDateReviver) as T;
  }

  static hashCode(s: string): number {
    // see https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
    let h = 0;
    if (Math.imul) {
      // faster
      for (let i = 0; i < s.length; i++) {
        // tslint:disable-next-line: no-bitwise
        h = Math.imul(31, h) + s.charCodeAt(i) | 0;
      }
    } else {
      // fallback for legacy browsers
      const l = s.length;
      let i = 0;
      if (l > 0) {
        while (i < l) {
          // tslint:disable-next-line: no-bitwise
          h = (h << 5) - h + s.charCodeAt(i++) | 0;
        }
      }
    }

    return h;
  }

  // tslint:disable-next-line: member-ordering
  private static _defaultDateReviver = StringHelper.buildJsonDateReviver([/date/i]);

  private static buildJsonDateReviver(dateFields: Array<string | RegExp>): (this: any, key: string, value: any) => any {
    return function customJsonDateReviver(key, value) {
      if (typeof value === 'string'
        && dateFields.some(fieldMatcher => (typeof fieldMatcher === 'string') ? includes(key, fieldMatcher) : (fieldMatcher as RegExp).test(key))
      ) {
        return new Date(value);
      }
      return value;
    };
  }

  // TODO étendre angular http body parse : https://github.com/angular/angular/blob/master/packages/http/src/body.ts#L26
  // => Ajouter un nouveau prototype ou faire un helper

  static formatDecimal(value: number, minDecimals: number = 2, maxDecimals: number = 3, decimalSeparator: string = ','): string {
    if (value === null) {
      return null;
    }

    // on garde 3 décimales max, à minima on en affiche 2
    const split = value.toFixed(maxDecimals).split('.');
    const integerPart = split[0];
    let decimalPart: string;
    if (split.length === 2) {
      decimalPart = (split[1] || '');
      decimalPart = trimEnd(decimalPart, '0');
      decimalPart = padEnd(decimalPart, minDecimals, '0');
    } else {
      decimalPart = padEnd('', minDecimals, '0');
    }

    return `${integerPart}${decimalPart.length > 0 ? decimalSeparator : ''}${decimalPart}`;
  }


  private static getCircularReplacer() {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  static jsonStringifyCircular(object: any): string {
    return JSON.stringify(object, StringHelper.getCircularReplacer());
  }

  /**
   *  Escape string for use in javascript regex. see: https://stackoverflow.com/a/6969486/590741
   */
  static escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
}
