import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SandboxComponent } from './sandbox/sandbox.component';
import { AuthGuard } from './shared/guards/auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Connexion' }
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { title: 'Bienvenue' }
  },
  {
    path: 'sandbox',
    component: SandboxComponent,
    data: { title: 'Sandbox' }
  },
  // {
  //   path: 'bonlivraison',
  //   loadChildren: () => import('./bon-livraison/bon-livraison.module').then(mod => mod.BonLivraisonModule)
  // },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
