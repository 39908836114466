/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./livraison-temperature.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../pipes/format-temperature.pipe";
import * as i4 from "@angular/common";
import * as i5 from "./livraison-temperature.component";
import * as i6 from "../../services/bon-livraison.service";
var styles_LivraisonTemperatureComponent = [i0.styles];
var RenderType_LivraisonTemperatureComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LivraisonTemperatureComponent, data: {} });
export { RenderType_LivraisonTemperatureComponent as RenderType_LivraisonTemperatureComponent };
function View_LivraisonTemperatureComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "input-temperature-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "generic-button inputPlusMoins input-moins"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.incrementTemperature((_co.step * (0 - 1))) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["remove"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "input", [["placeholder", "T\u00B0C"], ["type", "text"]], [[8, "className", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = (_co.parseTemperature($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(8, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵppd(9, 1), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(11, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "generic-button inputPlusMoins input-plus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.incrementTemperature(_co.step) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["add"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 0), _co.plat.livraisonTemperature)); _ck(_v, 8, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "inputTemperature temperature temperature-color-", _co.TemperatureStatusEnum[_co.temperatureStatus], ""); var currVal_1 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 11).ngClassValid; var currVal_6 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_LivraisonTemperatureComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "temperature temperature-color-", _co.TemperatureStatusEnum[_co.temperatureStatus], ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.plat.livraisonTemperature, "\u00B0C")); _ck(_v, 1, 0, currVal_1); }); }
export function View_LivraisonTemperatureComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.FormatTemperaturePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LivraisonTemperatureComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["elseTemplate", 2]], null, 0, null, View_LivraisonTemperatureComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isModifiable; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_LivraisonTemperatureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-livraison-temperature", [], null, null, null, View_LivraisonTemperatureComponent_0, RenderType_LivraisonTemperatureComponent)), i1.ɵdid(1, 114688, null, 0, i5.LivraisonTemperatureComponent, [i6.BonLivraisonService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LivraisonTemperatureComponentNgFactory = i1.ɵccf("app-livraison-temperature", i5.LivraisonTemperatureComponent, View_LivraisonTemperatureComponent_Host_0, { plat: "plat", settings: "settings", isModifiable: "isModifiable", step: "step" }, {}, []);
export { LivraisonTemperatureComponentNgFactory as LivraisonTemperatureComponentNgFactory };
