import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { RatingModel } from './rating.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingComponent implements OnInit, OnDestroy {

  @Input() itemId: number;
  @Input() ratings: RatingModel[] =
    [
      { rating: 1, tooltip: 'Pas du tout satisfait', label: this._domSanitizer.bypassSecurityTrustHtml(`<i class="material-icons">sentiment_very_dissatisfied</i>&nbsp;Pas du tout satisfait`) },
      { rating: 2, tooltip: 'Peu satisfait', label: this._domSanitizer.bypassSecurityTrustHtml(`<i class="material-icons">sentiment_dissatisfied</i>&nbsp;Peu satisfait`) },
      { rating: 3, tooltip: 'Ni satisfait, ni insatisfait', label: this._domSanitizer.bypassSecurityTrustHtml(`<i class="material-icons">sentiment_satisfied</i>&nbsp;Ni satisfait, ni insatisfait`) },
      { rating: 4, tooltip: 'Satisfait', label: this._domSanitizer.bypassSecurityTrustHtml(`<i class="material-icons">sentiment_satisfied_alt</i>&nbsp;Satisfait`) },
      { rating: 5, tooltip: 'Très satisfait', label: this._domSanitizer.bypassSecurityTrustHtml(`<i class="material-icons">sentiment_very_satisfied</i>&nbsp;Très satisfait`) },
    ].reverse(); // reverse pour gérer les styles facilement avec des siblings ~
  @Input() rating: number;
  @Input() ratingModel: RatingModel;
  @Output() ratingClick: EventEmitter<RatingModel> = new EventEmitter<RatingModel>();

  inputName: string;
  nextRating: RatingModel;

  constructor(
    private _domSanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    this.inputName = this.itemId + '_rating';
  }

  ngOnDestroy(): void {
    this.ratingClick.unsubscribe();
  }

  onClick(rating: RatingModel): void {
    this.ratingModel = rating;
    this.rating = rating.rating;
    this.ratingClick.emit(rating);
  }

  mouseenter(rating: RatingModel): void {
    this.nextRating = rating;
  }

  mouseleave() {
    this.nextRating = null;
  }
}
