import { EpicerieInventaireService } from '../epicerie-inventaire/services/epicerie-inventaire.service';
import { Component, OnInit } from '@angular/core';
import { CartArticleModel } from '../epicerie-inventaire/models/article.model';

@Component({
  selector: 'app-cart-tile',
  templateUrl: './cart-tile.component.html',
  styleUrls: ['./cart-tile.component.scss']
})
export class CartTileComponent implements OnInit {
  public get articles(): CartArticleModel[] {
    return this.epicerieInventaireService.currentCartArticles || [];
  }

  constructor(private epicerieInventaireService: EpicerieInventaireService) { }

  ngOnInit() {
  }

  onClick() {
    this.epicerieInventaireService.toggleCart();
  }
}
