import { OdgEvaluationNote } from '../models/odg.model';
import { SmileyBase } from './smiley-base';
import { EventEmitter } from '@angular/core';
export class OdgSmileyPasConsommeDuToutComponent extends SmileyBase {
    constructor() {
        super(...arguments);
        this.smileyClick = new EventEmitter();
        this.note = OdgEvaluationNote.PasConsommeDuTout;
    }
}
