import { Directive, Input, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
    selector: '[inputNumberRange]',
    host: {
        '(keyup)': 'onKeyUp($event)'
    }
})
export class InputNumberRangeDirective {
    range: NumberRange;

    constructor(private el: ElementRef) {
    }

    onKeyUp(event: Event) {
        let fc = this.range.fc;
        if (fc) {
            if (fc.value < this.range.min) {
                fc.setValue(this.range.min);
            }
            else if (fc.value > this.range.max) {
                fc.setValue(this.range.max);
            }
        }
        else {
            let val = this.el.nativeElement.value;
            if (val < this.range.min) {
                this.el.nativeElement.value = this.range.min;
            }
            else if (val > this.range.max) {
                this.el.nativeElement.value = this.range.max;
            }
        }
    }

    @Input()
    set inputNumberRange(range: NumberRange) {
        if (!range.min) {
            range.min = 0;
        }

        if (!range.max) {
            throw 'Range.max is required for input-number-range directive';
        }

        this.range = range;
    }

}

interface NumberRange {
    min: number;
    max: number;
    fc?: FormControl;
}