import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map, first } from 'rxjs/operators';
import { ROUTE_URLS } from '../constants/route_urls';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { LogService } from '../services/log.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private _authService: AuthenticationService,
    private router: Router,
    private logService: LogService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this._authService.currentUser$
      .pipe(
        first(),
        map((user) => {
        if (!user) {
          this.logService.Info('User not authenticated, redirecting to login', 'AuthGuard');
          this._authService.signOut(true, state.url);
          return false;
        } else if (route.url[0] != null && !user.hasAccessToFeature('/' + route.url[0].path)) {
          // --> 404 page ?
          this.router.navigate([ROUTE_URLS.Home]);
          return false;
        }
        this.logService.Info('[AuthGuard] User authenticated, navigation OK', 'AuthGuard');
        return true;
      }));

  }
}
