/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/directives/restaurant-type-color.directive";
import * as i3 from "../meal-tile/meal-tile.component.ngfactory";
import * as i4 from "../meal-tile/meal-tile.component";
import * as i5 from "@angular/router";
import * as i6 from "../meal-slider/meal-slider.component.ngfactory";
import * as i7 from "../meal-slider/meal-slider.component";
import * as i8 from "@angular/common";
import * as i9 from "./home.component";
import * as i10 from "../repas/services/commande-repas.service";
import * as i11 from "../shared/services/application-data.service";
import * as i12 from "../shared/services/api/logo/logo-route.service";
import * as i13 from "../shared/services/authentication/authentication.service";
import * as i14 from "../shared/services/interaction.service";
import * as i15 from "../alertes/services/alertes.service";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" : "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dailyQuoteTitle; _ck(_v, 2, 0, currVal_0); }); }
function View_HomeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "box btn-ripple pointerHover"]], [[2, "disabled", null]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpod(2, { "type": 0, "background": 1, "hover": 2, "isSecondary": 3 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-meal-tile", [], null, null, null, i3.View_MealTileComponent_0, i3.RenderType_MealTileComponent)), i1.ɵdid(4, 114688, null, 0, i4.MealTileComponent, [i5.Router], { item: [0, "item"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "border"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, _co.currentType, true, true, true); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = !_v.context.$implicit.isEnabled; _ck(_v, 0, 0, currVal_0); }); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mealSlider: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "home-top"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "welcome"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Bienvenue sur "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["class", "logo-icon"], ["src", "/assets/images/welcome_logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "tiles-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-meal-slider", [], [[8, "hidden", 0]], null, null, i6.View_MealSliderComponent_0, i6.RenderType_MealSliderComponent)), i1.ɵdid(9, 49152, [[1, 4]], 0, i7.MealSliderComponent, [i1.ChangeDetectorRef], { dailyPrestations: [0, "dailyPrestations"], currentType: [1, "currentType"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "jagged-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 13, "div", [["class", "home-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["class", "daily-quote pointerHover"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAlerte() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_1)), i1.ɵdid(15, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "hr", [["class", "bar"]], null, null, null, null, null)), i1.ɵdid(19, 16384, null, 0, i2.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpod(20, { "type": 0, "background": 1 }), (_l()(), i1.ɵeld(21, 0, null, null, 3, "div", [["class", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HomeComponent_2)), i1.ɵdid(23, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.dailyPrestations; var currVal_3 = _co.currentType; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_5 = _co.dailyQuoteTitle; _ck(_v, 15, 0, currVal_5); var currVal_7 = _ck(_v, 20, 0, _co.currentType, true); _ck(_v, 19, 0, currVal_7); var currVal_8 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform(_co.menuList$)); _ck(_v, 23, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.logoContratUrl) + ")"); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.dailyPrestations; _ck(_v, 8, 0, currVal_1); var currVal_4 = !_co.dailyQuote; _ck(_v, 13, 0, currVal_4); var currVal_6 = _co.dailyQuote; _ck(_v, 17, 0, currVal_6); }); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 245760, null, 0, i9.HomeComponent, [i10.CommandeRepasService, i11.ApplicationDataService, i12.LogoRouteService, i13.AuthenticationService, i14.InteractionService, i15.AlertesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i9.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
