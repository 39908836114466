/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./livraison-signature.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./livraison-signature.component";
var styles_LivraisonSignatureComponent = [i0.styles];
var RenderType_LivraisonSignatureComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LivraisonSignatureComponent, data: {} });
export { RenderType_LivraisonSignatureComponent as RenderType_LivraisonSignatureComponent };
export function View_LivraisonSignatureComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { canvasRef: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["signatureCanvas", 1]], null, 0, "canvas", [["class", "signature-pad-canvas"], ["height", "200"], ["tabindex", "0"], ["width", "400"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "button", [["class", "generic-button btn-clear"], ["title", "effacer la signature"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clear() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["clear"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Effacer"]))], null, null); }
export function View_LivraisonSignatureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-livraison-signature", [], null, null, null, View_LivraisonSignatureComponent_0, RenderType_LivraisonSignatureComponent)), i1.ɵdid(1, 4440064, null, 0, i2.LivraisonSignatureComponent, [i1.NgZone, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LivraisonSignatureComponentNgFactory = i1.ɵccf("app-livraison-signature", i2.LivraisonSignatureComponent, View_LivraisonSignatureComponent_Host_0, { disabled: "disabled", value: "value" }, { valueChange: "valueChange" }, []);
export { LivraisonSignatureComponentNgFactory as LivraisonSignatureComponentNgFactory };
