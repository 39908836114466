<div class="loader-container" *ngIf="isLoadingBonLivraison">
  <app-loader message="Chargement..."></app-loader>
</div>
<div class="details-container" *ngIf="!isLoadingBonLivraison && detailsBonLivraison != null">
  <div class="right-buttons">
    <button (click)="validate()" class="generic-button validate-button" [class.red-button]="!isValidate" [class.green-button]="isValidate" *ngIf="isModifiable">
      Valider
    </button>
  </div>
  <div class="buttons-container">
    <div class="save-button" title="Valider" (click)="validate()" [class.red-button]="!isValidate" [class.green-button]="isValidate" *ngIf="isModifiable">
      <div></div>
    </div>
  </div>
  <app-bon-livraison-info-header [bonLivraison]="detailsBonLivraison"></app-bon-livraison-info-header>
  <div class="prestation-container" *ngFor="let prestation of detailsBonLivraison.bonLivraisonPrestations">
    <div class="prestation-libelle restaurant-type-background">{{ prestation.libelle }}</div>
    <div *ngFor="let famillePlat of prestation.bonLivraisonFamillePlats">
      <div class="famille-plat-libelle">{{ famillePlat.libelle }}</div>
      <div *ngFor="let plat of famillePlat.bonLivraisonPlats">
        <div class="plat-libelle">{{ plat.libelle }}</div>
        <div *ngFor="let composant of plat.bonLivraisonComposants">
          <div class="composant-section">
            <div class="composant-libelle">{{ composant.libelle }}</div>
            <div class="composant-quantite">{{ composant.quantite }}</div>
            <div class="composant-contenance">x{{ composant.contenance }}</div>
            <div class="composant-mesure">{{ composant.unite }}</div>
            <div class="composant-quantite-relative">
              <app-composants-livres [composant]="composant" [isModifiable]="detailsBonLivraison.peutReceptionner"></app-composants-livres>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="restaurant-type-background secondary">
    <app-bonlivraison-info-panel [bonLivraison]="detailsBonLivraison"></app-bonlivraison-info-panel>
  </div>
  <!-- <div class="actions-right">
      <div class="right-buttons">
        <button (click)="validate()" class="generic-button validate-button"
                                            [class.red-button]="!isValidate"
                                            [class.green-button]="isValidate">
            Valider la réception
        </button>
      </div>
    </div> -->
</div>
