/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alertes-tile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./alertes-tile.component";
import * as i4 from "../../services/alertes.service";
var styles_AlertesTileComponent = [i0.styles];
var RenderType_AlertesTileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertesTileComponent, data: {} });
export { RenderType_AlertesTileComponent as RenderType_AlertesTileComponent };
function View_AlertesTileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge-alertes"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf.countUnread; _ck(_v, 1, 0, currVal_0); }); }
function View_AlertesTileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "btn-alertes"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showNotifications(_v.parent.context.ngIf) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["notifications_none"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertesTileComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.countUnread; _ck(_v, 4, 0, currVal_0); }, null); }
function View_AlertesTileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertesTileComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.hideIfNoAlerte || (_v.context.ngIf && _v.context.ngIf.hasDisplayableNotification)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AlertesTileComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_AlertesTileComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.notifications$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AlertesTileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alertes-tile", [], null, null, null, View_AlertesTileComponent_0, RenderType_AlertesTileComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertesTileComponent, [i4.AlertesService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertesTileComponentNgFactory = i1.ɵccf("app-alertes-tile", i3.AlertesTileComponent, View_AlertesTileComponent_Host_0, { hideIfNoAlerte: "hideIfNoAlerte" }, {}, []);
export { AlertesTileComponentNgFactory as AlertesTileComponentNgFactory };
