import { PromptForNavGuard } from "../shared/guards/prompt-for-nav-guard";
import { Routes } from "@angular/router";
import { AuthGuard } from "../shared/guards/auth-guard";
import { EpicerieInventaireCreateComponent } from "./create/epicerie-inventaire-create.component";
import { EpicerieInventaireComponent } from "./epicerie-inventaire.component";
const ɵ0 = { title: "Épicerie & inventaire" }, ɵ1 = { title: "Épicerie & inventaire - création/édition" };
const epicerieInventaireRoutes = [
    {
        path: "epicerieinventaire",
        component: EpicerieInventaireComponent,
        canActivate: [AuthGuard],
        data: ɵ0
    },
    {
        path: "epicerieinventaire/create",
        component: EpicerieInventaireCreateComponent,
        canActivate: [AuthGuard],
        canDeactivate: [PromptForNavGuard],
        data: ɵ1
    }
];
export class EpicerieInventaireRoutingModule {
}
export { ɵ0, ɵ1 };
