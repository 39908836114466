import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { SharedModule } from '../shared/shared.module';
import { DiscussionDetailsComponent } from './discussion-details/discussion-details.component';
import { DiscussionRoutingModule } from './discussion-routing.module';
import { DiscussionComponent } from './discussion.component';
import { ExportSuiviQualiteComponent } from './export-suivi-qualite/export-suivi-qualite.component';
import { NewDiscussionComponent } from './new-discussion/new-discussion.component';
import { DiscussionApiService } from './services/api/discussion.apiservice';
import { DiscussionService } from './services/discussion.service';
import { RequalifierDiscussionComponent } from './requalifier-discussion/requalifier-discussion.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DiscussionRoutingModule,
    FormsModule,
    FileUploadModule
  ],
  declarations:
    [
      DiscussionComponent,
      NewDiscussionComponent,
      DiscussionDetailsComponent,
      ExportSuiviQualiteComponent,
      RequalifierDiscussionComponent
    ],
  entryComponents: [
    RequalifierDiscussionComponent
  ],
  providers:
    [
      DiscussionService,
      DiscussionApiService
    ],
  exports: [ExportSuiviQualiteComponent]
})
export class DiscussionModule { }
