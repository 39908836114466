
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { OdgBadEvaluationComponent } from './odg-bad-evaluation/odg-bad-evaluation.component';
import { OdgEvaluationComponent } from './odg-evaluation/odg-evaluation.component';
import { OdgPrestationComponent } from './odg-prestation/odg-prestation.component';
import { OdgRoutingModule } from './odg-routing.module';
import { OdgComponent } from './odg.component';
import { OdgApiService } from './services/api/odg.apiservice';
import { OdgService } from './services/odg.service';
import { OdgSmileyPlutotBienConsommeComponent } from './smileys/odg-smiley-pbc.component';
import { OdgSmileyPasConsommeComponent } from './smileys/odg-smiley-pc.component';
import { OdgSmileyPasConsommeDuToutComponent } from './smileys/odg-smiley-pcdt.component';
import { OdgSmileyTresBienConsommeComponent } from './smileys/odg-smiley-tbc.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    OdgRoutingModule,
    FormsModule
  ],
  declarations:
  [
    OdgComponent,
    OdgPrestationComponent,
    OdgSmileyPlutotBienConsommeComponent,
    OdgSmileyPasConsommeComponent,
    OdgSmileyTresBienConsommeComponent,
    OdgSmileyPasConsommeDuToutComponent,
    OdgEvaluationComponent,
    OdgBadEvaluationComponent
  ],
  exports: [
    OdgBadEvaluationComponent
  ],
  providers: [
    OdgApiService,
    OdgService
  ]
})
export class OdgModule { }
