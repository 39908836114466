import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { BaseJourModel } from '../shared/models/base-jour.model';
import { SemaineModel } from '../shared/models/semaine.model';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss']
})


export class DateSelectorComponent {
  @Input()
  isSwipeNextAllowed: boolean;
  @Input()
  isSwipePrevAllowed: boolean;
  @Input()
  dateRanges: SemaineModel[];
  @Input()
  selectedRange: SemaineModel;

  previewClick: EventEmitter<void> = new EventEmitter<void>();
  nextClick: EventEmitter<void> = new EventEmitter<void>();

  _selectedJour: BaseJourModel;
  @Input()
  set selectedJour(val: BaseJourModel) {
    this._selectedJour = val;
    this.selectedJourChange.emit(this._selectedJour);
  }

  get selectedJour(): BaseJourModel {
    return this._selectedJour;
  }

  @Input()
  currentType: RestaurantTypeEnum;

  @Output()
  selectedJourChange: EventEmitter<BaseJourModel> = new EventEmitter<BaseJourModel>();
  constructor(private el: ElementRef) { }

  onNextClick() {
    if (!this.isSwipeNextAllowed) {
      return;
    }
    this.nextClick.emit();
  }

  onPrevClick() {
    if (!this.isSwipePrevAllowed) {
      return;
    }
    this.previewClick.emit();
  }

  onJourClick(jourModel: BaseJourModel) {
    this.selectedJour = jourModel;
  }
}
