import { CommandeRepasErrorModel, BaseErrorModel } from "../../shared/models/commande-repas-error.model";
import { Component, OnInit, Input, ChangeDetectionStrategy } from "@angular/core";
import { PopupCustomComponentBase } from "../../popup-custom/popup-custom-content.component";


@Component({
  selector: "error-popup",
  templateUrl: "./error-popup.component.html",
  styleUrls: ["./error-popup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorPopupComponent extends PopupCustomComponentBase {

  get errorPopupData(): ErrorPopupData[] {
    const errors: ErrorPopupData[] = [];

    (<CommandeRepasErrorModel>this.data).jours.map(jour => {
      const errorJour: ErrorPopupData = {
        jourDate: jour.date,
        messagesDatas: []
      }
      const aggregate: BaseErrorModel[] = [].concat(jour.famillePlats, jour.portions, jour.prestations);
      aggregate.map(item => {
        errorJour.messagesDatas.push(item);
      });
      errorJour.messagesDatas.sort((a, b) => {
        return a.index - b.index;
      });
      errors.push(errorJour);

    });
    errors.sort((a, b) => {
      return (b.jourDate < a.jourDate) ? 1 : -1;
    });
    return errors;
  };


  onClick(messageData: BaseErrorModel) {
    messageData.onClick();
    this.emitter.next();
  }

  constructor() {
    super();
  }
}


class ErrorPopupData {
  jourDate: Date;
  messagesDatas: BaseErrorModel[];
}
