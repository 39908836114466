import { PromptForNavGuard } from "../shared/guards/prompt-for-nav-guard";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "../shared/guards/auth-guard";

import { EpicerieInventaireCreateComponent } from "./create/epicerie-inventaire-create.component";
import { EpicerieInventaireComponent } from "./epicerie-inventaire.component";

const epicerieInventaireRoutes: Routes = [
  {
    path: "epicerieinventaire",
    component: EpicerieInventaireComponent,
    canActivate: [AuthGuard],
    data: { title: "Épicerie & inventaire" }
  },
  {
    path: "epicerieinventaire/create",
    component: EpicerieInventaireCreateComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PromptForNavGuard],
    data: { title: "Épicerie & inventaire - création/édition" }
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(epicerieInventaireRoutes)
  ],
  exports: [
    RouterModule
    ]
})

export class EpicerieInventaireRoutingModule { }
