/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loader/loader.component.ngfactory";
import * as i3 from "../loader/loader.component";
import * as i4 from "./contact-list/contact-list/contact-list.component.ngfactory";
import * as i5 from "./contact-list/contact-list/contact-list.component";
import * as i6 from "@angular/common";
import * as i7 from "./contact.component";
import * as i8 from "../shared/services/application-data.service";
import * as i9 from "../shared/services/api/logo/logo-route.service";
import * as i10 from "./services/contact.service";
var styles_ContactComponent = [i0.styles];
var RenderType_ContactComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactComponent, data: {} });
export { RenderType_ContactComponent as RenderType_ContactComponent };
function View_ContactComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "info-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loader", [["message", "Chargement des contacts..."]], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.LoaderComponent, [], { message: [0, "message"] }, null)], function (_ck, _v) { var currVal_0 = "Chargement des contacts..."; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ContactComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "no-contacts"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contacts"])), (_l()(), i1.ɵted(-1, null, [" Aucun contact n'est d\u00E9fini sur ce contrat "]))], null, null); }
function View_ContactComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Urgence"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-contact-list", [], null, null, null, i4.View_ContactListComponent_0, i4.RenderType_ContactListComponent)), i1.ɵdid(4, 49152, null, 0, i5.ContactListComponent, [], { contacts: [0, "contacts"], restType: [1, "restType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.urgenceContacts; var currVal_1 = _co.restType; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_ContactComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contacts"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-contact-list", [], null, null, null, i4.View_ContactListComponent_0, i4.RenderType_ContactListComponent)), i1.ɵdid(4, 49152, null, 0, i5.ContactListComponent, [], { contacts: [0, "contacts"], restType: [1, "restType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contacts; var currVal_1 = _co.restType; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_ContactComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactComponent_5)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactComponent_6)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.urgenceContacts.length > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.contacts.length > 0); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ContactComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactComponent_3)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactComponent_4)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hasContacts; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.hasContacts; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ContactComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "ribbon"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "page-container contact-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactComponent_2)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoadingContacts; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.isLoadingContacts; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.logoContratUrl) + ")"); _ck(_v, 1, 0, currVal_0); }); }
export function View_ContactComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact", [], null, null, null, View_ContactComponent_0, RenderType_ContactComponent)), i1.ɵdid(1, 245760, null, 0, i7.ContactComponent, [i8.ApplicationDataService, i9.LogoRouteService, i10.ContactService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactComponentNgFactory = i1.ɵccf("app-contact", i7.ContactComponent, View_ContactComponent_Host_0, {}, {}, []);
export { ContactComponentNgFactory as ContactComponentNgFactory };
