import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DiscussionService } from '../discussion/services/discussion.service';
import { ROUTE_URLS } from '../shared/constants/route_urls';
import { ApplicationDataService } from '../shared/services/application-data.service';

@Component({
  selector: 'app-notif-tile',
  templateUrl: './notif-tile.component.html',
  styleUrls: ['./notif-tile.component.scss']
})
export class NotifTileComponent implements OnInit, OnDestroy {
  public notifCount: number = 0;

  private notifLuSub: Subscription;

  @HostListener('click') onClick() {
    this.onNotifClick();
  }

  constructor(
    private appDataService: ApplicationDataService,
    private router: Router,
    private discussionService: DiscussionService) { }

  ngOnInit() {
    this.discussionService.getUnreadCount()
      .subscribe(count => {
        this.notifCount = count.unreadCount;
      },
        err => {
          this.notifCount = 0;
        });

    this.notifLuSub = this.appDataService.notificationLu
      .subscribe(decrease => {
        const newCount = this.notifCount - decrease;
        this.notifCount = Math.max(0, newCount);
      });
  }

  ngOnDestroy() {
    this.notifLuSub.unsubscribe();
  }

  onNotifClick() {
    this.router.navigate([ROUTE_URLS.Discussion]);
  }
}
