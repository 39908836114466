/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rating.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./rating.component";
import * as i4 from "@angular/platform-browser";
var styles_RatingComponent = [i0.styles];
var RenderType_RatingComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_RatingComponent, data: {} });
export { RenderType_RatingComponent as RenderType_RatingComponent };
function View_RatingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["type", "radio"]], [[8, "name", 0], [8, "value", 0], [8, "checked", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "label", [], [[8, "title", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.mouseenter(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["grade"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputName; var currVal_1 = _v.context.$implicit.rating; var currVal_2 = (_co.rating === _v.context.$implicit.rating); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.tooltip; _ck(_v, 2, 0, currVal_3); }); }
export function View_RatingComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "rating"]], null, [[null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseleave" === en)) {
        var pd_0 = (_co.mouseleave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RatingComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "rating-text"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ratings; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.nextRating && _co.nextRating.label) || (_co.ratingModel && _co.ratingModel.label)) || ""); _ck(_v, 3, 0, currVal_1); }); }
export function View_RatingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rating", [], null, null, null, View_RatingComponent_0, RenderType_RatingComponent)), i1.ɵdid(1, 245760, null, 0, i3.RatingComponent, [i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RatingComponentNgFactory = i1.ɵccf("app-rating", i3.RatingComponent, View_RatingComponent_Host_0, { itemId: "itemId", ratings: "ratings", rating: "rating", ratingModel: "ratingModel" }, { ratingClick: "ratingClick" }, []);
export { RatingComponentNgFactory as RatingComponentNgFactory };
