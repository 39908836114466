import { AppSettings } from "../app/shared/models/app-settings.model";
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  debugInfo: false,
  production: true,
  mockOdgService: false,
  FallBackAppSettings: null as AppSettings // Settings de remplacement si le webhost n'est pas joignable (utilisé en local pour le dev "ng serve")
};
