import { GetOdgApiResponse } from '../models/api/responses/get-odg.apiresponse';
import { PostOdgApiRequest } from '../models/api/requests/post-odg.apirequest';

import {
    OdgEvaluationModel,
    OdgEvaluationNote,
    OdgJourModel,
    OdgModel,
    OdgPrestationModel
} from '../../odg/models/odg.model';

export class OdgProjector {

    static projectOdg(odgApi: GetOdgApiResponse.Odg): OdgModel {
        return {
            odgContratId: odgApi.odgContratId,
            odgRestaurantId: odgApi.odgRestaurantId,
            restaurantId: odgApi.restaurantId,
            odgConvives: odgApi.odgConvives,
            odgJours: odgApi.odgJours.map((odgJ, i) => this.projectJours(odgJ, i))
        };
    }

    static projectJours(odgJourApi: GetOdgApiResponse.OdgJour, index: number): OdgJourModel {
        let jourModel = new OdgJourModel(odgJourApi.date);
        jourModel.index = index;
        jourModel.autoriserSaisie = odgJourApi.autoriserSaisie;
        jourModel.observations = odgJourApi.observations;
        jourModel.odgPrestations = odgJourApi.odgPrestations.map(odgP => this.projectPresta(odgP, odgJourApi.autoriserSaisie));
        return jourModel;
    }

    static projectPresta(odgPrestaApi: GetOdgApiResponse.OdgPrestation, autoriserSaisie: boolean): OdgPrestationModel {
        return {
            isCollapsed: false,
            id: odgPrestaApi.id,
            libelle: odgPrestaApi.libelle,
            odgEvaluations: odgPrestaApi.odgEvaluations.map(ev => this.projectEvaluation(ev, autoriserSaisie))
        };
    }

    static projectEvaluation(evalApi: GetOdgApiResponse.OdgEvaluation, autoriserSaisie: boolean): OdgEvaluationModel {
        let model = {
            id: evalApi.id,
            aspect: evalApi.aspect,
            assaisonnement: evalApi.assaisonnement,
            autre: evalApi.autre,
            autreRaison: evalApi.autreRaison,
            consistance: evalApi.consistance,
            note: OdgEvaluationNote[evalApi.note],
            libelle: evalApi.libelle,
            menuDetailId: evalApi.menuDetailId,
            platId: evalApi.platId,
            autoriserSaisie: autoriserSaisie
        };
        return model;
    }

    /** POST */
    static projectPostOdg(odg: OdgModel, odgConviveId: number): PostOdgApiRequest.Odg {
        return {
            odgRestaurantId: odg.odgRestaurantId,
            odgContratId: odg.odgContratId,
            odgConviveId: odgConviveId,
            odgJours: odg.odgJours.map(jour => this.projectPostOdgJour(jour))
        }
    }

    static projectPostOdgJour(odgJour: OdgJourModel): PostOdgApiRequest.OdgJour {
        return {
            date: odgJour.date,
            observations: odgJour.observations,
            autoriserSaisie: odgJour.autoriserSaisie,
            odgPrestations: odgJour.odgPrestations.map(presta => this.projectPostOdgPresta(presta))
        }
    }

    static projectPostOdgPresta(odgPresta: OdgPrestationModel): PostOdgApiRequest.OdgPrestation {
        return {
            id: odgPresta.id,
            libelle: odgPresta.libelle,
            odgEvaluations: odgPresta.odgEvaluations.map(evaluation => this.projectPostOdgEvaluation(evaluation))
        }
    }

    static projectPostOdgEvaluation(odgEvaluation: OdgEvaluationModel): PostOdgApiRequest.OdgEvaluation {
        return {
            id: odgEvaluation.id,
            aspect: odgEvaluation.aspect,
            assaisonnement: odgEvaluation.assaisonnement,
            autre: odgEvaluation.autre,
            autreRaison: odgEvaluation.autreRaison,
            consistance: odgEvaluation.consistance,
            note: OdgEvaluationNote[odgEvaluation.note],
            libelle: odgEvaluation.libelle,
            menuDetailId: odgEvaluation.menuDetailId,
            platId: odgEvaluation.platId
        }
    }
}