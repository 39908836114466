<div *ngIf="isLoading" class="info-container">
    <app-loader message="Chargement...">
    </app-loader>
</div>

<div class="legal-info-container" [innerHTML]="mentionsLegales">
    <!--
	<div class="zone">
		<div>L'application est éditée par la société ELRES – SAS au capital de 1 324 944 euros – Sis Tour Egée, 9-11 allée de l’Arche
			92032 Paris La Défense Cedex et inscrite au RCS NANTERRE n° 662 025 196. ELRES est une filiale du groupe ELIOR - Téléphone
			: 01 71 06 70 00</div>
		<div><span class="underlined">Directeur de la publication:</span> Benoit Drillon, Directeur général délégué d’ELRES</div>
		<div><span class="underlined">Directeur de la rédaction:</span> Laurence Sammuri, Directrice marketing d’ELRES</div>
		<div>L'application est hébergée par le Groupe ELIOR.</div>
		<div>ELRES est responsable du traitement des données mis en place pour permettre à chaque utilisateur de se créer un compte
			restauration pour consulter des menus et permettre la commande de repas.</div>
		<div>Conformément à la loi 78-17 du 6 janvier 1978 modifiée relative à l'informatique, aux fichiers et aux libertés, vous disposez
			d'un droit d'accès, de rectification et d'opposition aux données vous concernant que vous pouvez exercer en écrivant à
			"ELIOR 11 allée de l'arche, 92000 Paris La défense".</div>
		<div><a href="http://www.elior.com">http://www.elior.com</a></div>
	</div>

	<div class="title">Préambule</div>

	<div class="zone">
		<div>La connexion et l’accès au site/application (ci-après le «Site Portail Restaurant» ou le «Site») impliquent l’acception
			intégrale et sans réserve par l’Utilisateur des présentes Conditions Générales d’Utilisation.</div>
		<div>L’ensemble de ces dispositions s’impose aux Utilisateurs qui doivent consulter les présentes Conditions Générales d’Utilisation
			lors de chaque connexion.</div>
		<div>La société éditrice a mise en ligne le Site afin de Vous permettre de créer et de gérer un compte «Portail Restaurant »
			qui Vous permet de consulter les menus de l’établissement de votre enfant, de gérer vos commandes/décommandes de repas,
			d’effectuer le règlement de vos factures de restauration en ligne et d’avoir accès à des services et offres sur internet.</div>
		<div>Il est précisé que la société éditrice du Site peut proposer d’autres services via ce Site pour lesquels des conditions
			d’utilisation spécifiques devront être acceptées avant toute utilisation. Dans cette hypothèse les nouvelles Conditions
			d’Utilisation viendront compléter les présentes.</div>
	</div>

	<div class="title">Hébergeur</div>

	<div class="zone">
		<div>ELIOR DATA, Service PORTAILS, Tour Egée, 9-11 allée de l’Arche 92032 Paris La Défense. Téléphone : 01 71 06 70 00</div>
	</div>

	<div class="title">Définitions</div>

	<div class="zone">
		<div>« Données personnelles » désigne les données concernant les personnes physiques et permettant de les identifier directement
			ou indirectement. Il est précisé qu’aucune donnée personnelle dite «sensible» (à savoir notamment d’origine raciale, opinion
			politique, philosophique ou religieuse, appartenance syndicale, mœurs des personnes) n’est collectée.</div>
		<div>« Informations » désigne l’ensemble du contenu à caractère informationnel ou non disponible sur le Site.</div>
		<div>« Sociétés du Groupe » désigne soit l’ensemble des sociétés du Groupe ELIOR, soit une société du groupe lorsqu'il est inutile
			de l’identifier en particulier. Il est rappelé que les Sociétés du Groupe ont leur propre existence légale et disposent
			d’une personnalité juridique autonome. L’utilisation du terme « Sociétés du Groupe » ne saurait donc constituer la preuve
			d’un groupement solidaire tant entre lesdites sociétés du Groupe ELIOR que vis-à-vis des Utilisateurs du Site ou plus
			largement des tiers.</div>
		<div>« Vous » ou « L’Utilisateur » désigne tout visiteur du Site, client de la société éditrice ou non.</div>
	</div>

	<div class="title">Droits de propriété intellectuelle</div>

	<div class="zone">
		<div class="bold">Droits d'auteur :</div>
		<div>Le Site Portail restaurant constitue une création intellectuelle dont ELRES, Société du groupe ELIOR est l'auteur au sens
			des articles L. 111.1 et suivants du Code de la propriété intellectuelle.</div>
		<div>L’ensemble des éléments disponibles sur le Site dont notamment les photographies, textes, slogans, dessins, images, icônes,
			sons, séquences animées sonores ou non ainsi que toutes créations intellectuelles intégrées dans le Site dont notamment
			les bases de données et logiciels sont la propriété de ELRES et/ou des Sociétés du Groupe ELIOR ou de tiers ayant autorisé
			ELRES et/ou les Sociétés du Groupe ELIOR à les utiliser. Par conséquent, les droits de reproduction et de représentation
			de l’ensemble des éléments présentés sur le site sont strictement réservés et soumis à l’autorisation préalable et expresse
			de ELRES.</div>
		<div>L’accès au Site ne Vous confère aucun droit sur les droits de propriété intellectuelle relatifs au Site qui restent la
			propriété exclusive de la Société éditrice du Site et/ou des Sociétés du Groupe ou leurs partenaires.</div>
		<div>Toute reproduction, représentation, utilisation, adaptation ou modification, par quelque procédé que ce soit et sur quelque
			support que ce soit, de tout ou partie du Site, sans avoir obtenu l'autorisation expresse de ELRES est strictement interdite
			et constitue un délit de contrefaçon.</div>
		<div class="bold">Droits des marques :</div>
		<div>Les dénominations sociales, marques et signes distinctifs reproduits sur le Site sont protégés au titre du droit des marques.</div>
		<div>A ce titre, l'appellation Elior, le logo Elior, sont des marques déposées par les Sociétés du groupe ELIOR.</div>
		<div>D'autres marques sont citées et identifiées par une majuscule. Ces marques appartiennent généralement à ELRES et/ou à des
			sociétés du Groupe Elior ou l’une de ces sociétés en détient les droits d’utilisation afférents, et sont également protégées
			au titre du droit des marques.</div>
		<div>D’autres marques sont également présentées sur ce site, bien que ELRES et/ou les Sociétés du Groupe ELIOR n’en détienne
			pas la propriété, elle bénéficie des droits d’utilisation.</div>
		<div>La reproduction, la représentation, l’utilisation, l’adaptation ou la modification de ces marques sans avoir obtenu l'autorisation
			préalable de ELRES est strictement interdite et constitue un délit de contrefaçon.</div>
	</div>

	<div class="title">Création de lien</div>

	<div class="zone">
		<div>Le site Portail Restaurant autorise la mise en place d’un lien hypertexte pointant vers son contenu, sous réserve de l’accord
			préalable express de l’éditeur du Site.</div>
	</div>

	<div class="title">Protection des données personnelles</div>

	<div class="zone">
		<div>Les données personnelles recueillies dans le cadre des formulaires d’inscription et de contact, services personnalisés
			ou tout autre questionnaire font l’objet d’un traitement automatisé par ELRES, Société du groupe ELIOR et responsable
			du traitement.</div>
		<div>Les données personnelles sont collectées en vue de Vous permettre de créer et de gérer un compte Portail restaurant qui
			vous permet de bénéficier de service en ligne, notamment la consultation des menus de votre établissement, la gestion
			des commandes/décommandes de repas, de répondre à vos demandes, Vous contacter et/ou Vous informer sur l’exécution d’un
			service, sur les services que nous proposons et sur toutes informations utiles, de recueillir des enquêtes de satisfaction
			et de Vous permettre de transmettre des commentaires relatifs à la qualité de prestation.</div>
		<div>Certaines informations demandées dans les formulaires en ligne sont obligatoires. En cas d’absence de saisie ou de saisie
			erronée, les informations ne sont pas envoyées au responsable du traitement et Vous ne pourrez pas bénéficier des services
			du site.</div>
		<div>Les données personnelles que Vous avez transmises sont utilisées exclusivement pour la réalisation des services Portail
			Restaurant.
		</div>
		<div>A ce titre, les destinataires de ces données sont les services du responsable du traitement et ses sous- traitants ainsi
			que tout autre service d’une société membre du Groupe ELIOR intervenant dans la réalisation du traitement.</div>
		<div>Les données personnelles sont stockées par le responsable du traitement et/ou ses sous-traitants et sont conservées de
			manière sécurisée pour la durée nécessaire à la réalisation des finalités pour lesquelles elles sont collectées, en conformité
			avec la réglementation en vigueur.</div>
		<div>Conformément à la loi n°78-17 « informatique et libertés » du 6 janvier 1978 modifiée par la loi n°2004-801 du 6 août 2004,
			Vous bénéficiez d'un droit d'accès, de rectification et d’opposition aux informations qui vous concernant que Vous pouvez
			exercer à tout moment en adressant un email à <a href="mailto:projetportailrestaurant@elior.com">projetportailrestaurant@elior.com</a></div>
		<div>Pour toute information sur la protection des données personnelles, Vous pouvez consulter le site de la Commission Nationale
			de l’Informatique et des Libertés : <a href="http://www.cnil.fr">http://www.cnil.fr</a>.</div>
		<div class="bold">Notification :</div>
		<div>Lorsque Vous utilisez le site/application, et si Vous avez consenti à des notifications push et/ou SMS et/ou Email, le
			responsable du traitement peut Vous envoyez des informations concernant toutes informations utiles concernant votre compte,
			l’exécution d’un service ou sur les actualités et services que ELRES propose.</div>
		<div>Vos données personnelles ne seront pas utilisées pour l’envoi d’éventuels messages de partenaires commerciaux sans votre
			consentement préalable.</div>
		<div>L’envoi de notification peut être désactivé à tout moment en se rendant dans l’onglet « Mon Compte ».</div>
	</div>

	<div class="title">Cookies</div>

	<div class="zone">
		<div>Le cookie est un petit bloc de données envoyé à votre ordinateur par un serveur web et stocké sur le disque dur de votre
			ordinateur. De manière générale, il nous permet d’enregistrer des informations relatives à la navigation de votre ordinateur
			sur notre site (exemple : pages consultées, date et heure de consultation,…).</div>
		<div>Tout internaute peut s’opposer à l’enregistrement de cookies en se rapportant au manuel d’utilisation de son navigateur.
			Tout internaute peut également supprimer les cookies à tout moment et individuellement en se rapportant au manuel d’utilisation
			de son ordinateur.</div>
	</div>

	<div class="title">Allergènes</div>

	<div class="zone">
		<div>Nos informations sur les allergènes concernent exclusivement les 14 allergènes majeurs que nous avons introduits volontairement
			dans nos préparations.</div>
		<div>Nos plats sont préparés dans des ateliers ou cuisines où toutes ou partie de ces substances allergènes sont présentes.
			Aussi, malgré toutes nos précautions, nous ne pouvons pas exclure la contamination croisée accidentelle entre préparations.</div>
		<div>L’affichage des substances allergènes est effectué, à date, sur la base des informations communiquées par nos fournisseurs
			sur leurs propres produits.</div>
		<div>Les informations sur les substances allergènes sur le Site sont données à titre indicatif. Les informations d’étiquetage
			qui font foi sont celles misent à disposition par les moyens utilisées dans votre établissement.</div>
	</div>

	<div class="title">Responsabilité</div>

	<div class="zone">
		<div>Les éléments indiqués sur le Site sont donnés à titre indicatif et ne revêtent aucun caractère contractuel, notamment les
			informations sur les allergènes.</div>
		<div>Vous reconnaissez utiliser les informations contenues dans le Site sous votre propre responsabilité et assumez pleinement
			les risques liés au crédit que vous pourriez leur accorder.</div>
		<div>Ni ELRES, ni aucune autre Société du Groupe ne saurait être tenu responsable :</div>
		<div>
			<ul>
				<li>pour toute imprécision, inexactitude ou omission portant sur des informations indiquées sur le Site,</li>
				<li>pour tous éventuels dommages, directs et/ou indirects, quelles qu’en soient les causes, origines, natures ou conséquences,
					provoqués en raison de l’accès au Site ou de l’impossibilité d’y accéder,</li>
				<li>pour tous éventuels dommages, directs et/ou indirects, subis sur le matériel, la perte de données, et/ou le préjudice
					financier qui pourrait résulter de la connexion ou de l’utilisation des informations recueillies sur le Site.</li>
			</ul>
		</div>
		<div>La Société éditrice du Site décline formellement toute responsabilité quant aux contenus des sites vers lesquels elle offre
			des liens. Ces liens Vous sont proposés en tant que service. La décision d’activer les liens Vous appartient exclusivement.</div>
		<div>ELRES se réserve le droit de modifier, de corriger ou de supprimer le contenu du Site et/ou les présentes Conditions Générales
			d’Utilisation à tout moment, sans préavis, étant précisé que la modification et l’actualisation des Conditions Générales
			d’Utilisation s’imposent dès leur mise en ligne.</div>
		<div class="space">Il appartient à l’Utilisateur de prendre toutes les mesures nécessaires pour protéger son matériel, ses données et logiciels
			informatiques.</div>
	</div>

	<div class="title">Respect des règlementations et de la déontologie</div>

	<div class="zone">
		<div>Les présentes Conditions Générales d’Utilisation sont soumises au droit français.</div>
		<div>Les internautes se connectant au Site à partir d’autres pays que la France, doivent s’assurer du respect des lois applicable
			à ces pays.</div>
		<div>Tout litige relatif à l’interprétation, l’application et/ou à l’exécution des présentes sera soumis à la juridiction compétente
			des Tribunaux de Paris</div>
	</div>
	-->
</div>