import { AllowedFeatureEnum } from '../enums/allowed-feature.enum';
import { RythmeContratEnum } from '../enums/rythme-contrat.enum';
import { RestaurantTypeEnum } from '../enums/restaurant-type.enum';
import { RestaurantModel } from '../models/restaurant.model';
import { ContratModel } from '../models/contrat.model';
import { UserInfoModel, IUserInfo } from '../models/user-info.model';

import { GetMeApiResponse } from '../models/api/authentication';
import { CuisineCentraleModel } from '../models/cuisine-centrale.model';
import { GrandCompteModel } from '../models/grand-compte.model';

export class UserInfoProjector {

  static projectUserInfo(userApi: GetMeApiResponse.UserInfo): UserInfoModel {
    let fullName = '';
    if (userApi.firstName) {
      fullName += `${userApi.firstName} `;
    }

    fullName += (userApi.lastName || '').toLocaleUpperCase();

    const cuisines = this.projectCuisines(userApi.contrats);
    const grandComptes = this.projectGrandComptes(cuisines);

    return new UserInfoModel({
      fullName,
      id: userApi.userId,
      allowedFeatures: this.projectAllowedFeatures(userApi.allowedFeatures),
      cuisines,
      grandComptes
    });
  }

  private static projectAllowedFeatures(apiFeatures: string[]): AllowedFeatureEnum[] {
    return apiFeatures.map(f => {
      return AllowedFeatureEnum[f];
    });
  }

  private static projectContracts(contractsApi: GetMeApiResponse.Contrat[]): ContratModel[] {
    const contrats: ContratModel[] = [];
    contractsApi.forEach(c => {
      const restaurants = this.projectRestaurants(c.restaurants, c.id, c.cuisineCentraleId, c.cuisineCentraleCode);
      restaurants.sort((a, b) => a.name.localeCompare(b.name));

      if (restaurants.length !== 0) {
        contrats.push({
          id: c.id,
          restaurants,
          libelle: c.libelle,
          cuisineCentraleId: c.cuisineCentraleId,
          cuisineCentraleCode: c.cuisineCentraleCode,
          cuisineCentrale: c.cuisineCentrale,
          grandCompteId: c.grandCompteId,
          grandCompteLibelle: c.grandCompteLibelle,
          rythme: RythmeContratEnum[c.rythme]
        });
      }
    });

    return contrats;
  }

  private static projectCuisines(contratsApi: GetMeApiResponse.Contrat[]): CuisineCentraleModel[] {
    // distinct des cuisines sur les contrats puis générations des contrats de chaque cuisine
    const cuisinesById: { [id: number]: CuisineCentraleModel; } = {};
    contratsApi.forEach(c => {
      let cuisine = cuisinesById[c.cuisineCentraleId];
      if (!cuisine) {
        cuisine = { id: c.cuisineCentraleId, code: c.cuisineCentraleCode, libelle: c.cuisineCentrale, contrats: [] };
        cuisinesById[c.cuisineCentraleId] = cuisine;
      }
      cuisine.contrats.push(...this.projectContracts([c]));
    });

    const cuisines = Object.keys(cuisinesById).map<CuisineCentraleModel>(key => cuisinesById[key]);
    cuisines.sort((a, b) => a.libelle.localeCompare(b.libelle));

    return cuisines;
  }

  private static projectGrandComptes(cuisines: CuisineCentraleModel[]): GrandCompteModel[] {
    // distinct des grandComptes sur les contrats
    const grandComptesById: { [id: number]: GrandCompteModel; } = {};
    cuisines.forEach(cuisine => {
      cuisine.contrats.forEach(c => {
        if (!c.grandCompteId) {
          return;
        }
        let grandCompte = grandComptesById[c.grandCompteId];
        if (!grandCompte) {
          grandCompte = { id: c.grandCompteId, libelle: c.grandCompteLibelle/*, contrats: []*/ };
          grandComptesById[grandCompte.id] = grandCompte;
        }
        // grandCompte.contrats.push(c);
      });
    });

    const grandComptes = Object.keys(grandComptesById).map<GrandCompteModel>(key => grandComptesById[key]);
    grandComptes.sort((a, b) => a.libelle.localeCompare(b.libelle));

    return grandComptes;
  }

  private static projectRestaurants(restauApi: GetMeApiResponse.Restaurant[], contratId: number, cuisineCentraleId: number, cuisineCentraleCode: string): RestaurantModel[] {
    return restauApi.map(r => {
      return {
        id: r.id,
        name: r.libelle,
        code: r.code,
        type: this.projectRestaurantType(r.type),
        contratId,
        cuisineCentraleId,
        cuisineCentraleCode
      };
    });
  }

  private static projectRestaurantType(type: string): RestaurantTypeEnum {
    switch (type) {
      case 'Maternelles':
        return RestaurantTypeEnum.Maternelle;
      case 'Creches':
        return RestaurantTypeEnum.Creche;
      case 'Adultes':
        return RestaurantTypeEnum.Adulte;
      case 'Elementaires':
        return RestaurantTypeEnum.Elementaire;
      default:
        return RestaurantTypeEnum.Elementaire;
    }
  }
}
