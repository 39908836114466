<!--<svg [restaurantColor]="{'type': restaurantType, 'fill':true}" #logo viewBox="0 0 1110 420">
  <path id="logo_k1" d="m20.69411,171.48212l0,191.68503l36.79797,0l0,-90.10867l27.87305,0l57.795,90.10867l43.10999,0l-71.17401,-106.3912l0,-0.92899l63.92804,-84.36484l-41.99901,0l-52.40199,70.49371l-27.13105,0l0,-70.49371l-36.79797,0l-0.00001,0z" 
  />
  <path id="logo_o" d="m276.86667,334.86206c37.49799,0 67.53601,-30.03799 67.53601,-67.53601c0,-37.49799 -30.03802,-67.53799 -67.53601,-67.53799c-37.49799,0 -67.536,30.04001 -67.536,67.53799c0,37.49802 30.03801,67.53601 67.536,67.53601m0,33.58398c-55.64801,0 -101.11998,-45.47198 -101.11998,-101.12c0,-55.64899 45.47197,-101.12302 101.11998,-101.12302c55.64801,0 101.12097,45.474 101.12097,101.12302c0,55.64801 -45.47296,101.12 -101.12097,101.12" 
  />
  <path id="logo_O" d="m520.68207,363.56723c53.354,0 96.24097,-42.89099 96.24097,-96.24402c0,-53.35297 -42.88696,-96.24298 -96.24097,-96.24298c-53.35394,0 -96.24701,42.89001 -96.24701,96.24298c0,53.35303 42.89307,96.24402 96.24701,96.24402m0,33.58401c-71.50397,0 -129.83099,-58.32401 -129.83099,-129.82803c0,-71.50397 58.32703,-129.82788 129.83099,-129.82788c71.50403,0 129.82495,58.32391 129.82495,129.82788c0,71.50403 -58.32092,129.82803 -129.82495,129.82803" 
  />
  <path id="logo_k2" d="m667.03143,171.4816l0,191.68503l36.79797,0l0,-90.10867l27.87305,0l57.79504,90.10867l43.10999,0l-71.17407,-106.3912l0,-0.92899l63.9281,-84.36484l-41.99902,0l-52.40198,70.49371l-27.1311,0l0,-70.49371l-36.79797,0l0,0z" 
  />
  
  <path id="logo_z" d="m1084.99963,363.58411l0,-31.59003l-112.62421,0l116.9245,-139.56097l0,-21.36902l-174.83124,0l0,31.591l120.25446,-0.00299l-116.54279,139.75l0,21.18201l166.81927,0z" 
   fill="#666666"/>
  <path id="logo_i" d="m853.6828,171.06381l36.797,0l0,192.52396l-36.797,0" 
   fill="#666666"/>
  <path id="logo_idot" d="m853.26233,100.19315l36.797,0l0,40.21588l-36.797,0" 
   fill="#666666"/>
  <path id="logo_wifi" d="m556.24554,67.89139c-7.18304,0.03979 -13.14404,5.53088 -13.72504,12.64801c-0.58099,7.11609 4.41003,13.4928 11.49304,14.6825c29.52197,5.37184 56.82495,19.1618 78.58997,39.70021c5.51703,5.41557 14.43298,5.27342 19.77203,-0.31604c5.33899,-5.5885 5.01996,-14.44592 -0.70801,-19.64093c-25.66199,-24.21564 -57.86499,-40.48138 -92.672,-46.81433c-0.90601,-0.17786 -1.82703,-0.2644 -2.75,-0.2594m2.42401,-47.99609l0,0.005c-7.20502,0.20081 -13.05103,5.86188 -13.43805,13.01671c-0.388,7.15289 4.81305,13.40637 11.95599,14.37538c45.24902,6.96405 86.78705,28.94458 117.85504,62.37445c5.17798,5.63623 13.98102,6.00792 19.62201,0.82886c5.64099,-5.17908 5.96503,-13.93115 0.72101,-19.50671c-35.30902,-37.99475 -82.52905,-62.98267 -133.95703,-70.89783c-0.90997,-0.15704 -1.83502,-0.22266 -2.75897,-0.19586" 
   fill="#666666"/>
</svg>-->
<img #logo src="/assets/images/app_logo.png"/>