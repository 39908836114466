import { DateHelper } from '../helpers/date.helper';

export class BaseJourModel {
    index: number;
    date: Date;
    day: string;
    month: string;
    monthShort :string;
    dayString: string;
    dayShortString: string;
    isEmpty: boolean;
    isHorsRythme: boolean;
    dayOfWeek: number;
    hidden: boolean = false;

    setIfEmpty() {
        this.isEmpty = false;
    }

    constructor(date: Date) {
        this.date = new Date(date);
        this.dayOfWeek = this.date.getDay();
        this.month = DateHelper.getMonthName(this.date);
        this.monthShort = DateHelper.getMonthNameShort(this.date);
        this.dayString = DateHelper.getDayName(this.date);
        this.dayShortString = DateHelper.getDayNameShort(this.date);
        this.day = ('0' + this.date.getDate()).slice(-2);
    }
}
