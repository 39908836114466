
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { PrestationValidator } from '../validators/prestation.validator';

export class FormControlHelper {

  static famillePlatValidators: { [prestaFamillePlat: string]: FamillePlatValidators } = {};

  static addValue(fc: FormControl, val: number, max: number = null): void {
    let value = <number>fc.value + val;
    if (max == null) {
      fc.setValue(value);
    }
    else {
      fc.setValue(Math.min(value, max));
    }
  }

  /** Substract the maximum possible then returns the rest */
  static substractValue(fc: FormControl, toSubstract: number): number {
    let currVal = <number>fc.value;
    var newVal = currVal - toSubstract;
    if (newVal >= 0) {
      toSubstract = 0;
    }
    else {
      toSubstract = -newVal;
      newVal = 0;
    }

    fc.setValue(newVal);
    return toSubstract;
  }

  static addValidator(fc: AbstractControl, validators: ValidatorFn[]) {
    let oldValidators = fc.validator;
    if (oldValidators) {
      validators.push(oldValidators);
    }

    fc.setValidators(validators);
  }

  static addServerValidator(fc: FormControl) {
    fc.updateValueAndValidity(); // force value change to trigger potential serverRule validator removal (see next lines)
    const originalValidators = fc.validator; // save original validators
    fc.setValidators([originalValidators, PrestationValidator.serverRule()]); // add "dummy" serverRule error
    fc.updateValueAndValidity(); // will cause invalid state

    const subscription = fc.valueChanges.pipe(first()).subscribe(res => {
      // subscription.unsubscribe(); // useless (use of Observable.first)
      fc.setValidators(originalValidators); // restore original validators
      fc.updateValueAndValidity(); // re-evaluate state
    });
  }

  static addFamillePlatServerValidator(key: string, controls: FormControl[], famillePlatControl: FormControl = null) {
    let famillePlatData = new FamillePlatValidators();

    famillePlatData.setFamillePlat(famillePlatControl);

    // first loop to get the validators without the server validators
    famillePlatData.oldValidators = controls.map(c => {
      return {
        fc: c,
        validator: c.validator
      };
    });


    // second loop to add server validator AND the old validators for each control
    famillePlatData.oldValidators.forEach(item => {
      item.fc.setValidators([item.validator, PrestationValidator.serverRule()]);
      item.fc.updateValueAndValidity();
      // on value change we unsubscribe everything and remove server validators
      famillePlatData.subs.push(item.fc.valueChanges.pipe(first()).subscribe(fc => {
        famillePlatData.oldValidators.map(itemToClean => {

          famillePlatData.unsubscribeAll();

          itemToClean.fc.setValidators(itemToClean.validator);
          itemToClean.fc.updateValueAndValidity();

          famillePlatData.cleanFamillePlat();
        });
      }));
    });

    this.famillePlatValidators[key] = famillePlatData;
  }

  /** Used when another validation is made without modification */
  static cleanAllServerValidators() {
    for (let key in this.famillePlatValidators) {
      this.famillePlatValidators[key].destroy();
    }

    this.famillePlatValidators = {};
  }
}

export class FormControlData {
  fc: FormControl;
  validator: ValidatorFn;
}

export class FamillePlatValidators {
  oldValidators: FormControlData[];
  subs: Subscription[];
  famillePlatValidator: FormControlData;

  constructor() {
    this.subs = [];
    this.oldValidators = [];
  }

  public setFamillePlat(fpControl: FormControl) {
    if (fpControl) {
      let famillePlatOldValidator = fpControl.validator;
      fpControl.setValidators([famillePlatOldValidator, PrestationValidator.serverRule()]);

      this.famillePlatValidator = { fc: fpControl, validator: famillePlatOldValidator }
    }
  }

  public cleanFamillePlat() {
    if (this.famillePlatValidator) {
      this.famillePlatValidator.fc.setValidators(this.famillePlatValidator.validator);
      this.famillePlatValidator.fc.updateValueAndValidity();
    }
  }

  public destroy() {
    this.cleanFamillePlat();
    this.unsubscribeAll();
    this.cleanAllValidators();
  }

  public unsubscribeAll() {
    this.subs.forEach(s => {
      s.unsubscribe();
    });
  }

  private cleanAllValidators() {
    this.oldValidators.forEach(itemToClean => {
      itemToClean.fc.setValidators(itemToClean.validator);
      itemToClean.fc.updateValueAndValidity();
    });
  }
}
