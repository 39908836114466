<div class="alerte-content">
  <div class="alerte-title" *ngIf="showTitle">
    <h2>{{ alerte.titre }}</h2>
  </div>
  <div class="alerte-message" [innerHtml]="safeMessage"></div>
  <div class="alerte-actions">
    <button class="generic-button btn-alerte" [restaurantColor]="!receivedAlerte.isRead && {'type': restaurantType$ | async, 'background':true}" (click)="dismissAlerte()" [class.btn-alerte-lue]="receivedAlerte.isRead">
      <i class="material-icons" *ngIf="receivedAlerte.isRead" >check_box</i><span>{{ receivedAlerte.isRead ? 'Lu' : 'Ok' }}</span>
    </button>
  </div>
</div>
