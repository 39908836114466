import { Directive, AfterViewInit, ElementRef, Input, Optional, Inject, Renderer2 } from '@angular/core';
import { SwiperConfigInterface, SwiperConfig, SWIPER_CONFIG } from 'ngx-swiper-wrapper';


// Inspiré de https://github.com/zefoy/ngx-swiper-wrapper/blob/master/src/lib/swiper.directive.ts

/**
 *
 *  Utilisé pour ajouter des class custom sur les élements du composant swiper
 * @export
 */
@Directive({
  selector: 'swiper'
})
export class SwiperRestaurantThemeDirective implements AfterViewInit {

  @Input() config?: SwiperConfigInterface;

  constructor(private _elementRef: ElementRef,
              private _renderer: Renderer2,
              @Optional() @Inject(SWIPER_CONFIG
    ) private defaults: SwiperConfigInterface) { }

  ngAfterViewInit(): void {
    const params = new SwiperConfig(this.defaults);

    params.assign(this.config); // Custom configuration

    if (params.navigation === true) {
      params.navigation = {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next'
      };
    }

    const el = this._elementRef.nativeElement as HTMLElement;
    const prevBtn = el.querySelector(params.navigation.prevEl);
    this._renderer.addClass(prevBtn, 'restaurant-type-color');
    this._renderer.addClass(prevBtn, 'restaurant-type-background-hover');
    const nextBtn = el.querySelector(params.navigation.nextEl);
    this._renderer.addClass(nextBtn, 'restaurant-type-color');
    this._renderer.addClass(nextBtn, 'restaurant-type-background-hover');
  }

}
