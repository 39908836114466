import { Component, OnInit, Sanitizer, SecurityContext } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { PopupCustomComponentBase } from '../popup-custom/popup-custom-content.component';
import { ErrorApiModel } from '../shared/models/api/error/error.apimodel';
import { NotificationModel } from '../shared/models/notification.model';
import { InteractionService } from '../shared/services/interaction.service';
import { ParametreService } from '../shared/services/parametre.service';

@Component({
  selector: 'legal-info',
  templateUrl: './legal-info.component.html',
  styleUrls: ['./legal-info.component.scss']
})
export class LegalInfoComponent extends PopupCustomComponentBase implements OnInit {

  isLoading: boolean = false;
  mentionsLegales: string;

  constructor(private parametreService: ParametreService,
    private sanitizer: Sanitizer,
    private interactionService: InteractionService, ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.parametreService.getParametre("MENTIONSLG")
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(value => {
        this.mentionsLegales = this.sanitizer.sanitize(SecurityContext.HTML, value);
      },
        (error: ErrorApiModel) => {
          const notifModel = new NotificationModel();
          notifModel.title = "Erreur"
          notifModel.message = error.Message;

          this.interactionService.showNotification(notifModel);
        })
  }

}
