<div class="article-content" [style.background-image]="'url(' + articlePicture + ')'">
  <div class="header">
    <div class="left">
      {{article.libelle}}
    </div>
    <div class="right">
      <app-pretty-price [price]="article.unitPrice" *ngIf="showPrice">
      </app-pretty-price>
      <div>{{article.unite}}</div>
    </div>
  </div>
  <!--<div>
            <span class="price">
                {{article.unitPrice | currencyEuro}}
            </span>
            <span class="article-title">
                {{article.libelle}}
            </span>
        </div>
        <div class="unite">
            {{article.unite}}
        </div>-->
  <div *ngIf="!consult" class="footer">
    <button (click)="decrementCount()" class="generic-button btn-quantity btn-minus">
      <span class="minus"></span>
    </button>
    <!--<div class="amount" [restaurantColor]="{'type': type, 'color':true}">{{quantity}}</div>-->
    <input type="number" class="amount" [(ngModel)]="quantity" [restaurantColor]="{'type': type, 'color':true}" selectAllOnFocus [inputNumberRange]="{'min':QUANTITY_MIN,'max':QUANTITY_MAX}" />
    <button (click)="incrementCount()" class="generic-button btn-quantity btn-plus">
      <span class="plus"></span>
    </button>
    <button [class.activated]="activated" (click)="addToCart()" [restaurantColor]="{'type': type, 'background':true}" class="generic-button toggle">
      <div class="add">
        <span class="plus"></span>
        <span>AJOUTER</span>
      </div>
      <div class="added">Ajouté(e)</div>
    </button>
  </div>
</div>
