import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { RequalifierDiscussionModel } from './requalifier-discussion.model';
import { PopupCustomComponentGeneric } from 'src/app/popup-custom/popup-custom-content.component';
import { TypeInterventionModel, TypeMaintenanceModel } from '../models/type-intervention.model';
import { flatMap } from 'lodash';
import { NgForm, FormControl, FormGroup } from '@angular/forms';
import { DiscussionService } from '../services/discussion.service';
import { InteractionService } from 'src/app/shared/services/interaction.service';
import { ErrorApiModel } from 'src/app/shared/models/api/error/error.apimodel';
import { throttleTime, first, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-requalifier-discussion',
  templateUrl: './requalifier-discussion.component.html',
  styleUrls: ['./requalifier-discussion.component.scss']
})
export class RequalifierDiscussionComponent extends PopupCustomComponentGeneric<RequalifierDiscussionModel> implements OnInit, OnDestroy {

  public isSending: boolean;

  //#region data property override
  private _data: any;
  public get data(): any {
    return this._data;
  }
  public set data(data: any) {
    if (data !== this._data) {
      this._data = data;
      this.loadModel();
    }
  }
  //#endregion

  @ViewChild('form', { static: true })
  public form: NgForm; // FormGroup

  /** Form values */
  private _selectedTypeIntervention: TypeInterventionModel;
  public get selectedTypeIntervention(): TypeInterventionModel {
    return this._selectedTypeIntervention;
  }
  public set selectedTypeIntervention(value: TypeInterventionModel) {
    this._selectedTypeIntervention = value;
    this.selectedTypeMaintenance = null;
  }

  private _selectedTypeMaintenance: TypeMaintenanceModel;
  public get selectedTypeMaintenance(): TypeMaintenanceModel {
    return this._selectedTypeMaintenance;
  }
  public set selectedTypeMaintenance(v: TypeMaintenanceModel) {
    this._selectedTypeMaintenance = v;
  }

  public get hasTypeInterventions(): boolean {
    return this.model.typeInterventionGroups && this.model.typeInterventionGroups.length > 0;
  }

  public get hasTypeMaintenances(): boolean {
    return this.selectedTypeIntervention
      && this.selectedTypeIntervention.typeMaintenances
      && this.selectedTypeIntervention.typeMaintenances.length > 0;
  }

  public get hasChanged(): boolean {
    return this.model.discussion
      // HACK : quick null-safe check
      && (`${this.model.discussion.typeInterventionId}-${this.model.discussion.typeMaintenanceId}` !== `${this.selectedTypeIntervention && this.selectedTypeIntervention.id}-${this.selectedTypeMaintenance && this.selectedTypeMaintenance.id}`)
      ;
  }

  constructor(private discussionService: DiscussionService, private interactionService: InteractionService) {
    super();
  }

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  private loadModel() {
    const typeInterventions = flatMap(this.model.typeInterventionGroups, tg => tg.typeInterventions);
    const typeIntervention = typeInterventions
      .find(ti => ti.id === this.model.discussion.typeInterventionId);
    this._selectedTypeIntervention = typeIntervention;

    const typeMaintenances = flatMap(flatMap(typeInterventions, ti => ti.typeMaintenances), tmg => tmg.typeMaintenances);
    const typeMaintenance = typeMaintenances
      .find(tm => tm.id === this.model.discussion.typeMaintenanceId);

    this._selectedTypeMaintenance = typeMaintenance;
  }

  public requalifierDiscussion() {
    if (!this.validateForm() || this.isSending) {
      return;
    }
    this.isSending = true;

    this.discussionService
      .requalifierDiscussion(
        this.model.discussion.id,
        this.selectedTypeIntervention.id,
        this.selectedTypeMaintenance && this.selectedTypeMaintenance.id
      )
      .pipe(
        throttleTime(2000),
        first(),
        finalize(() => {
          this.isSending = false;
        })
      )
      .subscribe((success) => {
        this.model.onRequalificationSucceed();
        this.close();
      },
        (err: ErrorApiModel) => {
          this.interactionService.showSimpleMessage('Erreur', `Une erreur est survenue lors de la création d\'une nouvelle demande : ${err.Message}`);
        });
  }

  public close() {
    this.emitter.next();
  }

  private validateForm(): boolean {
    this.validateAllFormFields(this.form.form);
    return this.form.valid;
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
