<div class="warning-popup-container">
    <div class="global-message">Validation réussie pour les effectifs.</div>
    <div *ngIf="data">
        <div class="jour" *ngFor="let jour of warningPopupData">
            <span class="jour-date">{{jour.jourDate | errorPrettyDate}}</span>
            <ul class="warning-messages">
                <li class="warning-message" *ngFor="let messageData of jour.messagesDatas">
                    <span>{{messageData.message}}</span>
                </li>
            </ul>
            <hr/>
        </div>
    </div>
</div>