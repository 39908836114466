<ng-container *ngIf="currentNotification">
  <div class="popup" [ngClass]="{'visible': showPopup}">
    <div class="popup-content">
      <div class="popup-header" [restaurantColor]="{'type': currentType, 'background':true}">
        <span class="pop-close close-cross" (click)="cancel()"></span>
        <h3>{{currentNotification.title}}</h3>
      </div>
      <div class="popup-body">
        <div class="popup-message" [innerHTML]="currentNotification.message"></div>
        <div class="popup-actions">
          <button type="button" class="pop-close close-btn" [restaurantColor]="{'type': currentType, 'background':true}" (click)="ok()" *ngIf="currentNotification.showOk">OK</button>
          <button type="button" class="pop-close close-btn" [restaurantColor]="{'type': currentType, 'background':true}" (click)="cancel()" *ngIf="currentNotification.showCancel">Annuler</button>
        </div>
      </div>
    </div>
  </div>
  <div class="popup-bckg" [class.visible]="showPopup" (click)="currentNotification.closeOnBackdropClick && cancel()"></div>
</ng-container>
