<fieldset class="reporting" *ngIf="isAllowed">
  <legend><i class="material-icons restaurant-type-color">sms_failed</i> Demandes / Réclamations</legend>
  <div class="content">
    <ng-container *ngIf="!isLoading">
      <div *ngIf="isDowloading" class="loading-container overlay">
        <app-loader message="Téléchargement du rapport"></app-loader>
      </div>
      <ng-container *ngIf="!isDowloading">
        <div class="input-group dates-container">
          <h3>Période</h3>
          <app-date-input id="suiviqualite-start-date" name="suiviqualite-start-date" [(ngModel)]="dateDebut" [calendarInfo]="dateCalendarData" placeholder="Période début"></app-date-input>
          <app-date-input id="suiviqualite-end-date" name="suiviqualite-end-date" [(ngModel)]="dateFin" [calendarInfo]="dateCalendarData" placeholder="Période fin"></app-date-input>
        </div>
        <div class="input-group" *ngIf="grandComptes.length > 0 && allowGrandCompte">
          <h3 class="input-group-label">Grand Compte</h3>
          <select class="input-group-input selector" [(ngModel)]="selectedGrandCompte" name="select-grandcompte">
            <option [ngValue]="null">Tous</option>
            <option *ngFor="let grandCompte of grandComptes" [ngValue]="grandCompte">{{grandCompte.libelle}}</option>
          </select>
        </div>
        <div class="input-group" *ngIf="cuisines.length > 1">
          <h3 class="input-group-label">Cuisine</h3>
          <select class="input-group-input selector" [(ngModel)]="selectedCuisine" name="select-cuisine">
            <option *ngIf="cuisines.length > 1" [ngValue]="null">Toutes</option>
            <option *ngFor="let cuisine of cuisines" [ngValue]="cuisine">{{cuisine.libelle}}</option>
          </select>
        </div>
        <div class="input-group" *ngIf="cuisines.length > 1 || contrats.length > 1">
          <h3 class="input-group-label">Contrat</h3>
          <select class="input-group-input selector" [(ngModel)]="selectedContrat" name="select-contrat">
            <option *ngIf="contrats.length > 1 || (cuisines.length > 1 && selectedCuisine == null) " [ngValue]="null">Tous</option>
            <option *ngFor="let contrat of contrats" [ngValue]="contrat">{{contrat.libelle}}</option>
          </select>
        </div>
      </ng-container>

      <div class="input-group bottom">
        <button class="generic-button gray-tile-button" (click)="export()" [disabled]="isDowloading">{{ isDowloading ? "Téléchargement..." : "Extraire" }}</button>
      </div>
    </ng-container>

    <div *ngIf="isLoading" class="loading-container">
      <app-loader message="Préparation du formulaire"></app-loader>
    </div>
  </div>

</fieldset>
