/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hamburger.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./hamburger.component";
var styles_HamburgerComponent = [i0.styles];
var RenderType_HamburgerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HamburgerComponent, data: {} });
export { RenderType_HamburgerComponent as RenderType_HamburgerComponent };
export function View_HamburgerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["id", "menuToggle"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "right": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [], [[4, "background-color", null]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [], [[4, "background-color", null]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [], [[4, "background-color", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.isRight); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.color; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.color; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.color; _ck(_v, 6, 0, currVal_3); }); }
export function View_HamburgerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hamburger", [], null, null, null, View_HamburgerComponent_0, RenderType_HamburgerComponent)), i1.ɵdid(1, 114688, null, 0, i3.HamburgerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HamburgerComponentNgFactory = i1.ɵccf("app-hamburger", i3.HamburgerComponent, View_HamburgerComponent_Host_0, { isRight: "isRight", color: "color" }, {}, []);
export { HamburgerComponentNgFactory as HamburgerComponentNgFactory };
