export class ArticleApiModel {
    id: string;
    libelle: string;
    unite: string;
    prix: number;
    qteLimite: number | null;
}

export class ArticleDetailApiModel extends ArticleApiModel {
    quantite: number;
}
