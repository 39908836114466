<div class="page-container">
  <h1>Sandbox</h1>

  <div class="widget">
    <div class="widget-title">Clock</div>
    <div class="widget-content">
      <div class="widget-row"><label>Current clock : </label><span class="value">{{ currentClock }}</span></div>
      <div class="widget-row">
        <label>Set clock : </label>
        <input id="clockDateTimePicker" type="datetime-local" [(ngModel)]="clockDate" placeholder="Définir Date" />
        <button (click)="setClock()" class="red-button">Forcer la date</button>
        <button (click)="resetClock()" class="green-button">Restaurer la date</button>
      </div>
    </div>
  </div>


  <div class="widget">
    <div class="widget-title">Divers</div>
    <div class="widget-content">
      <button (click)="onButtonClick()">Test mention légales</button>
      <button (click)="throwException()">Test exception logging</button>
      <button (click)="logInfo()">Test info logging</button>
    </div>
  </div>

</div>
