/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-custom.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/directives/restaurant-type-color.directive";
import * as i3 from "@angular/common";
import * as i4 from "./popup-custom-content.component.ngfactory";
import * as i5 from "./popup-custom-content.component";
import * as i6 from "./popup-custom.component";
import * as i7 from "../shared/services/application-data.service";
var styles_PopupCustomComponent = [i0.styles];
var RenderType_PopupCustomComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupCustomComponent, data: {} });
export { RenderType_PopupCustomComponent as RenderType_PopupCustomComponent };
function View_PopupCustomComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "popup-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "pop-close close-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpod(3, { "type": 0, "background": 1 }), (_l()(), i1.ɵted(-1, null, ["Fermer"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.currentType, true); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PopupCustomComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "popup"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "visible": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 11, "div", [["class", "popup-content"]], [[2, "full-screen", null]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "popup-header"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpod(8, { "type": 0, "background": 1 }), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "pop-close close-cross"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "popup-body"]], [[2, "over-hide", null]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "popup-custom-content", [], null, [[null, "onHideEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onHideEmitter" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_PopupCustomContentComponent_0, i4.RenderType_PopupCustomContentComponent)), i1.ɵdid(14, 1228800, null, 0, i5.PopupCustomContentComponent, [i1.ComponentFactoryResolver], { innerComponentType: [0, "innerComponentType"], innerComponentData: [1, "innerComponentData"] }, { onHideEmitter: "onHideEmitter" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupCustomComponent_2)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 3, "div", [["class", "popup-bckg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.currentNotification.closeOnBackdropClick && _co.hide()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(19, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(20, { "visible": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "popup"; var currVal_1 = _ck(_v, 4, 0, _co.showPopup); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _ck(_v, 8, 0, _co.currentType, true); _ck(_v, 7, 0, currVal_3); var currVal_6 = _co.currentNotification.type; var currVal_7 = _co.currentNotification.data; _ck(_v, 14, 0, currVal_6, currVal_7); var currVal_8 = _co.withButton; _ck(_v, 16, 0, currVal_8); var currVal_9 = "popup-bckg"; var currVal_10 = _ck(_v, 20, 0, _co.showPopup); _ck(_v, 19, 0, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.currentNotification.fullScreen; _ck(_v, 5, 0, currVal_2); var currVal_4 = _co.currentNotification.title; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.currentNotification.hasNoOverflow; _ck(_v, 12, 0, currVal_5); }); }
export function View_PopupCustomComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupCustomComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentNotification; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PopupCustomComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-custom", [], [[2, "visible", null]], null, null, View_PopupCustomComponent_0, RenderType_PopupCustomComponent)), i1.ɵdid(1, 245760, null, 0, i6.PopupCustomComponent, [i7.ApplicationDataService, i1.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).showPopup; _ck(_v, 0, 0, currVal_0); }); }
var PopupCustomComponentNgFactory = i1.ɵccf("app-popup-custom", i6.PopupCustomComponent, View_PopupCustomComponent_Host_0, {}, {}, []);
export { PopupCustomComponentNgFactory as PopupCustomComponentNgFactory };
