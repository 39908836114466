import { HttpErrorResponse } from '@angular/common/http';

export class ErrorApiModel {
  Code: number;
  Message: string;
  Content?: any;

  static FromJsonObject(json: any): ErrorApiModel {
    // let object = Object.create(ErrorApiModel.prototype);
    // Object.assign(object, json);
    const error = new ErrorApiModel();
    if (json) {
      if (typeof (json.Code) !== 'undefined') {
        error.Code = Number(json.Code);
      }
      if (typeof (json.Message) !== 'undefined') {
        error.Message = json.Message;
      }
      if (typeof (json.Content) !== 'undefined') {
        error.Content = json.Content;
      }
    }
    return error;
  }

  static FromMessage(message: string): ErrorApiModel {
    const error = new ErrorApiModel();
    error.Message = message;
    return error;
  }

  static FromHttpErrorResponse(errorResponse: HttpErrorResponse) {
    const error = errorResponse.error;
    if (error && (error.Code || error.Content || error.Message)) {
      return {
        Code: error.Code,
        Message: error.Message,
        Content: error.Content
      };
    }
    return null;
  }

}

export function CheckIsErrorApiModel(obj: any): obj is ErrorApiModel {
  const error = obj as ErrorApiModel;
  return error && (error.Code || error.Content || error.Message);
}

