import { DailyMenuPrestationModel } from '../shared/models/daily-menu/daily-menu-prestation.model';
import { LogService } from '../shared/services/log.service';
import { Component, Input, AfterViewChecked, AfterViewInit, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';

import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';

// let Swiper = require('swiper');

@Component({
  selector: 'app-meal-slider',
  templateUrl: './meal-slider.component.html',
  styleUrls: ['./meal-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MealSliderComponent {
  public initialIndex: number = 0;

  private _dailyPrestations: DailyMenuPrestationModel[];
  @Input() set dailyPrestations(value: DailyMenuPrestationModel[]) {
    this._dailyPrestations = value;
    this.forceBindingRefresh();
  }

  get dailyPrestations(): DailyMenuPrestationModel[] {
    return this._dailyPrestations;
  }

  @Input() public currentType: RestaurantTypeEnum;

  @ViewChild(SwiperComponent, { static: true }) public componentRef: SwiperComponent;
  public swiperConfig: SwiperConfigInterface = {
    initialSlide: this.initialIndex,
    navigation: true,
    watchOverflow: true, // Swiper will be disabled and hide navigation buttons on case there are not enough slides for sliding
    spaceBetween: 30,
    centeredSlides: true,
    centerInsufficientSlides: true,
    slidesPerView: 5,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    effect: 'slide',
    coverflowEffect: {
      rotate: 40,
      stretch: 0,
      depth: 200,
      modifier: 1,
      slideShadows: false // for performance
    },
    breakpoints: {
      768: { slidesPerView: 1, effect: 'coverflow' },
      970: { slidesPerView: 3, effect: 'coverflow' },
      1280: { slidesPerView: 4 },
    }
    /*
$smWidth: 768px;
$mdWidth: 970px;
$lgWidth: 1280px;
$xlWidth: 1500px;
    */
  };

  constructor(private _changeDetectorRef: ChangeDetectorRef) { }

  public setInitialIndex(index: number) {
    this.initialIndex = index;
    this.forceBindingRefresh();
  }

  public forceBindingRefresh() {
    setTimeout(() => {
      // this.componentRef.directiveRef.setIndex(this.initialIndex);
      this._changeDetectorRef.markForCheck();
    }, 50);
  }
}
