<ng-container *ngIf="currentNotification">
  <div class="popup" [ngClass]="{'visible': showPopup}">
    <div class="popup-content">
      <div class="popup-header" [restaurantColor]="{'type': currentType, 'background':true}">
        <span class="pop-close close-cross" (click)="hide()"></span>
        <h3>{{currentNotification.title}}</h3>
      </div>
      <div class="popup-body">
        <div class="popup-message" [innerHTML]="currentNotification.message"></div>
      </div>
      <div class="footer-container">
        <label class="custom-label-checkbox" *ngIf="currentNotification.checkboxText">
          <input name="isReaded" type="checkbox" [(ngModel)]="isChecked" />
          <span>{{currentNotification.checkboxText}}</span>
        </label>
        <div class="btns">
          <button *ngIf="currentNotification.isPrintable" type="button" class="print-btn" [restaurantColor]="{'type': currentType, 'background':true}" (click)="print()">Imprimer</button>
          <button type="button" class="close-btn" [restaurantColor]="{'type': currentType, 'background':true}" (click)="hide()">Fermer</button>
        </div>
      </div>
    </div>
  </div>
  <div class="popup-bckg" [ngClass]="{'visible': showPopup}" (click)="currentNotification.closeOnBackdropClick && hide()"></div>
</ng-container>
