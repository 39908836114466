import { AnalyticsDimensionMappings } from "../models/analytics-dimension-mapping.model";
import { AnalyticsDimensions } from "../models/analytics-dimensions.model";
import { AnalyticsService } from "../analytics-service";
export abstract class AnalyticsProviderBase {
    public dimensionPropertyMappings: AnalyticsDimensionMappings = new AnalyticsDimensionMappings();

    subscribeToMainService(analyticsService: AnalyticsService) {
        analyticsService.setUserId$.subscribe(userId => {
            this.setUserId_internal(userId);
        });

        analyticsService.eventTrack$.subscribe(payload => {
            this.eventTrack(payload.action, payload.properties, payload.dimensions);
        });

        analyticsService.pageTrack$.subscribe(payload => {
            this.pageTrack(payload.path, payload.dimensions);
        });
    }

    protected abstract pageTrack_internal(path: string, dimensions: AnalyticsDimensions): void;
    protected abstract eventTrack_internal(action: string, properties: any, dimensions: AnalyticsDimensions): void;
    protected abstract setUserId_internal(userId: string): void;


    protected pageTrack(path: string, dimensions: AnalyticsDimensions) {
        const mappedDimensions = this.mapDimensionProperties(dimensions);
        this.pageTrack_internal(path, mappedDimensions);
    }

    protected eventTrack(action: string, properties: any, dimensions: AnalyticsDimensions) {
        const mappedDimensions = this.mapDimensionProperties(dimensions);
        this.eventTrack_internal(action, properties, mappedDimensions);
    }

    protected mapDimensionProperties(dimensions: AnalyticsDimensions): AnalyticsDimensions {
        const mappedDimensions: AnalyticsDimensions = Object.assign({}, dimensions);
        const mappings = this.dimensionPropertyMappings;

        if (mappings) {
            for (const fromProp in mappings) {
                if (mappedDimensions[fromProp]) {
                    const toProp = mappings[fromProp];
                    mappedDimensions[toProp] = mappedDimensions[fromProp];
                    delete mappedDimensions[fromProp];
                }
            }
        }

        return mappedDimensions;
    }
}
