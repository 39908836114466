import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {
  transform(value: Date, format?: string): string {
    // TODO : Add moment
    if (!value) {
      return '';
    }
    if (format) {
      return moment(value).format(format);
    } else {
      return moment(value).format('DD/MM/YYYY');
    }
  }
}
