
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HttpStatusCode } from 'src/app/shared/enums/http-status-code.enum';
import { FileHelper } from 'src/app/shared/helpers/file.helper';
import { ErrorApiModel } from 'src/app/shared/models/api/error/error.apimodel';
import { NotificationModel } from 'src/app/shared/models/notification.model';
import { InteractionService } from 'src/app/shared/services/interaction.service';
import { Logger, LogService } from 'src/app/shared/services/log.service';
import { ContratModel } from '../../shared/models/contrat.model';
import { FileResultModel } from '../../shared/models/fileresult.model';
import { PrestationModel } from '../models/prestation.model';
import { ReportingProjector } from '../reporting.projector';
import { ReportingApiService } from './api/reporting.apiservice';

@Injectable()
export class ReportingService {

  private _log: Logger;

  constructor(
    private _reportingApiService: ReportingApiService,
    private _interactionService: InteractionService,
    logService: LogService
  ) {
    this._log = logService.getLogger('ReportingService');
  }

  public getEtatCommandeInventaireReport(cuisineCentraleId: number, contratId: number, typeListeArticleId: number, startDate: Date, endDate: Date): Observable<FileResultModel> {
    return this._reportingApiService.getEtatCommandeInventaireReport(cuisineCentraleId, contratId, typeListeArticleId, startDate, endDate);
  }

  public getBilanCommandeRepasOuPiqueNiqueReportForOneRestaurant(cuisineCentraleId: number, contratId: number, restaurantId: number, startDate: Date, endDate: Date, isPiqueNique: boolean): Observable<FileResultModel> {
    return this._reportingApiService.getBilanCommandeRepasOuPiqueNiqueReport(cuisineCentraleId, contratId, [restaurantId], startDate, endDate, isPiqueNique);
  }

  public getBilanCommandeRepasOuPiqueNiqueReportForAllRestaurant(cuisineCentraleId: number, contrat: ContratModel, startDate: Date, endDate: Date, isPiqueNique: boolean): Observable<FileResultModel> {
    return this._reportingApiService.getBilanCommandeRepasOuPiqueNiqueReport(cuisineCentraleId, contrat.id, contrat.restaurants.map(r => r.id), startDate, endDate, isPiqueNique);
  }

  public getCommandeRepasMenu(cuisineCentraleId: number, restaurantId: number, lieuCommandeId: number, date: Date, isPiqueNique: boolean) {
    return this._reportingApiService.getCommandeRepasMenu(cuisineCentraleId, restaurantId, lieuCommandeId, date, isPiqueNique);
  }

  public getCommandeRepasMatriceDeChoix(cuisineCentraleId: number, restaurantId: number, lieuCommandeId: number, date: Date, isPiqueNique: boolean) {
    return this._reportingApiService.getCommandeRepasMatriceDeChoix(cuisineCentraleId, restaurantId, lieuCommandeId, date, isPiqueNique);
  }

  public getPrestations(cuisineCentraleId: number): Observable<PrestationModel[]> {
    return this._reportingApiService.getPrestations(cuisineCentraleId).pipe(
      map(res => {
        return ReportingProjector.projectPrestations(res);
      }));
  }

  public handleReportResponse(reportRequest$: Observable<FileResultModel>) {
    reportRequest$
      .subscribe(fileresult => {
        if (fileresult.httpStatus == HttpStatusCode.NoContent) {
          FileHelper.readBlobAsString(fileresult.file)
            .subscribe(content => {
              this._interactionService.showSimpleMessage('Aucune donnée', content || 'La recherche n\'a retourné aucune donnée. \nVeuillez ajuster les paramètres.');
            });
        }
        else {
          FileHelper.saveFile(fileresult);
        }
      },
        (error: ErrorApiModel) => {
          this._interactionService.showSimpleMessage('Erreur', 'Une erreur est survenue lors de l\'extraction du rapport : \n' + error.Message);
        });
  }

}
