<div class="autocomplete-container"
     ngui-auto-complete
     z-index="10"
     match-formatted="true"
     [accept-user-input]="false"
     [list-formatter]="entityFormatter.bind(this)"
     [source]="getEntities"
     (valueChanged)="entityChanged($event)"
     no-match-found-text="Aucun résultat"
     [re-focus-after-select]="false"
     [title]="showTooltip && dropdownInput.value">
  <input #dropdownInput
         (focus)="onInputFocus($event)"
         [ngModel]="entityFormatter(entity)" />
  <img class="pointerHover"
       (click)="onArrowClick($event)"
       src="/assets/images/dropdown-arrow.png" />
</div>
