<div class="tile-container">
  <h3 class="title" title="{{dailyPrestation.date | dateFormat:'DD/MM/YYYY'}}">{{dailyPrestation.date | dateFormat:'dddd'}}</h3>
  <h4 class="subtitle">{{dailyPrestation.prestation}}</h4>
  <!-- Liste des plats regroupés par famille -->
  <div class="daily-prestation-container">
    <div class="daily-prestation">
      <ng-template ngFor let-famille [ngForOf]="dailyPrestation.familles" *ngIf="!dailyPrestation.isEmpty">
        <div *ngFor="let plat of famille.plats" class="daily-plat">
          {{plat.libelle}}
        </div>
        <!--Utilisation du SVG pour redimmentionner le texte automatiquement selon l'espace dispo-->
        <!-- <svg *ngFor="let plat of famille.plats"
             viewBox="0 -220 1 400"
             class="daily-plat">
            <text font-size="300">
                <tspan  text-anchor="middle">{{plat.libelle}}</tspan>
            </text>
        </svg> -->
        <hr [restaurantColor]="{'type': currentType, 'background':true}" class="bar" />
      </ng-template>
      <!-- Pas de data pour ce jour -->
      <div *ngIf="dailyPrestation.isEmpty" class="no-content">
        Pas de service pour cette journée.
      </div>
    </div>
  </div>

</div>
