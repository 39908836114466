
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, finalize, first } from 'rxjs/operators';
import { DiscussionMessageDocumentModel } from '../discussion/models/discussion-message-document.model';
import { FileTypeEnum } from '../discussion/models/file-type.enum';
import { DiscussionService } from '../discussion/services/discussion.service';
import { FileHelper } from '../shared/helpers/file.helper';
import { InteractionService } from '../shared/services/interaction.service';

const FILE_IMAGE_PATH = "/assets/images/files/";
const FILE_EXCEL = "excel.png";
const FILE_WORD = "word.png";
const FILE_PPT = "powerpoint.png";
const FILE_DEFAULT = "file.png";
const FILE_NOMINIATURE = "nominiature.png";

@Component({
  selector: 'app-document-thumbnail',
  templateUrl: './document-thumbnail.component.html',
  styleUrls: ['./document-thumbnail.component.scss'],
  host: {
    "(click)": "downloadDocument()"
  }
})
export class DocumentThumbnailComponent implements OnInit {

  @Input() document: DiscussionMessageDocumentModel;

  @Output('imageLoaded') imageLoadedEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  imageSource: string;
  isLoading: boolean = true;
  isImage: boolean;
  isOther: boolean;


  constructor(private discussionService: DiscussionService, private interactionService: InteractionService) { }

  ngOnInit() {
    this.isImage = this.document && this.document.type == FileTypeEnum.Image;
    this.isOther = this.document && this.document.type == FileTypeEnum.Other;

    this.loadDocumentThumbnail();
  }

  imageLoaded() {
    this.isLoading = false;
    this.imageLoadedEmitter.emit(true);
  }


  loadDocumentThumbnail() {
    if (this.document.type == FileTypeEnum.Image) {
      if (this.document.hasMiniature) {
        this.imageSource = this.discussionService.getThumbnailSrc(this.document.id);
      }
      else {
        this.imageSource = `${FILE_IMAGE_PATH}${FILE_NOMINIATURE}`;
      }
    }
    else {
      let fileName = this.getFileNameFromType(this.document.type);
      this.imageSource = `${FILE_IMAGE_PATH}${fileName}`;
    }
  }

  getFileNameFromType(type: FileTypeEnum): string {
    switch (this.document.type) {
      case FileTypeEnum.Excel:
        return FILE_EXCEL;
      case FileTypeEnum.Word:
        return FILE_WORD;
      case FileTypeEnum.Powerpoint:
        return FILE_PPT;
      default:
        return FILE_DEFAULT;
    }
  }


  public downloadDocument() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.discussionService.getDocument(this.document.id).pipe(
      debounceTime(500),
      first(),
      finalize(() => {
        this.isLoading = false;
      }))
      .subscribe(fileresult => {
        FileHelper.saveFile(fileresult);
      },
        err => {
          this.interactionService.showSimpleMessage("Erreur", "Une erreur est survenue lors de l'extraction du document.");
        });
  }
}
