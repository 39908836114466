import { HttpResponseBase, HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { FileResultModel } from '../models/fileresult.model';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ErrorApiModel } from '../models/api/error/error.apimodel';
export class FileHelper {
  static saveBlob(blob: Blob, fileName: string) {
    // console.log("cordova: saving " + fileName);

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else if (window.cordova) {
      window.resolveLocalFileSystemURL(window.cordova.file.externalDataDirectory,
        (entry: Entry) => {
          const dir = entry as DirectoryEntry;
          dir.getFile(fileName, { create: true }, (file: FileEntry) => {
            file.createWriter((fileWriter: FileWriter) => {
              fileWriter.onwriteend = () => {
                const filePath = decodeURIComponent(file.nativeURL);
                // console.log({ path: file.fullPath, nativeURL: file.nativeURL, internalUrl: file.toInternalURL(), url: file.toURL() })
                cordova.plugins.disusered.open(filePath);
              };
              fileWriter.write(blob);
            });
          });
        });
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  static saveFile(file: FileResultModel) {
    FileHelper.saveBlob(file.file, file.filename);
  }

  static getFileNameFromResponse(response: HttpResponseBase) {
    // const contentDispositionHeader = response.headers.get('Content-Disposition');
    // const fileName = contentDispositionHeader.split(';')[1].trim().split('=')[1].replace(/"/g, '');

    // Récupération du nom de fichier dans un header spécifique (plus simple que de décoder Content-Disposition en UTF-8)
    const fileName = decodeURIComponent(response.headers.get('filename'));
    return fileName;
  }

  /**
   * Try to download a file using GET request
   */
  static getFile(httpClient: HttpClient, url: string, params: HttpParams = null): Observable<FileResultModel> {

    return httpClient.get(url,
      {
        params,
        observe: 'response',
        responseType: 'blob'
      })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          const filename = FileHelper.getFileNameFromResponse(response);
          return new FileResultModel(response.body, filename, response.status);
        })
      );

  }

  static readBlobAsString(blob: Blob): Observable<string> {
    if (!blob) {
      return of(null);
    }

    return new Observable<string>(obs => {
      // extract blob Content
      const reader: FileReader = new FileReader();
      reader.onloadend = (e) => {
        obs.next(reader.result as string);
        obs.complete();
      };
      reader.onerror = (e) => {
        const errorContent = JSON.stringify(reader.error);
        const errorModel: ErrorApiModel = {
          Code: 0,
          Message: errorContent
        };
        obs.error(errorModel);
        obs.complete();
      };
      reader.readAsText(blob);
    });

  }

}
