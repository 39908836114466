<div class="user button-dropdown">
	<!-- make a svg color component instead of this -->
	<input id="menu-check2" type="checkbox" name="menu"/>
	<label for="menu-check2" class="pointerHover">
		<svg class="restaurant-type-fill" xmlns="http://www.w3.org/2000/svg" width="50" height="52" viewBox="0 0 50 52">
			<defs>
				<style>
					.cls-1 {
						fill: inherit;
						fill-rule: evenodd;
					}
				</style>
			</defs>
			<path id="user.svg" class="cls-1" d="M65,35C51.251,35,40,46.7,40,61S51.251,87,65,87s25-11.7,25-26S78.755,35,65,35Zm0.532,5.313a8.479,8.479,0,0,1,8.516,8.746,8.518,8.518,0,1,1-17.031,0A8.479,8.479,0,0,1,65.535,40.313Zm0,41.4A20.57,20.57,0,0,1,48.5,72.383c0-5.831,11.354-9.038,17.031-9.038s17.031,3.207,17.031,9.038A20.57,20.57,0,0,1,65.535,81.711Z"
				transform="translate(-40 -35)" />
		</svg>
		<span *ngIf="(user$ | async) as user" class="restaurant-type-color"> {{user.fullName}}</span>
	</label>
	<ul class="submenu submenu-style2 restaurant-type-background">
		<li class="pointerHover" (click)="logOff()">Se déconnecter</li>
	</ul>
</div>
