import { EventEmitter, Output } from '@angular/core';
import { OdgEvaluationNote } from '../models/odg.model';

export abstract class SmileyBase {
    // Input
    abstract activeNote: OdgEvaluationNote;
    // Output
    abstract smileyClick: EventEmitter<OdgEvaluationNote>;
    abstract note: OdgEvaluationNote;

    public onSmileyClick() {
        this.smileyClick.emit(this.note);
    }

    get isActive(): boolean {
        return this.note === this.activeNote;
    }
}