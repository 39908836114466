import { Component } from '@angular/core';
import { PopupCustomComponentBase } from '../../popup-custom/popup-custom-content.component';

@Component({
  selector: 'legal-info',
  templateUrl: './contrat-notification-popup.component.html',
  styleUrls: ['./contrat-notification-popup.component.scss']
})
export class ContratNotificationPopupComponent extends PopupCustomComponentBase {

  constructor() {
    super();
  }
}
