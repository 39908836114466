import { LogService } from "../shared/services/log.service";
import { RestaurantTypeEnum } from "../shared/enums/restaurant-type.enum";
import { ApplicationDataService } from "../shared/services/application-data.service";
import { NotificationModel } from "../shared/models/notification.model";
import { Component, OnInit, HostBinding, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.scss"]
})
export class PopupComponent implements OnInit, OnDestroy {
  @HostBinding("class.visible")
  public showPopup: boolean;
  public currentNotification: NotificationModel;
  public currentType: RestaurantTypeEnum;
  private restTypeSubscription: Subscription;

  constructor(private appDataService: ApplicationDataService, private logService: LogService) { }

  ngOnInit() {
    this.restTypeSubscription = this.appDataService.restaurantType$
      .subscribe(type => {
        this.currentType = type;
      });
  }

  get isChecked(): boolean {
    return this.currentNotification.isChecked;
  }

  set isChecked(value: boolean) {
    this.currentNotification.isChecked = value;
    this.currentNotification.onCheck(this.currentNotification.isChecked);
  }

  print() {
    if (this.currentNotification.isPrintable) {
      try {
        window.print();
      } catch (e) {
        // log / show message ?
        this.logService.Error(e, "PopupComponent");
      }
    }
  }

  show(notif: NotificationModel) {
    this.currentNotification = notif;
    this.showPopup = true;
  }

  hide() {
    this.showPopup = false;
    if (this.currentNotification) {
      this.currentNotification.onClose();
      this.currentNotification = null;
    }
  }

  ngOnDestroy() {
    this.restTypeSubscription.unsubscribe();
  }

}
