import { AlerteModel } from './alertes.model';
import { EnqueteModel } from './enquete.model';
import { ReceivedAlerteModel } from './received-alert.model';
import { ReceivedEnqueteModel } from './received-enquete.model';
import * as moment from 'moment';
import { RestaurantModel } from 'src/app/shared/models/restaurant.model';

export interface AlerteWithState { alerte: AlerteModel; state: ReceivedAlerteModel; }
export interface EnqueteWithState { enquete: EnqueteModel; state: ReceivedEnqueteModel; }

export class NotificationsModel {

  public alerteShortMessage: string;

  public get titre() {
    return this.hasMultipleNotifications
      ? 'Informations' // titre par défaut
      // on affiche le titre de l'alerte ou de l'enquete
      : this.hasDisplayableNotification
      && ((this.alertes[0] && this.alertes[0].titre)
        || (this.enquetes[0] && this.enquetes[0].libelle)); // titre générique pour les enquêtes
  }

  public get shouldAutoDisplayNotifications(): boolean {
    // on affiche les alertes/enquetes dont la showAfterDate est révolue
    const now = moment();
    return this.alertes.some(a => {
      const received = this.receivedAlertes.find((ra) => ra.alerteId === a.alerteId);
      return !received || now.isAfter(received.showAfterDate);
    })
      || this.enquetes.some(e => {
        const received = this.receivedEnquetes.find((re) => re.enqueteId === e.enqueteId);
        return !received || now.isAfter(received.showAfterDate);
      });
  }

  public get count() {
    return (this.alertes.length + this.enquetes.length);
  }

  public get countUnread() {
    return (this.alertesWithState.filter(a => !a.state.isRead).length
      + this.enquetesWithState.filter(e => !e.state.note).length);
  }

  public get hasMultipleNotifications() {
    return this.count > 1;
  }

  public get alertesWithState(): AlerteWithState[] {
    return this.alertes.map(a => ({ alerte: a, state: this.receivedAlertes.find(ra => ra.alerteId === a.alerteId) }));
  }

  public get enquetesWithState(): EnqueteWithState[] {
    return this.enquetes.map(e => ({ enquete: e, state: this.receivedEnquetes.find(re => re.enqueteId === e.enqueteId) }));
  }

  public get hasDisplayableNotification(): boolean {
    return (this.alertes && this.alertes.length > 0) || this.enquetesWithState.some(e => !e.state.note);
  }

  constructor(public restaurant: RestaurantModel,
              public alertes: AlerteModel[],
              public receivedAlertes: ReceivedAlerteModel[],
              public enquetes: EnqueteModel[],
              public receivedEnquetes: ReceivedEnqueteModel[],
  ) {
    // on extrait un message court pour affichage en rappel
    this.alerteShortMessage = this.hasDisplayableNotification
      && (
        (alertes && alertes.length > 0 && this.extractAlerteAlternativeMessage(this.getFirstUnreadAlerte().alerte))
        || (enquetes && enquetes.length > 0 && this.getFirstReplyedEnquete().enquete.question)
      );
  }

  private extractAlerteAlternativeMessage(alerte: AlerteModel): string {
    const extractAltRegex = /<!-- ALT=(.+)-->/gim;
    const match = extractAltRegex.exec(alerte.message);
    return (match && match.splice(1).join('\n')) || alerte.message;
  }

  private getFirstUnreadAlerte(fallbackToFirst: boolean = true): AlerteWithState {
    const alertes = this.alertesWithState;
    return alertes.find(a => !a.state.isRead)
      || (fallbackToFirst && alertes[0]);
  }
  private getFirstReplyedEnquete(fallbackToFirst: boolean = true): EnqueteWithState {
    const enquetes = this.enquetesWithState;
    return enquetes.find(e => !e.state.note)
      || (fallbackToFirst && enquetes[0]);
  }
}
