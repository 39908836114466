import { AllowedFeatureEnum } from '../enums/allowed-feature.enum';
import { ContratModel } from './contrat.model';
import { CuisineCentraleModel } from './cuisine-centrale.model';
import { RestaurantModel } from './restaurant.model';
import { GrandCompteModel } from './grand-compte.model';
import { ROUTE_URLS } from '../constants/route_urls';
import { includes } from 'lodash';

export interface IUserInfo {
  readonly fullName: string;
  readonly id: string;
  readonly allowedFeatures: AllowedFeatureEnum[];
  readonly cuisines: CuisineCentraleModel[];
  readonly grandComptes: GrandCompteModel[];
}

export class UserInfoModel implements IUserInfo {
  public readonly fullName: string;
  public readonly id: string;
  public readonly allowedFeatures: AllowedFeatureEnum[];
  public readonly cuisines: CuisineCentraleModel[];
  public readonly grandComptes: GrandCompteModel[];

  constructor(data: IUserInfo) {
    this.fullName = data.fullName;
    this.id = data.id;
    this.allowedFeatures = data.allowedFeatures || [];
    this.cuisines = data.cuisines || [];
    this.grandComptes = data.grandComptes || [];
  }

  public get allContrats(): ContratModel[] {
    return this.cuisines && this.cuisines.reduce<ContratModel[]>((prev, current) => prev.concat(current.contrats), []) || [];
  }
  // public *getAllContrats(): IterableIterator<ContratModel> {
  //   for (const cuisine of this.cuisines) {
  //     for (const contrat of cuisine.contrats) {
  //       yield contrat;
  //     }
  //   }
  // }

  public get allRestaurants(): RestaurantModel[] {
    return this.allContrats.reduce<RestaurantModel[]>((prev, current) => prev.concat(current.restaurants), []);
  }
  // public *getAllRestaurants(): IterableIterator<RestaurantModel> {
  //   for (const cuisine of this.cuisines) {
  //     for (const contrat of cuisine.contrats) {
  //       for (const restaurant of contrat.restaurants) {
  //         yield restaurant;
  //       }
  //     }
  //   }
  // }

  public hasAccessToFeature(featureLink: string | AllowedFeatureEnum): boolean {
    if (featureLink === ROUTE_URLS.Home || featureLink === ROUTE_URLS.Login ) {
      return true;
    }
    const feature = typeof featureLink === 'string' ? this.linkToFeatureEnum(featureLink) : featureLink;
    return includes(this.allowedFeatures, feature);
  }

  private linkToFeatureEnum(link: string): AllowedFeatureEnum {
    switch (link) {
      case ROUTE_URLS.CmdRepas:
        return AllowedFeatureEnum.CommandeRepas;
      case ROUTE_URLS.PiqueNique:
        return AllowedFeatureEnum.PiqueNiques;
      case ROUTE_URLS.EpicerieInventaire:
        return AllowedFeatureEnum.EpicerieInventaire;
      case ROUTE_URLS.Observatoire:
        return AllowedFeatureEnum.ObservatoireDuGout;
      case ROUTE_URLS.Documentheque:
        return AllowedFeatureEnum.Documentheque;
      case ROUTE_URLS.Discussion:
        return AllowedFeatureEnum.Discussion;
      case ROUTE_URLS.Reporting:
        return AllowedFeatureEnum.Reporting;
      case ROUTE_URLS.Contact:
        return AllowedFeatureEnum.Contacts;
      case ROUTE_URLS.BonLivraison:
        return AllowedFeatureEnum.BonLivraison;
      default:
        return null;
    }
  }

  public get isAdmin(): boolean {
    return this.allowedFeatures.some(af => af === AllowedFeatureEnum.Administration);
  }
}
