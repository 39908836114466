import { ApiSettings } from 'src/app/shared/models/api/api-settings';
import { LogService } from 'src/app/shared/services/log.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/models/api/api-settings";
import * as i2 from "@angular/common/http";
import * as i3 from "../../shared/services/log.service";
const CONTROLLER_URL = 'alerte';
export class AlertesApiService {
    constructor(_apiSettings, _http, _logService) {
        this._apiSettings = _apiSettings;
        this._http = _http;
        this._logService = _logService;
    }
    getRestaurantAlertes(cuisineCentraleId, restaurantId) {
        const url = `${this._apiSettings.apiUrl}/alertes`;
        return this._http.get(url, {
            params: {
                cuisineCentraleId: cuisineCentraleId.toString(),
                restaurantId: restaurantId.toString()
            }
        });
    }
}
AlertesApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertesApiService_Factory() { return new AlertesApiService(i0.ɵɵinject(i1.ApiSettings), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.LogService)); }, token: AlertesApiService, providedIn: "root" });
