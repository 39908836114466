import { ValidatorFn, AbstractControl } from '@angular/forms';
import { ValidationResult } from '../models/validators/validation-result';

export class NumberValidator {
    static range(inclusiveMin: number, inclusiveMax?: number): ValidatorFn {
        if (isNaN(inclusiveMin) || (inclusiveMax && isNaN(inclusiveMax))) {
            throw Error(`Boundaries of NumberValidator.range are invalid {min:${inclusiveMin},max:${inclusiveMax}}`);
        }

        return (control: AbstractControl): ValidationResult => {
            let val = <number>control.value;
            if (!isNaN(val) && (val >= inclusiveMin && (!inclusiveMax || val <= inclusiveMax))) {
                return null;
            }
            else {
                let error: string;
                if (inclusiveMax) {
                    if (inclusiveMax != inclusiveMin) {
                        error = `La valeur doit être comprise entre ${inclusiveMin} et ${inclusiveMax}`;
                    }
                    else {
                        error = `La valeur doit être égale à ${inclusiveMin}`;
                    }
                }
                else {
                    error = `La valeur doit être supérieure à ${inclusiveMin}`
                }
                return {
                    range: {
                        isValid: true,
                        error: error
                    }
                }
            }
        };
    }
}