import { Directive, ElementRef, Input, DoCheck } from '@angular/core';

import { RestaurantTypeHelper, RestaurantColorClasses } from '../helpers/restaurant-type.helper';

import { RestaurantTypeEnum } from '../enums/restaurant-type.enum';

@Directive({ selector: '[paginationColor]' })
export class PaginationColorDirective implements DoCheck {

    type: RestaurantTypeEnum = undefined;
    constructor(public el: ElementRef) {
    }

    ngDoCheck() {
        if (this.type == undefined)
            return;

        let bg = RestaurantTypeHelper.getClasses(this.type).backgroundClass;
        let bullets: Element[] = this.el.nativeElement.querySelectorAll('.swiper-pagination-bullet');

        if (bullets != undefined) {
            let count = bullets.length;
            for (let i = 0; i < count; i++) {
                let bullet = bullets[i];
                let isActive: boolean = bullet.classList.contains('swiper-pagination-bullet-active');
                if (isActive) {
                    bullet.classList.add(bg);
                }
                else {
                    bullet.classList.remove(bg);
                }
            }
        }
    }

    @Input()
    set paginationColor(type: RestaurantTypeEnum) {
        this.type = type;
    }
}