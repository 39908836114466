/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./date-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/pipes/dateFormat.pipe";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "./date-input.component";
import * as i6 from "../shared/pipes/error-pretty-date.pipe";
var styles_DateInputComponent = [i0.styles];
var RenderType_DateInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DateInputComponent, data: {} });
export { RenderType_DateInputComponent as RenderType_DateInputComponent };
function View_DateInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "generic-button clear-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clear() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["clear"]))], null, null); }
export function View_DateInputComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DateFormatPipe, []), i1.ɵqud(402653184, 1, { _inputRef: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["dateInput", 1]], null, 6, "input", [], [[1, "id", 0], [8, "className", 0], [8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "blur"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = (_co.onModelChange($event) !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (_co.onBlur() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(6, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵppd(7, 2), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(9, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DateInputComponent_1)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = _co.disabled; var currVal_11 = i1.ɵunv(_v, 6, 1, _ck(_v, 7, 0, i1.ɵnov(_v, 0), _co.value, _co.format)); _ck(_v, 6, 0, currVal_10, currVal_11); var currVal_12 = (!_co.disabled && _co.calendarInfo.showClearButton); _ck(_v, 11, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputId; var currVal_1 = ("date-input " + _co.class); var currVal_2 = _co.placeholder; var currVal_3 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 9).ngClassValid; var currVal_8 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_DateInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-date-input", [], null, null, null, View_DateInputComponent_0, RenderType_DateInputComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DateInputComponent]), i1.ɵprd(4608, null, i6.ErrorPrettyDatePipe, i6.ErrorPrettyDatePipe, []), i1.ɵdid(3, 4243456, null, 0, i5.DateInputComponent, [i1.ElementRef, i1.Renderer2], null, null)], null, null); }
var DateInputComponentNgFactory = i1.ɵccf("app-date-input", i5.DateInputComponent, View_DateInputComponent_Host_0, { disabled: "disabled", calendarInfo: "calendarInfo", format: "format", placeholder: "placeholder", class: "class" }, {}, []);
export { DateInputComponentNgFactory as DateInputComponentNgFactory };
