import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupNotificationsComponent } from './components/popup-notifications/popup-notifications.component';
import { AlerteComponent } from './components/alerte/alerte.component';
import { SharedModule } from '../shared/shared.module';
import { EnqueteComponent } from './components/enquete/enquete.component';
import { RatingComponent } from './components/rating/rating.component';
import { AlertesTileComponent } from './components/alertes-tile/alertes-tile.component';
import { ExportEnquetesComponent } from './components/export-enquetes/export-enquetes.component';

@NgModule({
  declarations: [PopupNotificationsComponent, AlerteComponent, EnqueteComponent, RatingComponent, AlertesTileComponent, ExportEnquetesComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    AlertesTileComponent,
    ExportEnquetesComponent
  ],
  entryComponents: [PopupNotificationsComponent]
})
export class AlertesModule { }
