import { Injectable } from '@angular/core';
import { Observable, of as observableOf, throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { map, switchMap, shareReplay, distinctUntilChanged, tap, catchError } from 'rxjs/operators';
import { AuthTokenModel as AuthToken } from '../../models/auth-token.model';
import { UserInfoModel as User, IUserInfo } from '../../models/user-info.model';
import { AuthenticationToken } from './authentication-token.model';
import * as moment from 'moment';
import { LogService, Logger } from '../log.service';
import { Router } from '@angular/router';
import { StorageServiceBase, SessionStorageService, LocalStorageService } from '../browser-storage.service';
import { AuthenticationApiService } from '../api/authentication/authentication.apiservice';
import { AuthTokenProjector } from '../../projectors/auth-token.projector';
import { UserInfoProjector } from '../../projectors/user-info.projector';
import { MenuLinkModel } from '../../models/menu-link.model';
import { ROUTE_URLS } from '../../constants/route_urls';

const USER_STORAGEKEY = 'USER';
const TOKEN_STORAGEKEY = 'TOKEN';

const LEGACY_RTOKEN_STORAGEKEY = 'REFRESHTOKEN';
const LEGACY_TOKEN_STORAGEKEY = 'ACCESSTOKEN';

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class AuthenticationService {

  constructor(private _apiAuthenticationService: AuthenticationApiService,
              private _router: Router,
              private _sessionStorageService: SessionStorageService,
              private _localStorageService: LocalStorageService,
              private _logService: LogService) {
    this._log = _logService.getLogger('AuthenticationService');
  }

  private _currentTokenSubject = new BehaviorSubject<AuthenticationToken>(this.currentToken);
  public currentToken$ = this._currentTokenSubject.asObservable()
    .pipe(
      shareReplay(1) // on partage toujours la même subscription et on propose la dernière valeur
    );

  private _currentUserSubject = new BehaviorSubject<User>(this.currentUser);
  public currentUser$ = this._currentUserSubject.asObservable()
    .pipe(
      distinctUntilChanged(),
      shareReplay(1) // on partage toujours la même subscription et on propose la dernière valeur
    );

  private _token: AuthenticationToken;
  private get currentToken(): AuthenticationToken {
    return this._token = this._token || this.loadStoredToken();
  }
  private set currentToken(token: AuthenticationToken) {
    this._token = token;
    this.storeToken(token ? token.authToken : null);
    this._currentTokenSubject.next(token);
  }

  private _user: User;
  private get currentUser(): User {
    return this._user = this._user || this.loadStoredUser();
  }
  private set currentUser(user: User) {
    this._user = user;
    this.storeUser(user);
    this._currentUserSubject.next(user);
  }

  private _log: Logger;
  // store the URL so we can redirect after logging in
  public redirectUrl: string;




  public signIn(username: string, password: string): Observable<User> {
    return this._apiAuthenticationService.login(username, password)
      .pipe(
        switchMap(authData => {
          const token = AuthTokenProjector.projectFromApi(authData);
          // store Token & get user info
          this.currentToken = new AuthenticationToken(token);
          return this.loadUser();
        })
        // TODO voir pour debouncer les appels
        // , shareReplay() // We are calling shareReplay to prevent the receiver of this Observable from accidentally triggering multiple POST requests due to multiple subscriptions.
      );
  }

  public getToken(checkExpiration = true, canRefreshToken = true): Observable<AuthenticationToken | null> {
    if (this.currentToken && checkExpiration) {
      if (!this.currentToken.isAccessTokenExpired()) {
        return observableOf(this.currentToken);
      }

      this._log.debug('access token expired');
      if (canRefreshToken && !this.currentToken.isRefreshTokenExpired()) {
        return this.refreshToken();
      }
      return observableOf(null);
    } else {
      return observableOf(this.currentToken);
    }
  }

  private loadUser(): Observable<User> {
    return this._apiAuthenticationService.me()
      .pipe(
        map(apiUserModel => UserInfoProjector.projectUserInfo(apiUserModel)),
        tap((user) => {
          this.currentUser = user;
        })
      );
  }

  private storeUser(user: User) {
    this._sessionStorageService.setObject(USER_STORAGEKEY, user);
    // on défini un scope user sur le localStorage
    this._localStorageService.keyPrefix = user && `USER${user.id}` || null;
  }

  private loadStoredUser(): User {
    const sessionUser = this._sessionStorageService.getObject<IUserInfo>(USER_STORAGEKEY);
    // check suite à un changement de la structure du user afin de forcer le rafraichissement pour migrer vers la nouvelle structure
    return sessionUser && sessionUser.cuisines && new User(sessionUser);
  }

  private storeToken(token: AuthToken) {
    // this._storageService.setObject(TOKEN_STORAGEKEY, token);
    this._sessionStorageService.setString(LEGACY_TOKEN_STORAGEKEY, token && token.accessToken);
    this._sessionStorageService.setString(LEGACY_RTOKEN_STORAGEKEY, token && token.refreshToken);
  }

  private loadStoredToken(): AuthenticationToken {
    // const token = this._storageService.getObject(TOKEN_STORAGEKEY);
    const token: AuthToken = {
      accessToken: this._sessionStorageService.getString(LEGACY_TOKEN_STORAGEKEY),
      refreshToken: this._sessionStorageService.getString(LEGACY_RTOKEN_STORAGEKEY)
    };
    return token && token.accessToken && new AuthenticationToken(token);
  }

  public refreshToken(): Observable<AuthenticationToken> {
    if (this.currentToken) {
      this._log.debug('refreshing access token...');
      if (this.currentToken.isRefreshTokenExpired()) {
        this._log.debug('refresh token expired');
        return observableThrowError(new Error('Refresh token expired'));
      }
      return this._apiAuthenticationService.refresh(this.currentToken.authToken.refreshToken)
        .pipe(
          map(token => {
            this._log.debug('access token refreshed');
            this.currentToken = new AuthenticationToken(token);
            return this.currentToken;
          })
          // TODO voir pour debouncer les appels
          // , shareReplay() // We are calling shareReplay to prevent the receiver of this Observable from accidentally triggering multiple POST requests due to multiple subscriptions.
        );
    } else {
      return observableThrowError(new Error('No refresh token'));
    }
  }

  public signOut(rerouteToLogin: boolean = true, redirectUrl: string = null) {
    this._log.info('User logged off');
    this.currentToken = null;
    this.currentUser = null;

    // Store the attempted URL for redirecting
    this.redirectUrl = redirectUrl;

    if (rerouteToLogin) {
      this._router.navigate(['/login']);
    }
  }

  public isLoggedIn(): Observable<boolean> {
    return this.getToken()
      .pipe(
        map(token => {
          return token && !token.isAccessTokenExpired();
        })
      );
  }

  public sendPassword(username: string): Observable<boolean> {
    return this._apiAuthenticationService.sendPassword(username);
  }

  public _debug_ExpireToken() {
    // expire le token (seulement côté client, le token original n'est pas modifié)
    this.currentToken.accessTokenExpirationDate = moment().subtract(1, 'day').toDate();
  }

  public _debug_TestAuthApi(): Observable<any> {
    // appel à un service qui requiert le token d'authentification
    return this._apiAuthenticationService.me();
  }

  public getAvailableMenuLinks(): Observable<MenuLinkModel[]> {
    return this.currentUser$
      .pipe(map(user =>
        [
          new MenuLinkModel(ROUTE_URLS.Home, true, 'accueil', 'Accueil', false, true, false, 'home'),
          new MenuLinkModel(ROUTE_URLS.CmdRepas, user && user.hasAccessToFeature(ROUTE_URLS.CmdRepas), 'repas', 'Commande de repas', false, true, true, 'repas'),
          new MenuLinkModel(ROUTE_URLS.PiqueNique, user && user.hasAccessToFeature(ROUTE_URLS.PiqueNique), 'pique-nique', 'Commande de pique-niques', false, true, true, 'piquenique'),
          new MenuLinkModel(ROUTE_URLS.EpicerieInventaire, user && user.hasAccessToFeature(ROUTE_URLS.EpicerieInventaire), 'epicerie & inventaire', 'Commandes d\'épicerie & inventaires', false, true, true, 'epicerieinventaire'),
          new MenuLinkModel(ROUTE_URLS.Observatoire, user && user.hasAccessToFeature(ROUTE_URLS.Observatoire), 'observatoire du goût', 'Observatoire du goût', false, true, true, 'observatoiregout'),
          new MenuLinkModel(ROUTE_URLS.Documentheque, user && user.hasAccessToFeature(ROUTE_URLS.Documentheque), 'documenthèque', 'Documenthèque', false, true, true, 'documentheque'),
          new MenuLinkModel(ROUTE_URLS.Discussion, user && user.hasAccessToFeature(ROUTE_URLS.Discussion), 'Demande / Réclamation', 'Demande / Réclamation', true, true, false, 'mail'),
          new MenuLinkModel(ROUTE_URLS.Reporting, user && user.hasAccessToFeature(ROUTE_URLS.Reporting), 'reporting', 'Reporting', false, true, true, 'reporting'),
          new MenuLinkModel(ROUTE_URLS.BonLivraison, user && user.hasAccessToFeature(ROUTE_URLS.BonLivraison), 'réception', 'Réception', false, true, true, 'bonLivraison')
          // new MenuLinkModel("/paramètres", true, "paramètres", "Paramètres", false, false, false, "setting"),
        ].filter(m => m.isEnabled) // n'afficher que les éléments accessibles #24071
      )
      );
  }
}
