/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../shared/directives/restaurant-type-color.directive";
import * as i4 from "@angular/common";
import * as i5 from "./popup.component";
import * as i6 from "../shared/services/application-data.service";
import * as i7 from "../shared/services/log.service";
var styles_PopupComponent = [i0.styles];
var RenderType_PopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupComponent, data: {} });
export { RenderType_PopupComponent as RenderType_PopupComponent };
function View_PopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "label", [["class", "custom-label-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["name", "isReaded"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.isChecked = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = "isReaded"; var currVal_8 = _co.isChecked; _ck(_v, 4, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = _co.currentNotification.checkboxText; _ck(_v, 8, 0, currVal_9); }); }
function View_PopupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "print-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.print() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpod(2, { "type": 0, "background": 1 }), (_l()(), i1.ɵted(-1, null, ["Imprimer"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.currentType, true); _ck(_v, 1, 0, currVal_0); }, null); }
function View_PopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "popup"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "visible": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 18, "div", [["class", "popup-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "popup-header"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i3.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpod(8, { "type": 0, "background": 1 }), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "pop-close close-cross"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "popup-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "popup-message"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 9, "div", [["class", "footer-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupComponent_2)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [["class", "btns"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupComponent_3)), i1.ɵdid(19, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 3, "button", [["class", "close-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 16384, null, 0, i3.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpod(22, { "type": 0, "background": 1 }), (_l()(), i1.ɵted(-1, null, ["Fermer"])), (_l()(), i1.ɵeld(24, 0, null, null, 3, "div", [["class", "popup-bckg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.currentNotification.closeOnBackdropClick && _co.hide()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(26, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(27, { "visible": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "popup"; var currVal_1 = _ck(_v, 4, 0, _co.showPopup); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 8, 0, _co.currentType, true); _ck(_v, 7, 0, currVal_2); var currVal_5 = _co.currentNotification.checkboxText; _ck(_v, 16, 0, currVal_5); var currVal_6 = _co.currentNotification.isPrintable; _ck(_v, 19, 0, currVal_6); var currVal_7 = _ck(_v, 22, 0, _co.currentType, true); _ck(_v, 21, 0, currVal_7); var currVal_8 = "popup-bckg"; var currVal_9 = _ck(_v, 27, 0, _co.showPopup); _ck(_v, 26, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.currentNotification.title; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.currentNotification.message; _ck(_v, 13, 0, currVal_4); }); }
export function View_PopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentNotification; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup", [], [[2, "visible", null]], null, null, View_PopupComponent_0, RenderType_PopupComponent)), i1.ɵdid(1, 245760, null, 0, i5.PopupComponent, [i6.ApplicationDataService, i7.LogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).showPopup; _ck(_v, 0, 0, currVal_0); }); }
var PopupComponentNgFactory = i1.ɵccf("app-popup", i5.PopupComponent, View_PopupComponent_Host_0, {}, {}, []);
export { PopupComponentNgFactory as PopupComponentNgFactory };
