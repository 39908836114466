
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileHelper } from 'src/app/shared/helpers/file.helper';
import { DateHelper } from '../../../shared/helpers/date.helper';
import { ApiSettings } from '../../../shared/models/api/api-settings';
import { FileResultModel } from '../../../shared/models/fileresult.model';
import { LogService } from '../../../shared/services/log.service';
import { PrestationApiModel } from '../../models/api/prestation.apimodel';

@Injectable()
export class ReportingApiService {
  private controllerUrl: string = 'reporting';

  constructor(private apiSettings: ApiSettings,
              private http: HttpClient,
              private logService: LogService) {
  }

  getEtatCommandeInventaireReport(cuisineCentraleId: number, contratId: number, typeListeArticleId: number, startDate: Date, endDate: Date): Observable<FileResultModel> {
    const httpParams = new HttpParams()
      .set('cuisineCentraleId', cuisineCentraleId.toString())
      .set('contratId', contratId.toString())
      .set('typeListeArticleId', typeListeArticleId.toString())
      .set('dateDebut', DateHelper.toISODateString(startDate))
      .set('dateFin', DateHelper.toISODateString(endDate));

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/etatcommandesinventaire`;
    return FileHelper.getFile(this.http, url, httpParams);
  }

  getBilanCommandeRepasOuPiqueNiqueReport(cuisineCentraleId: number, contratId: number, restaurantIds: number[], startDate: Date, endDate: Date, isPiqueNique: boolean): Observable<FileResultModel> {
    let httpParams = new HttpParams()
      .set('cuisineCentraleId', cuisineCentraleId.toString())
      .set('contratId', contratId.toString())
      .set('dateDebut', DateHelper.toISODateString(startDate))
      .set('dateFin', DateHelper.toISODateString(endDate))
      .set('isPiqueNique', isPiqueNique.toString());

    for (const resId of restaurantIds) {
      httpParams = httpParams.append('restaurantIds', resId.toString());
    }

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/recapitulatifcommanderepas`;
    return FileHelper.getFile(this.http, url, httpParams);
  }


  getCommandeRepasMenu(cuisineCentraleId: number, restaurantId: number, lieuCommandeId: number, date: Date, isPiqueNique: boolean): Observable<FileResultModel> {
    const httpParams = new HttpParams()
      .set('cuisineCentraleId', cuisineCentraleId.toString())
      .set('restaurantId', restaurantId.toString())
      .set('lieuCommandeId', lieuCommandeId.toString())
      .set('date', DateHelper.toISODateString(date))
      .set('isPiqueNique', isPiqueNique.toString());

    const url = `${this.apiSettings.apiUrl}/commanderepas/ImprimerMenu`;
    return FileHelper.getFile(this.http, url, httpParams);
  }

  getCommandeRepasMatriceDeChoix(cuisineCentraleId: number, restaurantId: number, lieuCommandeId: number, date: Date, isPiqueNique: boolean): Observable<FileResultModel> {
    const httpParams = new HttpParams()
      .set('cuisineCentraleId', cuisineCentraleId.toString())
      .set('restaurantId', restaurantId.toString())
      .set('lieuCommandeId', lieuCommandeId.toString())
      .set('date', DateHelper.toISODateString(date))
      .set('isPiqueNique', isPiqueNique.toString());

    const url = `${this.apiSettings.apiUrl}/commanderepas/ExportMatriceDeChoix`;
    return FileHelper.getFile(this.http, url, httpParams);
  }

  getPrestations(cuisineCentraleId: number): Observable<PrestationApiModel[]> {
    const httpParams = new HttpParams()
      .set('cuisineCentraleId', cuisineCentraleId.toString());

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/prestations`;
    return this.http.get<PrestationApiModel[]>(url, { params: httpParams });
  }
}
