/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./user-info.component";
import * as i4 from "@angular/router";
import * as i5 from "../shared/services/authentication/authentication.service";
var styles_UserInfoComponent = [i0.styles];
var RenderType_UserInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserInfoComponent, data: {} });
export { RenderType_UserInfoComponent as RenderType_UserInfoComponent };
function View_UserInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "restaurant-type-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf.fullName; _ck(_v, 1, 0, currVal_0); }); }
export function View_UserInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "user button-dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["id", "menu-check2"], ["name", "menu"], ["type", "checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "label", [["class", "pointerHover"], ["for", "menu-check2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, ":svg:svg", [["class", "restaurant-type-fill"], ["height", "52"], ["viewBox", "0 0 50 52"], ["width", "50"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, ":svg:defs", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:style", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" .cls-1 { fill: inherit; fill-rule: evenodd; } "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:path", [["class", "cls-1"], ["d", "M65,35C51.251,35,40,46.7,40,61S51.251,87,65,87s25-11.7,25-26S78.755,35,65,35Zm0.532,5.313a8.479,8.479,0,0,1,8.516,8.746,8.518,8.518,0,1,1-17.031,0A8.479,8.479,0,0,1,65.535,40.313Zm0,41.4A20.57,20.57,0,0,1,48.5,72.383c0-5.831,11.354-9.038,17.031-9.038s17.031,3.207,17.031,9.038A20.57,20.57,0,0,1,65.535,81.711Z"], ["id", "user.svg"], ["transform", "translate(-40 -35)"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_UserInfoComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 2, "ul", [["class", "submenu submenu-style2 restaurant-type-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "li", [["class", "pointerHover"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logOff() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Se d\u00E9connecter"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.user$)); _ck(_v, 9, 0, currVal_0); }, null); }
export function View_UserInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-info", [], null, null, null, View_UserInfoComponent_0, RenderType_UserInfoComponent)), i1.ɵdid(1, 49152, null, 0, i3.UserInfoComponent, [i4.Router, i5.AuthenticationService], null, null)], null, null); }
var UserInfoComponentNgFactory = i1.ɵccf("app-user-info", i3.UserInfoComponent, View_UserInfoComponent_Host_0, { user$: "user$" }, {}, []);
export { UserInfoComponentNgFactory as UserInfoComponentNgFactory };
