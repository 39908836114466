<div class="main-container">
  <div class="ribbon" [style.background-image]="'url(' + logoContratUrl + ')'">
  </div>
  <div class="page-container contact-container">
    <div *ngIf="isLoadingContacts" class="info-container">
      <app-loader message="Chargement des contacts...">
      </app-loader>
    </div>
    <div *ngIf="!isLoadingContacts">
      <div *ngIf="!hasContacts" class="no-contacts">
        <h2>Contacts</h2>
        Aucun contact n'est défini sur ce contrat
      </div>
      <div *ngIf="hasContacts">
        <section *ngIf="urgenceContacts.length > 0">
          <h2>Urgence</h2>
          <app-contact-list [contacts]="urgenceContacts" [restType]="restType">
          </app-contact-list>
        </section>
        <section *ngIf="contacts.length > 0">
          <h2>Contacts</h2>
          <app-contact-list [contacts]="contacts" [restType]="restType">
          </app-contact-list>
        </section>
      </div>
    </div>
  </div>
</div>