import { ContactModel } from '../models/contact.model';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { stringToColor } from '../../shared/helpers/color.helper';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactCardComponent implements OnInit {
  public initialsColor: string;
  public initials: string;
  private _contact: ContactModel;
  @Input() set contact(value: ContactModel) {
    this._contact = value;
    if (!value) {
      this.initials = '';
      this.initials = '#000';
    } else {
      this.initials = value.nom[0] + value.prenom[0];
      this.initialsColor = stringToColor(this.initials);
    }
  }
  get contact(): ContactModel {
    return this._contact;
  }

  constructor() { }

  ngOnInit() {
  }

}
