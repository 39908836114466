<fieldset class="reporting"
          *ngIf="isAllowed">
  <legend><i class="material-icons restaurant-type-color">bar_chart</i> Commande repas</legend>
  <div class="content">
    <ng-container *ngIf="!isLoading">
      <div *ngIf="isDowloading"
           class="loading-container overlay">
        <app-loader message="Téléchargement du rapport"></app-loader>
      </div>
      <ng-container *ngIf="!isDowloading">

        <h3>Dates de consommation</h3>
        <div class="input-group dates-container">
          <app-date-input id="bilanrepas-start-date"
                          name="bilanrepas-start-date"
                          [(ngModel)]="dateConsoDebut"
                          [calendarInfo]="dateCalendarData"
                          placeholder="Consommation Début"></app-date-input>
          <app-date-input id="bilanrepas-end-date"
                          name="bilanrepas-end-date"
                          [(ngModel)]="dateConsoFin"
                          [calendarInfo]="dateCalendarData"
                          placeholder="Consommation Fin"></app-date-input>
        </div>
        <div class="input-group type-container">
          <label>
            <input type="radio"
                   [(ngModel)]="isPiqueNique"
                   name="bilanrepas-is-pique-nique"
                   [value]="false" />
            <span class="custom-radio"></span>
            <span>Repas</span>
          </label>
          <label>
            <input type="radio"
                   [(ngModel)]="isPiqueNique"
                   name="bilanrepas-is-pique-nique"
                   [value]="true" />
            <span class="custom-radio"></span>
            <span>Pique-nique</span>
          </label>
        </div>
        <div class="input-group"
             *ngIf="grandComptes.length > 0 && allowGrandCompte">
          <h3 class="input-group-label">Grand Compte</h3>
          <select class="input-group-input selector"
                  [(ngModel)]="selectedGrandCompte"
                  name="select-grandcompte">
            <option [ngValue]="null">Tous</option>
            <option *ngFor="let grandCompte of grandComptes"
                    [ngValue]="grandCompte">{{grandCompte.libelle}}</option>
          </select>
        </div>
        <div class="input-group"
             *ngIf="cuisines.length > 1">
          <h3 class="input-group-label">Cuisine</h3>
          <select class="input-group-input selector"
                  [(ngModel)]="selectedCuisine"
                  name="select-cuisine">
            <option *ngIf="cuisines.length > 1"
                    [ngValue]="null">Toutes</option>
            <option *ngFor="let cuisine of cuisines"
                    [ngValue]="cuisine">{{cuisine.libelle}}</option>
          </select>
        </div>
        <div class="input-group"
             *ngIf="cuisines.length > 1 || contrats.length > 1">
          <h3 class="input-group-label">Contrat</h3>
          <select class="input-group-input selector"
                  [(ngModel)]="selectedContrat"
                  name="select-contrat">
            <option *ngIf="contrats.length > 1 || (cuisines.length > 1 && selectedCuisine == null) "
                    [ngValue]="null">Tous</option>
            <option *ngFor="let contrat of contrats"
                    [ngValue]="contrat">{{contrat.libelle}}</option>
          </select>
        </div>
      </ng-container>

      <div class="input-group bottom">
        <button class="generic-button gray-tile-button"
                (click)="export()"
                [disabled]="isDowloading">{{ isDowloading ? "Téléchargement..." : "Extraire" }}</button>
      </div>
    </ng-container>

    <div *ngIf="isLoading"
         class="loading-container">
      <app-loader message="Préparation du formulaire"></app-loader>
    </div>
  </div>

</fieldset>
