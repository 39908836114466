import { Component, OnInit, Input, HostBinding, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { PopupCustomComponentBase } from '../../popup-custom/popup-custom-content.component';
import { BonLivraisonService } from '../services/bon-livraison.service';
import { Router } from '@angular/router';
import { NotificationModel } from '../../shared/models/notification.model';
import { InteractionService } from '../../shared/services/interaction.service';
import { ErrorApiModel } from '../../shared/models/api/error/error.apimodel';
import { BonLivraisonApiErrorCodes } from '../models/api/bon-livraison-api-error-codes';
import { BonLivraisonStatut } from '../models/bon-livraison.model';
// import Quagga from "quagga";

const QUAGGA_DEFAULT_CONFIG = {
  inputStream: {
    name: 'Live',
    type: 'LiveStream',
    target: null,
    constraints: {
      width: { min: 640 },
      height: { min: 480 },
      aspectRatio: { min: 1, max: 100 },
      facingMode: 'environment', // or user
    },
    singleChannel: false // true: only the red color-channel is read
  },
  locator: {
    patchSize: 'large', // "medium",
    halfSample: true
  },
  locate: true,
  numOfWorkers: 4,
  decoder: {
    readers: ['code_39_reader'],
    debug: {
      drawBoundingBox: true,
      showFrequency: true,
      drawScanline: true,
      showPattern: true
    }
  }
} as any; // TODO faire un resultLocator pour filtrer sur le format désiré https://github.com/serratus/quaggaJS/blob/master/example/live_w_locator.js

const BARCODE_TYPES = [
  'code_128',
  'code_39',
  'code_39_vin',
  'ean',
  'ean_extended',
  'ean_8',
  'upc',
  'upc_e',
  'codabar',
  'i2of5',
  '2of5',
  'code_93'
];

function mapToReader(value) {
  return [value + '_reader'];
}

@Component({
  selector: 'app-popup-search-code-barre',
  templateUrl: './popup-search-code-barre.component.html',
  styleUrls: ['./popup-search-code-barre.component.scss']
})
export class PopupSearchCodeBarreComponent extends PopupCustomComponentBase implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('inputCodeBarre', { static: true })
  private _inputCodeBarre: ElementRef;

  @ViewChild('barcodeScanner', { static: true })
  private _barcodeScanner: ElementRef;

  public isLoading: boolean = false;

  public codeBarre: string;

  private configQuagga = QUAGGA_DEFAULT_CONFIG;


  constructor(
    private bonLivraisonService: BonLivraisonService,
    private router: Router,
    private interactionService: InteractionService) {

    super();
  }

  ngOnInit() {
    // Quagga.onProcessed((result) => this.onProcessed(result));

    // Quagga.onDetected((result) => this.onDetected(result));

    // (this.configQuagga.inputStream as any).target = this._barcodeScanner.nativeElement;

    // // if (this.type) {
    // //   this.configQuagga.decoder.readers = mapToReader(this.type);
    // // }

    // Quagga.init(this.configQuagga,
    //   (err) => {
    //     if (err) {
    //       console.log(err);
    //     }
    //     Quagga.start();
    //   });
  }

  ngOnDestroy() {
    // Quagga.stop();
  }

  onProcessed(result: any): any {
    // const drawingCtx = Quagga.canvas.ctx.overlay;
    // const drawingCanvas = Quagga.canvas.dom.overlay;

    // if (result) {
    //   if (result.boxes) {
    //     drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width"), 10), parseInt(drawingCanvas.getAttribute("height"), 10));
    //     result.boxes.filter(function (box) {
    //       return box !== result.box;
    //     }).forEach(function (box) {
    //       Quagga.ImageDebug.drawPath(box, {
    //         x: 0,
    //         y: 1,
    //       }, drawingCtx, {
    //           color: "green",
    //           lineWidth: 2,
    //         });
    //     });
    //   }

    //   if (result.box) {
    //     Quagga.ImageDebug.drawPath(result.box, {
    //       x: 0,
    //       y: 1,
    //     }, drawingCtx, {
    //         color: "#00F",
    //         lineWidth: 2,
    //       });
    //   }

    //   if (result.codeResult && result.codeResult.code) {
    //     Quagga.ImageDebug.drawPath(result.line, {
    //       x: "x",
    //       y: "y",
    //     }, drawingCtx, {
    //         color: "red",
    //         lineWidth: 3,
    //       });
    //   }

    // }
  }

  onDetected(result) {
    const code = result.codeResult.code;
    // this.valueChanges.next({code});
    const log = `barcode read : ${code}`;
    console.log(log);
  }

  ngAfterViewInit(): void {
    this._inputCodeBarre.nativeElement.focus();
  }

  searchBonLivraison() {
    const codeBonLivraison = (this.data.cuisineCode + '-' + this.codeBarre).toUpperCase();
    this.isLoading = true;

    this.bonLivraisonService.getBonLivraison(codeBonLivraison)
      .subscribe(
        bonLivraison => {
          this.isLoading = false;
          this.hide();

          if (bonLivraison.statut === BonLivraisonStatut.ALivrer) {
            this.router.navigate(['/bonlivraison/livraison/' + codeBonLivraison], { queryParams: {} });
          } else {
          this.router.navigate(['/bonlivraison/details/' + codeBonLivraison], { queryParams: {} });
          }
        },
        (error: ErrorApiModel) => {
          this.isLoading = false;

          if (error.Code === BonLivraisonApiErrorCodes.BonLivraisonNotFound) {
            this.interactionService.showSimpleMessage(`Bon de livraison ${codeBonLivraison} inconnu`, 'Veuillez saisir un autre code.');
          } else {
            const notifModel = new NotificationModel();
            notifModel.title = 'Erreur';
            notifModel.message = 'Erreur de chargement du bon : \n' + error.Message;
            this.interactionService.showNotification(notifModel);
          }

        }
      );
  }

  hide() {
    this.emitter.next();
  }
}
