import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/guards/auth-guard';

import { ReportingComponent } from './reporting.component';

const reportingRoutes: Routes = [
  {
    path: 'reporting',
    component: ReportingComponent,
    canActivate: [AuthGuard],
    data: { title: "Reporting" }

  }
];
@NgModule({
  imports: [
    RouterModule.forChild(reportingRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class ReportingRouteModule { }
