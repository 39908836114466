import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from '../helpers/string.helper';

@Pipe({ name: 'currencyEuro' })
export class CurrencyEuroPipe implements PipeTransform {
  transform(value: number): string {
    if (value != null) {
      const formatted = StringHelper.formatDecimal(value);
      return `${formatted} €`;
    } else {
      return value + ' €';
    }
  }
}
