export class MathHelper {

    /** Round to lower when .5 */
    static roundToLowerWhenMiddle(value: number): number {
        if (value == 0) {
            return 0;
        }
        else if (value < 0) {
            return Math.round(value);
        }
        else {
            return -Math.round(-value);
        }
    }
}