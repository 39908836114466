import { Pipe, PipeTransform } from '@angular/core';
import { ReceptionStatut, BonLivraisonModel, LivraisonStatut } from '../models/bon-livraison.model';
import { BonLivraisonService } from '../services/bon-livraison.service';

@Pipe({ name: 'formatLivraisonStatut' })
export class FormatLivraisonStatutPipe implements PipeTransform {

  constructor(private _bonLivraisonService: BonLivraisonService) { }

  transform(value: BonLivraisonModel): string {
    let result = this.formatStatut(value.livraisonStatut);

    if (value.livraisonStatut === LivraisonStatut.NonConforme) {
      const hasDlcFault = this._bonLivraisonService.checkHasDlcFault(value);
      const hasTemperatureFault = this._bonLivraisonService.checkHasTemperatureFault(value);

      const faults: string[] = [];
      if (hasDlcFault) { faults.push('DLC'); }
      if (hasTemperatureFault) { faults.push('°C'); }

      if (faults.length > 0) {
        result += ` (${faults.join(', ')})`;
      }
    }

    return result;
  }

  private formatStatut(statut: LivraisonStatut): string {
    switch (statut) {
      case LivraisonStatut.NonLivre: return 'Non livré';
      case LivraisonStatut.Conforme: return 'Conforme';
      case LivraisonStatut.NonConforme: return 'Non conforme';
    }
    return status;
  }

}
