import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { DocumenthequeRoutingModule } from './documentheque-routing.module';
import { DocumenthequeComponent } from './documentheque.component';
import { DocumenthequeApiService } from './services/api/documentheque.apiservice';
import { DocumenthequeService } from './services/documentheque.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DocumenthequeRoutingModule,
    FormsModule
  ],
  declarations:
  [
    DocumenthequeComponent
  ],
  providers:
  [
    DocumenthequeApiService,
    DocumenthequeService
  ]
})
export class DocumenthequeModule { }
