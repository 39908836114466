import { Directive, Input, ElementRef, Renderer2, EventEmitter, OnInit, DoCheck, HostListener, OnDestroy, NgZone, Inject } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { outsideZone } from '../rx/operators/outsideZone.operator';
import { DOCUMENT } from '@angular/common';

interface Data {
  container: HTMLElement;
  blockClass: string;
  containerClass: string;
  stickyClass: string;
  stickyBottomClass: string;
  mobile: boolean;
  scrollContainer: string;
}

@Directive({
  selector: '[stickyBlock]'
})
export class StickyBlockDirective implements OnInit, DoCheck, OnDestroy {

  private data: Data = undefined;

  private elementHeight: number;
  private elementWidth: number;

  private winHeight: number;

  private container: HTMLElement;
  private containerHeight: number;
  private containerTopOffset: number;
  private containerBottomOffset: number;

  private isSticky: boolean;
  private isStickyBottom: boolean;

  private isScrollable: boolean;

  private scrollPos: number;
  private stickyBottomPos: number;

  private mobileMQ: string = '(min-width: 768px)';
  private scrollContainer: Element;
  private onScrollSubscription: Subscription;

  constructor(public el: ElementRef,
    private renderer: Renderer2,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document,
  ) { }



  @Input() set stickyBlock(data: Data) {
    this.setDefaults(data);

    this.container = this.data.container;

    this.el.nativeElement.classList.add(this.data.blockClass);
    this.container.classList.add(this.data.containerClass);

    if (data.mobile === false) {
      this.toggleClass('sticky-from-mobile', !this.data.mobile);
    }

    this.scrollContainer = data.scrollContainer && this.document.querySelector(data.scrollContainer) || this.document.documentElement;

    if (this.onScrollSubscription) {
      this.onScrollSubscription.unsubscribe();
    }
    this.onScrollSubscription = fromEvent(this.scrollContainer, 'scroll')
      .pipe(
        outsideZone(this.ngZone),
        untilDestroyed(this),
      ).subscribe(event => {
        this.checkSticky();
      });

    this.setDimensions();
  }

  ngOnInit() {
    this.setDimensions();

    fromEvent(window, 'resize').pipe(
      outsideZone(this.ngZone),
      untilDestroyed(this),
    ).subscribe(event => {
      this.setDimensions();
    });
  }

  ngDoCheck() {
    const blockChanged = (this.elementHeight !== this.el.nativeElement.clientHeight);
    const containerChanged = (this.containerHeight !== this.container.clientHeight);
    const bodyChanged = (this.winHeight !== this.scrollContainer.clientHeight);

    // Reset dimensions if block or container size change
    if (blockChanged || containerChanged || bodyChanged) {
      this.setDimensions();
    }
  }

  ngOnDestroy(): void {
    // required (untilDestroyed)
  }

  // Sets defaults classes
  setDefaults(data: Data) {
    this.data = data;

    if (this.data.blockClass == undefined) { this.data.blockClass = 'sticky-block'; }

    if (this.data.containerClass == undefined) { this.data.containerClass = 'sticky-container'; }

    if (this.data.stickyClass == undefined) { this.data.stickyClass = 'is-sticky'; }

    if (this.data.stickyBottomClass == undefined) { this.data.stickyBottomClass = 'is-sticky-bottom'; }

    if (this.data.mobile == undefined) { this.data.mobile = false; }
  }


  // Sets elements dimensions and boundaries
  setDimensions() {
    // Reset status and dimensions
    this.resetDisplay();

    // Do nothing if sticky is not needed on mobile
    if (this.data.mobile === false && !window.matchMedia(this.mobileMQ).matches) { return; }

    this.winHeight = this.scrollContainer.clientHeight;

    this.elementHeight = this.el.nativeElement.clientHeight;
    this.elementWidth = this.el.nativeElement.clientWidth;


    this.containerHeight = this.container.clientHeight;
    this.containerTopOffset = this.container.offsetTop;
    this.containerBottomOffset = this.containerHeight + this.containerTopOffset;

    this.stickyBottomPos = this.containerBottomOffset - this.elementHeight;

    this.isScrollable = ((this.containerHeight - this.elementHeight) > 0);

    this.el.nativeElement.style.width = this.elementWidth + 'px';

    this.checkSticky();
  }

  // Checks if the block is sticky or not
  checkSticky() {

    this.setScrollPos();

    // If there is enough height for fixed position
    if (this.isScrollable) {
      // If the top of the container is reached
      if (this.scrollPos >= this.containerTopOffset) {
        // If the bottom of the container is reached
        if (this.scrollPos >= this.stickyBottomPos) {
          this.isStickyBottom = true;
          this.isSticky = false;
        } else {
          this.isStickyBottom = false;
          this.isSticky = true;
        }
      } else {
        this.isStickyBottom = false;
        this.isSticky = false;
      }

      this.setClasses();
    }
  }

  // Sets the scroll position
  setScrollPos() {
    this.scrollPos = this.scrollContainer.scrollTop || window.scrollY;
  }

  // Sets sticky block's classes
  setClasses() {
    this.toggleClass(this.data.stickyClass, this.isSticky);
    this.toggleClass(this.data.stickyBottomClass, this.isStickyBottom);
  }

  // Reset sticky mode
  resetDisplay() {
    this.isStickyBottom = false;
    this.isSticky = false;

    this.el.nativeElement.style.removeProperty('width');
  }

  private toggleClass(name: string, isAdd: boolean) {
    (isAdd ? this.renderer.addClass : this.renderer.removeClass).call(this.renderer, this.el.nativeElement, name);
  }
}
