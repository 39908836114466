import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { first } from 'rxjs/operators';
import { IPromptForNavigation } from '../interfaces/iprompt-for-navigation';
import { InteractionService } from '../services/interaction.service';

@Injectable()
export class PromptForNavGuard implements CanDeactivate<IPromptForNavigation> {

  constructor(private interactionService: InteractionService) { }

  canDeactivate(component: IPromptForNavigation, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot)
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    return new Observable((sub: Subscriber<boolean>) => {
      if (component.checkIsDirty()) {
        this.interactionService.showConfirmNavigationDialog()
          .subscribe(canExit => {
            sub.next(canExit);
          },
          err => {
            sub.next(false);
          });
      } else {
        sub.next(true);
      }
    }).pipe(first()); // on doit compléter l'observable sinon le router va attendre indéfiniment

  }

}
