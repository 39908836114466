<div class="main-container">
    <div class="ribbon" [style.background-image]="'url(' + logoContratUrl + ')'">
    </div>
    <div class="page-container">
        <h1>Epicerie & inventaire</h1>
        <div class="left-side">
            <span class="title">Recherche</span>
            <form class="content-container search-form" (submit)="searchCommandes($event)">
                <label>
                    {{ isInventaire ? "Inventaire" : "Livraison" }}
                 </label>
                <div class="sub-container dates-container">
                    <app-date-input id="start-date" name="start-date" [(ngModel)]="startDate" (ngModelChange)="askLoadListeArticles()" [calendarInfo]="dateCalendarData" placeholder="Début"></app-date-input>
                    <app-date-input id="end-date" name="end-date" [(ngModel)]="endDate" (ngModelChange)="askLoadListeArticles()" [calendarInfo]="dateCalendarData" placeholder="Fin"></app-date-input>
                </div>
                <div class="type-container">
                    <label>
                        <input type="radio" [(ngModel)]="isInventaire" name="type" [value]="false"/>
                        <span class="custom-radio"></span>
                        <span>Commande épicerie</span>
                    </label>
                    <label>
                        <input type="radio" [(ngModel)]="isInventaire" name="type" [value]="true"/>
                        <span class="custom-radio"></span>
                        <span>Inventaire</span>
                    </label>
                </div>

                <select *ngIf="selectedTypeListeArticle" class="sub-container selector" [(ngModel)]="selectedTypeListeArticle" name="selectedTypeListeArticle">
                    <option *ngFor="let liste of selectedTypeListeArticles" [ngValue]="liste">{{liste.libelle}}</option>
                </select>

                <div *ngIf="!selectedTypeListeArticle">
                    Il n'y a pas d'article correspondant
                </div>
                <div *ngIf="selectedTypeListeArticle && selectedTypeListeArticle.type == TypeListeEnum.Inventaire">
                    <span *ngIf="selectedTypeListeArticle.dateDebutInventaire; else noInventaireBlock">Prochain inventaire entre le {{selectedTypeListeArticle.dateDebutInventaire | date: 'dd/MM/yyyy'}} et le {{selectedTypeListeArticle.dateFinInventaire | date: 'dd/MM/yyyy'}}.</span>
                    <ng-template #noInventaireBlock>Pas d'inventaire prochainement</ng-template>
                </div>
                <div *ngIf="isTypeListValidationRequise" class="sub-container">
                    <label class="custom-label-checkbox" title="Afficher seulement les commandes/inventaires en attente de validation">
                        <input name="estEnAttenteValidation" type="checkbox" [(ngModel)]="estEnAttenteValidation" >
                        <span>En attente de validation</span>
                    </label>
                </div>
                <div class="sub-container buttons-container">
                    <button [restaurantColor]="{'type': currentType, 'background':true}" type="button" class="generic-button plus-button" (click)="navigateToCreate()"><span class="plus"></span> {{ isInventaire ? "Nouvel inventaire" : "Commander" }}</button>
                    <button type="submit" class="generic-button gray-tile-button search-button">Rechercher</button>
                </div>
                <div *ngIf="!hasLoadedForm" class="info-container">
                    <app-loader message="Chargement..."></app-loader>
                </div>
            </form>
        </div>
        <div class="results-container right-side">
            <span class="title">Historique des {{ isInventaire ? "inventaires" : "commandes" }}</span>

            <div class="results-list content-container">
                <div *ngIf="isLoadingCommandes" class="info-container">
                    <app-loader message="Chargement..."></app-loader>
                </div>
                <table *ngIf="!isLoadingCommandes">
                    <thead *ngIf="commandes && commandes.length != 0">
                        <tr>
                            <th class="is-traite">Ok</th>
                            <th class="commande">{{ isInventaire ? "Inventaire" : "Commande" }}</th>
                            <th class="livraison" *ngIf="!isInventaire">Livraison</th>
                            <th class="restaurant">Restaurant</th>
                            <th class="actions"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Affichage sans commandes -->
                        <tr *ngIf="!commandes || commandes.length == 0">
                            <td colspan="5">
                                Aucune commande trouvée
                            </td>
                        </tr>
                        <tr class="pointerHover" [restaurantColor]="{'type': currentType, 'background':true, 'hover':true, 'isSecondary': true}" (click)="openCommande(commande)" *ngFor="let commande of displayedCommandes">
                            <!-- 1. IsTraite -->
                            <td class="is-traite-container" [title]='getToolTip(commande.etat)'>
                                <!-- est en attente : pas encore validé par un admin -->
                                <img *ngIf="commande.etat == EtatCommandeEnum.EnAttenteValidation" class="en-attente-img" src="/assets/images/epicerie-inventaire/en-attente.png" />

                                <!-- est validé par un admin, mais pas encore transmis à Elixir -->
                                <div *ngIf="commande.etat == EtatCommandeEnum.ValideeNonTransmise" class="validee-non-transmise-check"></div>

                                <!-- est transmis à Elixir -->
                                <div *ngIf="commande.etat == EtatCommandeEnum.Transmise" class="validee-transmise-check"></div>
                            </td>
                            <!-- 2. Commande -->
                            <td>
                                {{commande.dateCommande | dateFormat}}
                            </td>
                            <!-- 3. Livraison -->
                            <td *ngIf="!isInventaire">
                                {{commande.dateLivraison | dateFormat}}
                            </td>
                            <!-- 4. Restaurant -->
                            <td>
                                {{commande.restaurant}}
                            </td>
                            <!-- 5. Actions -->
                            <td class="action-container">
                                <button title="Imprimer" type="button" class="generic-button gray-button  print-button" [disabled]="isPrintCommandeDownloading" [restaurantColor]="{'type': currentType, 'background':true}" (click)="printCommande(commande.id, $event)"></button>
                                <button title="Dupliquer" [class.btn-hidden]="!commande.canDuplicate" type="button" class="generic-button duplicate-button" [restaurantColor]="{'type': currentType, 'background':true}" (click)="navigateToDuplicate(commande.id, $event)"></button>
                                <button title="Supprimer" [class.btn-hidden]="!commande.canDelete" type="button" class="generic-button medium-gray-tile-button trash-button" (click)="deleteCommande(commande,$event)"></button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="pagination" *ngIf="!isLoadingCommandes && commandes && commandes.length > 0 && totalPage > 1">
                    <button class="generic-button prev-button" (click)="prev()" [class.hidden]="page == 1"></button>
                    <button class="generic-button page-button" (click)="changePage(1)" [restaurantColor]="{'type': currentType, 'background':page == 1}" [class.selected-page-button]="page == 1">1</button>
                    <span *ngIf="page > 3">...</span>
                    <button class="generic-button page-button" (click)="changePage(p)" *ngFor="let p of pages" [class.hidden]="!isPageDisplayed(p)" [class.selected-page-button]="p == page" [restaurantColor]="{'type': currentType, 'background':page == p}">{{p}}</button>
                    <span *ngIf="page < totalPage - 2">...</span>
                    <button class="generic-button page-button" (click)="changePage(totalPage)" [class.hidden]="totalPage == 1" [restaurantColor]="{'type': currentType, 'background':page == totalPage}" [class.selected-page-button]="page == totalPage">{{totalPage}}</button>
                    <button class="generic-button next-button" (click)="next()" [class.hidden]="page == totalPage"></button>
                </div>
            </div>
        </div>
    </div>
</div>
