
import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { orderBy, flatMap } from 'lodash';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { finalize, first, throttleTime } from 'rxjs/operators';
import { DatePickerInfo } from '../date-input/date-input.component';
import { ContratModel } from '../shared/models/contrat.model';
import { RestaurantModel } from '../shared/models/restaurant.model';
import { LogoRouteService } from '../shared/services/api/logo/logo-route.service';
import { ApplicationDataService } from '../shared/services/application-data.service';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { InteractionService } from '../shared/services/interaction.service';
import { DiscussionDetailsComponent } from './discussion-details/discussion-details.component';
import { DiscussionModel } from './models/discussion.model';
import { TypeInterventionModel, TypeInterventionGroup } from './models/type-intervention.model';
import { NewDiscussionComponent } from './new-discussion/new-discussion.component';
import { DiscussionService } from './services/discussion.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-Discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss']
})
export class DiscussionComponent implements OnInit, OnDestroy {
  @ViewChild(NewDiscussionComponent, { static: true })
  newDiscussionComponent: NewDiscussionComponent;

  @ViewChild(DiscussionDetailsComponent, { static: true })
  discussionDetailsComponent: DiscussionDetailsComponent;

  private askLoadDiscussionsEmitter: EventEmitter<void> = new EventEmitter<void>();


  /** Contrat/Restaurant fields */
  public contrat: ContratModel;
  public logoContratUrl: string = '';
  public restaurant: RestaurantModel;

  /** Search fields  */
  public searchText: string;
  public startDate: Date;
  public endDate: Date;
  public dateCalendarData: DatePickerInfo;
  public filterOnContrat: boolean;
  public showFilterOnContrat: boolean = false;

  /** Type Interventions */
  public typeInterventionGroups$ = new BehaviorSubject<TypeInterventionGroup[]>(undefined);
  public get isLoadingTypeInterventions(): boolean {
    return this.typeInterventionGroups$.value === undefined;
  }

  /** Discussions */
  public selectedDiscussion: DiscussionModel;
  public currentDiscussions: DiscussionModel[];
  public isLoadingFilters: boolean;
  public isLoadingDiscussions: boolean;

  /** getter/setter */
  private _blockLoadDicussionOnIsOuvertChange: boolean;
  private _isOuvertSelected = true;
  public get isOuvertSelected(): boolean {
    return this._isOuvertSelected;
  }

  public set isOuvertSelected(value: boolean) {
    this._isOuvertSelected = value;
    if (!this._blockLoadDicussionOnIsOuvertChange) {
      this.askLoadDiscussions();
    }
  }

  constructor(
    private router: Router,
    private appDataService: ApplicationDataService,
    private discussionService: DiscussionService,
    private interactionService: InteractionService,
    private logoService: LogoRouteService,
    private authenticationService: AuthenticationService
  ) { }

  /** Implementations */
  ngOnInit() {
    // Init search
    this.startDate = moment().add(-1, 'month').toDate();
    this.endDate = moment().toDate();

    this.dateCalendarData = {
      minDate: moment().add(-10, 'year').toDate(),
      maxDate: moment().endOf('month').toDate()
    };

    // On recherche les DateDebut/fin via l'api unreadCount
    this.isLoadingFilters = true;
    this.discussionService.getUnreadCount()
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.startDate = moment.min(moment(result.minDate), moment(this.startDate)).toDate();
        this.endDate = moment.max(moment(result.maxDate), moment(this.endDate)).toDate();

        this.isLoadingFilters = false;
        // on demande ensuite le chargement des discussions
        this.askLoadDiscussions();
      });

    // On ne passe que par askLoadDiscussions() et non loadDiscussions() pour avoir du debounce
    this.askLoadDiscussionsEmitter
      .pipe(
        untilDestroyed(this),
        throttleTime(500)
      )
      .subscribe(() => {
        this.loadDiscussions();
      });

    this.authenticationService.currentUser$
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.showFilterOnContrat = user && user.allContrats.length > 1;
      });

    this.appDataService.contrat$
      .pipe(untilDestroyed(this))
      .subscribe(contrat => {
        this.contrat = contrat;
        this.loadTypeInterventions();
        if (contrat) {
          this.logoContratUrl = this.logoService.GetPicture2Url(contrat.id, contrat.cuisineCentraleId);
        }
      });

    this.appDataService.restaurant$
      .pipe(untilDestroyed(this))
      .subscribe(restaurant => {
        this.restaurant = restaurant;
      });


  }

  ngOnDestroy() {
    this.askLoadDiscussionsEmitter.unsubscribe();
  }

  /** Type interventions */
  private loadTypeInterventions() {
    this.typeInterventionGroups$.next(undefined);
    if (this.contrat == null) {
      return;
    }

    this.discussionService.getTypeInterventionsForContrat(this.contrat.cuisineCentraleId, this.contrat.id)
      .subscribe(typeInterventionGroups => {
        this.typeInterventionGroups$.next(typeInterventionGroups);
      },
        err => {
          this.interactionService.showSimpleMessage('Erreur', 'Nous n\'avons pas réussi à récupérer les types d\'interventions.');
        });
  }

  askLoadDiscussions() {
    this.askLoadDiscussionsEmitter.next();
  }

  /** Discussions  */
  loadDiscussions(preselectDiscussionId: number = null) {
    if (this.filterOnContrat && this.contrat == null) {
      return;
    }

    this.selectedDiscussion = null;
    this.isLoadingDiscussions = true;

    const task = this.filterOnContrat
      ? this.discussionService.getDiscussions(
        this._isOuvertSelected,
        this.contrat.cuisineCentraleId,
        this.contrat.id,
        this.restaurant.id,
        this.startDate,
        this.endDate,
        this.searchText)
      : this.discussionService.getDiscussions(
        this._isOuvertSelected,
        null, // pas de filtre sur Cuisine/Contrat/Resto
        null,
        null,
        this.startDate,
        this.endDate,
        this.searchText);

    task
      .pipe(
        first(),
        finalize(() => {
          this.isLoadingDiscussions = false;
        })
      )
      .subscribe(discussions => {
        this.currentDiscussions = orderBy(discussions, ['dateModification'], ['desc']);

        if (preselectDiscussionId != null) {
          this.selectedDiscussion = this.currentDiscussions.find(d => d.id === preselectDiscussionId);
        } else {
          this.selectedDiscussion = null;
        }
      },
        err => {
          this.currentDiscussions = [];
          this.interactionService.showSimpleMessage('Erreur', 'Nous n\'avons pas réussi à récupérer les discussions. Veuillez réessayer.');
        });
  }

  /** New discussion */
  showNewDiscussion() {
    this.newDiscussionComponent.open();
  }

  onNewDiscussionCreated() {
    // force endDate filter at today then reload Discussions
    this.endDate = moment().toDate();
    this._isOuvertSelected = true;
    this.loadDiscussions();
  }

  onDiscussionClick(discussion: DiscussionModel) {
    // Pour l'affichage du selected
    this.selectedDiscussion = discussion;
  }

  onDiscussionClosed() {
    this.selectedDiscussion = null;
  }

  onRefreshDiscussionsRequired(data: { isClosed: boolean, discussionId: number }) {
    this._blockLoadDicussionOnIsOuvertChange = true;
    this.isOuvertSelected = !data.isClosed;
    this._blockLoadDicussionOnIsOuvertChange = false;
    this.loadDiscussions(data.discussionId);
  }

  onMessageLuTriggered(discussionId: number) {
    const discussion = this.currentDiscussions.find(d => d.id == discussionId);
    if (discussion != null) {
      discussion.lu = true;
    }
  }
}
