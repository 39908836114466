/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notif-tile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./notif-tile.component";
import * as i4 from "../shared/services/application-data.service";
import * as i5 from "@angular/router";
import * as i6 from "../discussion/services/discussion.service";
var styles_NotifTileComponent = [i0.styles];
var RenderType_NotifTileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotifTileComponent, data: {} });
export { RenderType_NotifTileComponent as RenderType_NotifTileComponent };
function View_NotifTileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notifCount; _ck(_v, 1, 0, currVal_0); }); }
export function View_NotifTileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["mail_outline"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotifTileComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notifCount; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_NotifTileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notif-tile", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_NotifTileComponent_0, RenderType_NotifTileComponent)), i1.ɵdid(1, 245760, null, 0, i3.NotifTileComponent, [i4.ApplicationDataService, i5.Router, i6.DiscussionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotifTileComponentNgFactory = i1.ɵccf("app-notif-tile", i3.NotifTileComponent, View_NotifTileComponent_Host_0, {}, {}, []);
export { NotifTileComponentNgFactory as NotifTileComponentNgFactory };
