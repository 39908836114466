<div class="home-top" [style.background-image]="'url(' + logoContratUrl + ')'">
    <div class="welcome">
        <div>
            Bienvenue sur
        </div>
        <div>
            <!--<app-logo class="logo-icon" [restaurantType]="currentType"></app-logo>-->
            <img class="logo-icon" src="/assets/images/welcome_logo.png"/>
        </div>

    </div>
    <div class="tiles-container">
        <app-meal-slider [currentType]="currentType" [dailyPrestations]="dailyPrestations" [hidden]="!dailyPrestations">
        </app-meal-slider>
    </div>
    <div class="jagged-border">
    </div>
</div>
<div class="home-bottom">
    <div class="daily-quote pointerHover" (click)="openAlerte()">
        <div [hidden]="!dailyQuote"><span *ngIf="dailyQuoteTitle"><span class="title">{{dailyQuoteTitle}}</span> : </span><span class="message">{{dailyQuote}}</span></div>
    </div>

    <hr [restaurantColor]="{'type': currentType, 'background':true}" class="bar" />

    <div class="menu">
        <div class="box btn-ripple pointerHover" *ngFor="let item of menuList$ | async" [class.disabled]="!item.isEnabled" [restaurantColor]="{'type': currentType, 'background':true, 'hover':true, 'isSecondary': true}">
            <app-meal-tile [item]="item">
            </app-meal-tile>
            <div class="border"> </div>
        </div>
    </div>
</div>
