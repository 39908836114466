<form class="form-requalifier-discussion form-discussion" (submit)="requalifierDiscussion()" ngNativeValidate autocomplete="off" #form="ngForm">
  <div class="form-group">
    <label>Restaurant : </label><span class="form-value">{{ this.model.restaurant.name }}</span>
  </div>
  <div class="form-group">
    <div class="form-group">
      <label>Type : </label><span class="form-value">{{ this.model.discussion.typeIntervention }}</span>
    </div>
    <div class="form-group" *ngIf="this.model.discussion.typeMaintenanceId">
      <label>Catégorie : </label><span class="form-value">{{ this.model.discussion.typeMaintenance }}</span>
    </div>
  </div>
  <div class="form-group">
    <label>Objet : </label><span class="form-value">{{ this.model.discussion.sujet }}</span>
  </div>
  <div class="form-group" *ngIf="hasTypeInterventions; else noTypeInterventionTemplate">
    <label>Nouveau type</label>
    <select [disabled]="isSending" [(ngModel)]="selectedTypeIntervention" name="type-intervention" required>
      <optgroup *ngFor='let grp of this.model.typeInterventionGroups' label="{{grp.groupe}}">
        <option *ngFor='let typeIntervention of grp.typeInterventions' [ngValue]="typeIntervention">{{typeIntervention.libelle}}</option>
      </optgroup>
    </select>
  </div>
  <ng-template #noTypeInterventionTemplate>
    <div class="form-group error-message">Aucun type n'est disponible pour ce contrat, veuillez contacter un administrateur.</div>
  </ng-template>

  <div class="form-group" *ngIf="hasTypeMaintenances">
    <label>Nouvelle catégorie</label>
    <select [disabled]="isSending" [(ngModel)]="selectedTypeMaintenance" name="selectedTypeMaintenance" required #typeMaintenanceCtrl="ngModel">
      <!-- <option [ngValue]="null">- Veuillez sélectionner une catégorie</option> -->
      <optgroup *ngFor='let grp of selectedTypeIntervention.typeMaintenances' label="{{grp.groupe}}">
        <option *ngFor='let typeMaintenance of grp.typeMaintenances' [ngValue]="typeMaintenance">{{typeMaintenance.libelle}}</option>
      </optgroup>
    </select>
    <div *ngIf="(form.submitted || form.dirty || form.touched || form.invalid ) && typeMaintenanceCtrl.invalid && (typeMaintenanceCtrl.dirty || typeMaintenanceCtrl.touched)" class="error-message">La catégorie est requise.</div>
  </div>

  <div class="btn-row">
    <button [disabled]="isSending" class="generic-button cancel-button" (click)="close()">
      <i class="material-icons">cancel</i><span> Annuler</span>
    </button>
    <button [disabled]="isSending || !hasChanged || !hasTypeInterventions" type="submit" class="generic-button restaurant-type-background">
      <i class="material-icons">edit</i><span> Valider{{ isSending ? '...' : '' }}</span>
    </button>
  </div>
</form>
