<div class="eval-container">
  <div class="plat-name">{{odgEval.libelle}}</div>
  <div class="eval-smileys">
    <!-- Très bien consommé -->
    <odg-smiley-tbc (smileyClick)="onSmileyClick($event)" [activeNote]="odgEval.note" class="smiley pointerHover">
    </odg-smiley-tbc>

    <!-- Plutot bien consommé -->
    <odg-smiley-pbc (smileyClick)="onSmileyClick($event)" [activeNote]="odgEval.note" class="smiley pointerHover">
    </odg-smiley-pbc>

    <!-- Pas consommé -->
    <odg-smiley-pc (smileyClick)="onSmileyClick($event)" [activeNote]="odgEval.note" class="smiley pointerHover">
    </odg-smiley-pc>

    <!-- Pas consommé du tout -->
    <odg-smiley-pcdt (smileyClick)="onSmileyClick($event)" [activeNote]="odgEval.note" class="smiley pointerHover">
    </odg-smiley-pcdt>
  </div>
  <!--<div class="eval-constat-list">
    <div class="assaisonnement" *ngIf="odgEval.assaisonnement">
      Assaisonnement
    </div>
    <div class="consistance" *ngIf="odgEval.consistance">
      Consistance
    </div>
    <div class="aspect" *ngIf="odgEval.aspect">
      Aspect
    </div>
  </div>-->
  <div class="eval-constat">
    <span *ngIf="constat">{{constat}}</span>
  </div>
</div>