
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileHelper } from 'src/app/shared/helpers/file.helper';
import { DateHelper } from '../../../shared/helpers/date.helper';
import { ApiSettings } from '../../../shared/models/api/api-settings';
import { FileResultModel } from '../../../shared/models/fileresult.model';
import { GetDiscussionMessagesApiResponse } from '../../models/api/get-discussion-messages.apiresponse';
import { GetDiscussionsApiResponse } from '../../models/api/get-discussions.apiresponse';
import { GetUnreadCountApiResponse } from '../../models/api/get-unread-count.apiresponse';
import { LireDiscussionApiRequest } from '../../models/api/lire-discussion.apirequest';
import { TypeInterventionApiResponse } from '../../models/api/type-intervention.apiresponse';
import { DiscussionStatusEnum } from '../../models/discussion-status.enum';
import { ExportSuiviQualiteRequest } from '../../models/export-suivi-qualite-request.model';

@Injectable()
export class DiscussionApiService {

  private controllerUrl: string = 'discussion';

  constructor(private apiSettings: ApiSettings,
              private _http: HttpClient) {
  }

  /** Type Maintenance */
  public getTypeInterventionsForContrat(cuisineCentraleId: number, contratId: number): Observable<TypeInterventionApiResponse.TypeIntervention[]> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/gettypeinterventions?contratId=${contratId}&cuisineCentraleId=${cuisineCentraleId}`;
    return this._http.get<TypeInterventionApiResponse.TypeIntervention[]>(url);
  }

  /** Discussion & Messages */
  public sendNewDiscussion(cuisineCentraleId: number, restaurantId: number, typeInterventionId: number, sujet: string, message: string, files: File[], typeMaintenanceId?: number): Observable<boolean> {
    const formData = new FormData();
    formData.append('restaurantId', restaurantId.toString());
    formData.append('cuisineCentraleId', cuisineCentraleId.toString());
    formData.append('typeInterventionId', typeInterventionId.toString());
    if (typeMaintenanceId != null) {
      formData.append('typeMaintenanceId', typeMaintenanceId.toString());
    }
    formData.append('sujet', sujet);
    formData.append('message', message);
    for (const file of files) {
      formData.append(`documents`, file, file.name);
    }

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/nouvellediscussion`;
    return this._http.post(url, formData).pipe(map(r => true));
  }

  /** Discussion & Messages */
  public requalifierDiscussion(discussionId: number, typeInterventionId: number, typeMaintenanceId?: number): Observable<boolean> {
    const formData = new FormData();
    formData.append('discussionId', discussionId.toString());
    formData.append('typeInterventionId', typeInterventionId.toString());
    if (typeMaintenanceId != null) {
      formData.append('typeMaintenanceId', typeMaintenanceId.toString());
    }

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/requalifierdiscussion`;
    return this._http.post(url, formData).pipe(map(r => true));
  }

  public getDiscussions(statut: DiscussionStatusEnum, cuisineCentraleId: number | null, contratId: number | null, restaurantId: number | null, dateDebut: Date, dateFin: Date, searchFilter: string)
    : Observable<GetDiscussionsApiResponse.Discussion[]> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/getdiscussions`;
    let httpParams = new HttpParams()
      .set('statut', statut.toString());
    if (cuisineCentraleId) {
      httpParams = httpParams.set('cuisineCentraleId', cuisineCentraleId.toString());
    }
    if (contratId) {
      httpParams = httpParams.set('contratId', contratId.toString());
    }
    if (restaurantId) {
      httpParams = httpParams.set('restaurantId', restaurantId.toString());
    }
    httpParams = httpParams.set('dateDebut', DateHelper.toISODateString(dateDebut));
    httpParams = httpParams.set('dateFin', DateHelper.toISODateString(dateFin));
    if (searchFilter != null) {
      httpParams = httpParams.set('searchFilter', searchFilter);
    }

    return this._http.get<GetDiscussionsApiResponse.Discussion[]>(url, { params: httpParams });
  }

  public getDiscussionMessages(discussionId: number): Observable<GetDiscussionMessagesApiResponse.Message[]> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/getMessages?discussionId=${discussionId}`;
    return this._http.get<GetDiscussionMessagesApiResponse.Message[]>(url);
  }

  public sendNewMessage(discussionId: number, texte: string, cloturerDiscussion: boolean, files: File[]): Observable<GetDiscussionMessagesApiResponse.Message> {
    const formData = new FormData();
    formData.append('cloturerDiscussion', cloturerDiscussion.toString());
    formData.append('texte', texte);
    formData.append('discussionId', discussionId.toString());
    for (const file of files) {
      formData.append(`documents`, file, file.name);
    }
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/nouveaumessage`;
    return this._http.post<GetDiscussionMessagesApiResponse.Message>(url, formData);
  }

  public lireDiscussion(discussionId: number): Observable<boolean> {
    const payload: LireDiscussionApiRequest = {
      discussionId
    };
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/lireDiscussion?discussionId=${discussionId}`;
    return this._http.post(url, payload).pipe(map(res => {
      return true;
    }));
  }

  public getUnreadCount(): Observable<GetUnreadCountApiResponse.CountResult> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/getunreadcount`;
    return this._http.get<GetUnreadCountApiResponse.CountResult>(url);
  }

  public getThumbnailSrc(documentId: number) {
    return `${this.apiSettings.apiUrl}/${this.controllerUrl}/getdocumentthumbnail/${documentId}`;
  }

  public getDocument(documentId: number): Observable<FileResultModel> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/getDocument/${documentId}`;
    return FileHelper.getFile(this._http, url);
  }

  exportEnquetesSatisfaction(request: ExportSuiviQualiteRequest): Observable<FileResultModel> {
    let params = new HttpParams();
    if (request.grandCompteId) {
      params = params.append('grandCompteId', String(request.grandCompteId));
    }
    if (request.cuisineCentraleId) {
      params = params.append('cuisineCentraleId', String(request.cuisineCentraleId));
    }
    if (request.contratId) {
      params = params.append('contratId', String(request.contratId));
    }
    if (request.dateDebut) {
      params = params.append('dateDebut', DateHelper.toISODateString(request.dateDebut));
    }
    if (request.dateFin) {
      params = params.append('dateFin', DateHelper.toISODateString(request.dateFin));
    }

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/ExportSuiviQualite`;
    return FileHelper.getFile(this._http, url, params);
  }
}
