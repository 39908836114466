import { RestaurantTypeEnum } from '../../../shared/enums/restaurant-type.enum';
import { ContactModel } from '../../models/contact.model';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactListComponent {
  @Input() contacts: ContactModel[];
  @Input() restType: RestaurantTypeEnum;
}