import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { RestaurantTypeHelper, RestaurantColorClasses } from '../helpers/restaurant-type.helper';
import { RestaurantTypeEnum } from '../enums/restaurant-type.enum';
import { values as objectValues } from 'lodash';

interface Data {
  type: RestaurantTypeEnum;
  isSecondary: boolean;
  isBlack: boolean;
  background: boolean;
  border: boolean;
  color: boolean;
  fill: boolean;
  hover: boolean;
}

@Directive({ selector: '[restaurantColor]' })
export class RestaurantTypeColorDirective {
  data: Data = undefined;
  // remove this and use the data above
  isSecondary: boolean = false;
  isBlack: boolean = false;
  type: RestaurantTypeEnum = undefined;
  hover: boolean = false;

  constructor(public el: ElementRef, private renderer: Renderer2) {
  }

  @Input()
  set restaurantColor(data: Data) {

    if (!data) {
      this.clearAll();
      return;
    }

    if (data.type == undefined) {
      data.type = RestaurantTypeEnum.Adulte;
    }

    if (data === this.data) {
      // no need to redraw, include isVisible next
      return;
    }

    this.hover = data.hover || false;

    // clear old if defined
    if (this.type != undefined) {
      this.clear(RestaurantTypeHelper.getClasses(this.type, this.hover));
    }

    this.apply(RestaurantTypeHelper.getClasses(data.type, this.hover), data.background, data.border, data.color, data.fill);

    if (this.isSecondary != data.isSecondary) {
      this.handleSecondary(data.isSecondary);
    }

    if (this.isBlack != data.isBlack) {
      this.handleBlack(data.isBlack);
    }

    this.isSecondary = data.isSecondary;
    this.isBlack = data.isBlack;
    this.type = data.type;
    this.data = data;
  }

  handleSecondary(isSec: boolean) {
    this.toggleClass('secondary', isSec);
  }

  handleBlack(isBlack: boolean) {
    this.toggleClass('black', isBlack);
  }

  clear(data: RestaurantColorClasses) {
    this.toggleClass(data.backgroundClass, false);
    this.toggleClass(data.borderClass, false);
    this.toggleClass(data.colorClass, false);
    this.toggleClass(data.fillClass, false);
  }

  clearAll() {
    // Compat IE11 //    Object.values(RestaurantTypeEnum).forEach(restoType => {
    // avec angular 8 on devrait avoir une meilleur compat avec CoreJs v3 =>
    objectValues(RestaurantTypeEnum)
      .map(v => (v as any) as RestaurantTypeEnum)
      .forEach(restoType => {
        this.clear(RestaurantTypeHelper.getClasses(restoType, true));
        this.clear(RestaurantTypeHelper.getClasses(restoType, false));
      });
  }

  apply(data: RestaurantColorClasses, background: boolean = false, border: boolean = false, color: boolean = false, fill: boolean = false) {
    this.toggleClass(data.backgroundClass, background);
    this.toggleClass(data.borderClass, border);
    this.toggleClass(data.colorClass, color);
    this.toggleClass(data.fillClass, fill);
  }

  private toggleClass(name: string, isAdd: boolean) {
    (isAdd ? this.renderer.addClass : this.renderer.removeClass).call(this.renderer, this.el.nativeElement, name);
  }
}
