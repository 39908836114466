import { Component, OnInit, OnChanges, Input, SimpleChanges } from "@angular/core";
import { BonLivraisonComposantModel } from "../../models/bon-livraison-composant.model";
import { InteractionService } from "../../../shared/services/interaction.service";

@Component({
  selector: "app-composants-livres",
  templateUrl: "./composants-livres.component.html",
  styleUrls: ["./composants-livres.component.scss"]
})
export class ComposantsLivresComponent implements OnInit, OnChanges {


  @Input("composant")
  public composant: BonLivraisonComposantModel;

  @Input("isModifiable")
  public isModifiable: boolean;

  private _originalQuantiteLivreeRelative?: number;
  public get HasChanged(): boolean{
    return this.composant && this.composant.quantiteLivreeRelative != this._originalQuantiteLivreeRelative;
  }

  public get formattedQuantiteLivreeRelative(): string {
    //   let qte = this.composant && this.composant.quantiteLivreeRelative || 0;
    //   if (qte == 0)
    //     qte = this.composant.quantite || 0;
    //   return ((qte > 0 && this.composant.quantiteLivreeRelative != 0) ? "+" : "") + qte;
    const qte = this.composant && this.composant.quantiteLivreeRelative || 0;
    return (qte > 0 ? "+" : "") + qte;
  }
  public set formattedQuantiteLivreeRelative(value: string) {
    // parse
    const qte = parseFloat(value);
    if (this.composant)
      this.composant.quantiteLivreeRelative = qte;
  }

  constructor() { }

  ngOnInit() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["composant"]) {
      this._originalQuantiteLivreeRelative = this.composant.quantiteLivreeRelative;
    }
  }

  manqueComposant(relative: number) {
    // 0n bloque la declaration au nombre desiré
    // (cad si on devait recevoir 5 yaourt, on ne peut se plaindre de -8 yaourt, le + lui n'est pas bloquer)

    let testerPositivite = true;
    if (this.composant.quantiteLivreeRelative < 0)
      testerPositivite = false;

    this.composant.quantiteLivreeRelative += relative;

    if (relative < 0 && Math.abs(this.composant.quantiteLivreeRelative) > this.composant.quantite) {
      if (!testerPositivite)
        this.composant.quantiteLivreeRelative = this.composant.quantite * -1;
      else
        this.composant.quantiteLivreeRelative += relative;
    }

  }
}

