import { DateLivraisonApiModel } from "./models/api/date-livraison.apimodel";
import { DateLivraisonModel } from "./models/date-livraison.model";
import { CommandeModel } from "./models/commande.model";
import { CommandeApiModel } from "./models/api/commande.apimodel";
import { EtatCommandeEnum, HistoryCommandeModel } from "./models/history-commande.model";
import {
    TypeListArticleFrequenceEnum,
    TypeListeArticleModel,
    TypeListeArticlesModel,
    TypeListeEnum
} from "./models/type-liste-articles.model";
import { TypeListeArticlesApiModel, TypeListeArticleApiModel } from "./models/api/type-liste-articles.apimodel";
import { ArticleModel, CartArticleModel } from "./models/article.model";
import { ArticleApiModel, ArticleDetailApiModel } from "./models/api/article.apimodel";
import { GetCommandesApiResponse } from "./models/api/get-commandes.apiresponse";

export class EpicerieInventaireProjector {
    public static projectArticleToCartArticle(article: ArticleModel, quantity: number): CartArticleModel {
        return {
            id: article.id,
            libelle: article.libelle,
            quantity: quantity,
            unite: article.unite,
            unitPrice: article.unitPrice,
            qteLimite: article.qteLimite
        };
    }

    public static projectArticleDetailToCartArticle(article: ArticleDetailApiModel): CartArticleModel {
        return {
            id: article.id,
            libelle: article.libelle,
            quantity: article.quantite,
            unite: article.unite,
            unitPrice: article.prix,
            qteLimite: article.qteLimite
        };
    }

    public static projectTypeListeArticles(typeListeArticle: TypeListeArticlesApiModel): TypeListeArticlesModel {
        return {
            typesListeArticleEpicerie: typeListeArticle.typesListeArticleEpicerie.map(tl => this.projectTypeListArticle(tl)),
            typesListeArticleInventaire: typeListeArticle.typesListeArticleInventaire.map(tl => this.projectTypeListArticle(tl))
        };
    }


    public static projectTypeListArticle(typeListeApi: TypeListeArticleApiModel): TypeListeArticleModel {
        return {
            estGratuit: typeListeApi.estGratuit,
            frequence: TypeListArticleFrequenceEnum[typeListeApi.frequence],
            id: typeListeApi.id,
            libelle: typeListeApi.libelle,
            validationRequise: typeListeApi.validationRequise,
            actif: typeListeApi.actif,
            type: TypeListeEnum[typeListeApi.type],
            dateDebutInventaire: typeListeApi.dateDebutInventaire,
            dateFinInventaire: typeListeApi.dateFinInventaire,
        };
    }

    public static projectCommandesResponseToHistoryCommande(commandesResponse: GetCommandesApiResponse.Commande[])
        : HistoryCommandeModel[] {
        return commandesResponse.map(commande => {
            const historyCommande = new HistoryCommandeModel();
            historyCommande.id = commande.id;
            historyCommande.numberArticles = commande.nombreArticles;
            historyCommande.canEdit = commande.peutModifier;
            historyCommande.canDuplicate = commande.peutCopier;
            historyCommande.canDelete = commande.peutSupprimer;
            historyCommande.restaurant = commande.restaurant;


            historyCommande.dateLivraison = new Date(commande.dateLivraison);
            historyCommande.dateCommande = new Date(commande.dateCommande);
            historyCommande.estTransmise = commande.estTransmise;

            historyCommande.estValidee = commande.estValidee;

            // Compute de l'état
            if (historyCommande.estTransmise) {
                historyCommande.etat = EtatCommandeEnum.Transmise;
            }
            else if (commande.estValidee === true && !commande.estTransmise) {
                historyCommande.etat = EtatCommandeEnum.ValideeNonTransmise;
            }
            else if (commande.estValidee === false) {
                historyCommande.etat = EtatCommandeEnum.EnAttenteValidation;
            }

            return historyCommande;
        });
    }

    public static projectCommande(commande: CommandeApiModel): CommandeModel {
        const model = new CommandeModel();
        model.id = commande.id;
        model.cuisineCentraleId = commande.cuisineCentraleId;
        model.restaurantId = commande.restaurantId;
        model.typeListeArticleId = commande.typeListeArticleId;
        model.dateCommande = commande.dateCommande;
        model.dateValidation = commande.dateValidation;
        model.dateLivraison = commande.dateLivraison;
        model.dateReception = commande.dateReception;
        model.validationRequise = commande.validationRequise;
        model.articles = commande.articles.map(art => {
            return this.projectArticleDetailToCartArticle(art);
        });
        return model;
    }

    public static projectArticles(articles: ArticleApiModel[]): ArticleModel[] {
        return articles.map(article => {
            const model = new ArticleModel();
            model.id = article.id;
            model.libelle = article.libelle;
            model.unite = article.unite;
            model.unitPrice = article.prix;
            model.qteLimite = article.qteLimite;
            return model;
        });
    }

    public static projectArticleDetails(articles: CartArticleModel[]): ArticleDetailApiModel[] {
        return articles.map(article => {
            const model = new ArticleDetailApiModel();
            model.id = article.id;
            model.libelle = article.libelle;
            model.unite = article.unite;
            model.prix = article.unitPrice;
            model.quantite = article.quantity;
            model.qteLimite = article.qteLimite;
            return model;
        });
    }

    public static projectDateLivraisons(dateLivraisons: DateLivraisonApiModel[]): DateLivraisonModel[] {
        return dateLivraisons.map(date => {
            return <DateLivraisonModel>date;
        });
    }
}
