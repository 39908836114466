/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/directives/restaurant-type-color.directive";
import * as i3 from "./menu-item.component";
import * as i4 from "@angular/router";
import * as i5 from "../shared/services/log.service";
var styles_MenuItemComponent = [i0.styles];
var RenderType_MenuItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuItemComponent, data: {} });
export { RenderType_MenuItemComponent as RenderType_MenuItemComponent };
export function View_MenuItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "tile"]], [[2, "menu-link-active", null], [2, "menu-link-disabled", null], [8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[2, "specialHeight", null], [8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "link-hover"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpod(6, { "type": 0, "background": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_6 = _ck(_v, 6, 0, _co.restaurantType, true); _ck(_v, 5, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isActive(); var currVal_1 = !_co.isEnabled(); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.item.description, ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.item.isSpecialHeight; var currVal_4 = _co.item.icon; _ck(_v, 1, 0, currVal_3, currVal_4); var currVal_5 = _co.item.name; _ck(_v, 3, 0, currVal_5); }); }
export function View_MenuItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-item", [], null, null, null, View_MenuItemComponent_0, RenderType_MenuItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.MenuItemComponent, [i4.Router, i5.LogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuItemComponentNgFactory = i1.ɵccf("app-menu-item", i3.MenuItemComponent, View_MenuItemComponent_Host_0, { item: "item", restaurantType: "restaurantType" }, { onNavigated: "onNavigated" }, []);
export { MenuItemComponentNgFactory as MenuItemComponentNgFactory };
