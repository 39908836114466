import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { BonLivraisonPlatModel } from '../../models/bon-livraison-plat.model';
import { BonLivraisonSettingsModel } from '../../models/bon-livraison-settings.model';
import { toNumber, round } from 'lodash';
import { BonLivraisonTemperatureStatus } from '../../models/bon-livraison-temperature-status.model';
import { BonLivraisonService } from '../../services/bon-livraison.service';

@Component({
  selector: 'app-livraison-temperature',
  templateUrl: './livraison-temperature.component.html',
  styleUrls: ['./livraison-temperature.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LivraisonTemperatureComponent implements OnInit {

  // Calcule la conformité de la température
  public get temperatureStatus(): BonLivraisonTemperatureStatus {
    if (!this.settings || !this.plat) { return BonLivraisonTemperatureStatus.NA; }

    return this._bonLivraisonService.computeTemperatureStatus(this.plat.livraisonTemperature, this.settings);
  }

  @Input()
  public plat: BonLivraisonPlatModel;
  @Input()
  public settings: BonLivraisonSettingsModel;

  @Input()
  public isModifiable: boolean = true;
  @Input()
  public step: number = 0.1;

  // declare enum property to allow use in component template. see: http://stackoverflow.com/a/40034109/590741
  public TemperatureStatusEnum: typeof BonLivraisonTemperatureStatus = BonLivraisonTemperatureStatus; // tslint:disable-line: variable-name

  constructor(private _bonLivraisonService: BonLivraisonService) { }

  ngOnInit() { }

  public incrementTemperature(increment: number): void {
    let newTemperature = (this.plat.livraisonTemperature === null)
      ? 0  // au premier incrément on défini à zéro
      : (this.plat.livraisonTemperature + increment);
    // on utilise lodash pour faire l'arrondi pour éviter certaines erreurs avec les floats javascript. see:https://www.jacklmoore.com/notes/rounding-in-javascript/
    newTemperature = round(newTemperature, 1);
    this.plat.livraisonTemperature = newTemperature;
  }

  public parseTemperature(value: string) {
    value = (value || '').replace('°C', '').replace(',', '.');
    value = value.trim();
    const temp = (value.length > 0) ? toNumber(value) : null; // NaN si contient du text
    this.plat.livraisonTemperature = isNaN(temp) ? null : round(temp, 1);
  }
}

