<app-loading-overlay #mainLoadingOverlay></app-loading-overlay>
<app-popup></app-popup>
<odg-bad-evaluation [currentType]="currentType"
                    *ngIf="badOdgEvaluation"
                    [badEvaluation]="badOdgEvaluation"
                    (badEvaluationClose)="onBadEvaluationClose()">
</odg-bad-evaluation>
<app-popup-custom></app-popup-custom>
<app-popup-confirm></app-popup-confirm>
<!-- <app-popup-bon-livraison [currentType]="currentType"></app-popup-bon-livraison> -->
<!-- <app-popup-search-code-barre [currentType]="currentType"></app-popup-search-code-barre> -->
<app-layout-header [menuList]="headerMenuList"
                   [logoContratUrl]="logoContratUrl"
                   [contrats]="contrats"
                   [restaurant]="currentRestaurant"
                   (onShowDrawer)="onShowDrawer()"
                   (selectedRestaurantContextChange)="selectedRestaurantContextChanged($event)"
                   [showDebugInfo]="isUserAdmin"
                   *ngIf="isUserLogged && !isLoginPage"></app-layout-header>
<app-drawer [menuList]="menuList"
            [logoContratUrl]="logoContratUrl"
            [contrats]="contrats"
            [restaurant]="currentRestaurant"
            (selectedRestaurantContextChange)="selectedRestaurantContextChanged($event)"
            [showDebugInfo]="isUserAdmin"
            *ngIf="isUserLogged && !isLoginPage"></app-drawer>
<router-outlet></router-outlet>
