import { Injectable, Type } from '@angular/core';

import { InteractionService } from './interaction.service';
import { IPromptForNavigation } from '../interfaces/iprompt-for-navigation';

@Injectable()
export class NavigationService {
    private components: IPromptForNavigation[] = [];
    constructor(private interactionService: InteractionService) {
    }

    /** Enregistre le composant pour le isAnyComponentDirty */
    public registerComponent(component: IPromptForNavigation) {
        this.components.push(component);
    }

    /** Sur le Destroy du composant, on le dé-référence, quand on naviguera dessus on aura une nouvelle instance */
    public unregisterComponent(component: IPromptForNavigation) {
        const index = this.components.indexOf(component, 0);
        if (index > -1) {
            this.components.splice(index, 1);
        }
    }

    public isAnyComponentDirty(): boolean {
        return this.components && this.components.some(c => c.checkIsDirty());
    }

}
