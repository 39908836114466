import { BaseJourModel } from '../../shared/models/base-jour.model';
export class OdgModel {
    odgConvives: OdgConviveModel[];
    odgJours: OdgJourModel[];
    restaurantId: number;
    odgRestaurantId: number;
    odgContratId: number;
}

export class OdgConviveModel {
    id: number;
    libelle: string;
}

export class OdgJourModel extends BaseJourModel {
    observations: string;
    autoriserSaisie: boolean;
    odgPrestations: OdgPrestationModel[];

    setIfEmpty() {
        this.isEmpty = false;
    }
}

export class OdgPrestationModel {
    id: number;
    isCollapsed: boolean;
    libelle: string;
    odgEvaluations: OdgEvaluationModel[]
}

export class OdgEvaluationModel {
    id: number;
    menuDetailId: number;
    platId: number;
    libelle: string;
    assaisonnement: boolean;
    consistance: boolean;
    aspect: boolean;
    autre: boolean;
    autreRaison: string;
    note: OdgEvaluationNote;
    autoriserSaisie: boolean;
}

export enum OdgEvaluationNote {
    None,
    TresBienConsomme,
    PlutotBienConsomme,
    PasConsomme,
    PasConsommeDuTout
}