
import { filter } from 'rxjs/operators';

import { AnalyticsDimensions } from "../models/analytics-dimensions.model";
import { AnalyticsProviderBase } from "./analytics-provider-base";
import { Injectable } from "@angular/core";

import { AppInsights } from "applicationinsights-js"

import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd, NavigationStart, NavigationError } from "@angular/router";

@Injectable()
export class AppInsightsAnalyticsProvider extends AnalyticsProviderBase {
  loadStartTime: number = null;
  loadTime: number = null;

  constructor(
    private title: Title,
    private router: Router) {
    super();

    this.router.events.pipe(
      filter(event => event instanceof NavigationStart))
      .subscribe(event => this.startTimer());

    this.router.events.pipe(
      filter(event =>
        (event instanceof NavigationError) ||
        (event instanceof NavigationEnd)
      ))
      .subscribe(error => this.stopTimer());
  }

  startTimer() {
    this.loadStartTime = Date.now();
    this.loadTime = null;
  }

  stopTimer() {
    this.loadTime = Date.now() - this.loadStartTime;
    this.loadStartTime = null;
  }

  pageTrack_internal(path: string, dimensions: AnalyticsDimensions) {
    AppInsights.trackPageView(
      this.title.getTitle(),
      path,
      dimensions,
      null, // metrics
      this.loadTime // DDJ : j'ai un doute sur ce paramètre : load time ou temps resté sur la vue ?
    );
  }

  eventTrack_internal(action: string, properties: any, dimensions: AnalyticsDimensions) {
    AppInsights.trackEvent(action, Object.assign({}, properties, dimensions));
  }

  setUserId_internal(userId: string) {
    AppInsights.setAuthenticatedUserContext(userId);
  }

  clear() {
    AppInsights.clearAuthenticatedUserContext();
  }
}
