/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./dropdown.component";
var styles_DropdownComponent = [i0.styles];
var RenderType_DropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropdownComponent, data: {} });
export { RenderType_DropdownComponent as RenderType_DropdownComponent };
function View_DropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[2, "selected", null], [8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onItemClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit == _co.selectedItem); var currVal_1 = _co.applyStyle(_v.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_DropdownComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "button", [["class", "dropbtn"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "dropdown-content"]], [[2, "is-open", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.items; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.applyStyle(_co.selectedItem); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isOpen; _ck(_v, 2, 0, currVal_1); }); }
export function View_DropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dropdown", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DropdownComponent_0, RenderType_DropdownComponent)), i1.ɵdid(1, 114688, null, 0, i3.DropdownComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DropdownComponentNgFactory = i1.ɵccf("app-dropdown", i3.DropdownComponent, View_DropdownComponent_Host_0, { items: "items", selectedItem: "selectedItem", fnStyle: "fnStyle" }, { selectedItemChange: "selectedItemChange" }, []);
export { DropdownComponentNgFactory as DropdownComponentNgFactory };
