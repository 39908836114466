import { SessionStorageService } from "./browser-storage.service";
import { Injectable } from "@angular/core";

const CORRELATIONTOKEN_STORAGEKEY = "CORRELATION_ID";

@Injectable()
export class CorrelationTokenService {

    private _correlationId: string;
    public get correlationId(): string {
        if (!this._correlationId) {
            this.correlationId = this._sessionStorageService.getString(CORRELATIONTOKEN_STORAGEKEY);
            if (!this._correlationId) {
                // set property with new correlationToken
                this.correlationId = this._createCorrelationId();
            }
        }
        return this._correlationId;
    }
    public set correlationId(correlationId: string) {
        this._correlationId = correlationId;
        this._sessionStorageService.setString(CORRELATIONTOKEN_STORAGEKEY, correlationId);
    }

    constructor(private _sessionStorageService: SessionStorageService) {
    }

    private _createCorrelationId(): string {
        return "_" + new Date().valueOf() + "_" + Math.random().toFixed(16).substring(2);
    }
}
