<ng-container *ngIf="currentNotification">
  <div class="popup" [ngClass]="{'visible': showPopup}">
    <div class="popup-content" [class.full-screen]="currentNotification.fullScreen">
      <div class="popup-header" [restaurantColor]="{'type': currentType, 'background':true}">
        <span class="pop-close close-cross" (click)="hide()"></span>
        <h3>{{currentNotification.title}}</h3>
      </div>
      <div class="popup-body" [class.over-hide]="currentNotification.hasNoOverflow">
        <popup-custom-content (onHideEmitter)='hide()' [innerComponentType]="currentNotification.type" [innerComponentData]="currentNotification.data"></popup-custom-content>
      </div>
      <div class="popup-footer" *ngIf="withButton">
        <button type="button" class="pop-close close-btn" [restaurantColor]="{ 'type': currentType, 'background':true}" (click)="hide()">Fermer</button>
      </div>
    </div>
  </div>
  <div class="popup-bckg" [ngClass]="{ 'visible': showPopup}" (click)="currentNotification.closeOnBackdropClick && hide()"></div>
</ng-container>
