import { Component, Input, OnInit, EventEmitter, Output, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ApplicationDataService } from '../../../shared/services/application-data.service';
import { AlerteModel } from '../../models/alertes.model';
import { ReceivedAlerteModel } from '../../models/received-alert.model';

@Component({
  selector: 'app-alerte',
  templateUrl: './alerte.component.html',
  styleUrls: ['./alerte.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush // on push pour éviter un redraw des Iframes
})
export class AlerteComponent implements OnInit {

  @Input()
  public alerte: AlerteModel;
  @Input()
  public showTitle: boolean = true;
  @Input()
  public receivedAlerte: ReceivedAlerteModel;
  @Output()
  public alerteDismissed: EventEmitter<ReceivedAlerteModel> = new EventEmitter<ReceivedAlerteModel>();

  public restaurantType$ = this._appDataService.restaurantType$;


  // public get safeMessage(): SafeHtml {
  //   return this.alerte && this._domSanitizer.bypassSecurityTrustHtml(this.alerte.message);
  // }
  // on passe par une variable privée pour éviter de regénérer un SafeHtml qui provoque le rechargement du innerHtml (et recharge une potentielle iframe)
  public safeMessage: SafeHtml;

  constructor(
    private _domSanitizer: DomSanitizer,
    private _appDataService: ApplicationDataService
  ) { }

  ngOnInit() {
    this.safeMessage = this.alerte && this._domSanitizer.bypassSecurityTrustHtml(this.alerte.message);
  }

  dismissAlerte() {
    // Alerte lue
    const wasUnread = !this.receivedAlerte.isRead;
    this.receivedAlerte.isRead = true;
    // on laisse un léger timing lorsque l'on coche valide la lecture d'une alerte => faire plutôt une animation
    setTimeout(() => this.alerteDismissed.next(this.receivedAlerte), wasUnread ? 300 : 1);
  }

}
