import { LogService } from './services/log.service';
import { CorrelationTokenService } from './services/correlationToken.service';
import { PrettyPriceComponent } from '../pretty-price/pretty-price.component';
import { FileTagListComponent } from '../file-tag-list/file-tag-list.component';
import { RepasJourVignetteDirective } from './directives/repas-jour-vignette.directive';
import { LoadingOverlayComponent } from '../loading-overlay/loading-overlay.component';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { LegalInfoComponent } from '../legal-info/legal-info.component';
import { ErrorPopupComponent } from '../popup/error-popup/error-popup.component';
import { WarningPopupComponent } from '../popup/warning-popup/warning-popup.component';
import { DateInputComponent } from '../date-input/date-input.component';
import { ContratNotificationPopupComponent } from '../popup/contrat-notification-popup/contrat-notification-popup.component';
import { DateSelectorComponent } from '../date-selector/date-selector.component';
import { ItemSelectorComponent } from '../item-selector/item-selector.component';
import { LoaderComponent } from '../loader/loader.component';

import { TranslateSliderDirective } from './directives/translate-slider.directive';
import { RestaurantTypeColorDirective } from './directives/restaurant-type-color.directive';
import { TranslateYSliderDirective } from './directives/translateY-slider.directive';
import { PaginationColorDirective } from './directives/pagination-color.directive';
import { SelectAllOnFocusDirective } from './directives/select-all-on-focus.directive';
import { InputNumberRangeDirective } from './directives/input-number-range.directive';
import { StickyBlockDirective } from './directives/sticky-block.directive';
import { TakePhotoDirective } from './directives/takePhoto.directive';

import { CurrencyEuroPipe } from './pipes/currency-euro.pipe';
import { ErrorPrettyDatePipe } from './pipes/error-pretty-date.pipe';
import { DateFormatPipe } from './pipes/dateFormat.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DocumentThumbnailComponent } from '../document-thumbnail/document-thumbnail.component';
import { CircleLoaderComponent } from '../circle-loader/circle-loader.component';
import { SwiperRestaurantThemeDirective } from './directives/swiper-restaurant-theme.directive';
import { ObjToArrayPipe } from './pipes/obj-to-array.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SwiperModule
  ],
  declarations: [
    // directives
    PaginationColorDirective,
    RestaurantTypeColorDirective,
    TranslateSliderDirective,
    TranslateYSliderDirective,
    SelectAllOnFocusDirective,
    InputNumberRangeDirective,
    StickyBlockDirective,
    RepasJourVignetteDirective,
    TakePhotoDirective,
    // pipes
    ErrorPrettyDatePipe,
    CurrencyEuroPipe,
    DateFormatPipe,
    TruncatePipe,
    ObjToArrayPipe,
    // component
    // PopupComponent,
    ContratNotificationPopupComponent,
    DropdownComponent,
    ErrorPopupComponent,
    WarningPopupComponent,
    DateInputComponent,
    LegalInfoComponent,
    DateSelectorComponent,
    LoaderComponent,
    ItemSelectorComponent,
    LoadingOverlayComponent,
    FileTagListComponent,
    DocumentThumbnailComponent,
    CircleLoaderComponent,
    PrettyPriceComponent,
    SwiperRestaurantThemeDirective
  ],
  exports: [
    // modules
    CommonModule,
    FormsModule,
    SwiperModule,
    // directives
    PaginationColorDirective,
    RepasJourVignetteDirective,
    RestaurantTypeColorDirective,
    TranslateSliderDirective,
    TranslateYSliderDirective,
    SelectAllOnFocusDirective,
    InputNumberRangeDirective,
    StickyBlockDirective,
    TakePhotoDirective,
    // pipes
    ErrorPrettyDatePipe,
    CurrencyEuroPipe,
    DateFormatPipe,
    TruncatePipe,
    ObjToArrayPipe,
    // component
    ContratNotificationPopupComponent, // TODO pourquoi exporter des composants qui ne sont pas dans le module shared?
    DropdownComponent,
    ErrorPopupComponent,
    WarningPopupComponent,
    LegalInfoComponent,
    DateInputComponent,
    DateSelectorComponent,
    LoaderComponent,
    ItemSelectorComponent,
    LoadingOverlayComponent,
    FileTagListComponent,
    DocumentThumbnailComponent,
    CircleLoaderComponent,
    PrettyPriceComponent,
    SwiperRestaurantThemeDirective,
  ],
  entryComponents: [
    LegalInfoComponent,
    ContratNotificationPopupComponent,
    ErrorPopupComponent,
    WarningPopupComponent
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        CorrelationTokenService,
        LogService
      ]
    };
  }

  constructor( @Optional() @SkipSelf() parentModule: SharedModule) {
    // https://angular.io/guide/singleton-services#prevent-reimport-of-the-greetingmodule
    if (parentModule) {
      throw new Error('SharedModule is already loaded. Import it in the AppModule only');
    }
  }
}
