/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./document-thumbnail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../circle-loader/circle-loader.component.ngfactory";
import * as i3 from "../circle-loader/circle-loader.component";
import * as i4 from "@angular/common";
import * as i5 from "./document-thumbnail.component";
import * as i6 from "../discussion/services/discussion.service";
import * as i7 from "../shared/services/interaction.service";
var styles_DocumentThumbnailComponent = [i0.styles];
var RenderType_DocumentThumbnailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentThumbnailComponent, data: {} });
export { RenderType_DocumentThumbnailComponent as RenderType_DocumentThumbnailComponent };
function View_DocumentThumbnailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.imageLoaded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageSource; _ck(_v, 0, 0, currVal_0); }); }
function View_DocumentThumbnailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-circle-loader", [["class", "loader"]], null, null, null, i2.View_CircleLoaderComponent_0, i2.RenderType_CircleLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.CircleLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_DocumentThumbnailComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "extension"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.document.extension; _ck(_v, 1, 0, currVal_0); }); }
export function View_DocumentThumbnailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentThumbnailComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentThumbnailComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "filename"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentThumbnailComponent_3)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageSource; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isLoading; _ck(_v, 3, 0, currVal_1); var currVal_3 = _co.isOther; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.document.nomFichier; _ck(_v, 5, 0, currVal_2); }); }
export function View_DocumentThumbnailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-document-thumbnail", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).downloadDocument() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DocumentThumbnailComponent_0, RenderType_DocumentThumbnailComponent)), i1.ɵdid(1, 114688, null, 0, i5.DocumentThumbnailComponent, [i6.DiscussionService, i7.InteractionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentThumbnailComponentNgFactory = i1.ɵccf("app-document-thumbnail", i5.DocumentThumbnailComponent, View_DocumentThumbnailComponent_Host_0, { document: "document" }, { imageLoadedEmitter: "imageLoaded" }, []);
export { DocumentThumbnailComponentNgFactory as DocumentThumbnailComponentNgFactory };
