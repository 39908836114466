<div class="bg-overlay">
</div>
<div class="bad-eval-container">
  <div class="bad-eval-content">
    <div class="inner-content">
      <span class="title">RAISON DE LA NON CONSOMMATION</span>
      <div class="checkboxCustom">
        <input [(ngModel)]="badEvaluation.assaisonnement" type="checkbox" id="assaisonnement" name="" />
        <label for="assaisonnement"><span>Assaisonnement</span></label>
      </div>
      <div class="checkboxCustom">
        <input [(ngModel)]="badEvaluation.consistance" type="checkbox" id="consistance" name="" />
        <label for="consistance"><span>Consistance</span></label>
      </div>
      <div class="checkboxCustom">
        <input [(ngModel)]="badEvaluation.aspect" type="checkbox" id="aspect" name="" />
        <label for="aspect"><span>Aspect</span></label>
      </div>
      <textarea class="simple-textarea" placeholder="Commentaire (3 caractères min.)" [(ngModel)]="badEvaluation.autreRaison"></textarea>
      <button [restaurantColor]="{'type': currentType, 'background':true, 'border':true}" [disabled]="!canValidate" class="generic-button validate-button" (click)="validate()">VALIDER</button>
    </div>
  </div>
</div>