import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CartArticleModel } from '../models/article.model';
import { EpicerieInventaireService } from '../services/epicerie-inventaire.service';


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {
  @Input()
  consult: boolean;

  @Input()
  isInventaire: boolean;

  /** subscriptions  */
  addSubscription: Subscription;

  /** getter & setter */
  get totalPrice(): number {
    const total = this.epicerieService.currentCartArticles
      .reduce((lastValue, article) => lastValue + (article.quantity * article.unitPrice), 0);
    return total;
  }

  public get articles(): CartArticleModel[] {
    return this.epicerieService.currentCartArticles || [];
  }

  /** Ctor & Lifecycle */
  constructor(private epicerieService: EpicerieInventaireService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  /** Events */
  deleteArticle(article: CartArticleModel) {
    const index = this.epicerieService.currentCartArticles.indexOf(article);
    if (index !== -1) {
      this.epicerieService.currentCartArticles.splice(index, 1);
    }
    this.epicerieService.isDirty = true;
  }

}
