import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

@Component({
  selector: 'select-autocomplete',
  templateUrl: './select-autocomplete.component.html',
  styleUrls: ['./select-autocomplete.component.scss']
})
export class SelectAutocompleteComponent {

  @ViewChild('dropdownInput', { static: true }) input: ElementRef;

  @Input()
  entityDisplayProp: string;

  @Input()
  entityDisplayFunc: ((entity: any) => string) | null;

  @Input()
  entity: any;

  @Input()
  entities: any[];

  @Input()
  showTooltip: boolean = true;


  @Output()
  entityChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _cd: ChangeDetectorRef
  ) { }

  public getEntities = (search: string): Observable<any[]> => {

    let filteredList: any[];

    if (search != null) {
      search = search.toLowerCase();
      filteredList = this.entities
        // && this.entities.filter(el => StringHelper.includes(this.entityFormatter(el), search))
        && this.entities.filter(el => this.entityFormatter(el).toLowerCase().indexOf(search) > -1) // bcp plus rapide
        || [];
    }
    else {
      filteredList = this.entities;
    }

    //console.log(`🔎 SelectAutocomplete Search for '${search}'`);

    // FIX bug dans le composant AutoComplete, il ne fait pas de check après la souscription à cette méthode
    // see: https://github.com/ng2-ui/auto-complete/blob/master/projects/auto-complete/src/lib/auto-complete.component.ts#L231
    // du coup on force le refresh de toute la grappe
    setTimeout(() => {
      this._cd.markForCheck();
    }, 50);

    return observableOf(filteredList);
  }

  entityChanged(newEntity: any) {
    // On ignore les strings pour l'instant car lorsqu'on ne clic pas sur une case c'est la string
    // et non l'objet qui est renvoyé. On pourrait faire du matching de nom par compairson de string par contre
    if (typeof (newEntity) === 'string') {
      if (newEntity === '') {
        this.input.nativeElement.value = this.entityFormatter(this.entity);
      }
      return;
    } else if (this.entity != newEntity) {
      // On met à jour l'input car le composant affiche undefined quand on clic sur un élément (à voir pourquoi pour enlever ça potentiellement)
      this.input.nativeElement.value = this.entityFormatter(newEntity);
      // On emit la nouvelle donnée
      this.entityChange.emit(newEntity);
    } else {
      this.input.nativeElement.value = this.entityFormatter(this.entity);
    }
  }

  onArrowClick(event: Event) {
    this.input.nativeElement.value = '';
    this.input.nativeElement.focus();

    this.forceAutoCompleteReloadList();
  }

  onInputFocus(event: FocusEvent) {
    this.input.nativeElement.value = '';

    this.forceAutoCompleteReloadList();
  }

  private forceAutoCompleteReloadList() {
    // raise 'Input' Event to force reload (without this, the autocomplete input requires at leat one key to display the dropdown).
    // see: https://github.com/ng2-ui/auto-complete/blob/master/projects/auto-complete/src/lib/auto-complete.directive.ts#L362

    //use setTimeout to operate after other js & angular events
    setTimeout(() => {
      // https://stackoverflow.com/a/35659572/590741
      var event = document.createEvent('Event');
      event.initEvent('input', true, true);
      this.input.nativeElement.dispatchEvent(event);
    }, 100);

  }

  entityFormatter(entity: any): string {
    return entity
      && (this.entityDisplayProp
        ? entity[this.entityDisplayProp] // display using entityDisplayProp
        : (this.entityDisplayFunc && this.entityDisplayFunc(entity)) // display using entityDisplayFunc
      ) || entity;
  }
}
