<form class="main-container" #form="ngForm">
  <div class="loader-container" *ngIf="isLoading">
    <app-loader message="Chargement..."></app-loader>
  </div>

  <div class="livraison-header">
    <label class="elior-label">Livraison le </label>
    <span class="heure-jour no-wrap">{{ livraisonDate | dateFormat:'DD/MM/YYYY à HH:mm' }}</span>
    <label class="elior-label"> sur la tournée </label>
    <!-- TODO https://netbasal.com/keeping-it-simple-implementing-edit-in-place-in-angular-4fd92c4dfc70 -->
    <span class="tournee no-wrap">'{{ model.bonLivraison.tournee }}'</span>
  </div>

  <p class="nonconformites-intro" *ngIf="hasPlatFaults">Vous avez relevé les non-conformités suivantes: </p>
  <div class="container-nonconformites" *ngIf="hasPlatFaults">
    <div class="container-nonconformite-temperature" *ngIf="hasNonConformiteTemperature">
      <h3>TEMPERATURE</h3>
      <div class="nonconformite-temperature-item" *ngFor="let ncPlat of nonConformiteTemperaturePlats">
        <div class="temperature temperature-color-{{ TemperatureStatusEnum[ncPlat.status] }}">{{ ncPlat.plat.livraisonTemperature | formatTemperature:'°C' }}</div>
        <div class="plat">{{ ncPlat.plat.libelle }}</div>
      </div>
    </div>
    <div class="container-nonconformite-dlc" *ngIf="hasNonConformiteDlc">
      <h3>DLC/DDM</h3>
      <div class="nonconformite-dlc-item" *ngFor="let ncPlat of nonConformiteDlcPlats">
        <div class="dlc dlc-color-{{ DLCStatusEnum[ncPlat.status] }}">{{ ncPlat.plat.livraisonDLC | dateFormat }}</div>
        <div class="plat">{{ ncPlat.plat.libelle }}</div>
      </div>
    </div>
  </div>


  <div class="livraison-signaturepad">
    <h3>SIGNATURE</h3>
    <ng-container *ngIf="allowLivraison; else elseDisplaySignatureImageTemplate">
      <app-livraison-signature></app-livraison-signature>
    </ng-container>
    <ng-template #elseDisplaySignatureImageTemplate>
      <div class="signature-image-container">
        <ng-container *ngIf="this.model.bonLivraison.livraisonSignature; else elseNoSignatureImageTemplate">
          <img src="{{this.model.bonLivraison.livraisonSignature}}" />
        </ng-container>
        <ng-template #elseNoSignatureImageTemplate>
          <span>Signature indisponible sur ce bon</span>
        </ng-template>
      </div>
    </ng-template>
  </div>

  <div class="commentaire-container" *ngIf="allowLivraison">
    <h3><label for="commentaire">COMMENTAIRE</label></h3>
    <textarea [(ngModel)]="commentaire" name="commentaireControl" #commentaireControl="ngModel" placeholder="Veuillez indiquer un commentaire / plan d'action" [required]="isCommentaireRequired" minlength="3" autocomplete="off" (focus)="scrollToElement(buttonContainer)" (input)="scrollToElement(buttonContainer)">
            </textarea>
    <div *ngIf="(form.submitted || form.dirty || form.touched ) && !commentaireControl.valid">
      <div *ngIf="commentaireControl.errors['required']" class="error-message">Le commentaire est requis.</div>
      <div *ngIf="commentaireControl.errors['minlength']" class="error-message">Le commentaire doit contenir au moins 3 caractères.</div>
    </div>
  </div>

  <div class="validation-error error-message" *ngIf="erreurValidationMessage != ''">
    {{ erreurValidationMessage }}
  </div>

  <div class="button-container" #buttonContainer>
    <button class="generic-button" (click)="hide()">Annuler</button>
    <button class="generic-button revoke-button red-button" (click)="DeclarerLivraison()" *ngIf="allowLivraison && hasPlatFaults">Non conforme</button>
    <button class="generic-button validate-button green-button" (click)="DeclarerLivraison()" *ngIf="allowLivraison && !hasPlatFaults">Conforme</button>
  </div>
</form>
