/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./meal-tile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./meal-tile.component";
import * as i3 from "@angular/router";
var styles_MealTileComponent = [i0.styles];
var RenderType_MealTileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MealTileComponent, data: {} });
export { RenderType_MealTileComponent as RenderType_MealTileComponent };
export function View_MealTileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.redirect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.icon; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.item.name; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.item.description; _ck(_v, 5, 0, currVal_2); }); }
export function View_MealTileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-meal-tile", [], null, null, null, View_MealTileComponent_0, RenderType_MealTileComponent)), i1.ɵdid(1, 114688, null, 0, i2.MealTileComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MealTileComponentNgFactory = i1.ɵccf("app-meal-tile", i2.MealTileComponent, View_MealTileComponent_Host_0, { item: "item", route: "route", imgSrc: "imgSrc", titleHTML: "titleHTML", descriptionHTML: "descriptionHTML" }, {}, []);
export { MealTileComponentNgFactory as MealTileComponentNgFactory };
