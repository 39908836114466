import { CartArticleModel } from "./article.model";

export class CommandeModel {
    id: number;
    cuisineCentraleId: number;
    restaurantId: number;
    typeListeArticleId: number;
    dateCommande: Date;
    dateValidation: Date;
    dateLivraison: Date;
    dateReception: Date;
    articles: CartArticleModel[];
    validationRequise: boolean;
}
