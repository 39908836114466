import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from 'src/app/shared/helpers/string.helper';

@Pipe({ name: 'formatTemperature' })
export class FormatTemperaturePipe implements PipeTransform {
  transform(value: number, unit: string = null): string {
    if (value != null) {
      const formatted = StringHelper.formatDecimal(value, 0, 2);
      return `${formatted}${unit ? ' ' + unit : ''}`;
    } else {
      return null;
    }
  }
}
