const MENU_IMG_BASE_FOLDER = "/assets/images/menu-icons/"
export class MenuLinkModel {
    icon: string;
    name: string;
    path: string;
    description: string;
    isInHeader: boolean
    isEnabled: boolean = true;
    isSpecialHeight: boolean;
    isInHomeTile: boolean

    /**  make this in projector + set this as interface*/
    constructor(path: string, isEnabled: boolean, name: string, description: string, isSpecialHeight: boolean, isInHeader: boolean, isInHomeTile: boolean, img: string = undefined) {
        this.name = name;
        this.path = path;
        this.isInHeader = isInHeader;
        this.isInHomeTile = isInHomeTile;
        this.description = description;
        this.isEnabled = isEnabled;

        let imgName = img || name;
        this.icon = `${MENU_IMG_BASE_FOLDER}${imgName}.png`;

        this.isSpecialHeight = isSpecialHeight;
    }
}