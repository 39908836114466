<div class="card">
  <div class="top">
    <div class="left">
      <div class="initials" [style.background-color]="initialsColor">
        {{initials}}
      </div>
    </div>
    <div class="right">
      <div class="name">
        <span class="lastname">
        {{contact.nom}}
        </span>
        <span class="firstname">
        {{contact.prenom}}
        </span>
      </div>
      <div class="email">
        <a [href]="'mailto:' +contact.email">{{contact.email}}</a>
      </div>
      <div class="job">
        {{contact.fonction}}
      </div>
    </div>
  </div>
  <div class="bottom">
    <div class="block mobile">
      <img *ngIf="contact.mobile" src="/assets/images/contact/mobile-icon.png" />
      <a [href]="'tel:' + contact.mobile">
        {{contact.mobile}}</a>
    </div>
    <div class="block phone">
      <img *ngIf="contact.telephone" src="/assets/images/contact/phone-icon.png" />
      <a [href]="'tel:' + contact.telephone">
        {{contact.telephone}}</a>
    </div>
  </div>
</div>