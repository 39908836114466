import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild, NgZone, ViewEncapsulation } from '@angular/core';
import SignaturePad, { IOptions as ISignaturePadOptions, IPointGroup } from 'signature_pad';
import { fromEvent } from 'rxjs';
import { outsideZone } from 'src/app/shared/rx/operators/outsideZone.operator';
import { untilDestroyed } from 'ngx-take-until-destroy';
// import SignaturePad, { IOptions as ISignaturePadOptions, IPointGroup } from 'signature_pad_bj';

// Inspiré de https://github.com/BioPhoton/angular-signature-pad/blob/master/src/libs/angular-signature-pad/src/components/signature-pad/signature-pad.component.ts
// et https://github.com/wulfsolter/angular2-signaturepad/blob/master/signature-pad.ts

@Component({
  selector: 'app-livraison-signature',
  templateUrl: './livraison-signature.component.html',
  styleUrls: ['./livraison-signature.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LivraisonSignatureComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('signatureCanvas', { static: true })
  private canvasRef: ElementRef;

  private canvas: HTMLCanvasElement;

  private container: HTMLElement;

  private signaturePad: SignaturePad;

  private _disabled: boolean;
  public get disabled(): boolean {
    return this._disabled;
  }
  @Input()
  public set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.renderViewDisabled();
  }
  private options: ISignaturePadOptions = {
    onEnd: (event) => {
      this.value = this.toData();
      this.valueChange.emit(this.value);
    }
  };

  private _value: IPointGroup[];
  public get value(): IPointGroup[] {
    return this._value;
  }
  @Input()
  public set value(value: IPointGroup[]) {
    this._value = value;
    this.renderViewValue(this._value);
  }

  @Output()
  public valueChange: EventEmitter<IPointGroup[]> = new EventEmitter<IPointGroup[]>();

  constructor(private ngZone: NgZone,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    this.initializeSignaturePad();
  }

  ngAfterViewInit(): void {
    // handle resize
    this.resizeSignaturePad();

    fromEvent(window, 'resize').pipe(
      outsideZone(this.ngZone),
      untilDestroyed(this),
    ).subscribe(event => {
      this.resizeSignaturePad();
    });
  }

  ngOnDestroy(): void {
    if (this.signaturePad) {
      this.signaturePad.off(); // cleanup internal document events subscriptions
    }
  }

  private resizeSignaturePad = (): void => {
    if (!this.canvas) {
      return;
    }

    this.resizeCanvas();

    // When the width or height of a canvas gets modified,
    // it will be automatically cleared by the browser.
    // How ever the data of the signature are still stores in the model value => this.value.
    // Because of this we have to reassign the value from this.value to the signaturePad.
    this.renderViewValue(this.value);

  }

  private renderViewValue(signatureData: IPointGroup[]) {
    if (!this.signaturePad) {
      return;
    }

    if (signatureData && signatureData.constructor === [].constructor) {
      this.signaturePad.fromData([...signatureData]);
    // } else if (signatureData && typeof signatureData === 'string') {
    //   this.fromDataURL(signatureData);
    } else {
      this.signaturePad.clear();
    }
  }

  private renderViewDisabled() {
    if (!this.canvas) {
      return;
    }

    this.renderer.setProperty(this.canvas, 'disabled', this.disabled);

    if (this.disabled) {
      this.renderer.addClass(this.canvas, 'disabled');
      this.signaturePad.off();
    } else {
      this.renderer.removeClass(this.canvas, 'disabled');
      this.signaturePad.on();
    }
  }

  protected initializeSignaturePad() {
    this.canvas = this.canvasRef.nativeElement;
    this.container = this.canvas.parentElement;
    this.container.className = this.container.className + ' signature-pad';

    this.signaturePad = new SignaturePad(this.canvas, this.options);
    // // onBegin
    // this.signaturePad.onBegin = this.onBeginWrapper;
    // // onEnd
    // this.signaturePad.onEnd = this.onEndWrapper;
    this.renderViewDisabled();
  }

  public resizeCanvas(): void {
    if (!this.canvas) {
      return;
    }
    // // When zoomed out to less than 100%, for some very strange reason,
    // // some browsers report devicePixelRatio as less than 1
    // // and only part of the canvas is cleared then.
    // const ratio: number = Math.max(window.devicePixelRatio || 1, 1);
    // const canvas: any = this.canvas; // this.signaturePad._canvas;
    // canvas.width = canvas.offsetWidth * ratio;
    // canvas.height = canvas.offsetHeight * ratio;
    // canvas.getContext('2d').scale(ratio, ratio);
    // // When the width or height of a canvas gets modified,
    // // it will be automatically cleared by the browser.
    // // So we have to call signaturePad.clear() to make sure
    // // that signaturePad.isEmpty() returns correct value in this case.
    // this.signaturePad.clear();

    interface ICssWidthHeight {
      top: number; bottom: number; left: number; right: number; fullHeight: number; fullWidth: number;
    }

    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    // So we will have at least 1 as ration.
    // const ratio = 1; // @TODO fix offset on ratios > 1. => Math.max(window.devicePixelRatio || 1, 1);

    // // information needed to calculate the available width and height
    // const canvasStyles = window.getComputedStyle(this.canvas, null);
    // const canvasBorder: ICssWidthHeight = {} as ICssWidthHeight;
    // canvasBorder.top = parseInt(canvasStyles.borderTopWidth, null) || 0;
    // canvasBorder.bottom = parseInt(canvasStyles.borderBottomWidth, null) || 0;
    // canvasBorder.left = parseInt(canvasStyles.borderLeftWidth, null) || 0;
    // canvasBorder.right = parseInt(canvasStyles.borderRightWidth, null) || 0;
    // canvasBorder.fullHeight = canvasBorder.top + canvasBorder.bottom;
    // canvasBorder.fullWidth = canvasBorder.left + canvasBorder.right;

    // const containerStyles = window.getComputedStyle(this.container, null);
    // const parentPadding: ICssWidthHeight = {} as ICssWidthHeight;
    // parentPadding.top = parseInt(containerStyles.paddingTop, null) || 0;
    // parentPadding.bottom = parseInt(containerStyles.paddingBottom, null) || 0;
    // parentPadding.left = parseInt(containerStyles.paddingLeft, null) || 0;
    // parentPadding.right = parseInt(containerStyles.paddingRight, null) || 0;
    // parentPadding.fullHeight = parentPadding.top + parentPadding.bottom;
    // parentPadding.fullWidth = parentPadding.left + parentPadding.right;

    // const withToSubtract = parentPadding.fullWidth + canvasBorder.fullWidth;
    // const heightToSubtract = parentPadding.fullHeight + canvasBorder.fullHeight;

    // // resize canvas

    // // get most right point of signature
    // let signatureWidth = 0;
    // const signatureData = this.toData();
    // if (this.value && [].constructor === this.value.constructor) {
    //   signatureWidth = signatureData
    //     .reduce((concated, arr) => concated.concat(arr), [])
    //     .reduce((mR, segment) => mR < segment.x ? segment.x : mR, 0);
    // }

    // // get most left point of signature
    // let signatureHeight = 0;
    // if (this.value && [].constructor === this.value.constructor) {
    //   signatureHeight = signatureData
    //     .reduce((concated, arr) => concated.concat(arr), [])
    //     .reduce((mL, segment) => mL < segment.y ? segment.y : mL, 0);
    // }

    // // calc new width and height
    // const newCanvasWidth = Math.max(this.container.clientWidth, signatureWidth);
    // const newCanvasHeight = Math.max(this.container.clientHeight, signatureHeight);

    // // adopt canvas scales
    // this.canvas.width = (newCanvasWidth - withToSubtract) * ratio;
    // this.canvas.height = (newCanvasHeight - heightToSubtract - 6) * ratio; // @TODO find the 6px
    // this.canvas.getContext('2d').scale(ratio, ratio);

    // // adopt show/hide scroll of vertical canvas container
    // this.container.style.overflowX = 'inherit';
    // if (this.canvas.width + withToSubtract > this.container.clientWidth) {
    //   this.container.style.overflowX = 'scroll';
    // }

    // // adopt show/hide scroll of horizontal canvas container
    // this.container.style.overflowY = 'inherit';
    // if (this.canvas.height > this.container.clientHeight) {
    //   this.container.style.overflowY = 'scroll';
    // }

    // When the width or height of a canvas gets modified,
    // it will be automatically cleared by the browser.
    // So we have to call signaturePad.clear() to make sure
    // that signaturePad.isEmpty() returns correct value in this case.
    this.signaturePad.clear();
  }

  // Returns signature image as an array of point groups
  public toData(): IPointGroup[] {
    return this.signaturePad.toData();
  }

  // Draws signature image from an array of point groups
  public fromData(points: IPointGroup[]): void {
    this.signaturePad.fromData(points);
  }

  // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible paramters)
  public toDataURL(imageType?: string, quality?: number): string {
    return this.signaturePad.toDataURL(imageType, quality); // save image as data URL
  }

  // Draws signature image from data URL
  public fromDataURL(dataURL: string, options: {
    ratio?: number;
    width?: number;
    height?: number;
  } = {}): void {
    // set default height and width on read data from URL
    if (!options.hasOwnProperty('height') && (this.options as any).canvasHeight) {
      options.height = (this.options as any).canvasHeight;
    }
    if (!options.hasOwnProperty('width') && (this.options as any).canvasWidth) {
      options.width = (this.options as any).canvasWidth;
    }
    this.signaturePad.fromDataURL(dataURL, options);
  }

  // Clears the canvas
  public clear(): void {
    this.signaturePad.clear();
  }

  // Returns true if canvas is empty, otherwise returns false
  public isEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }

}
