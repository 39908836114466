import { BaseJourModel } from '../../shared/models/base-jour.model';
export class PeriodeModel {
}
export class PortionModel {
}
export class FamillePlatModel {
}
export class PrestationModel {
}
export class JourModel extends BaseJourModel {
    constructor() {
        super(...arguments);
        this.periodeTooltipInfo = '';
    }
    setIfEmpty() {
        this.isEmpty = this.prestations.length === 0;
    }
}
export class RegimeDataModel {
}
export class ConviveModel {
}
export class LieuCommandeModel {
}
export class SemaineInfosModel {
}
export class CommandeRepasModel {
}
/** enums  */
export var ModeSaisieEnum;
(function (ModeSaisieEnum) {
    ModeSaisieEnum[ModeSaisieEnum["Standard"] = 0] = "Standard";
    ModeSaisieEnum[ModeSaisieEnum["Portion"] = 1] = "Portion";
})(ModeSaisieEnum || (ModeSaisieEnum = {}));
export var ModeSaisiePortionEnum;
(function (ModeSaisiePortionEnum) {
    ModeSaisiePortionEnum[ModeSaisiePortionEnum["LectureSeule"] = 0] = "LectureSeule";
    ModeSaisiePortionEnum[ModeSaisiePortionEnum["Quantite"] = 1] = "Quantite";
    ModeSaisiePortionEnum[ModeSaisiePortionEnum["TauxPrise"] = 2] = "TauxPrise";
})(ModeSaisiePortionEnum || (ModeSaisiePortionEnum = {}));
export var PeriodeContratTypeEnum;
(function (PeriodeContratTypeEnum) {
    PeriodeContratTypeEnum[PeriodeContratTypeEnum["Precommande"] = 0] = "Precommande";
    PeriodeContratTypeEnum[PeriodeContratTypeEnum["Commande"] = 1] = "Commande";
    PeriodeContratTypeEnum[PeriodeContratTypeEnum["Reajustement"] = 2] = "Reajustement";
    PeriodeContratTypeEnum[PeriodeContratTypeEnum["Reel"] = 3] = "Reel";
    PeriodeContratTypeEnum[PeriodeContratTypeEnum["HorsPeriode"] = 4] = "HorsPeriode";
})(PeriodeContratTypeEnum || (PeriodeContratTypeEnum = {}));
