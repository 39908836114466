
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { LegalInfoComponent } from '../legal-info/legal-info.component';
import { ApiSettings } from '../shared/models/api/api-settings';
import { NotificationCustomModel } from '../shared/models/notification.model';
import { InteractionService } from '../shared/services/interaction.service';
import { LogService } from '../shared/services/log.service';

@Component({
  selector: 'sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.scss']
})
export class SandboxComponent implements OnInit {

  public currentClock: string;
  private _clockDate: Date = new Date();

  public set clockDate(v: string) {
    const actualParsedDate = v ? new Date(v) : new Date();
    const normalizedParsedDate = new Date(actualParsedDate.getTime() + (actualParsedDate.getTimezoneOffset() * 60000));
    this._clockDate = normalizedParsedDate;
  }


  public get clockDate(): string {
    return this.parseDateToStringWithFormat(this._clockDate);
  }

  constructor(private _interactionService: InteractionService,
              private _logService: LogService,
              private _http: HttpClient,
              private _apiSettings: ApiSettings) {
  }

  ngOnInit(): void {
    this.refreshServerClock();
  }

  onButtonClick() {
    const notif: NotificationCustomModel = new NotificationCustomModel();
    notif.id = 1;
    notif.title = 'Mentions légales';
    notif.type = LegalInfoComponent;
    notif.fullScreen = true;

    this._interactionService.showNotification(notif);
  }

  throwException() {
    throw new Error('TEST EXCEPTION');
  }

  logInfo() {
    this._logService.Info('Test log d\'information.');
  }

  setClock() {
    const serverDate = this.clockDate && moment(this.clockDate).toDate();
    this.setServerClock(serverDate).subscribe(() => this.refreshServerClock());
  }

  resetClock() {
    this.setServerClock(null).subscribe(() => {
      this.refreshServerClock();
      this._clockDate = new Date();
    });
  }

  private refreshServerClock() {
    this.getServerClock().subscribe(result => this.currentClock = result);
  }

  private getServerClock() {
    const url = `${this._apiSettings.apiUrl}/debug/getclock`;
    return this._http.get(url, { responseType: 'text' });
  }

  private setServerClock(serverDate?: Date) {
    const serverDateQueryParam: string = (serverDate && moment(serverDate).format('YYYY-MM-DD hh:mma')) || '';
    const url = `${this._apiSettings.apiUrl}/debug/setclock/${serverDateQueryParam}`;
    return this._http.post(url, null, { responseType: 'text' });
  }

  private parseDateToStringWithFormat(date: Date): string {
    let result: string;
    let dd = date.getDate().toString();
    let mm = (date.getMonth() + 1).toString();
    let hh = date.getHours().toString();
    let min = date.getMinutes().toString();
    dd = dd.length === 2 ? dd : '0' + dd;
    mm = mm.length === 2 ? mm : '0' + mm;
    hh = hh.length === 2 ? hh : '0' + hh;
    min = min.length === 2 ? min : '0' + min;
    result = [date.getFullYear(), '-', mm, '-', dd, 'T', hh, ':', min].join('');

    return result;
  }

}
