import { parse as parseUrl } from 'url';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpStatusCode } from '../../../enums/http-status-code.enum';
import { ApiSettings } from '../../../models/api/api-settings';
import { AuthenticateApiRequest, AuthTokenApiRequest, AuthTokenApiResponse, GetMeApiResponse } from '../../../models/api/authentication';
import { SendPasswordApiRequest } from '../../../models/api/authentication/requests/send-password.apirequest';
import { ErrorApiModel, CheckIsErrorApiModel } from '../../../models/api/error/error.apimodel';
import { LogService } from '../../log.service';

const CONTROLLER_URL = 'oauth';

@Injectable()
export class AuthenticationApiService {

  private readonly LOGIN_ACTION_URL = `${this._apiSettings.apiUrl}/${CONTROLLER_URL}`;

  constructor(private _apiSettings: ApiSettings,
              private _http: HttpClient,
              private _logService: LogService) {
  }

  refresh(refreshToken: string): Observable<AuthTokenApiResponse.AuthData> {
    const url = `${this._apiSettings.apiUrl}/${CONTROLLER_URL}/refresh`;
    const request: AuthTokenApiRequest = { refreshToken };
    return this.postRequest<AuthTokenApiResponse.AuthData>(url, request);
  }

  login(username: string, password: string): Observable<AuthTokenApiResponse.AuthData> {
    const request: AuthenticateApiRequest = { username, password };
    return this.postRequest<AuthTokenApiResponse.AuthData>(this.LOGIN_ACTION_URL, request);
  }

  sendPassword(username: string): Observable<boolean> {
    const payload: SendPasswordApiRequest = { username };
    const url = `${this._apiSettings.apiUrl}/${CONTROLLER_URL}/sendpassword`;
    return this.postRequest(url, payload).pipe(
      map(res => {
        return true;
      }));
  }

  me(): Observable<GetMeApiResponse.UserInfo> {
    const url = `${this._apiSettings.apiUrl}/${CONTROLLER_URL}/me`;
    return this._http.get<GetMeApiResponse.UserInfo>(url);
  }

  private postRequest<R>(url: string, payload: any) {
    return this._http.post<R>(url, payload)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          return this.handleError(response);
        })
      );
  }

  private throwError(error: string, extraInfo: any = null) {
    const errorModel = new ErrorApiModel();
    errorModel.Message = error;
    errorModel.Content = extraInfo;
    this._logService.Error(JSON.stringify(errorModel), 'AuthenticationApiService');
    return observableThrowError(errorModel);
  }

  private handleError(response: HttpErrorResponse) {
    if (response.status === HttpStatusCode.ConnectionRefused) { // net::ERR_CONNECTION_REFUSED
      return this.throwError('Le serveur n\'est pas joignable, veuillez réessayer dans quelques instants.');
    }

    if (CheckIsErrorApiModel(response)) {
      return observableThrowError(response);
    }

    if (response.url) {
      const responseUrl = parseUrl(response.url);
      if (responseUrl.path === this.LOGIN_ACTION_URL && response.status === HttpStatusCode.Unauthorized) { // 401 (Unauthorized)
        return this.throwError('Utilisateur ou mot de passe non valide.');
      }
    }

    return this.throwError(response.message);
  }
}
