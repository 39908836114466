/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/pipes/currency-euro.pipe";
import * as i4 from "./cart.component";
import * as i5 from "../services/epicerie-inventaire.service";
var styles_CartComponent = [i0.styles];
var RenderType_CartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CartComponent, data: {} });
export { RenderType_CartComponent as RenderType_CartComponent };
function View_CartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "empty-cart"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Votre panier est vide"]))], null, null); }
function View_CartComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.unitPrice)); _ck(_v, 1, 0, currVal_0); }); }
function View_CartComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "delete pointerHover"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteArticle(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "/assets/images/epicerie-inventaire/trash2.png"]], null, null, null, null, null))], null, null); }
function View_CartComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "article"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "article-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "left restaurant-type-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CartComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CartComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (!_co.isInventaire && (_v.context.$implicit.unitPrice > 0)); _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.consult; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.quantity; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.libelle; _ck(_v, 5, 0, currVal_1); }); }
function View_CartComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "footer restaurant-type-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "span", [["class", "right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "total"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["TOTAL HT"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "totalPrice"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _co.totalPrice)); _ck(_v, 5, 0, currVal_0); }); }
export function View_CartComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.CurrencyEuroPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "cart-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "articles-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CartComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CartComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CartComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.articles.length == 0); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.articles; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.isInventaire; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_CartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cart", [], null, null, null, View_CartComponent_0, RenderType_CartComponent)), i1.ɵdid(1, 245760, null, 0, i4.CartComponent, [i5.EpicerieInventaireService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CartComponentNgFactory = i1.ɵccf("app-cart", i4.CartComponent, View_CartComponent_Host_0, { consult: "consult", isInventaire: "isInventaire" }, {}, []);
export { CartComponentNgFactory as CartComponentNgFactory };
