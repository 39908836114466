import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from './services/log.service';

@Injectable()
export class EliorErrorHandler extends ErrorHandler {
  constructor(private logService: LogService) {
    super();
  }

  handleError(error) {
    try {
      this.logService.Fatal(error, 'ErrorHandler');
    } catch (err) {
      // on loggue le problème de log
      this.logService.Fatal(err, 'ErrorHandlerError');
      // fallback vers la méthode générique
      super.handleError(error);
    }
  }
}
