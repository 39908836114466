import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BonLivraisonComponent } from './bon-livraison.component';
import { AuthGuard } from '../shared/guards/auth-guard';
import { DetailsBonLivraisonComponent } from './details-bon-livraison/details-bon-livraison.component';
import { LivraisonBonLivraisonComponent } from './livraison-bon-livraison/livraison-bon-livraison.component';

const routes: Routes = [
  {
    path: 'bonlivraison',
    component: BonLivraisonComponent,
    canActivate: [AuthGuard],
    data: { title: 'Réceptions' }
  },
  {
    path: 'bonlivraison/livraison/:codeBonLivraison',
    component: LivraisonBonLivraisonComponent,
    canActivate: [AuthGuard],
    data: { title: 'Livraison' }
  },
  {
    path: 'bonlivraison/details/:codeBonLivraison',
    redirectTo: 'bonlivraison/reception/:codeBonLivraison',
  },
  {
    path: 'bonlivraison/reception/:codeBonLivraison',
    component: DetailsBonLivraisonComponent,
    canActivate: [AuthGuard],
    data: { title: 'Réception' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class BonLivraisonRoutingModule { }
