<div class="date-selector">
  <div (click)="onPrevClick()" [class.disabled]="!isSwipePrevAllowed" class="button-prev">
    <div class="arrow">
    </div>
  </div>

  <div class="container">
    <div *ngIf="selectedRange" [translateYSlider]="dateRanges.indexOf(selectedRange)" class="date-container">
      <div *ngFor="let range of dateRanges" class="range">
        <div class="range-libelle" *ngIf="range.validJours.length == 0">{{selectedRange.libelle}}</div>
        <div *ngFor="let jour of range.validJours" [class.selected]="jour == selectedJour" [restaurantColor]="{'type': currentType, 'background':jour == selectedJour, 'border':true}"
          (click)="onJourClick(jour)" class="jour">
          <div class="jour-type" [jourType]="jour.periode"></div>
          <div class="dayString-container">
            <span *ngIf="jour.hasError" class="invalid-container">
              <img *ngIf="jour != selectedJour" class="invalid" src="/assets/images/repas/warning.png" />
              <img *ngIf="jour == selectedJour" class="invalid" src="/assets/images/repas/warning-white.png" />
            </span>
            <div class="rangeDayString">
              {{jour.dayShortString}}
            </div>
            <span *ngIf="jour.quantitePiqueNique != null && jour.quantitePiqueNique > 0" class="has-piquenique-container">
              <img *ngIf="jour != selectedJour" class="has-piquenique" src="/assets/images/repas/piquenique.png" />
              <img *ngIf="jour == selectedJour" class="has-piquenique" src="/assets/images/repas/piquenique-white.png" />
            </span>
          </div>
          <div class="rangeDay">
            {{jour.day}}
          </div>
          <div class="rangeMonth">
            {{jour.monthShort}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div (click)="onNextClick()" [class.disabled]="!isSwipeNextAllowed" class="button-next">
    <div class="arrow">
    </div>
  </div>
</div>
