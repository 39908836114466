/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./meal-slider.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../meal-resume/meal-resume.component.ngfactory";
import * as i3 from "../meal-resume/meal-resume.component";
import * as i4 from "../../../node_modules/ngx-swiper-wrapper/dist/ngx-swiper-wrapper.ngfactory";
import * as i5 from "ngx-swiper-wrapper";
import * as i6 from "../shared/directives/pagination-color.directive";
import * as i7 from "../shared/directives/swiper-restaurant-theme.directive";
import * as i8 from "@angular/common";
import * as i9 from "./meal-slider.component";
var styles_MealSliderComponent = [i0.styles];
var RenderType_MealSliderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MealSliderComponent, data: {} });
export { RenderType_MealSliderComponent as RenderType_MealSliderComponent };
function View_MealSliderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-meal-resume", [], null, null, null, i2.View_MealResumeComponent_0, i2.RenderType_MealResumeComponent)), i1.ɵdid(1, 114688, null, 0, i3.MealResumeComponent, [], { dailyPrestation: [0, "dailyPrestation"], currentType: [1, "currentType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.currentType; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_MealSliderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { componentRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "swiper", [["class", "swiper-custom-navigation"]], null, [[null, "indexChange"], [null, "init"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("indexChange" === en)) {
        var pd_0 = (true !== false);
        ad = (pd_0 && ad);
    } if (("init" === en)) {
        var pd_1 = (_co.forceBindingRefresh() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_SwiperComponent_0, i4.RenderType_SwiperComponent)), i1.ɵdid(2, 4374528, [[1, 4]], 0, i5.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i5.SWIPER_CONFIG]], { index: [0, "index"], config: [1, "config"] }, { indexChange: "indexChange", S_INIT: "init" }), i1.ɵdid(3, 278528, null, 0, i6.PaginationColorDirective, [i1.ElementRef], { paginationColor: [0, "paginationColor"] }, null), i1.ɵdid(4, 4210688, null, 0, i7.SwiperRestaurantThemeDirective, [i1.ElementRef, i1.Renderer2, [2, i5.SWIPER_CONFIG]], { config: [0, "config"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MealSliderComponent_1)), i1.ɵdid(6, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.initialIndex; var currVal_1 = _co.swiperConfig; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.currentType; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.swiperConfig; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.dailyPrestations; _ck(_v, 6, 0, currVal_4); }, null); }
export function View_MealSliderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-meal-slider", [], null, null, null, View_MealSliderComponent_0, RenderType_MealSliderComponent)), i1.ɵdid(1, 49152, null, 0, i9.MealSliderComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var MealSliderComponentNgFactory = i1.ɵccf("app-meal-slider", i9.MealSliderComponent, View_MealSliderComponent_Host_0, { dailyPrestations: "dailyPrestations", currentType: "currentType" }, {}, []);
export { MealSliderComponentNgFactory as MealSliderComponentNgFactory };
