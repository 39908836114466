/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./popup-custom-content.component";
var styles_PopupCustomContentComponent = [];
var RenderType_PopupCustomContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PopupCustomContentComponent, data: {} });
export { RenderType_PopupCustomContentComponent as RenderType_PopupCustomContentComponent };
export function View_PopupCustomContentComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { target: 0 }), (_l()(), i0.ɵeld(1, 16777216, [[1, 3], ["target", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_PopupCustomContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "popup-custom-content", [], null, null, null, View_PopupCustomContentComponent_0, RenderType_PopupCustomContentComponent)), i0.ɵdid(1, 1228800, null, 0, i1.PopupCustomContentComponent, [i0.ComponentFactoryResolver], null, null)], null, null); }
var PopupCustomContentComponentNgFactory = i0.ɵccf("popup-custom-content", i1.PopupCustomContentComponent, View_PopupCustomContentComponent_Host_0, { innerComponentType: "innerComponentType", innerComponentData: "innerComponentData" }, { onHideEmitter: "onHideEmitter" }, []);
export { PopupCustomContentComponentNgFactory as PopupCustomContentComponentNgFactory };
