import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ContactCardComponent } from './contact-card/contact-card.component';
import { ContactListComponent } from './contact-list/contact-list/contact-list.component';
import { ContactRoutingModule } from './contact-routing.module';
import { ContactComponent } from './contact.component';
import { ContactApiService } from './services/api/contact.apiservice';
import { ContactService } from './services/contact.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ContactRoutingModule
  ],
  declarations: [ContactComponent, ContactListComponent, ContactCardComponent],
  providers: [
    ContactApiService,
    ContactService,
  ]
})
export class ContactModule { }
