<div class="main-container">
  <div class="ribbon" [style.background-image]="'url(' + logoContratUrl + ')'">
  </div>
  <div class="page-container">
    <h1>Reporting</h1>
    <div class="reportings">

      <fieldset class="reporting">
        <legend><i class="material-icons restaurant-type-color">shopping_cart</i> ÉPICERIE & INVENTAIRE</legend>
        <div class="content">
          <ng-container *ngIf="!epicerieInventaireFilter.loadingData">
            <div *ngIf="epicerieInventaireFilter.downloading" class="loading-container overlay">
              <app-loader message="Téléchargement du rapport"></app-loader>
            </div>
            <ng-container *ngIf="!epicerieInventaireFilter.downloading">
              <h3>Recherche</h3>
              <div class="input-group dates-container">
                <app-date-input id="epicerie-start-date" name="epicerie-start-date" [(ngModel)]="epicerieInventaireFilter.startDate" [calendarInfo]="dateCalendarData" placeholder="Début"></app-date-input>
                <app-date-input id="epicerie-end-date" name="epicerie-end-date" [(ngModel)]="epicerieInventaireFilter.endDate" [calendarInfo]="dateCalendarData" placeholder="Fin"></app-date-input>
              </div>
              <div class="input-group type-container">
                <label>
                  <input type="radio" [(ngModel)]="epicerieInventaireFilter.type" (ngModelChange)="epicerieInventaireFilter.epicerieInventaireEmitter.next()" name="epicerie-inventaire-type" value="Epicerie" />
                  <span class="custom-radio"></span>
                  <span>Epicerie</span>
                </label>
                <label>
                  <input type="radio" [(ngModel)]="epicerieInventaireFilter.type" (ngModelChange)="epicerieInventaireFilter.epicerieInventaireEmitter.next()" name="epicerie-inventaire-type" value="Inventaire" />
                  <span class="custom-radio"></span>
                  <span>Inventaire</span>
                </label>
              </div>
              <select *ngIf="epicerieInventaireFilter.selectedTypeListeArticle" class="input-group selector" [(ngModel)]="epicerieInventaireFilter.selectedTypeListeArticle" name="selectedTypeListeArticle">
                <option *ngFor="let liste of epicerieInventaireFilter.selectedTypeListeArticles" [ngValue]="liste">{{liste.libelle}}</option>
              </select>
              <div *ngIf="!epicerieInventaireFilter.selectedTypeListeArticle">
                Il n'y a pas d'article correspondant
              </div>
            </ng-container>
            <div class="input-group bottom">
              <button class="generic-button gray-tile-button" (click)="getEtatCommandeInventaireReport()" [disabled]="epicerieInventaireFilter.downloading || this.epicerieInventaireFilter.selectedTypeListeArticle == null">{{downloadButtonText(epicerieInventaireFilter.downloading)}}</button>
            </div>
          </ng-container>
          <div *ngIf="epicerieInventaireFilter.loadingData" class="loading-container">
            <app-loader message="Préparation du formulaire"></app-loader>
          </div>
        </div>
      </fieldset>

      <app-export-bilan-repas></app-export-bilan-repas>

      <app-export-ecarts></app-export-ecarts>

      <fieldset class="reporting">
        <legend><i class="material-icons restaurant-type-color">toc</i> MATRICE DE CHOIX</legend>
        <div class="content">
          <ng-container *ngIf="matriceDeChoixFilter">
            <div *ngIf="matriceDeChoixFilter.downloading" class="loading-container overlay">
              <app-loader message="Téléchargement du rapport"></app-loader>
            </div>
            <ng-container *ngIf="!matriceDeChoixFilter.downloading">
              <h3>Recherche</h3>
              <!-- Lieu de commande -->
              <select *ngIf="matriceDeChoixFilter.lieuCommandeList.length > 1" class="input-group selector" [(ngModel)]="matriceDeChoixFilter.selectedLieuCommande" name="matrice-lieuCmd">
                <option *ngFor="let lieuCommande of matriceDeChoixFilter.lieuCommandeList" [ngValue]="lieuCommande">{{lieuCommande.libelle}}</option>
              </select>
              <!-- Semaine -->
              <select *ngIf="matriceDeChoixFilter.semaineList.length > 0" class="input-group selector" [(ngModel)]="matriceDeChoixFilter.selectedSemaine" name="matrice-semaine">
                <option *ngFor="let semaine of matriceDeChoixFilter.semaineList" [ngValue]="semaine">{{semaine.libelle}}</option>
              </select>
              <!-- No data -->
              <div *ngIf="matriceDeChoixFilter.semaineList.length == 0 || matriceDeChoixFilter.lieuCommandeList.length == 0">
                Aucune donnée pour ce restaurant.
              </div>
            </ng-container>
            <div class="input-group bottom">
              <button class="generic-button gray-tile-button" (click)="getCommandeRepasMatriceDeChoix()" [disabled]="matriceDeChoixFilter.downloading">{{downloadButtonText(matriceDeChoixFilter.downloading)}}</button>
            </div>
          </ng-container>
          <div *ngIf="!matriceDeChoixFilter" class="loading-container">
            <app-loader message="Préparation du formulaire"></app-loader>
          </div>
        </div>
      </fieldset>

      <app-export-non-conformites></app-export-non-conformites>
      <app-export-manques></app-export-manques>
      <app-export-enquetes></app-export-enquetes>
      <app-export-suivi-qualite></app-export-suivi-qualite>

    </div>
  </div>
</div>
