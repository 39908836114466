import { Component, Input, Output, AfterViewInit, AfterViewChecked, EventEmitter, ElementRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { SwiperConfigInterface, SwiperComponent, SwiperDirective } from 'ngx-swiper-wrapper';

// var Swiper = require('swiper');

@Component({
  selector: 'app-item-selector',
  templateUrl: './item-selector.component.html',
  styleUrls: ['./item-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ItemSelectorComponent implements AfterViewInit, AfterViewChecked {
  requireUpdate: boolean;
  hasTransmisProp: boolean;
  _selectedItem: any;

  @Input()
  set selectedItem(val: any) {
    this._selectedItem = val;
    // first time mySwiper is not initialized, it will be done in the ngAfterViewInit
    if (this.mySwiper && val) {
      this.refreshCurrent();
    }
  }
  get selectedItem(): any {
    return this._selectedItem;
  }
  @Output()
  selectedItemChange: EventEmitter<any> = new EventEmitter();

  _items: any[];
  @Input()
  set items(val: any[]) {
    this._items = val;
    this.requireUpdate = true;
    this.hasTransmisProp = val ? val.some(item => item.hasOwnProperty('transmis')) : false;
  }
  get items(): any[] {
    return this._items;
  }

  @Input()
  propName: string;
  @Input()
  propComparer: string;

  @ViewChild(SwiperComponent, { static: true }) public componentRef: SwiperComponent;
  public swiperConfig: SwiperConfigInterface = {
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: true
  };

  // private mySwiper: any;
  public get mySwiper(): SwiperDirective {
    return this.componentRef.directiveRef;
  }


  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    const element = this.el.nativeElement as Element;
    const id = element.id;
    if (!id) {
      // TODO: handle this
    }

    const setIndex = idx => this.changeIndex(idx);

    // this.mySwiper = new Swiper(`#${id} .item-selector .swiper-container`, {
    //   slidesPerView: 1,
    //   spaceBetween: 30,
    //   nextButton: '.swiper-button-next',
    //   prevButton: '.swiper-button-prev',
    //   onSlideChangeEnd: function (swiper) { setIndex(swiper.activeIndex); },
    // });

    this.refreshCurrent();
  }

  ngAfterViewChecked() {
    if (this.requireUpdate) {
      this.mySwiper.update();
      this.requireUpdate = false;
    }
  }

  refreshCurrent() {
    if (!this.items) {
      return;
    }

    let currIndex;
    if (this.propComparer) {
      currIndex = this.items.findIndex(item => item[this.propComparer] == this._selectedItem[this.propComparer]);
    } else {
      currIndex = this.items.indexOf(this._selectedItem);
    }

    this.mySwiper.setIndex(currIndex);
  }

  changeIndex(idx) {
    this._selectedItem = this.items[idx];
    this.selectedItemChange.emit(this._selectedItem);
  }
}
