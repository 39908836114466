/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./warning-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/pipes/error-pretty-date.pipe";
import * as i4 from "./warning-popup.component";
var styles_WarningPopupComponent = [i0.styles];
var RenderType_WarningPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WarningPopupComponent, data: {} });
export { RenderType_WarningPopupComponent as RenderType_WarningPopupComponent };
function View_WarningPopupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "warning-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.message; _ck(_v, 2, 0, currVal_0); }); }
function View_WarningPopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "jour"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "jour-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [["class", "warning-messages"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WarningPopupComponent_3)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.messagesDatas; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.jourDate)); _ck(_v, 2, 0, currVal_0); }); }
function View_WarningPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WarningPopupComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.warningPopupData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WarningPopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.ErrorPrettyDatePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "warning-popup-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "global-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Validation r\u00E9ussie pour les effectifs."])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WarningPopupComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_WarningPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "warning-popup", [], null, null, null, View_WarningPopupComponent_0, RenderType_WarningPopupComponent)), i1.ɵdid(1, 180224, null, 0, i4.WarningPopupComponent, [], null, null)], null, null); }
var WarningPopupComponentNgFactory = i1.ɵccf("warning-popup", i4.WarningPopupComponent, View_WarningPopupComponent_Host_0, {}, {}, []);
export { WarningPopupComponentNgFactory as WarningPopupComponentNgFactory };
