import { Pipe, PipeTransform } from '@angular/core';
import { MiseEnConformiteStatut } from '../models/bon-livraison.model';

@Pipe({ name: 'formatMiseEnConformiteStatut' })
export class FormatMiseEnConformiteStatutPipe implements PipeTransform {
  transform(value: MiseEnConformiteStatut): string {
    return this.format(value);
  }

  private format(statut: MiseEnConformiteStatut): string {
    switch (statut) {
      case MiseEnConformiteStatut.NA: return 'Non applicable';
      case MiseEnConformiteStatut.ARelivrer: return 'Re-livraison';
      case MiseEnConformiteStatut.Conforme: return 'Conforme';
      case MiseEnConformiteStatut.NonConforme: return 'Non conforme';
    }
    return status;
  }

}
