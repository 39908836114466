import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth-guard';
import { DiscussionComponent } from './discussion.component';

const DiscussionRoutes: Routes = [
  {
    path: 'demande',
    component: DiscussionComponent,
    canActivate: [AuthGuard],
    data: { title: 'Demande / Réclamation' }
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(DiscussionRoutes)
  ],
  exports: [
    RouterModule
    ]
})

export class DiscussionRoutingModule { }
