import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { includes } from 'lodash';
import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { MenuLinkModel } from '../shared/models/menu-link.model';
import { LogService } from '../shared/services/log.service';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  @Input()
  item: MenuLinkModel;

  @Input()
  restaurantType: RestaurantTypeEnum;

  @Output()
  onNavigated: EventEmitter<any> = new EventEmitter<any>();


  constructor(private router: Router, private logService: LogService) { }

  ngOnInit() {

  }

  onClick() {
    if (this.item.isEnabled) {
      this.onNavigated.next();
      this.router.navigate([this.item.path]);
    } else {
      this.logService.Info(`The navigation is disabled for ${this.item.name}`);
    }
  }

  isActive(): boolean {

    return includes(this.router.url, this.item.path);

    // return (this.item.path == this.router.url);
  }

  isEnabled(): boolean {
    return this.item.isEnabled;
  }
}
