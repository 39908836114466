import { Pipe, PipeTransform } from '@angular/core';
import { BonLivraisonStatut } from '../models/bon-livraison.model';

@Pipe({ name: 'formatBonLivraisonStatut' })
export class FormatBonLivraisonStatutPipe implements PipeTransform {
  transform(value: BonLivraisonStatut): string {
    return this.formatBonLivraisonStatut(value);
  }

  private formatBonLivraisonStatut(statut: BonLivraisonStatut): string {
    switch (statut) {
      case BonLivraisonStatut.ALivrer:
        return 'À livrer';
      case BonLivraisonStatut.AReceptionner:
        return 'À réceptionner';
      case BonLivraisonStatut.Manque:
        return 'Manque';
      case BonLivraisonStatut.Conforme:
        return 'Conforme';
      case BonLivraisonStatut.ManqueCloture:
        return 'Clôturé auto. (Manque)';
      case BonLivraisonStatut.AReceptionnerCloture:
        return 'Clôturé auto. (À réceptionner)';
      case BonLivraisonStatut.NonConforme:
        return 'Non conforme';
    }
    return status;
  }

}
