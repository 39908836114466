import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { MenuLinkModel } from '../shared/models/menu-link.model';

@Component({
  selector: 'app-meal-tile',
  templateUrl: './meal-tile.component.html',
  styleUrls: ['./meal-tile.component.scss']
})

/** this should be taking a menu-link model */
export class MealTileComponent implements OnInit {

  @Input()
  item: MenuLinkModel;

  @Input()
  route: string;
  @Input()
  imgSrc: string;
  @Input()
  titleHTML: string;
  @Input()
  descriptionHTML: string;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  redirect() {
    this.router.navigate([this.item.path]);
  }
}
