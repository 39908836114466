import { CorrelationTokenService } from './correlationToken.service';
import { ApiSettings } from '../models/api/api-settings';
import { Injectable, isDevMode, NgZone } from '@angular/core';
import { JL } from 'jsnlog';
import { AppInsights } from 'applicationinsights-js';
import * as moment from 'moment';
import { StringHelper } from '../helpers/string.helper';

// import * as appPackage from 'json!../../../';
const { version: appVersion } = require('../../../../package.json');

/*
enum SeverityLevel {
        Verbose = 0,
        Information = 1,
        Warning = 2,
        Error = 3,
        Critical = 4,
    }
*/

@Injectable()
export class LogService {

  private _correlationId: string;
  public get correlationId(): string {
    return this._correlationId;
  }
  public set correlationId(correlationId: string) {
    this._correlationId = correlationId;
  }

  constructor(private _apiSettings: ApiSettings,
              private _ngZone: NgZone) {

    const consoleAppender = JL.createConsoleAppender('consoleAppender');
    consoleAppender.setOptions({
      level: isDevMode() ? JL.getTraceLevel() : JL.getWarnLevel()
    });
    // const ajaxAppender = JL.createAjaxAppender("ajaxAppender");
    // ajaxAppender.setOptions({
    //     // see:http://jsnlog.com/Documentation/JSNLogJs/AjaxAppender/SetOptions
    //     url: `${this.apiSettings.apiUrl}/log`,
    //     storeInBufferLevel: JL.getTraceLevel(),
    //     level: JL.getWarnLevel(),
    //     sendWithBufferLevel: JL.getFatalLevel(),
    //     bufferSize: 20,
    //     beforeSend: this.addRequestHeaders
    // });

    // création d'un appender JSNLog pour ApplicationInsight (à partir d'un appender quelconque en réécrivant la méthode d'envoi)
    const appInsightAppender = JL.createAjaxAppender('appInsightAppender');
    appInsightAppender.setOptions({
      // see:http://jsnlog.com/Documentation/JSNLogJs/AjaxAppender/SetOptions
      storeInBufferLevel: JL.getTraceLevel(), // on stocke toutes les traces
      level: JL.getWarnLevel(), // on envoie les logs warn/error/fatal
      sendWithBufferLevel: JL.getErrorLevel(), // à partir de error on envoie aussi toutes les traces
      bufferSize: 20,
      beforeSend: this.addRequestHeaders
    });
    // Dirty replace Appender log function with a custom one implementing AppInsight
    (appInsightAppender as any).sendLogItems = (logItems: LogItem[], successCallback: () => void) => {
      for (const log of logItems) {
        const props: { [name: string]: string } = {};
        props.logger = log.n;
        props.timestamp = moment(log.t).toISOString();
        const level = this.JSNLogLevelToAppInsightLevel(log.l);

        // TODO repasser le bon timestamp à APPInsight => pas possible alors au moins convertir le timestamp en date
        // => toISOString

        if (level < 4) { // < Error
          AppInsights.trackTrace(log.m, props, level);
        } else { // Error | Fatal
          AppInsights.trackException((log.m as any), null, props, null, level);
        }
      }
      successCallback();
    };

    // Attention: à faire après le lazy load AppInsights (voir https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#addtelemetryinitializer)
    AppInsights.queue.push(() => {
      // On ajoute un telemtryInitializer au TelemetryContext de AppInsight pour redéfinir le time des élément trackés (car poussés en batch par l'appender précédent)
      AppInsights.context.addTelemetryInitializer(envelope => {
        const timestamp = envelope.data && envelope.data.baseData && envelope.data.baseData.properties && envelope.data.baseData.properties.timestamp;
        if (timestamp) {
          envelope.time = timestamp;
          delete envelope.data.baseData.properties.timestamp;
        }
      });
    });

    JL().setOptions({
      // see http://jsnlog.com/Documentation/JSNLogJs/JL/SetOptions
      //            appenders: [ajaxAppender, consoleAppender],
      appenders: [consoleAppender, appInsightAppender],
    });
  }

  private addRequestHeaders(xhr: XMLHttpRequest): void {
    // ajout de la version de l'api
    xhr.setRequestHeader('client-version', appVersion); // non autorisé à cause de CORS (Request header field client-version is not allowed by Access-Control-Allow-Headers in preflight response.)
    // ajout de l'identifiant de correlation
    xhr.setRequestHeader('client-correlationId', this._correlationId);
  }

  Debug(msg: any, logger?: string): void {
    this._ngZone.runOutsideAngular(() => {
      JL(logger).debug(msg);
    });
  }

  Info(msg: any, logger?: string): void {
    this._ngZone.runOutsideAngular(() => {
      JL(logger).info(msg);
    });
  }

  Warn(msg: any, logger?: string): void {
    this._ngZone.runOutsideAngular(() => {
      JL(logger).warn(msg);
    });
  }

  Error(msg: any, logger?: string): void {
    this._ngZone.runOutsideAngular(() => {
      JL(logger).error(this.DumpError(msg));
    });
  }

  Fatal(msg: any, logger?: string): void {
    this._ngZone.runOutsideAngular(() => {
      JL(logger).fatal(this.DumpError(msg));
    });
  }

  // tslint:disable-next-line:member-ordering
  private DumpError(error: any): any {
    let logPayload = error;
    if (typeof (logPayload) === 'object') {
      logPayload = {};
      if (error.message) {
        logPayload.message = error.message;
        logPayload.stack = error.stack;
      } else {
        const hasToStringOverride = ((error as object).toString !== Object.prototype.toString);
        logPayload.message = hasToStringOverride
          ? (error as object).toString() // dump using toString
          : StringHelper.jsonStringifyCircular(error); // generic dump (json)
      }
    }
    return logPayload;
  }

  private JSNLogLevelToAppInsightLevel(level: number): number {
    // if (level <= 1000) { return 0; } // trace => Verbose (0)
    if (level <= 2000) { return 0; } // debug => Verbose (0)
    if (level <= 3000) { return 1; } // info => Information (1)
    if (level <= 4000) { return 2; } // warn => Warning (2)
    if (level <= 5000) { return 3; } // error => Error (3)
    return 4; // fatal => Critical (4)
  }

  public getLogger(name: string): Logger {
    return new Logger(this, name);
  }

}


export class Logger {

  constructor(private _logService: LogService, private _name: string) {
  }

  public debug(msg: any): void {
    this._logService.Debug(msg, this._name);
  }

  public info(msg: any, logger?: string): void {
    this._logService.Info(msg, this._name);
  }

  public warn(msg: any, logger?: string): void {
    this._logService.Warn(msg, this._name);
  }

  public error(msg: any, logger?: string): void {
    this._logService.Error(msg, this._name);
  }

  public fatal(msg: any, logger?: string): void {
    this._logService.Fatal(msg, this._name);
  }
}


class LogItem {
  // l: level
  // m: message
  // n: logger name
  // t (timeStamp) is number of milliseconds since 1 January 1970 00:00:00 UTC
  //
  // Keeping the property names really short, because they will be sent in the
  // JSON payload to the server.
  constructor(
    public l: number,
    public m: string,
    public n: string,
    public t: number) { }
}
