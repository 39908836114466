<!-- mobile -->
<div class="container">
	<!-- mobile header -->
	<div class="mobile-header">
		<div *ngIf="isRestaurantDisplay">
			<app-item-selector id="restaurant-selector" [items]="restaurantList" [(selectedItem)]="selectedRestaurant" propName="name"
			 propComparer="id" class="color-animation" [restaurantColor]="{'type': currentType, 'isSecondary': true, 'background':true}">
			</app-item-selector>
			<hr class="separator" />
		</div>
		<app-item-selector *ngIf="odg && isConviveDisplay" id="convive-selector" [items]="conviveList" [(selectedItem)]="selectedConvive"
		 propName="libelle" propComparer="id" class="color-animation" [restaurantColor]="{'type': currentType, 'isSecondary': true, 'background':true}"></app-item-selector>

		<app-date-selector [selectedRange]="selectedRange" [dateRanges]="dateRanges" [currentType]="currentType" id="date-selector"
		 [(selectedJour)]="selectedJour" [isSwipePrevAllowed]="isPrevWeekAllowed" [isSwipeNextAllowed]="isNextWeekAllowed">
		</app-date-selector>
	</div>
	<!-- desktop header -->
	<div class="sm-header">
		<div class="convives-container" [style.background-image]="'url(' + logoContratUrl + ')'">
			<div class="convive-tab pointerHover" [class.selected]="selectedConvive == convive" (click)="newConvive(convive)" *ngFor="let convive of conviveList">
				<div class="circle"></div>
				<span class="convive-libelle">{{convive.libelle}}</span>
			</div>
		</div>
		<div class="middle-container">
			<div class="actions-container">

				<div class="actions-left">
					<div class="range-selector">
						<button [class.disabled]="!isPrevWeekAllowed" class="generic-button navWeek prevWeek pointerHover" (click)="handleNavigation(-1)">
							<div class="arrow"></div>
						</button>
						<select [ngModel]="selectedRange" (ngModelChange)="selectedRangeChanged($event)">
							<option *ngFor="let range of dateRanges" [ngValue]="range">{{range.libelle}}</option>
						</select>
						<button [class.disabled]="!isNextWeekAllowed" class="generic-button navWeek nextWeek pointerHover" (click)="handleNavigation(+1)">
							<div class="arrow"></div>
						</button>
					</div>
				</div>
				<div class="actions-right">
					<div class="right-buttons">
						<button [disabled]="isLoading || isNoContent" (click)="validateOdg()" class="generic-button validate-button" [restaurantColor]="{'type': currentType, 'background':true}">
							{{isSendingOdg? 'Validation...' : 'Valider'}}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="isLoading || isNoContent" class="info-container">
		<app-loader *ngIf="isLoading" message="Chargement des données..."></app-loader>
		<div class="no-content" *ngIf="!isLoading && isNoContent"><span>Aucune donnée...</span></div>
	</div>
	<div class="main-container">
		<div #odgRepasContainer class="repas-container" *ngIf="!isLoading && selectedJour && !isNoContent">
			<div class="buttons-container">
				<div class="save-button" (click)="validateOdg()" [restaurantColor]="{'type': currentType, 'background':true}">
					<div></div>
				</div>
			</div>
			<div [translateSlider]="selectedRange.validJours.indexOf(selectedJour)" class="jours-container" [style.width]="selectedRange.validJours.length*100 + 'vw'">
				<div style="width:100vw !important;" class="jour" *ngFor="let jour of odg.odgJours">
					<!-- days -->
					<div [jourType]="jour.periode" class="day-head" [restaurantColor]="{'type': currentType, 'isSecondary':true, 'background':true}">
						<span>{{jour.dayString + ' ' +jour.day+' ' +jour.monthShort}}</span>
					</div>

					<div class="prestations">
						<div *ngFor="let presta of jour.odgPrestations">
							<odg-prestation [currentType]="currentType" [odgPresta]="presta" (prestaToggle)="onPrestaToggle($event)"></odg-prestation>
						</div>
						<div class="observations">
							<span>Observations</span>
							<textarea [disabled]="!jour.autoriserSaisie" class="simple-textarea" [(ngModel)]="jour.observations" placeholder="Observations"></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
