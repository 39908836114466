<div class="loader-container">
  <div class="loader-content">
    <div class="loading-wrap">
      <div class="loading">
        <div class="loading-bar"></div>
        <div class="loading-bar"></div>
        <div class="loading-bar"></div>
        <div class="loading-bar"></div>
      </div>
      <div *ngIf="message" class="loader-text">{{message}}</div>
    </div>
  </div>
</div>