import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LinkFormControlDirective } from '../shared/directives/link-form-control.directive';
import { SharedModule } from '../shared/shared.module';
import { ExportBilanRepasComponent } from './export-bilan-repas/export-bilan-repas.component';
import { ExportEcartsComponent } from './export-ecarts/export-ecarts.component';
import { PrestationComponent } from './prestation/prestation.component';
import { RepasRoutingModule } from './repas-routing.module';
import { RepasComponent } from './repas.component';
import { CommandeRepasApiService } from './services/api/commande-repas.apiservice';
import { CommandeRepasService } from './services/commande-repas.service';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    ReactiveFormsModule,
    RepasRoutingModule
  ],
  declarations: [
    // Components
    RepasComponent,
    PrestationComponent,
    ExportEcartsComponent,
    ExportBilanRepasComponent,
    // directives
    LinkFormControlDirective,
  ],
  exports: [
    ExportEcartsComponent,
    ExportBilanRepasComponent,
  ],
  providers: [
    CommandeRepasService,
    CommandeRepasApiService
  ]
})
export class RepasModule { }
