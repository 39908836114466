import { Directive, Input, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
    selector: '[linkFormControl]'
})
export class LinkFormControlDirective {

    public nativeElement: any;
    public formControl: FormControl;
    constructor(private el: ElementRef) {
        this.nativeElement = el.nativeElement;
    }

    @Input()
    set linkFormControl(fc: FormControl) {
        this.formControl = fc;
    }
}