import { ApplicationDataService } from '../../shared/services/application-data.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { OdgEvaluationModel, OdgEvaluationNote } from '../models/odg.model';

@Component({
  selector: 'odg-evaluation',
  templateUrl: './odg-evaluation.component.html',
  styleUrls: ['./odg-evaluation.component.scss']
})
export class OdgEvaluationComponent {
  @Input()
  odgEval: OdgEvaluationModel;

  get constat(): string {
    let str = "";
    let alreadySmth = false;
    if (this.odgEval.assaisonnement) {
      str += "Assaisonnement"
      alreadySmth = true;
    }
    if (this.odgEval.consistance) {
      str +=
        ((alreadySmth) ? ", " : "")
        + "Consistance";
      alreadySmth = true;
    }

    if (this.odgEval.aspect) {
      str +=
        ((alreadySmth) ? ", " : "")
        + "Aspect"
    }

    return str;
  }

  constructor(private appDataService: ApplicationDataService) {
  }

  onSmileyClick(note: OdgEvaluationNote) {
    if (!this.odgEval.autoriserSaisie) {
      return;
    }

    this.odgEval.note = note;
    switch (note) {
      case 1:
      case 2:
        this.cleanBadEvaluationData();
        break;
      case 3:
      case 4:
        this.appDataService.showBadEvaluation(this.odgEval);
        break;
    }
  }

  cleanBadEvaluationData() {
    this.odgEval.aspect = false;
    this.odgEval.assaisonnement = false;
    this.odgEval.consistance = false;
    this.odgEval.autreRaison = "";
  }
}
