import {
  NgModule, Component, ViewChild, ViewContainerRef, Input,
  ComponentFactoryResolver, ComponentRef, OnChanges,
  AfterContentInit, OnDestroy, Type, Output, EventEmitter
} from '@angular/core';
import { inherits } from 'util';

export abstract class PopupCustomComponentBase implements OnDestroy {
  public data: any;
  public emitter: EventEmitter<any>;
  ngOnDestroy(): void {
    if (this.emitter) {
      // this.emitter.unsubscribe();
    }
  }
}

export abstract class PopupCustomComponentGeneric<TModel> extends PopupCustomComponentBase {

  public get model(): TModel {
    return this.data as TModel;
  }
  public hide() {
    this.emitter.next();
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'popup-custom-content',
  templateUrl: './popup-custom-content.component.html'
})
export class PopupCustomContentComponent implements OnDestroy, AfterContentInit {
  @ViewChild('target', { read: ViewContainerRef, static: true }) target: ViewContainerRef;

  //#region innerComponentType
  private _innerComponentType: Type<Component>;
  public get innerComponentType(): Type<Component> {
    return this._innerComponentType;
  }
  @Input()
  public set innerComponentType(value: Type<Component>) {
    this._innerComponentType = value;
    setTimeout(() => {
      if (this.isViewInitialized) {
        this.updateComponent(value);
      }
    }, 100);
  }
  //#endregion

  @Input() innerComponentData: any;
  @Output() onHideEmitter = new EventEmitter<void>();

  componentRef: ComponentRef<Component> = null;
  isViewInitialized: boolean;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  updateComponent(componentType: Type<Component>) {
    // tslint:disable-next-line:no-debugger
    // debugger;
    if (!this.isViewInitialized) {
      return;
    }
    if (!componentType) {
      return;
    }
    if (!this.target) {
      return;
    }

    if (this.componentRef && this.componentRef.componentType !== componentType) {
      this.componentRef.destroy();
      this.componentRef = null;
    }

    if (this.componentRef == null) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(this.innerComponentType);
      this.componentRef = this.target.createComponent(factory);
    }
    const component = this.componentRef.instance as PopupCustomComponentBase;
    component.data = this.innerComponentData;
    component.emitter = this.onHideEmitter;
  }

  ngAfterContentInit() {
    this.isViewInitialized = true;
    this.updateComponent(this.innerComponentType);
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
