<div class="cart-container">
  <div class="articles-list">
    <div *ngIf="articles.length == 0" class="empty-cart">Votre panier est vide</div>

    <div class="article" *ngFor="let article of articles">
      <div class="article-container">
        <div class="left restaurant-type-color">
          {{article.quantity}}
        </div>
        <div class="middle">
          {{article.libelle}}
        </div>
        <div class="right" *ngIf="!isInventaire && article.unitPrice > 0">
          {{article.unitPrice | currencyEuro}}
        </div>
        <div *ngIf="!consult" class="delete pointerHover" (click)="deleteArticle(article)">
          <img src="/assets/images/epicerie-inventaire/trash2.png" />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isInventaire" class="footer restaurant-type-background">
    <span class="right">
      <span class="total">TOTAL HT</span>
      <span class="totalPrice">{{totalPrice | currencyEuro}}</span>
    </span>
  </div>
</div>
