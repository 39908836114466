/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./logo.component";
var styles_LogoComponent = [i0.styles];
var RenderType_LogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoComponent, data: {} });
export { RenderType_LogoComponent as RenderType_LogoComponent };
export function View_LogoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { logo: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["logo", 1]], null, 0, "img", [["src", "/assets/images/app_logo.png"]], null, null, null, null, null))], null, null); }
export function View_LogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logo", [], null, null, null, View_LogoComponent_0, RenderType_LogoComponent)), i1.ɵdid(1, 114688, null, 0, i2.LogoComponent, [i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoComponentNgFactory = i1.ɵccf("app-logo", i2.LogoComponent, View_LogoComponent_Host_0, { restaurantType: "restaurantType", class: "class" }, {}, []);
export { LogoComponentNgFactory as LogoComponentNgFactory };
