import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BonLivraisonRoutingModule } from './bon-livraison-routing.module';
import { BonLivraisonComponent } from './bon-livraison.component';
import { BonLivraisonService } from './services/bon-livraison.service';
import { BonLivraisonApiService } from './services/api/bon-livraison-api.service';
import { SharedModule } from '../shared/shared.module';
import { DetailsBonLivraisonComponent } from './details-bon-livraison/details-bon-livraison.component';
import { ComposantsLivresComponent } from './details-bon-livraison/composants-livres/composants-livres.component';
import { FormatBonLivraisonStatutPipe } from './pipes/format-bon-livraison-statut.pipe';
import { FormatReceptionStatutPipe } from './pipes/format-reception-statut.pipe';
import { FormatMiseEnConformiteStatutPipe } from './pipes/format-mise-en-conformite-statut.pipe';
import { PopupValidationComponent } from './details-bon-livraison/popup-validation/popup-validation.component';
import { PopupSearchCodeBarreComponent } from './popup-search-code-barre/popup-search-code-barre.component';
import { ExportNonConformitesComponent } from './export-non-conformites/export-non-conformites.component';
import { ExportManquesComponent } from './export-manques/export-manques.component';
import { DiscussionModule } from '../discussion/discussion.module';
import { BonLivraisonInfoHeaderComponent } from './bon-livraison-info-header/bon-livraison-info-header.component';
import { LivraisonBonLivraisonComponent } from './livraison-bon-livraison/livraison-bon-livraison.component';
import { LivraisonTemperatureComponent } from './livraison-bon-livraison/livraison-temperature/livraison-temperature.component';
import { LivraisonDlcComponent } from './livraison-bon-livraison/livraison-dlc/livraison-dlc.component';
import { LivraisonPopupValidationComponent } from './livraison-bon-livraison/livraison-popup-validation/livraison-popup-validation.component';
import { LivraisonSignatureComponent } from './livraison-bon-livraison/livraison-signature/livraison-signature.component';
import { FormatTemperaturePipe } from './pipes/format-temperature.pipe';
import { FormatLivraisonStatutPipe } from './pipes/format-livraison-statut.pipe';
import { BonlivraisonInfoPanelComponent } from './bonlivraison-info-panel/bonlivraison-info-panel.component';
import { FilterAllowedFamillePlatPipe } from './pipes/filter-allowed-famille-plat.pipe';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BonLivraisonRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DiscussionModule
  ],
  declarations: [
    BonLivraisonComponent,
    DetailsBonLivraisonComponent,
    ComposantsLivresComponent,
    ExportNonConformitesComponent,
    ExportManquesComponent,
    // Pipes
    FormatBonLivraisonStatutPipe,
    FormatReceptionStatutPipe,
    FormatMiseEnConformiteStatutPipe,
    FormatTemperaturePipe,
    FormatLivraisonStatutPipe,
    // Popups
    PopupSearchCodeBarreComponent,
    PopupValidationComponent,
    BonLivraisonInfoHeaderComponent,
    LivraisonBonLivraisonComponent,
    LivraisonTemperatureComponent,
    LivraisonDlcComponent,
    LivraisonPopupValidationComponent,
    LivraisonSignatureComponent,
    BonlivraisonInfoPanelComponent,
    FilterAllowedFamillePlatPipe
  ],
  entryComponents: [
    // Composants créés dynamiquement (les popup custom)
    PopupSearchCodeBarreComponent,
    PopupValidationComponent,
    LivraisonPopupValidationComponent
  ],
  providers: [
    BonLivraisonService,
    BonLivraisonApiService
  ],
  exports: [
    ExportNonConformitesComponent,
    ExportManquesComponent
  ]
})
export class BonLivraisonModule { }
