
import { filter } from 'rxjs/operators';
import { AnalyticsDimensions } from "../models/analytics-dimensions.model";
import { AnalyticsProviderBase } from "./analytics-provider-base";
import { Injectable } from "@angular/core";

import { Router, NavigationEnd, NavigationStart, NavigationError } from "@angular/router";

import { AppSettings } from "../../shared/models/app-settings.model";

declare var _gaq: any;
declare var ga: any;

@Injectable()
export class GoogleAnalyticsProvider extends AnalyticsProviderBase {
  loadStartTime: number = null;
  loadTime: number = null;


  constructor(
    private router: Router,
    private appSettings: AppSettings) {
    super();

    if (!appSettings.googleAnalyticsKey) {
      // Add proper log
      console.warn("Pas de googleAnalyticsKey");
      return;
    }
    (function (i, s, o, g, r, a, m) {
      i["GoogleAnalyticsObject"] = r; i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments)
      }, i[r].l = 1 * new Date().valueOf(); a = s.createElement(o),
        m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
    })(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");

    ga("create", appSettings.googleAnalyticsKey, "auto");

    this.router.events.pipe(
      filter(event => event instanceof NavigationStart))
      .subscribe(event => this.startTimer());

    this.router.events.pipe(
      filter(event =>
        (event instanceof NavigationError) ||
        (event instanceof NavigationEnd)
      ))
      .subscribe(error => this.stopTimer());
  }

  startTimer() {
    this.loadStartTime = Date.now();
    this.loadTime = null;
  }

  stopTimer() {
    this.loadTime = Date.now() - this.loadStartTime;
    this.loadStartTime = null;
  }

  pageTrack_internal(path: string, dimensions: AnalyticsDimensions) {
    if ((typeof _gaq !== "undefined") && _gaq) {
      _gaq.push(["_trackPageview", path]);
    }
    if ((typeof ga !== "undefined") && ga) {
      this.prepareDimensionsTracking(dimensions);

      ga("send", "pageview", path);
    }
  }

  eventTrack_internal(action: string, properties: any, dimensions: AnalyticsDimensions) {

    // Google Analytics requires an Event Category
    if (!properties || !properties.category) {
      properties = properties || {};
      properties.category = "Event";
    }

    // GA requires that eventValue be an integer, see:
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#eventValue
    // https://github.com/luisfarzati/angulartics/issues/81
    if (properties.value) {
      const parsed = parseInt(properties.value, 10);
      properties.value = isNaN(parsed) ? 0 : parsed;
    }

    if (typeof ga !== "undefined") {
      const eventOptions = {
        eventCategory: properties.category,
        eventAction: action,
        eventLabel: properties.label,
        eventValue: properties.value,
        nonInteraction: properties.noninteraction || false, // https://support.google.com/analytics/answer/1033068#NonInteractionEvents
        page: properties.page || location.hash.substring(1) || location.pathname,
        userId: dimensions.userId
      };

      this.prepareDimensionsTracking(dimensions);

      ga("send", "event", eventOptions);

    } else if (typeof _gaq !== "undefined") {
      _gaq.push(["_trackEvent", properties.category, action, properties.label, properties.value, properties.noninteraction]);
    }
  }

  setUserId_internal(userId: string) {
    ga("set", "&uid", userId);
    ga("set", "userId", userId); // Définir l'ID utilisateur à partir du paramètre user_id de l'utilisateur connecté.
  }

  clear() {
    // Nothing specific here
  }

  private prepareDimensionsTracking(dimensions: any) {
    if (dimensions.userId) {
      this.setUserId_internal(dimensions.userId);
    }

    // tslint:disable-next-line:forin
    for (const dimensionProp in dimensions) {
      ga("set", dimensionProp, dimensions[dimensionProp]);
    }
  }
}
