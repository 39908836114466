/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loader/loader.component.ngfactory";
import * as i3 from "../loader/loader.component";
import * as i4 from "@angular/common";
import * as i5 from "./loading-overlay.component";
var styles_LoadingOverlayComponent = [i0.styles];
var RenderType_LoadingOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingOverlayComponent, data: {} });
export { RenderType_LoadingOverlayComponent as RenderType_LoadingOverlayComponent };
function View_LoadingOverlayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_LoadingOverlayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Chargement en cours..."]))], null, null); }
export function View_LoadingOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "pageload-overlay"], ["id", "overlay-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, ":svg:svg", [["height", "100%"], ["preserveAspectRatio", "xMidYMid slice"], ["viewBox", "0 0 80 60"], ["width", "100%"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:path", [["d", "M40,30 c 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 Z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:defs", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "loader-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-loader", [], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(6, 114688, null, 0, i3.LoaderComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingOverlayComponent_1)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingOverlayComponent_2)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 6, 0); var currVal_0 = _co.customMessage; _ck(_v, 8, 0, currVal_0); var currVal_1 = !_co.customMessage; _ck(_v, 10, 0, currVal_1); }, null); }
export function View_LoadingOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-overlay", [], [[2, "show", null]], null, null, View_LoadingOverlayComponent_0, RenderType_LoadingOverlayComponent)), i1.ɵdid(1, 49152, null, 0, i5.LoadingOverlayComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isDisplay; _ck(_v, 0, 0, currVal_0); }); }
var LoadingOverlayComponentNgFactory = i1.ɵccf("app-loading-overlay", i5.LoadingOverlayComponent, View_LoadingOverlayComponent_Host_0, {}, {}, []);
export { LoadingOverlayComponentNgFactory as LoadingOverlayComponentNgFactory };
