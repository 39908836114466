/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./restaurant-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../select-autocomplete/select-autocomplete.component.ngfactory";
import * as i3 from "../select-autocomplete/select-autocomplete.component";
import * as i4 from "@angular/common";
import * as i5 from "./restaurant-selector.component";
import * as i6 from "../shared/services/interaction.service";
var styles_RestaurantSelectorComponent = [i0.styles];
var RenderType_RestaurantSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RestaurantSelectorComponent, data: {} });
export { RenderType_RestaurantSelectorComponent as RenderType_RestaurantSelectorComponent };
function View_RestaurantSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "restaurant-selector-dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "/assets/images/layout-header/grandcompte.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "select-autocomplete", [], null, [[null, "entityChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("entityChange" === en)) {
        var pd_0 = (_co.onSelectedGrandCompteChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SelectAutocompleteComponent_0, i2.RenderType_SelectAutocompleteComponent)), i1.ɵdid(3, 49152, null, 0, i3.SelectAutocompleteComponent, [i1.ChangeDetectorRef], { entityDisplayFunc: [0, "entityDisplayFunc"], entity: [1, "entity"], entities: [2, "entities"] }, { entityChange: "entityChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formatGrandCompteLabel; var currVal_1 = _co.internalSelectedGrandCompte; var currVal_2 = _co.internalSelectableGrandComptes; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_RestaurantSelectorComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "restaurant-selector-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RestaurantSelectorComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "restaurant-selector-dropdown"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["src", "/assets/images/layout-header/contrat.png"]], null, [[null, "dblclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dblclick" === en)) {
        var pd_0 = (_co.displayDebugInfo(_co.selectedContratTooltip) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "select-autocomplete", [["entityDisplayProp", "libelle"]], null, [[null, "entityChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("entityChange" === en)) {
        var pd_0 = (_co.onSelectedContratChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SelectAutocompleteComponent_0, i2.RenderType_SelectAutocompleteComponent)), i1.ɵdid(6, 49152, null, 0, i3.SelectAutocompleteComponent, [i1.ChangeDetectorRef], { entityDisplayProp: [0, "entityDisplayProp"], entity: [1, "entity"], entities: [2, "entities"] }, { entityChange: "entityChange" }), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "restaurant-selector-dropdown"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["src", "/assets/images/layout-header/home.png"]], null, [[null, "dblclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dblclick" === en)) {
        var pd_0 = (_co.displayDebugInfo(_co.selectedRestaurantTooltip) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "select-autocomplete", [], null, [[null, "entityChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("entityChange" === en)) {
        var pd_0 = (_co.onSelectedRestaurantChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SelectAutocompleteComponent_0, i2.RenderType_SelectAutocompleteComponent)), i1.ɵdid(10, 49152, null, 0, i3.SelectAutocompleteComponent, [i1.ChangeDetectorRef], { entityDisplayFunc: [0, "entityDisplayFunc"], entity: [1, "entity"], entities: [2, "entities"] }, { entityChange: "entityChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayGrandCompteSelector; _ck(_v, 2, 0, currVal_0); var currVal_2 = "libelle"; var currVal_3 = _co.internalSelectedContrat; var currVal_4 = _co.internalSelectableContrats; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4); var currVal_6 = _co.formatRestaurantLabel; var currVal_7 = _co.selectedRestaurant; var currVal_8 = _co.restaurants; _ck(_v, 10, 0, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.selectedContratTooltip, ""); _ck(_v, 3, 0, currVal_1); var currVal_5 = i1.ɵinlineInterpolate(1, "", _co.selectedRestaurantTooltip, ""); _ck(_v, 7, 0, currVal_5); }); }
export function View_RestaurantSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-restaurant-selector", [], null, null, null, View_RestaurantSelectorComponent_0, RenderType_RestaurantSelectorComponent)), i1.ɵdid(1, 638976, null, 0, i5.RestaurantSelectorComponent, [i6.InteractionService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RestaurantSelectorComponentNgFactory = i1.ɵccf("app-restaurant-selector", i5.RestaurantSelectorComponent, View_RestaurantSelectorComponent_Host_0, { displayGrandCompteSelector: "displayGrandCompteSelector", contrats: "contrats", selectedRestaurant: "selectedRestaurant", showDebugInfo: "showDebugInfo" }, { selectedRestaurantContextChange: "selectedRestaurantContextChange" }, []);
export { RestaurantSelectorComponentNgFactory as RestaurantSelectorComponentNgFactory };
