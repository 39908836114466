import { Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth-guard';
import { ReportingComponent } from './reporting.component';
const ɵ0 = { title: "Reporting" };
const reportingRoutes = [
    {
        path: 'reporting',
        component: ReportingComponent,
        canActivate: [AuthGuard],
        data: ɵ0
    }
];
export class ReportingRouteModule {
}
export { ɵ0 };
