import { Routes } from '@angular/router';
import { RepasComponent } from '../repas/repas.component';
import { AuthGuard } from '../shared/guards/auth-guard';
import { PromptForNavGuard } from '../shared/guards/prompt-for-nav-guard';
const ɵ0 = { title: 'Repas' }, ɵ1 = { title: 'Pique-nique', isPiqueNique: true };
const reportingRoutes = [
    {
        path: 'repas',
        component: RepasComponent,
        canActivate: [AuthGuard],
        canDeactivate: [PromptForNavGuard],
        data: ɵ0
    },
    // On utilise le composant Repas pour le piquenique, il sait qu'il est en piquenique grâce à l'url (voir le ngOnInit du repas.component)
    {
        path: 'piquenique',
        component: RepasComponent,
        canActivate: [AuthGuard],
        canDeactivate: [PromptForNavGuard],
        data: ɵ1
    },
];
export class RepasRoutingModule {
}
export { ɵ0, ɵ1 };
