import { EpicerieInventaireService } from '../../services/epicerie-inventaire.service';
import { RestaurantTypeEnum } from '../../../shared/enums/restaurant-type.enum';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ArticleModel } from '../../models/article.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleComponent implements OnChanges {
  activated: boolean;
  quantity: number = 1;

  QUANTITY_MIN: number = 1;
  QUANTITY_MAX: number = this._epicerieService.QuantiteMax;

  articlePicture: string;

  /** Inputs  */
  @Input()
  isInventaire: boolean;
  @Input()
  article: ArticleModel;
  @Input()
  type: RestaurantTypeEnum;
  @Input()
  consult: boolean;

  get showPrice(): boolean {
    return this.article != null && this.article.unitPrice > 0 && !this.isInventaire;
  }

  /** Ctor & Lifecycle */
  constructor(
    private _epicerieService: EpicerieInventaireService,
    private _toastrService: ToastrService,
    private _cd: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    //compute this.QUANTITY_MAX
    if (changes['article'] && changes['article'].currentValue) {
      this.QUANTITY_MAX = this.article.qteLimite || this._epicerieService.QuantiteMax;
      this.articlePicture = this._epicerieService.getArticlePicture(this.article.id);
    }
  }

  /** Logic */
  incrementCount() {
    if (this.quantity < this.QUANTITY_MAX) {
      this.quantity++;
    }
    else {
      this._epicerieService.warnMaxArticlesReached(this.article)
    }
  }

  decrementCount() {
    if (this.quantity > this.QUANTITY_MIN) {
      this.quantity--;
    }
  }

  addToCart() {
    if (this.activated) {
      return;
    }

    const added = this._epicerieService.addArticle(this.article, this.quantity);
    if (added) {
      this.activated = true;
      setTimeout(() => {
        this.activated = false;
        this._cd.markForCheck();
      }, 1000);
    }

  }

}
