import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  host: {
    '(document:click)': 'onDocumentClick($event)',
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent implements OnInit {
  isOpen: boolean;
  _items: any[];
  get items(): any[] {
    return this._items;
  }

  @Input()
  set items(value: any[]) {
    if (this.items != null && this.items.length > 0 && this.selectedItem == null) {
      this.selectedItem = this.items[0];
    }
    this._items = value;
  }
  @Input()
  selectedItem: any;

  @Input()
  /** La fonction de style */
  fnStyle: (item: any) => string = null;

  @Output()
  selectedItemChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private el: ElementRef) { }

  ngOnInit() {
  }

  onDocumentClick(event: any) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  onItemClick(item: any) {
    if (item != this.selectedItem) {
      this.selectedItem = item;
      this.selectedItemChange.emit(this.selectedItem);
    }

    this.isOpen = false;
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  refreshCurrent(selectedItem: any) {
    this.selectedItem = selectedItem;
  }

  applyStyle(item: any): string {
    if (item == null) {
      return "";
    }

    // On utilise la fonction de style si définie, sinon on drop le json
    if (this.fnStyle != null) {
      return this.fnStyle(item);
    }
    else {
      return JSON.stringify(item);
    }
  }
}
