import { PeriodeContratTypeEnum, PeriodeModel } from '../../repas/models/commande-repas.model';
import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({ selector: '[jourType]' })
export class RepasJourVignetteDirective {

    type: PeriodeContratTypeEnum = undefined;
    constructor(public el: ElementRef, public renderer: Renderer2) {
    }

    apply() {
        let cls = "type-";

        switch (this.type) {
            case PeriodeContratTypeEnum.Commande:
                cls = `${cls}commande`;
                break;
            case PeriodeContratTypeEnum.Reajustement:
                cls = `${cls}reajustement`;
                break;
            case PeriodeContratTypeEnum.HorsPeriode:
                cls = `${cls}horsperiode`;
                break;
            case PeriodeContratTypeEnum.Precommande:
                cls = `${cls}preco`;
                break;
            case PeriodeContratTypeEnum.Reel:
                cls = `${cls}reel`;
                break;
            default:
                cls = "";
                break;
        }

        if (cls !== "")
            this.renderer.addClass(this.el.nativeElement, cls);
    }

    @Input()
    set jourType(periode: PeriodeModel) {
        if (periode) {
            this.type = periode.type;
            this.apply();
        }
    }
}
