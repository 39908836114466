import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { parse as parseUrl } from 'url';
import { Logger } from './log.service';

export abstract class BaseHttpInterceptor implements HttpInterceptor {

  constructor(protected _log: Logger,
              private _options: HttpInterceptorOptions) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.canIntercept(req)) {
      this._log.debug(`intercept ${req.url}`);
      return this.handleInterception(req, next);
    } else {
      this._log.debug(`bypass ${req.url}`);
      return next.handle(req);
    }
  }

  private canIntercept(request: HttpRequest<any>): boolean {
    return this.isWhitelistedDomain(request)
      && !this.isBlacklistedRoute(request);
  }

  protected abstract handleInterception(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>;

  private isBlacklistedRoute(request: HttpRequest<any>): boolean {
    const url = request.url;

    return this._options.blacklistedRoutes
      && (
        this._options.blacklistedRoutes.findIndex(
          route =>
            typeof route === 'string'
              ? route === url
              : route instanceof RegExp
                ? route.test(url)
                : false
        ) > -1
      );
  }

  private isWhitelistedDomain(request: HttpRequest<any>): boolean {
    const requestUrl = parseUrl(request.url, false, true);

    return !this._options.whitelistedDomains
      || (
        requestUrl.host === null ||
        this._options.whitelistedDomains.findIndex(
          domain =>
            typeof domain === 'string'
              ? domain === requestUrl.host
              : domain instanceof RegExp
                ? domain.test(requestUrl.host)
                : false
        ) > -1
      );
  }
}

export interface HttpInterceptorOptions {
  whitelistedDomains?: Array<string | RegExp>;
  blacklistedRoutes?: Array<string | RegExp>;
}
