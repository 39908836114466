/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./odg-prestation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../odg-evaluation/odg-evaluation.component.ngfactory";
import * as i3 from "../odg-evaluation/odg-evaluation.component";
import * as i4 from "../../shared/services/application-data.service";
import * as i5 from "../../shared/directives/restaurant-type-color.directive";
import * as i6 from "@angular/common";
import * as i7 from "./odg-prestation.component";
var styles_OdgPrestationComponent = [i0.styles];
var RenderType_OdgPrestationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OdgPrestationComponent, data: {} });
export { RenderType_OdgPrestationComponent as RenderType_OdgPrestationComponent };
function View_OdgPrestationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "odg-evaluation", [], null, null, null, i2.View_OdgEvaluationComponent_0, i2.RenderType_OdgEvaluationComponent)), i1.ɵdid(1, 49152, null, 0, i3.OdgEvaluationComponent, [i4.ApplicationDataService], { odgEval: [0, "odgEval"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OdgPrestationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "prestation-header pointerHover"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i5.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpod(2, { "type": 0, "background": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "nowrap header-libelle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "prestation-row-span chevron"]], [[2, "down", null]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "prestation-row-span"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "collapsable"]], [[2, "collapsed", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OdgPrestationComponent_1)), i1.ɵdid(9, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.currentType, true); _ck(_v, 1, 0, currVal_0); var currVal_4 = _co.odgPresta.odgEvaluations; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.odgPresta.isCollapsed; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.odgPresta.libelle; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.odgPresta.isCollapsed; _ck(_v, 7, 0, currVal_3); }); }
export function View_OdgPrestationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "odg-prestation", [], null, null, null, View_OdgPrestationComponent_0, RenderType_OdgPrestationComponent)), i1.ɵdid(1, 114688, null, 0, i7.OdgPrestationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OdgPrestationComponentNgFactory = i1.ɵccf("odg-prestation", i7.OdgPrestationComponent, View_OdgPrestationComponent_Host_0, { odgPresta: "odgPresta", currentType: "currentType" }, { prestaToggle: "prestaToggle", openBadEvaluation: "openBadEvaluation" }, []);
export { OdgPrestationComponentNgFactory as OdgPrestationComponentNgFactory };
