import { DocumenthequeComponent } from './documentheque.component';
import { Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth-guard';
const ɵ0 = { title: "Documenthèque" };
const documenthequeRoutes = [
    {
        path: 'documentheque',
        component: DocumenthequeComponent,
        canActivate: [AuthGuard],
        data: ɵ0
    }
];
export class DocumenthequeRoutingModule {
}
export { ɵ0 };
