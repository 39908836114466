import { HttpStatusCode } from "../enums/http-status-code.enum";

export class FileResultModel {

  constructor(
    public file: Blob,
    public filename: string,
    public httpStatus: HttpStatusCode
  ) { }

}
