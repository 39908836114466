/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sandbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./sandbox.component";
import * as i4 from "../shared/services/interaction.service";
import * as i5 from "../shared/services/log.service";
import * as i6 from "@angular/common/http";
import * as i7 from "../shared/models/api/api-settings";
var styles_SandboxComponent = [i0.styles];
var RenderType_SandboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SandboxComponent, data: {} });
export { RenderType_SandboxComponent as RenderType_SandboxComponent };
export function View_SandboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 34, "div", [["class", "page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sandbox"])), (_l()(), i1.ɵeld(3, 0, null, null, 21, "div", [["class", "widget"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "widget-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Clock"])), (_l()(), i1.ɵeld(6, 0, null, null, 18, "div", [["class", "widget-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "widget-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Current clock : "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 12, "div", [["class", "widget-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Set clock : "])), (_l()(), i1.ɵeld(15, 0, null, null, 5, "input", [["id", "clockDateTimePicker"], ["placeholder", "D\u00E9finir Date"], ["type", "datetime-local"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 16)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 16)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.clockDate = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(18, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(20, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "red-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setClock() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Forcer la date"])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "button", [["class", "green-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetClock() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Restaurer la date"])), (_l()(), i1.ɵeld(25, 0, null, null, 9, "div", [["class", "widget"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "div", [["class", "widget-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Divers"])), (_l()(), i1.ɵeld(28, 0, null, null, 6, "div", [["class", "widget-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Test mention l\u00E9gales"])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.throwException() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Test exception logging"])), (_l()(), i1.ɵeld(33, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Test info logging"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.clockDate; _ck(_v, 18, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentClock; _ck(_v, 11, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 20).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 20).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 20).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 20).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 20).ngClassValid; var currVal_6 = i1.ɵnov(_v, 20).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 20).ngClassPending; _ck(_v, 15, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_SandboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sandbox", [], null, null, null, View_SandboxComponent_0, RenderType_SandboxComponent)), i1.ɵdid(1, 114688, null, 0, i3.SandboxComponent, [i4.InteractionService, i5.LogService, i6.HttpClient, i7.ApiSettings], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SandboxComponentNgFactory = i1.ɵccf("sandbox", i3.SandboxComponent, View_SandboxComponent_Host_0, {}, {}, []);
export { SandboxComponentNgFactory as SandboxComponentNgFactory };
