import { Component, OnInit, Input } from '@angular/core';
import { PopupCustomComponentBase } from '../../popup-custom/popup-custom-content.component';
import { PostCommandeRepasWarningApiResponse } from '../../shared/models/api/warning/post-commande-repas-warning.apiresponse';


@Component({
  selector: 'warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss']
})
export class WarningPopupComponent extends PopupCustomComponentBase {

  get warningPopupData(): WarningPopupData[] {
    let warnings: WarningPopupData[] = [];

    (<PostCommandeRepasWarningApiResponse.Warning>this.data).jours.map(jour => {
      let warningJour: WarningPopupData = {
        jourDate: jour.date,
        messagesDatas: []
      }
      let aggregate: any = [].concat(jour.famillePlats, jour.portions, jour.prestations);
      aggregate.map(item => {
        warningJour.messagesDatas.push(
          new WarningPopupDataMessage(item)
        )
      });
      warningJour.messagesDatas.sort((a, b) => {
        return a.index - b.index;
      });
      warnings.push(warningJour);

    });
    warnings.sort((a, b) => {
      return (b.jourDate < a.jourDate) ? 1 : -1;
    });
    return warnings;
  };

  constructor() {
    super();
  }
}

class WarningPopupData {
  jourDate: Date;
  messagesDatas: WarningPopupDataMessage[];
}

class WarningPopupDataMessage {
  index: number;
  message: string;

  constructor(data: any) {
    this.index = data.index;
    this.message = data.message;
  }
}