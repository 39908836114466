import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { DatePickerInfo } from 'src/app/date-input/date-input.component';
import { BonLivraisonPlatModel } from '../../models/bon-livraison-plat.model';
import { BonLivraisonDLCStatus } from '../../models/bon-livraison-dlc-status.model';
import { BonLivraisonService } from '../../services/bon-livraison.service';

@Component({
  selector: 'app-livraison-dlc',
  templateUrl: './livraison-dlc.component.html',
  styleUrls: ['./livraison-dlc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LivraisonDlcComponent implements OnInit {

  // Calcule la conformité de la DLC
  public get dlcStatus(): BonLivraisonDLCStatus {
    if (!this.plat || !this.plat.livraisonDLC || !this.dateDLCMin) {
      return BonLivraisonDLCStatus.NA;
    }

    return this._bonLivraisonService.computeDLCStatus(this.plat.livraisonDLC, this.dateDLCMin);
  }

  @Input()
  public plat: BonLivraisonPlatModel;
  @Input()
  public dateDLCMin: Date;
  @Input()
  public isModifiable: boolean;

  public dateCalendarData: DatePickerInfo = {
    minDate: moment().add(-1, 'year').startOf('year').toDate(),
    maxDate: moment().add(3, 'year').endOf('year').toDate(),
    showClearButton: true
  };

  // declare enum property to allow use in component template. see: http://stackoverflow.com/a/40034109/590741
  public DLCStatusEnum: typeof BonLivraisonDLCStatus = BonLivraisonDLCStatus; // tslint:disable-line: variable-name

  constructor(private _bonLivraisonService: BonLivraisonService) { }


  ngOnInit() { }

}

