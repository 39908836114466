/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./item-selector.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../node_modules/ngx-swiper-wrapper/dist/ngx-swiper-wrapper.ngfactory";
import * as i4 from "ngx-swiper-wrapper";
import * as i5 from "../shared/directives/swiper-restaurant-theme.directive";
import * as i6 from "./item-selector.component";
var styles_ItemSelectorComponent = [i0.styles];
var RenderType_ItemSelectorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ItemSelectorComponent, data: {} });
export { RenderType_ItemSelectorComponent as RenderType_ItemSelectorComponent };
function View_ItemSelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "circle"]], [[2, "red-button", null], [2, "green-button", null]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = !_v.parent.context.$implicit.transmis; var currVal_1 = _v.parent.context.$implicit.transmis; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ItemSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemSelectorComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasTransmisProp; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit[_co.propName]; _ck(_v, 4, 0, currVal_1); }); }
export function View_ItemSelectorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { componentRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "item-selector"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "swiper", [], null, [[null, "indexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("indexChange" === en)) {
        var pd_0 = (_co.changeIndex($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_SwiperComponent_0, i3.RenderType_SwiperComponent)), i1.ɵdid(3, 4374528, [[1, 4]], 0, i4.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i4.SWIPER_CONFIG]], { config: [0, "config"] }, { indexChange: "indexChange" }), i1.ɵdid(4, 4210688, null, 0, i5.SwiperRestaurantThemeDirective, [i1.ElementRef, i1.Renderer2, [2, i4.SWIPER_CONFIG]], { config: [0, "config"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ItemSelectorComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.swiperConfig; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.swiperConfig; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.items; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ItemSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-item-selector", [], null, null, null, View_ItemSelectorComponent_0, RenderType_ItemSelectorComponent)), i1.ɵdid(1, 12632064, null, 0, i6.ItemSelectorComponent, [i1.ElementRef], null, null)], null, null); }
var ItemSelectorComponentNgFactory = i1.ɵccf("app-item-selector", i6.ItemSelectorComponent, View_ItemSelectorComponent_Host_0, { selectedItem: "selectedItem", items: "items", propName: "propName", propComparer: "propComparer" }, { selectedItemChange: "selectedItemChange" }, []);
export { ItemSelectorComponentNgFactory as ItemSelectorComponentNgFactory };
