/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-confirm.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/directives/restaurant-type-color.directive";
import * as i3 from "@angular/common";
import * as i4 from "./popup-confirm.component";
import * as i5 from "../shared/services/application-data.service";
var styles_PopupConfirmComponent = [i0.styles];
var RenderType_PopupConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupConfirmComponent, data: {} });
export { RenderType_PopupConfirmComponent as RenderType_PopupConfirmComponent };
function View_PopupConfirmComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "pop-close close-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpod(2, { "type": 0, "background": 1 }), (_l()(), i1.ɵted(-1, null, ["OK"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.currentType, true); _ck(_v, 1, 0, currVal_0); }, null); }
function View_PopupConfirmComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "pop-close close-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpod(2, { "type": 0, "background": 1 }), (_l()(), i1.ɵted(-1, null, ["Annuler"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.currentType, true); _ck(_v, 1, 0, currVal_0); }, null); }
function View_PopupConfirmComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "popup"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "visible": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 13, "div", [["class", "popup-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "popup-header"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpod(8, { "type": 0, "background": 1 }), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "pop-close close-cross"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "popup-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "popup-message"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "popup-actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupConfirmComponent_2)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupConfirmComponent_3)), i1.ɵdid(18, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 0, "div", [["class", "popup-bckg"]], [[2, "visible", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.currentNotification.closeOnBackdropClick && _co.cancel()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "popup"; var currVal_1 = _ck(_v, 4, 0, _co.showPopup); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 8, 0, _co.currentType, true); _ck(_v, 7, 0, currVal_2); var currVal_5 = _co.currentNotification.showOk; _ck(_v, 16, 0, currVal_5); var currVal_6 = _co.currentNotification.showCancel; _ck(_v, 18, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.currentNotification.title; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.currentNotification.message; _ck(_v, 13, 0, currVal_4); var currVal_7 = _co.showPopup; _ck(_v, 19, 0, currVal_7); }); }
export function View_PopupConfirmComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupConfirmComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentNotification; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PopupConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-confirm", [], [[2, "visible", null]], null, null, View_PopupConfirmComponent_0, RenderType_PopupConfirmComponent)), i1.ɵdid(1, 245760, null, 0, i4.PopupConfirmComponent, [i5.ApplicationDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).showPopup; _ck(_v, 0, 0, currVal_0); }); }
var PopupConfirmComponentNgFactory = i1.ɵccf("app-popup-confirm", i4.PopupConfirmComponent, View_PopupConfirmComponent_Host_0, {}, {}, []);
export { PopupConfirmComponentNgFactory as PopupConfirmComponentNgFactory };
