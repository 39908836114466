
import { EventEmitter, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable, of, Subscriber } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { OdgEvaluationModel } from '../../odg/models/odg.model';
import { RestaurantTypeEnum } from '../enums/restaurant-type.enum';
import { RythmeContratEnum } from '../enums/rythme-contrat.enum';
import { ContratModel } from '../models/contrat.model';
import { RestaurantModel } from '../models/restaurant.model';
import { InteractionService } from './interaction.service';
import { LogService } from './log.service';
import { NavigationService } from './navigation.service';

/**
 * Service for sharing app Data with BehaviorSubject
 */
@Injectable()
export class ApplicationDataService {
  currentRythme: RythmeContratEnum;

  private _restaurantSubject = new BehaviorSubject<RestaurantModel>(undefined);
  private _restaurantTypeSubject = new BehaviorSubject<RestaurantTypeEnum>(undefined);
  private _pageTitleSubject = new BehaviorSubject<string>('');
  private _contratSubject = new BehaviorSubject<ContratModel>(undefined);

  restaurant$ = this._restaurantSubject.asObservable().pipe(distinctUntilChanged());
  restaurantType$ = this._restaurantTypeSubject.asObservable().pipe(distinctUntilChanged());
  pageTitle$ = this._pageTitleSubject.asObservable().pipe(distinctUntilChanged());

  contrat$ = this._contratSubject.asObservable().pipe(distinctUntilChanged());

  showBadEval: EventEmitter<OdgEvaluationModel> = new EventEmitter<OdgEvaluationModel>();
  closeBadEval: EventEmitter<OdgEvaluationModel> = new EventEmitter<OdgEvaluationModel>();

  notificationLu: EventEmitter<number> = new EventEmitter<number>();

  constructor(private logService: LogService,
              private navService: NavigationService,
              private interactionService: InteractionService,
              private title: Title) {
  }

  changeContrat(contrat: ContratModel): Observable<boolean> {
    if(contrat == this._contratSubject.value){
      return of(true);
    }

    return new Observable((sub: Subscriber<boolean>) => {
      if (this.navService.isAnyComponentDirty()) {
        this.interactionService.showConfirmNavigationDialog().subscribe(canExecute => {
          if (canExecute) {
            this.changeContrat_Imp(contrat);
          }

          sub.next(canExecute);
          sub.complete();
        });
      } else {
        this.changeContrat_Imp(contrat);
        sub.next(true);
        sub.complete();
      }
    });
  }

  changeRestaurant(rest: RestaurantModel, force: boolean = false): Observable<boolean> {
    if(rest == this._restaurantSubject.value){
      return of(true);
    }
    // Force -> Ignore dirty and force change
    return new Observable((sub: Subscriber<boolean>) => {
      if (!force && this.navService.isAnyComponentDirty()) {
        this.interactionService.showConfirmNavigationDialog().subscribe(canExecute => {
          if (canExecute) {
            this.changeRestaurant_Imp(rest);
          }

          sub.next(canExecute);
          sub.complete();
        });
      } else {
        this.changeRestaurant_Imp(rest);
        sub.next(true);
        sub.complete();
      }
    });
  }

  private changeContrat_Imp(contrat: ContratModel) {
    this.logService.Debug(`New contrat : [${contrat && contrat.id}] ${contrat && contrat.libelle} `, 'ApplicationDataService');
    this._contratSubject.next(contrat);
    this.currentRythme = contrat && contrat.rythme;
  }

  private changeRestaurant_Imp(rest: RestaurantModel) {
    this.logService.Debug(`New restaurant : [${rest && rest.id}] ${rest && rest.name} `, 'ApplicationDataService');
    this._restaurantSubject.next(rest);
    this._restaurantTypeSubject.next(rest && rest.type);
  }

  changePageTitle(newTitle: string) {
    this.logService.Debug('New PageTitle : ' + newTitle, 'ApplicationDataService');
    const applicationTitle = `${this.title.getTitle().split('-')[0].trim()} - ${newTitle}`;
    this.title.setTitle(applicationTitle);

    this._pageTitleSubject.next(newTitle);
  }

  showBadEvaluation(odgEval: OdgEvaluationModel) {
    this.showBadEval.emit(odgEval);
  }

  closeBadEvaluation(odgEval: OdgEvaluationModel) {
    this.closeBadEval.emit(odgEval);
  }

  getCloseBadEvaluation() {
    return this.closeBadEval;
  }

  discussionLuDecreaseCount(count: number) {
    this.notificationLu.next(count);
  }

}
