<div #messageList class="messages-list">
  <div class="messages-header">
    <div class="messages-header-column">
      <div class="messages-header-item"><i class="material-icons restaurant-type-color">home</i><span> {{ discussion && (discussion.restaurant + ' (' + discussion.contrat + ')') }}</span></div>
      <div class="messages-header-item"><i class="material-icons restaurant-type-color">chevron_right</i><span> {{ discussion && discussion.typeIntervention }}<small>{{ discussion && discussion.typeMaintenance ? (' - ' + discussion.typeMaintenance) : null }}</small></span></div>
      <div class="messages-header-item"><i class="material-icons restaurant-type-color">message</i><span> {{ discussion && discussion.sujet }}</span></div>
    </div>
    <div class="messages-header-column">
      <button *ngIf="discussion && discussion.peutRequalifier" class="generic-button btn-requalifier restaurant-type-background" title="Requalifier la demande" (click)="requalifierDemande()">
        <i class="material-icons">edit</i><span>Requalifier la demande</span>
      </button>
    </div>
  </div>
  <div *ngIf="isLoadingMessages" class="info-container">
    <app-loader message="Chargement des messages...">
    </app-loader>
  </div>
  <div *ngFor="let msg of discussionMessages" class="message" [class.my-message]="!msg.fromOtherUser" [hidden]="isLoadingMessages">
    <div class="infos">
      <span class="date restaurant-type-color">{{msg.dateCreation | dateFormat:'DD/MM/YYYY à HH:mm:ss'}}</span>
      <span *ngIf="msg.fromOtherUser" class="user">- {{msg.utilisateur}}</span>
    </div>
    <div class="message-text secondary" [ngClass]="{'restaurant-type-border': !msg.fromOtherUser, 'restaurant-type-background': !msg.fromOtherUser}">
      <span>{{msg.texte}}</span>
      <div class="documents" *ngIf="msg.documents.length > 0">
        <app-document-thumbnail *ngFor="let doc of msg.documents" [document]="doc" [title]="doc.nomFichier"></app-document-thumbnail>
      </div>
    </div>
  </div>
</div>

<form *ngIf="!isLoadingMessages " class="form-new-message " (submit)="sendMessage() ">
  <div *ngIf="uploader.queue.length==1 ">
    1 fichier
  </div>
  <div *ngIf="uploader.queue.length> 1"> {{uploader.queue.length}} fichiers
  </div>
  <app-file-tag-list [readonly]="isSendingMessage" [uploader]="uploader">
  </app-file-tag-list>
  <textarea [disabled]="isSendingMessage" [(ngModel)]="text" name="message" id="new-message-text" placeholder="Message" required></textarea>
  <div class="btn-row">
    <div class="cloture" *ngIf="discussion && discussion.peutCloturer">
      <label>
        <input [disabled]="isSendingMessage" [(ngModel)]="isClosed" type="checkbox" name="is-closed" />
        Clôturer
      </label>
    </div>
    <div class='right'>
      <button [disabled]="isSendingMessage" class="generic-button camera-button restaurant-type-background" type="button" takePhoto [uploader]="uploader" *ngIf="showTakePictureButton"></button>
      <button [disabled]="isSendingMessage" class="generic-button upload-button restaurant-type-background" type="button" (click)="fileInput.click()"></button>
      <button [disabled]="isSendingMessage" class="generic-button red-tile-button cancel-button" (click)="close($event)"><span>Annuler</span></button>
      <button type="submit" [disabled]="isSendingMessage" class="generic-button green-tile-button send-button restaurant-type-background"><span>{{isSendingMessage?'Envoi en cours...' : 'Envoyer'}}</span></button>
      <!-- not displayed -->
      <input [disabled]="isSendingMessage" #fileInput name="file-input" type="file" ng2FileSelect [uploader]="uploader" multiple capture="true" />
    </div>
  </div>
</form>
