import { InteractionService } from '../../services/interaction.service';
import { IPromptForNavigation } from '../../interfaces/iprompt-for-navigation';
import { RythmeContratEnum } from '../../enums/rythme-contrat.enum';
import { BaseJourModel } from '../base-jour.model';
import { LogoRouteService } from '../../services/api/logo/logo-route.service';
import { RestaurantTypeEnum } from '../../enums/restaurant-type.enum';
import { RestaurantModel } from '../restaurant.model';
import { DateSelectorComponent } from '../../../date-selector/date-selector.component';
import { DateHelper } from '../../helpers/date.helper';
import { ViewChild } from '@angular/core';

import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { SemaineModel } from '../semaine.model';
import { ApplicationDataService } from '../../services/application-data.service';

export abstract class BaseComponentNav implements IPromptForNavigation {
    abstract dateSelector: DateSelectorComponent;
    abstract checkIsDirty(): boolean;
    /** Jour Model */
    _selectedJour: BaseJourModel;
    get selectedJour(): BaseJourModel {
        return this._selectedJour;
    }
    set selectedJour(val: BaseJourModel) {
        if (val == undefined) {
            this._selectedJour = undefined;
            this.selectedDate = undefined;
            return;
        }

        this._selectedJour = val;
        this.selectedDate = val.date;
    }

    /** - Restaurant */
    _selectedRestaurant: RestaurantModel;
    get selectedRestaurant() {
        return this._selectedRestaurant;
    }
    set selectedRestaurant(rest: RestaurantModel) {
        if (rest != this._selectedRestaurant) {
            let oldRestaurant = this._selectedRestaurant;
            this._selectedRestaurant = rest;
            // notify app that we changed the restaurant in this view,
            // it will trigger the handleNewRestaurant subscription
            this._appDataService.changeRestaurant(rest).subscribe(canExecute => {
                if (!canExecute) {
                    // L'utilisateur a refusé, on remet le contrat précédent
                    this._selectedRestaurant = oldRestaurant;
                }
            },
                err => {
                    // Une erreur est arrivée : on remet le contrat précédent
                    this._selectedRestaurant = oldRestaurant;
                });
        }
    }

    currentType: RestaurantTypeEnum;
    isNoContent: boolean = false;

    restaurantList: RestaurantModel[];
    isRestaurantDisplay: boolean = true;
    logoContratUrl: string = '';

    /** - Date */
    selectedDate: Date;
    dateRanges: SemaineModel[];
    selectedRange: SemaineModel;
    isNextWeekAllowed: boolean;
    isPrevWeekAllowed: boolean;

    /** Subscriptions */
    restaurantSubscription: Subscription;
    contratSubscription: Subscription;

    private _appDataService: ApplicationDataService;
    private _logoService: LogoRouteService;
    private _interactionService: InteractionService;

    constructor(appDataService: ApplicationDataService, logoService: LogoRouteService, interactionService: InteractionService) {
        this._appDataService = appDataService;
        this._logoService = logoService;
        this._interactionService = interactionService;
    }

    abstract handleNewDate(date: Date);
    abstract handleNewRestaurant(rest: RestaurantModel)

    initComponent() {

        this.restaurantSubscription = this._appDataService.restaurant$.subscribe(
            rest => {
                if (rest != null) {
                    this.currentType = rest.type;
                    this._selectedRestaurant = rest;
                    this.handleNewRestaurant(rest);
                }
            });
    }

    destroy() {
        this.restaurantSubscription.unsubscribe();
        this.contratSubscription.unsubscribe();
    }

    refreshNavigationAllowed() {
        const index = this.dateRanges.indexOf(this.selectedRange);
        this.isNextWeekAllowed = index != this.dateRanges.length - 1;
        this.isPrevWeekAllowed = index != 0;
    }

    handleNavigation(i: number) {
        if (i > 0 && !this.isNextWeekAllowed || i < 0 && !this.isPrevWeekAllowed) {
            return;
        }

        if (this.checkIsDirty()) {
            this._interactionService.showConfirmNavigationDialog().subscribe(confirm => {
                if (confirm) {
                    this.handleNavigation_imp(i);
                }
            });
        }
        else {
            this.handleNavigation_imp(i);
        }
    };


    private handleNavigation_imp(i: number) {
        // On incrémente/décrémente sur l'index du current tout simplement
        // isNextWeekAllowed & isPrevWeekAllowed nous assure qu'on ne va pas dépasser les bounds
        // Mais on check consciencieusment
        let index = this.dateRanges.indexOf(this.selectedRange);
        let newIndex = index + i;
        if (index != - 1 && newIndex > 0 && newIndex < this.dateRanges.length) {

            this.selectedRange = this.dateRanges[newIndex];
        }
        else {
            this.selectedRange = this.dateRanges[0];
        }

        // On refresh déjà ici pour éviter le spam
        // Sera refresh avec le retour de l'API
        this.refreshNavigationAllowed();

        let now = new Date();

        // SI NOW est dans la semaine retournée, on le préselectionne (pour le mobile)
        if (DateHelper.isBetweenDates(this.selectedRange.firstDay, this.selectedRange.lastDay, now)) {
            this.selectedDate = now;
        }
        else {
            this.selectedDate = this.selectedRange.firstDay;
        }

        this.handleNewDate(this.selectedDate);
    }

    subscribeDateSelectorEvents() {
        this.dateSelector.nextClick.subscribe(
            () => {
                this.handleNavigation(1);
            });
        this.dateSelector.previewClick.subscribe(
            () => {
                this.handleNavigation(-1);
            });
    }

    /** Helpers  */
    getRangeForDate(date: Date): SemaineModel {
        for (let i = 0; i < this.dateRanges.length; i++) {
            if (DateHelper.isBetweenDates(this.dateRanges[i].firstDay, this.dateRanges[i].lastDay, date))
                return this.dateRanges[i];
        }
    }

    areSameList(list1: any[], list2: any[], prop: string): boolean {
        if (!list1 || !list2) {
            return false;
        }
        if (list1.length != list2.length) {
            return false;
        }

        return list1.every(item1 => {
            return list2.find(l2 => l2[prop] == item1[prop]);
        });
    }
}
