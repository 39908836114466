import { Component, OnInit, AfterViewInit, ViewChild, ViewEncapsulation, ElementRef, OnDestroy } from '@angular/core';
import { PopupCustomComponentGeneric } from 'src/app/popup-custom/popup-custom-content.component';
import { PopupNotificationsModel } from '../../models/popup-notifications.model';
import { AlerteModel } from '../../models/alertes.model';
import { ReceivedAlerteModel } from '../../models/received-alert.model';
import { ReceivedEnqueteModel } from '../../models/received-enquete.model';
import { EnqueteModel } from '../../models/enquete.model';
import { SwiperConfigInterface, SwiperComponent } from 'ngx-swiper-wrapper';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { ToastrService } from 'ngx-toastr';
import { orderBy } from 'lodash';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-popup-notifications',
  templateUrl: './popup-notifications.component.html',
  styleUrls: ['./popup-notifications.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopupNotificationsComponent extends PopupCustomComponentGeneric<PopupNotificationsModel> implements OnInit, OnDestroy {

  public alertes: { alerte: AlerteModel, state: ReceivedAlerteModel }[];
  public enquetes: { enquete: EnqueteModel, state: ReceivedEnqueteModel }[];

  public swiperConfig: SwiperConfigInterface = {
    navigation: true,
    watchOverflow: true, // Swiper will be disabled and hide navigation buttons on case there are not enough slides for sliding
    // slidesOffsetBefore: 30,
    // slidesOffsetAfter: 30,
    // scrollbar: true,
    simulateTouch: false, // If true, Swiper will accept mouse events like touch events (click and drag to change slides)
    // shortSwipes: false,
    // longSwipesRatio: 0.2,
    // longSwipesMs: 200,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    // autoHeight: true, // Set to true and slider wrapper will adopt its height to the height of the currently active slide
  };
  public slideIndex?: number;

  public get hasMultipleNotifications() { return ((this.alertes && this.alertes.length) + (this.enquetes && this.enquetes.length)) > 1; }

  @ViewChild(SwiperComponent, { static: true }) public componentRef: SwiperComponent;

  public restaurantType$ = this._appDataService.restaurantType$;

  constructor(private _appDataService: ApplicationDataService,
              private _toastrService: ToastrService,
              private _elementRef: ElementRef
  ) {
    super();
  }

  private loadViewModels() {
    this.alertes = orderBy(
      this.model.notifications.alertes.map(a => ({ alerte: a, state: this.model.notifications.receivedAlertes.find(ra => ra.alerteId === a.alerteId) })),
      ['state.isRead', 'alerte.alerteId']
    );
    this.enquetes = this.model.notifications.enquetes.map(e => ({ enquete: e, state: this.model.notifications.receivedEnquetes.find(re => re.enqueteId === e.enqueteId) }))
      // on ne réaffiche pas les enquêtes répondues
      .filter(e => !e.state.note);
  }

  ngOnInit() {
    this.loadViewModels();
    this.componentRef.S_SLIDECHANGE
      .pipe(untilDestroyed(this))
      .subscribe(_ => {
        // HACK, on vient faire un scroll top sur le div ".popup-body"
        const el = (this._elementRef.nativeElement as HTMLElement);
        if (el && el.closest) {
          const popupBodyEl = el.closest('div.popup-body');
          if (popupBodyEl) {
            popupBodyEl.scrollTo(0, 0);
          }
        }
      });
    // On effectue une navigation (avec animation) vers la 1ere notif non lue
    // this.slideToFirstUnread();
    this.componentRef.S_INIT // On attends que le composant soit chargé pour que l'animation fonctionne
      .pipe(untilDestroyed(this))
      .subscribe(_ => {
        this.slideToFirstUnread();
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  dismissAlerte() {
    this.slideNext();
  }

  dismissEnquete(enqueteState: ReceivedEnqueteModel) {
    if (enqueteState.note) {
      // toast de confirmation
      this._toastrService.success('Merci de votre participation!', 'Enquête');
      // on retire l'enquete de l'affichage
      this.enquetes = this.enquetes.filter(e => e.state !== enqueteState);
      this.componentRef.directiveRef.update();
      this.hideIfLastSlide();
    } else {
      this.slideNext();
    }
  }

  private slideToFirstUnread() {
    let unreadIndex = this.alertes.findIndex(a => !a.state.isRead);
    if (unreadIndex === -1) {
      unreadIndex = this.enquetes.findIndex(e => !e.state.note);
      if (unreadIndex > -1) {
        unreadIndex += this.alertes.length;
      }
    }
    if (unreadIndex > -1) {
      // this.componentRef.index = unreadIndex;
      this.componentRef.directiveRef.setIndex(unreadIndex, 300, true);
      // this.swiperConfig.initialSlide = unreadIndex;
      // this.slideIndex = unreadIndex;
    }
  }

  private slideNext() {
    this.hideIfLastSlide();
    this.componentRef.directiveRef.nextSlide();
  }

  private hideIfLastSlide() {
    const index = this.componentRef.directiveRef.getIndex();
    const notifCount = this.alertes.length + this.enquetes.length;
    if (notifCount === 0 || index === (notifCount - 1)) {
      // on ferme si on est arrivé au bout de la liste
      this.hide();
    }
  }
}
