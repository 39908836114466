export enum AllowedFeatureEnum {
  CommandeRepas,
  PiqueNiques,
  EpicerieInventaire,
  Documentheque,
  Discussion,
  ObservatoireDuGout,
  Reporting,
  Contacts,
  BonLivraison,
  Administration
}
