import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import 'hammerjs';

// HACK pour cordova: le plugin cordova-plugin-file écrase window.File, ce qui empeche d'instancier un File natif
// du coup on redirige le File natif dans une autre variable avant l'injection de cordova
window.NativeFile = File;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
