<form class="enquete-content" (submit)="repondreEnquete()" ngNativeValidate autocomplete="off">
  <div class="enquete-title" *ngIf="showTitle">
    <h2>{{ enquete.libelle }}</h2>
  </div>
  <div class="enquete-message" [innerHtml]="safeMessage"></div>
  <app-rating class="enquete-rating" [rating]="note" [itemId]="receivedEnquete.enqueteId" (ratingClick)="ratingComponentClick($event)"></app-rating>
  <div class="enquete-commentaires-container">
    <label for="commentaire">Commentaire</label>
    <textarea [placeholder]="commentaireRequired && 'Veuillez préciser' || ''" [(ngModel)]="commentaire" name="commentaire" [required]="commentaireRequired" maxlength="4000"></textarea>
  </div>
  <div class="enquete-actions">
    <button class="generic-button btn-enquete" [restaurantColor]="note && {'type': restaurantType$ | async, 'background':true}" type="submit" [class.btn-enquete-skip]="!note">
      <i class="material-icons">{{ note ? 'check' : 'chevron_right' }}</i><span>{{ note ? 'Valider' : 'Passer' }}</span>
    </button>
  </div>
  <div class="enquete-footer">
    <span>{{ enquete.informations }}</span>
  </div>
</form>
