import { RestaurantTypeEnum } from '../enums/restaurant-type.enum';

export interface RestaurantColorClasses {
  backgroundClass: string;
  borderClass: string;
  colorClass: string;
  fillClass: string;
}

export class RestaurantTypeHelper {

  static getThemeClass(type: RestaurantTypeEnum): string {
    let theme: string;
    switch (type) {
      case RestaurantTypeEnum.Creche:
        theme = 'creche';
        break;
      case RestaurantTypeEnum.Maternelle:
        theme = 'maternelle';
        break;
      case RestaurantTypeEnum.Elementaire:
        theme = 'elementaire';
        break;
      case RestaurantTypeEnum.Adulte:
        theme = 'adulte';
        break;
      default:
        theme = 'adulte'; // adulte (bleu canard)
        break;
    }
    return theme;
  }

  static getClasses(type: RestaurantTypeEnum, hover: boolean = false): RestaurantColorClasses {
    const prefix: string = RestaurantTypeHelper.getThemeClass(type) ;
    let suffix = '';

    if (hover) { suffix = '-hover'; }

    return {
      backgroundClass: `${prefix}-background${suffix}`,
      borderClass: `${prefix}-border`,
      colorClass: `${prefix}-color`,
      fillClass: `${prefix}-fill`
    };
  }
}
