import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, finalize, first, map } from 'rxjs/operators';
import { DatePickerInfo } from 'src/app/date-input/date-input.component';
import { AllowedFeatureEnum } from 'src/app/shared/enums/allowed-feature.enum';
import { FileHelper } from 'src/app/shared/helpers/file.helper';
import { ErrorApiModel } from 'src/app/shared/models/api/error/error.apimodel';
import { ContratModel } from 'src/app/shared/models/contrat.model';
import { CuisineCentraleModel } from 'src/app/shared/models/cuisine-centrale.model';
import { FileResultModel } from 'src/app/shared/models/fileresult.model';
import { GrandCompteModel } from 'src/app/shared/models/grand-compte.model';
import { NotificationModel } from 'src/app/shared/models/notification.model';
import { RestaurantModel } from 'src/app/shared/models/restaurant.model';
import { UserInfoModel } from 'src/app/shared/models/user-info.model';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { InteractionService } from 'src/app/shared/services/interaction.service';
import { DiscussionService } from '../services/discussion.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ReportingService } from 'src/app/reporting/services/reporting.service';

@Component({
  selector: 'app-export-suivi-qualite',
  templateUrl: './export-suivi-qualite.component.html',
  styleUrls: ['./export-suivi-qualite.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportSuiviQualiteComponent implements OnInit, OnDestroy {

  public dateCalendarData: DatePickerInfo = {
    minDate: moment().add(-2, 'year').startOf('year').toDate(),
    maxDate: moment().add(1, 'year').endOf('year').toDate()
  };

  public isLoading: boolean = false;
  public isDowloading: boolean = false;
  public isAllowed: boolean = false;

  public cuisines: CuisineCentraleModel[] = [];
  public grandComptes: GrandCompteModel[] = [];

  public allowGrandCompte: boolean = true;
  private _selectedGrandCompte: GrandCompteModel = null;
  public get selectedGrandCompte(): GrandCompteModel {
    return this._selectedGrandCompte;
  }
  public set selectedGrandCompte(v: GrandCompteModel) {
    if (this._selectedGrandCompte !== v) {
      this._selectedGrandCompte = v;
      if (this._selectedGrandCompte !== null) {
        this._selectedCuisine = null; // si grand compte alors toutes cuisines
      }
      const contrats = this.contrats; // on sélectionne le contrat si c'est le seul accessible
      this.selectedContrat = (contrats.length == 1)
        ? contrats[0] // seul contrat accessible sur le grand compte
        : null; // tous les contrats
    }
  }

  private _selectedCuisine: CuisineCentraleModel = null;
  public get selectedCuisine(): CuisineCentraleModel {
    return this._selectedCuisine;
  }
  public set selectedCuisine(v: CuisineCentraleModel) {
    if (this._selectedCuisine !== v) {
      this._selectedCuisine = v;
      if (this._selectedCuisine !== null) {
        this._selectedGrandCompte = null; // si cuisine alors pas de filtre grand compte
      }
      const contrats = this.contrats; // on sélectionne le contrat si c'est le seul accessible
      this.selectedContrat = (contrats.length == 1)
        ? contrats[0] // seul contrat accessible sur la cuisine
        : null; // tous les contrats
    }
  }

  public get contrats(): ContratModel[] {
    return (this.selectedCuisine && this.selectedCuisine.contrats)
      || (this.selectedGrandCompte && this.getGrandCompteContrats(this.selectedGrandCompte))
      || [];
  }

  public selectedContrat: ContratModel = null;
  public dateDebut: Date = moment().startOf('month').toDate();
  public dateFin: Date = moment().endOf('month').startOf('day').toDate();

  constructor(
    private _appDataService: ApplicationDataService,
    private _authService: AuthenticationService,
    private _discussionService: DiscussionService,
    private _reportingService: ReportingService
  ) { }

  ngOnInit() {
    this.isAllowed = false;
    this.isLoading = true;

    // on prends le dernier restaurant et on complete le stream que l'on combine ensuite aux infos user pour en tirer le context actuel
    combineLatest([
      this._appDataService.restaurant$,
      this._authService.currentUser$]
    )
      .pipe(
        untilDestroyed(this),
        map(([restaurant, user]) => ({ restaurant, user }))
      )
      .subscribe((result: { restaurant: RestaurantModel, user: UserInfoModel }) => {
        this.cuisines = result && result.user && result.user.cuisines || [];
        this.grandComptes = result && result.user && result.user.grandComptes || [];
        // on affiche les grand compte que s'il y en a plusieurs (null compris)
        this.allowGrandCompte = result && result.user && new Set(result.user.allContrats.map(c => c.grandCompteId)).size > 1;

        this.selectedCuisine = result && result.user && result.restaurant && this.cuisines.find(c => c.id === result.restaurant.cuisineCentraleId);
        this.selectedContrat = result && result.user && result.restaurant && this.contrats.find(c => c.id === result.restaurant.contratId);

        this.isAllowed = result && result.user && result.user.hasAccessToFeature(AllowedFeatureEnum.Discussion);

        this.isLoading = false;
      });

  }

  ngOnDestroy(): void {
  }

  export() {
    this.isDowloading = true;
    const report$ = this._discussionService
      .exportEnquetesSatisfaction({
        grandCompteId: this.selectedGrandCompte && this.selectedGrandCompte.id,
        cuisineCentraleId: this.selectedCuisine && this.selectedCuisine.id,
        contratId: this.selectedContrat && this.selectedContrat.id,
        dateDebut: this.dateDebut,
        dateFin: this.dateFin
      }
      ).pipe(
        debounceTime(1000),
        first(),
        finalize(() => {
          this.isDowloading = false;
        }));

    this._reportingService.handleReportResponse(report$);
  }

  private getGrandCompteContrats(grandCompte: GrandCompteModel): ContratModel[] {
    return this.cuisines
      && this.cuisines.reduce<ContratModel[]>((prev, current) => prev.concat(grandCompte == null ? current.contrats : current.contrats.filter(c => c.grandCompteId === grandCompte.id)), []) || [];
  }

}

