import { OdgComponent } from './odg.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/guards/auth-guard';


const odgRoutes: Routes = [
  {
    path: 'observatoire',
    component: OdgComponent,
    canActivate: [AuthGuard],
    data: { title: "Observatoire du goût" }
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(odgRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class OdgRoutingModule { }
