export let ROUTE_URLS = {
    Home: '/home',
    CmdRepas: '/repas',
    PiqueNique: '/piquenique',
    EpicerieInventaire: '/epicerieinventaire',
    Observatoire: '/observatoire',
    Documentheque: '/documentheque',
    Discussion: '/demande',
    Reporting: '/reporting',
    Login: '/login',
    Contact: '/contact',
    BonLivraison: '/bonlivraison'
};
