
import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, first, filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { ErrorApiModel } from '../../shared/models/api/error/error.apimodel';
import { NotificationCustomModel, NotificationModel } from '../../shared/models/notification.model';
import { ApplicationDataService } from '../../shared/services/application-data.service';
import { InteractionService } from '../../shared/services/interaction.service';
import { BonLivraisonApiErrorCodes } from '../models/api/bon-livraison-api-error-codes';
import { BonLivraisonModel, BonLivraisonStatut, MiseEnConformiteStatut, ReceptionStatut } from '../models/bon-livraison.model';
import { BonLivraisonService } from '../services/bon-livraison.service';
import { PopupValidationComponent } from './popup-validation/popup-validation.component';
import { PopupValidationModel } from './popup-validation/popup-validation.model';

@Component({
  selector: 'app-details-bon-livraison',
  templateUrl: './details-bon-livraison.component.html',
  styleUrls: ['./details-bon-livraison.component.scss']
})
export class DetailsBonLivraisonComponent implements OnInit, OnDestroy {

  private bonLivraisonCode: string;
  public detailsBonLivraison: BonLivraisonModel;
  private _originalBonLivraisonQteLivreeCheckSum: string;

  public get hasChanged() {
    return this.detailsBonLivraison && (this._originalBonLivraisonQteLivreeCheckSum != this.bonLivraisonService.checkSumQteLivree(this.detailsBonLivraison));
  }

  public get hasManque() {
    return this.detailsBonLivraison && this.bonLivraisonService.checkHasManque(this.detailsBonLivraison);
  }

  public get notifyDateFinReceptionExpired() {
    return this.detailsBonLivraison
      && this.detailsBonLivraison.dateFinReceptionExpired
      && !this.detailsBonLivraison.dateClotureExpired;
  }

  public get notifyDateClotureExpired() {
    // on met en évidence la Clotûre lorsque le bon a été cloturé automatiquement (statut ConformeCloture)
    return this.detailsBonLivraison
      && this.detailsBonLivraison.dateClotureExpired
      && (this.detailsBonLivraison.statut == BonLivraisonStatut.AReceptionnerCloture
        || this.detailsBonLivraison.statut == BonLivraisonStatut.ManqueCloture);
  }

  public isValidate: boolean = false;

  public isLoadingBonLivraison: boolean = false;

  private askLoadDetailsBLEmitter: EventEmitter<void> = new EventEmitter<void>();

  private _currentRestaurantId?: number;

  // declare enum property to allow use in component template. see: http://stackoverflow.com/a/40034109/590741
  public BonLivraisonStatutEnum: typeof BonLivraisonStatut = BonLivraisonStatut; // tslint:disable-line: variable-name
  public ReceptionStatutEnum: typeof ReceptionStatut = ReceptionStatut;  // tslint:disable-line: variable-name
  public MiseEnConformiteStatutEnum: typeof MiseEnConformiteStatut = MiseEnConformiteStatut;  // tslint:disable-line: variable-name

  constructor(private route: ActivatedRoute,
              private bonLivraisonService: BonLivraisonService,
              private appDataService: ApplicationDataService,
              private authService: AuthenticationService,
              private interactionService: InteractionService,
              private router: Router
  ) { }

  ngOnInit() {

    this.route.params
      .pipe(
        untilDestroyed(this)
      )
      // .observeOn(Scheduler.asap) // TODO: à voir : le changement de title doit être fait de façon synchrone pour ne pas planter le ChangeDetector
      .subscribe(params => {
        this.bonLivraisonCode = params.codeBonLivraison;
        if (this.bonLivraisonCode != null) {
          this.appDataService.changePageTitle(`Réception ${this.bonLivraisonCode}`);
          this.askToGetDetails();
        }
      }
      );

    this.appDataService.restaurant$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(
        restaurant => {
          if (restaurant != null) {
            this._currentRestaurantId = restaurant.id;
          }
        }
      );

    this.askLoadDetailsBLEmitter
      .pipe(
        untilDestroyed(this),
        debounceTime(500))
      .subscribe(
        () => {
          this.bonLivraisonService.getBonLivraison(this.bonLivraisonCode)
            .subscribe(
              bonLivraison => {
                if (bonLivraison.statut === BonLivraisonStatut.ALivrer) {
                  this.router.navigate(['/bonlivraison/livraison/' + bonLivraison.code], { queryParams: {} });
                } else {
                  this.isLoadingBonLivraison = false;
                  this.detailsBonLivraison = bonLivraison;
                  this._originalBonLivraisonQteLivreeCheckSum = this.bonLivraisonService.checkSumQteLivree(this.detailsBonLivraison);
                  // console.log(success);
                  this.updateCurrentRestaurantIfNeeded();
                }
              },
              (error: ErrorApiModel) => {
                this.isLoadingBonLivraison = false;
                if (error.Code === BonLivraisonApiErrorCodes.BonLivraisonNotFound) {
                  this.interactionService.showSimpleMessage(`Réception ${this.bonLivraisonCode} inconnu`, 'Veuillez saisir un autre code.');
                  this.router.navigate(['/bonlivraison'], { queryParams: {} });
                } else {
                  const notifModel = new NotificationModel();
                  notifModel.title = 'Erreur';
                  notifModel.message = 'Erreur de chargement du bon : \n' + error.Message;
                  this.interactionService.showNotification(notifModel);
                }
              });
        }
      );

    this.askToGetDetails();
  }

  ngOnDestroy(): void {
    // mandatory (untildestroy)
  }

  private askToGetDetails() {
    this.isLoadingBonLivraison = true;
    this.askLoadDetailsBLEmitter.emit();
  }

  private updateCurrentRestaurantIfNeeded() {
    if (this._currentRestaurantId != null
      && this._currentRestaurantId != this.detailsBonLivraison.restaurantId) {
      this.authService.currentUser$
        .pipe(
          first(),
          filter(user => !!user),
          untilDestroyed(this)
        )
        .subscribe(user => {
          // find restaurantModel in user perimeter
          const bonLivraisonContrat = user.allContrats.find(c => c.id === this.detailsBonLivraison.contratId);
          const bonLivraisonResto = bonLivraisonContrat.restaurants.find(r => r.id === this.detailsBonLivraison.restaurantId);
          this.appDataService.changeContrat(bonLivraisonContrat).pipe(first()).subscribe();
          this.appDataService.changeRestaurant(bonLivraisonResto, true).pipe(first()).subscribe();
        });
    }
  }

  validate() {
    const notif: NotificationCustomModel = new NotificationCustomModel();
    notif.id = 1;
    notif.title = 'Valider le BL';
    notif.type = PopupValidationComponent;
    notif.fullScreen = false;
    notif.closeOnBackdropClick = false;
    const popupModel: PopupValidationModel = {
      bonLivraison: this.detailsBonLivraison,
      hasModification: this.hasChanged
    };
    notif.data = popupModel;
    notif.hasNoOverflow = true;

    this.interactionService.showNotification(notif);

  }

  public get isModifiable() {
    return ((this.detailsBonLivraison.peutReceptionner) || (this.detailsBonLivraison.peutMettreEnConformite));
  }

}
