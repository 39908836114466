<div class="container"
     [ngClass]="{'visible': showDrawer}">
  <div (click)="hide()"
       id="behind-layout">
  </div>
  <nav id="drawer">
    <app-user-info></app-user-info>
    <app-hamburger class="pointerHover"
                   color="#000"
                   isRight="true"
                   (click)="hide()"></app-hamburger>
    <div class="clear"></div>
    <div class="dropdown-wrapper" *ngIf="showDrawer">
      <!-- <select-autocomplete [(entity)]="currentContrat" [entities]="contrats" entityDisplayProp="libelle"></select-autocomplete>
            <select-autocomplete [(entity)]="currentRestaurant" [entities]="restaurants" entityDisplayProp="name"></select-autocomplete> -->

      <app-restaurant-selector [contrats]="contrats"
                               [selectedRestaurant]="restaurant"
                               (selectedRestaurantContextChange)="selectedRestaurantContextChanged($event)"
                               [showDebugInfo]="showDebugInfo"
                               [displayGrandCompteSelector]="displayGrandCompteSelector"></app-restaurant-selector>

    </div>
    <div class="menu-item-container">
      <app-menu-item (onNavigated)="hide()"
                     class="pointerHover"
                     *ngFor="let item of menuList"
                     [restaurantType]="currentType"
                     [item]="item">
      </app-menu-item>
    </div>
    <div [restaurantColor]="{'type': currentType, 'background':true}"
         class="company-bar color-animation">
      <img class="brand"
           src="/assets/images/brand-logo.png"
           (click)="navigateToHome()" />
      <img *ngIf="hasAccessToContact"
           title="Contacts"
           class="contact pointerHover"
           (click)="navigateToContact()"
           src="assets/images/mobile-circle.png" />
    </div>
    <div class="contrat-bar"
         [style.background-image]="'url(' + logoContratUrl + ')'">
      <!--<img [src]="logoContratUrl" />-->
    </div>
  </nav>
</div>
