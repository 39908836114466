
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LegalInfoComponent } from '../legal-info/legal-info.component';
import { ROUTE_URLS } from '../shared/constants/route_urls';
import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { ErrorApiModel } from '../shared/models/api/error/error.apimodel';
import { NotificationCustomModel } from '../shared/models/notification.model';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { InteractionService } from '../shared/services/interaction.service';
import { LogService } from '../shared/services/log.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { first, filter } from 'rxjs/operators';

const { version: appVersion } = require('../../../package.json');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {


  isLoading: boolean;
  username: string = '';
  keepMeSignedIn: boolean;
  activeFormClass: string = 'show-login';

  errorMessage: string = '';

  isPasswordSent: boolean;
  isPasswordFail: boolean;
  passwordFailError: string;

  appVersion: string;

  loginForm: FormGroup;
  RestaurantTypeEnum: typeof RestaurantTypeEnum = RestaurantTypeEnum; // declare enum property to allow use in component template. see: http://stackoverflow.com/a/40034109/590741

  showInternetExplorerWarning: boolean;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private logService: LogService,
    private interactionService: InteractionService,
    private formBuilder: FormBuilder,
    @Inject(PLATFORM_ID) private platformId) {
    if (isPlatformBrowser(this.platformId)) {
      this.showInternetExplorerWarning = this.isIE();
    }
    this.appVersion = appVersion;
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required],
    });

    // Rediriger vers la page d'accueil si on est déjà loggué
    this.authService.currentUser$
      .pipe(
        first(),
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => {
        if (user) {
          this.router.navigate([ROUTE_URLS.Home]);
        }
      });

  }

  ngOnDestroy(): void {
    // mandatory, used par untilDestroy
  }

  tryLogin() {

    if (!this.loginForm.valid) {
      return;
    }

    this.isLoading = true;
    this.errorMessage = '';

    this.authService.signIn(this.loginForm.value.login, this.loginForm.value.password)
      .subscribe(userInfoModel => {
        this.logService.Info('User login successfull : redirecting to /home', 'LoginComponent');
        if (userInfoModel) {
          this.redirectOnLogin();
        } else {
          this.errorMessage = 'Nous n\'avons pas réussi à récupérer vos informations d\'utilisateur.';
        }
      },
        (error: ErrorApiModel) => {
          this.errorMessage = 'Votre tentative de connexion a échoué, réessayez ou contactez le service client';
          if (error && error.Message) {
            this.errorMessage = `${this.errorMessage} : \r\n${error.Message}`;
          }
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        });
  }

  redirectOnLogin() {
    const redirectUrl = this.authService.redirectUrl;
    if (redirectUrl) {
      this.router.navigateByUrl(redirectUrl);
    } else {
      this.router.navigate([ROUTE_URLS.Home]);
    }
  }

  getPassword() {
    this.isPasswordSent = false;
    this.isPasswordFail = false;
    this.authService.sendPassword(this.username)
      .subscribe(res => {
        this.isPasswordSent = true;
        this.isPasswordFail = false;
      },
        (error: ErrorApiModel) => {
          this.isPasswordFail = true;
          this.passwordFailError = error && error.Message;
        });
  }

  showForgottenPwd() {
    this.activeFormClass = 'show-forgotten-pwd';
  }

  showLogin() {
    this.activeFormClass = 'show-login';
  }

  showLegalInfos() {
    const notif: NotificationCustomModel = new NotificationCustomModel();
    notif.title = 'Mentions légales';
    notif.type = LegalInfoComponent;
    notif.fullScreen = true;

    this.interactionService.showNotification(notif);
  }

  private isIE() {
    try {
      const ua = window.navigator.userAgent;
      if (ua.indexOf('MSIE ') !== -1 || ua.indexOf('Trident/') !== -1) {
        return true;
      }
    } catch (error) {
    }
    return false;
  }
}
