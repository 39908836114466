
import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { debounceTime, switchMap } from 'rxjs/operators';
import { DatePickerInfo } from '../date-input/date-input.component';
import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { ErrorApiModel } from '../shared/models/api/error/error.apimodel';
import { NotificationCustomModel, NotificationModel } from '../shared/models/notification.model';
import { LogoRouteService } from '../shared/services/api/logo/logo-route.service';
import { ApplicationDataService } from '../shared/services/application-data.service';
import { InteractionService } from '../shared/services/interaction.service';
import { BonLivraisonListModel } from './models/bon-livraison-list.model';
import { BonLivraisonStatut } from './models/bon-livraison.model';
import { PopupSearchCodeBarreComponent } from './popup-search-code-barre/popup-search-code-barre.component';
import { BonLivraisonService } from './services/bon-livraison.service';

@Component({
  selector: 'app-bon-livraison',
  templateUrl: './bon-livraison.component.html',
  styleUrls: ['./bon-livraison.component.scss']
})
export class BonLivraisonComponent implements OnInit {

  public BonLivraisonStatutEnum: typeof BonLivraisonStatut = BonLivraisonStatut; // declare enum property to allow use in component template. see: http://stackoverflow.com/a/40034109/590741
  public startDate: Date;
  public endDate: Date;

  public currentType: RestaurantTypeEnum;
  public dateCalendarData: DatePickerInfo;
  public contratPicture: string = '';

  private askLoadListeBLsEmitter: EventEmitter<void> = new EventEmitter<void>();
  public listeBonsLivraison: BonLivraisonListModel[] = new Array<BonLivraisonListModel>();

  public isLoadingBonsLivraison: boolean = false;

  private cuisineId: number;
  private cuisineCode: string;
  private contratId: number;
  private restaurantId: number;

  constructor(
    private router: Router,
    private logoService: LogoRouteService,
    private appDataService: ApplicationDataService,
    private bonLivraisonService: BonLivraisonService,
    private interactionService: InteractionService
  ) { }

  ngOnInit() {
    this.startDate = moment().add(-1, 'days').toDate(); // date d'hier
    this.endDate = moment().add(1, 'days').toDate(); // Jusqu"à demain (+1 jour)
    this.dateCalendarData = {
      minDate: moment().year(1970).startOf('year').toDate()
    };

    this.appDataService.contrat$.subscribe(
      contrat => {
        if (contrat != null) {
          this.contratPicture = this.logoService.GetPicture2Url(contrat.id, contrat.cuisineCentraleId);
        }
      }
    );

    this.appDataService.restaurant$.subscribe(
      restaurant => {
        if (restaurant != null) {
          this.currentType = restaurant.type;
          this.restaurantId = restaurant.id;
          this.contratId = restaurant.contratId;
          this.cuisineId = restaurant.cuisineCentraleId;
          this.cuisineCode = restaurant.cuisineCentraleCode;
          this.askLoadListeBLs();
        }
      }
    );

    this.askLoadListeBLsEmitter.pipe(
      debounceTime(500),
      switchMap(() => {
        this.isLoadingBonsLivraison = true;
        return this.bonLivraisonService.getBonsLivraisonListe(this.cuisineId, this.contratId, this.restaurantId, this.startDate, this.endDate);
      }))
      .subscribe(bons => {
        this.listeBonsLivraison = bons;
        this.isLoadingBonsLivraison = false;
      },
        (error: ErrorApiModel) => {
          this.isLoadingBonsLivraison = false;

          const notifModel = new NotificationModel();
          notifModel.title = 'Erreur';
          notifModel.message = 'Une erreur est survenue lors du chargement des bons : \n' + error.Message;
          this.interactionService.showNotification(notifModel);
        }
      );

    this.askLoadListeBLs();
  }

  askLoadListeBLs() {
    this.askLoadListeBLsEmitter.emit();
  }

  navigateToDetails(codeBarre: string) {
    this.router.navigate(['/bonlivraison/details/' + codeBarre], { queryParams: {} });
  }

  searchBonsLivraison($event: any) {
    this.askLoadListeBLs();
  }

  OpenAjouterBL() {
      const notif: NotificationCustomModel = new NotificationCustomModel();
      notif.id = 1;
      notif.title = 'Rechercher un bon de livraison';
      notif.type = PopupSearchCodeBarreComponent;
      notif.data = { currentType: this.currentType, cuisineCode: this.cuisineCode };
      notif.fullScreen = false;

      this.interactionService.showNotification(notif);
  }

}
