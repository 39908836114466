import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss']
})
export class HamburgerComponent implements OnInit {
  @Input()
  isRight: boolean;

  @Input()
  color: string;

  constructor() { }

  ngOnInit() {
    if (!this.color) {
      this.color = "#fff";
    }
  }

}
