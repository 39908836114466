
import { Directive, ElementRef, EventEmitter, HostListener, Input, Renderer2 } from "@angular/core";
import { debounceTime } from 'rxjs/operators';

@Directive({ selector: '[translateSlider]' })
export class TranslateSliderDirective {

  // Emitter to avoid spam renderer through window.resize event
  private translateEmitter: EventEmitter<void>;
  private index: number;
  private width: number;
  constructor(public el: ElementRef, public renderer: Renderer2) {
    this.width = window.innerWidth;
    this.translateEmitter = new EventEmitter<void>();
    this.translateEmitter
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.setTranslate();
      });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.width = event.target.innerWidth;
    this.translateEmitter.next();
  }

  @Input()
  set translateSlider(index: number) {
    if (index == undefined) {
      index = 0;
    }
    else {
      this.index = index;
    }
    this.setTranslate();
  }

  setTranslate() {
    if (this.width <= 768) {
      const translateX = `translateX(${this.index * -100}vw)`;
      this.renderer.setStyle(this.el.nativeElement, "transform", translateX);
    }
    else if (this.width > 768) {
      this.renderer.setStyle(this.el.nativeElement, "transform", "none");
    }
  }
}
