import {
  CommandeRepasErrorModel,
  FamillePlatErrorModel,
  JourErrorModel,
  PortionErrorModel,
  PrestationErrorModel,
  CommandeRepasUpdateErrorTypeEnum
} from '../../shared/models/commande-repas-error.model';
import {
  DailyFamilleModel,
  DailyMenuPrestationModel,
  DailyPlatModel
} from '../../shared/models/daily-menu/daily-menu-prestation.model';
import {
  CommandeRepasModel,
  ConviveModel,
  FamillePlatModel,
  JourModel,
  ModeSaisieEnum,
  ModeSaisiePortionEnum,
  PeriodeContratTypeEnum,
  PeriodeModel,
  PortionModel,
  PrestationModel,
  RegimeDataModel
} from '../models/commande-repas.model';
import { DateHelper } from '../../shared/helpers/date.helper';
import { FamillePlatsControl, PrestationFormModel } from '../models/prestation-form.model';

import { GetDailyMenuApiResponse } from '../models/api/responses/get-daily-menu.apiresponse';
import { GetCommandeRepasApiResponse } from '../models/api/responses/get-commande-repas.apiresponse';
import { PostCommandeRepasApiRequest } from '../models/api/requests/post-commande-repas.apirequest';
import { PostCommandeRepasErrorApiResponse } from '../../shared/models/api/error/post-commande-repas-error.apiresponse';

function formatDate(date?: Date): string {
  return date != null ? DateHelper.format(date, 'DD/MM/YYYY à HH:mm') : null;
}

export class CommandeRepasProjector {

  /** Daily functions */
  public static projectDailyMenus(apiPrestas: GetDailyMenuApiResponse.Prestation[]): DailyMenuPrestationModel[] {
    return apiPrestas.map((apiPresta, i) => {
      const prestaModel: DailyMenuPrestationModel = {
        date: apiPresta.date,
        familles: this.projectDailyFamilles(apiPresta.familles),
        isEmpty: !apiPresta.familles || apiPresta.familles.length == 0,
        prestation: apiPresta.prestation
      };
      return prestaModel;
    });
  }

  private static projectDailyFamilles(familles: GetDailyMenuApiResponse.Famille[]): DailyFamilleModel[] {
    return familles.map(f => {
      return {
        plats: this.projectDailyPlats(f.plats)
      };
    });
  }

  private static projectDailyPlats(plats: GetDailyMenuApiResponse.Plat[]): DailyPlatModel[] {
    return plats.map(p => {
      return {
        libelle: p.libelle
      };
    });
  }

  /** Commande repas */
  static projectCmdRepas(apiCmdRepas: GetCommandeRepasApiResponse.CommandeRepas): CommandeRepasModel {
    return {
      transmis: apiCmdRepas.valide,
      semaines: apiCmdRepas.semaines,
      autoriserDepassementEffectif: apiCmdRepas.autoriserDepassementEffectif,
      autoriserInsuffisanceEffectif: apiCmdRepas.autoriserInsuffisanceEffectif,
      convives: this.projectConvives(apiCmdRepas.convivesDisponibles),
      lieuCommandes: apiCmdRepas.lieuCommandeDisponibles,
      jourFinSemaine: apiCmdRepas.jourFinSemaine,
      jours: this.projectJours(apiCmdRepas.jours),
      modifiePar: apiCmdRepas.modifiePar,
      dateModification: formatDate(apiCmdRepas.dateModification),
      exportRequis: apiCmdRepas.exportRequis
    };
  }

  private static projectConvives(convivesApi: GetCommandeRepasApiResponse.Convive[]): ConviveModel[] {
    return convivesApi.map(convApi => {
      const convive: ConviveModel = {
        abrege: convApi.abrege,
        id: convApi.id,
        libelle: convApi.libelle,
        ordre: convApi.ordre,
        transmis: convApi.transmis
      };
      return convive;
    });
  }

  private static projectJours(jours: GetCommandeRepasApiResponse.Jour[]): JourModel[] {
    return jours.map((j) => {
      const model = new JourModel(j.date);
      model.periode = this.projectPeriode(j.periode);
      model.quantitePiqueNique = j.quantitePiqueNique;
      model.prestations = this.projectPrestations(j.prestations);

      const dateFormat = 'DD/MM/YYYY HH:mm:ss';
      const periodeType = this.getPeriodeTypeText(model.periode.type);
      let tooltipText = `[${periodeType}] ${DateHelper.format(model.periode.startDate, dateFormat)} -> ${model.periode.endDate ? DateHelper.format(model.periode.endDate, dateFormat) : 'Pas de date'}`;
      if (j.quantitePiqueNique > 0) {
        tooltipText += `\nCette journée contient des pique-niques (${j.quantitePiqueNique}).`;
      }

      if (j.dateModification) {
        tooltipText += `\nValidé le ${DateHelper.format(j.dateModification, dateFormat)} par ${j.modifiePar}.`;
      }

      model.periodeTooltipInfo = tooltipText;
      return model;
    });
  }

  private static getPeriodeTypeText(type: PeriodeContratTypeEnum): string {
    switch (type) {
      case PeriodeContratTypeEnum.Commande:
        return 'COMMANDE';
      case PeriodeContratTypeEnum.HorsPeriode:
        return 'HORS PERIODE';
      case PeriodeContratTypeEnum.Precommande:
        return 'PRECO';
      case PeriodeContratTypeEnum.Reel:
        return 'REEL';
      case PeriodeContratTypeEnum.Reajustement:
        return 'REAJUST';
    }
  }
  private static projectModeSaisie(modeSaisie: string): ModeSaisieEnum {
    return ModeSaisieEnum[modeSaisie];
  }

  private static projectPeriode(periode: GetCommandeRepasApiResponse.Periode): PeriodeModel {
    return {
      dateReference: periode.dateReference,
      endDate: periode.endDate,
      ratioModification: periode.ratioModification,
      startDate: periode.startDate,
      type: this.projectPeriodeType(periode.type)
    };
  }

  private static projectPeriodeType(type: string): PeriodeContratTypeEnum {
    return PeriodeContratTypeEnum[type];
  }

  private static handleSpecificData(model: PrestationModel, p: GetCommandeRepasApiResponse.Prestation, famillePlats: FamillePlatModel[]) {
    if (p.regimeId) {
      const regimeData: RegimeDataModel = {
        quantiteJour: p.quantiteJour,
        quantiteJourMax: p.quantiteJourMax,
        quantiteJourMin: p.quantiteJourMin,
        quantitePrecommande: p.quantitePrecommande,
        regimeId: p.regimeId,
        regimeLibelle: p.regimeLibelle,
        effectifId: p.effectifId,

        dateModification: formatDate(p.dateModification),
        dateExport: formatDate(p.dateExport),
        dateImport: formatDate(p.dateImport),
        exportRequis: p.exportRequis
      };

      model.regimeData = regimeData;

      model.prestationTooltipInfo += `\nEffectif régime ${p.regimeLibelle} min-max : ${p.quantiteJourMin}-${p.quantiteJourMax === null ? 'max' : p.quantiteJourMax}.`;
      model.prestationTooltipInfo += `\nHistorique (régime ${p.regimeLibelle}) :`;
    } else {
      model.quantiteJour = p.quantiteJour;
      model.quantiteJourMax = p.quantiteJourMax;
      model.quantiteJourMin = p.quantiteJourMin;
      model.quantitePrecommande = p.quantitePrecommande;
      model.effectifId = p.effectifId;
      model.dateModification = formatDate(p.dateModification);
      model.dateExport = formatDate(p.dateExport);
      model.dateImport = formatDate(p.dateImport);
      model.exportRequis = p.exportRequis;

      model.prestationTooltipInfo += `\nEffectif min-max : ${p.quantiteJourMin}-${p.quantiteJourMax === null ? 'max' : p.quantiteJourMax}.`;
      model.prestationTooltipInfo += '\nHistorique :';
    }

    const hasCCUpdatedAfterTransmission = p.dateExport && (p.dateImport > p.dateExport);

    p.historique.forEach(h => {
      const isLastTransmission = h.dateExport && (h.dateExport === p.dateExport);
      model.prestationTooltipInfo += `\n - ${h.periode} : ${(h.quantite === null) ? 'non modifié' : h.quantite}`;
      if (isLastTransmission && hasCCUpdatedAfterTransmission) {
        model.prestationTooltipInfo += `. Modifié par la CC le ${formatDate(p.dateImport)}`;
      } else {
        model.prestationTooltipInfo += `${h.dateExport ? ('. Transmis le ' + formatDate(h.dateExport)) : ''}`;
      }
    });
    model.prestationTooltipInfo += '\n';

    // La première fois on initialise tout simplement avec les premières données
    if (!model.famillePlats || model.famillePlats.length == 0) {
      model.famillePlats = famillePlats;
    } else {
      const fpToAdd: FamillePlatModel[] = [];
      // Sinon, on doit merger les nouvelles avec l'existant
      // Pour chaque famille :
      const newFamillePlats = famillePlats.forEach(newFp => {
        // On cherche si la famille existe déja
        const existingFp = model.famillePlats.find(fp => fp.famillePlat == newFp.famillePlat);
        if (existingFp) {
          // La famille de plat existe déja, donc on append les portions
          // on filtre si on a 2 plats identiques : mais ça ne devrait pas arriver non ?
          const newPortions = newFp.portions.filter(newP => {
            return existingFp.portions.find(presta => presta.plat != newP.plat);
          });

          existingFp.portions = existingFp.portions.concat(newPortions);
        } else {
          // La famille de plat n'existe pas, on a juste à l'ajouter
          model.famillePlats.push(newFp);
        }
      });

      // add
      model.famillePlats = model.famillePlats.concat(fpToAdd);
    }
  }

  private static projectPrestations(prestasApi: GetCommandeRepasApiResponse.Prestation[]): PrestationModel[] {
    const prestaDico: { [key: string]: PrestationModel } = {}; // TODO : pourquoi faire un set basé sur prestationId ??
    prestasApi.forEach(p => {
      if (prestaDico[p.prestationId] === undefined) {
        const model = new PrestationModel();
        model.isCollapsed = false;
        model.nombreLimiteFamillePlats = p.nombreLimiteFamillePlats;
        model.prestation = p.prestation;
        model.prestationId = p.prestationId;
        model.autoriserSaisie = p.autoriserSaisie;
        model.modeSaisie = this.projectModeSaisie(p.modeSaisie);
        model.historique = p.historique;
        model.autoriserSaisieTauxPrise = p.autoriserSaisieTauxPrise;
        model.prestationOrdre = p.prestationOrdre;
        model.valide = p.valide;
        model.dateModification = formatDate(p.dateModification);
        model.dateExport = formatDate(p.dateExport);
        model.dateImport = formatDate(p.dateImport);
        model.exportRequis = p.exportRequis;

        prestaDico[p.prestationId] = model;

        let tooltipText = model.prestation;

        // si la cuisine n'a pas modifié depuis la transmission alors "Importé de la cuisine..."
        if (!p.dateExport || p.dateImport < p.dateExport) {
          tooltipText += `\nImporté de la CC le ${model.dateImport}.`;
        }

        tooltipText += p.dateModification
          ? `\nValidé le ${model.dateModification} par ${p.modifiePar}.`
          : '\nNon validé';

        if (model.exportRequis) {
          tooltipText += '\nEn attente de transmission à la CC.';
        }

        if (model.dateExport) {
          tooltipText += `\nTransmis à la CC le ${model.dateExport}.`;
        }

        // si la cuisine a modifié depuis la transmission alors "nModifié par la cuisine..."
        if (p.dateExport && p.dateImport > p.dateExport) {
          tooltipText += `\nModifié par la CC le ${model.dateImport}.`;
        }

        model.prestationTooltipInfo = tooltipText;

        this.handleSpecificData(model, p, this.projectFamillePlats(p.famillePlats));
      } else {
        this.handleSpecificData(prestaDico[p.prestationId], p, this.projectFamillePlats(p.famillePlats));
      }
    });

    // to Array
    const toReturn: PrestationModel[] = [];
    // tslint:disable-next-line:forin
    for (const key in prestaDico) {
      toReturn.push(prestaDico[key]);
    }

    // retrier par prestationOrdre
    toReturn.sort((a, b) => {
      return (b.prestationOrdre < a.prestationOrdre) ? 1 : -1;
    });

    return toReturn;
  }

  private static projectFamillePlats(famillesPlatsApi: GetCommandeRepasApiResponse.FamillePlat[]): FamillePlatModel[] {
    return famillesPlatsApi.map(f => {

      const model: FamillePlatModel = {
        isComposanteRestreinte: f.isComposanteRestreinte,
        autoriserEffectifZero: f.autoriserEffectifZero,
        famillePlatId: f.famillePlatId,
        famillePlatOrdre: f.famillePlatOrdre,
        famillePlat: f.famillePlat,
        nombreLimitePlats: f.nombreLimitePlats,
        portions: this.projectPortions(f.portions)
      };

      return model;
    });
  }

  private static projectPortions(portionApi: GetCommandeRepasApiResponse.Portion[]): PortionModel[] {
    return portionApi.map(p => {
      const model: PortionModel = {
        effectifPortionId: p.effectifPortionId,
        plat: p.plat,
        platId: p.platId,
        quantite: p.quantite,
        quantitePrecommande: p.quantitePrecommande,
        historique: p.historique,
        modeSaisie: ModeSaisiePortionEnum[p.modeSaisie],
        dateModification: formatDate(p.dateModification),
        dateExport: formatDate(p.dateExport),
        dateImport: formatDate(p.dateImport),
        exportRequis: p.exportRequis,
        portionTooltipInfo: ''
      };

      let tooltipText = p.plat;

      // si la cuisine n'a pas modifié depuis la transmission alors "Importé de la cuisine..."
      if (!p.dateExport || p.dateImport < p.dateExport) {
        tooltipText += `\nImporté de la CC le ${model.dateImport}.`;
      }

      tooltipText += p.dateModification
        ? `\nValidé le ${model.dateModification}.`
        : '\nNon validé';

      if (model.exportRequis) {
        tooltipText += '\nEn attente de transmission à la CC.';
      }

      if (model.dateExport) {
        tooltipText += `\nTransmis à la CC le ${model.dateExport}.`;
      }

      // si la cuisine a modifié depuis la transmission alors "nModifié par la cuisine..."
      if (p.dateExport && p.dateImport > p.dateExport) {
        tooltipText += `\nModifié par la CC le ${model.dateImport}.`;
      }

      tooltipText += '\n\nHistorique :';
      model.historique.forEach(h => {
        tooltipText += `\n - ${h.periode} : ${(h.quantite === null) ? 'non modifié' : h.quantite}`;
      });

      model.portionTooltipInfo = tooltipText;

      return model;
    });
  }

  static projectCmdRepasUpdate(
    contratId: number,
    restaurantId: number,
    lieuCommandeId: number,
    conviveId: number,
    cuisineCentraleId: number,
    date: Date,
    prestaDataList: PrestationFormModel[]): PostCommandeRepasApiRequest.CommandeRepasUpdate {

    const prestas: PostCommandeRepasApiRequest.Prestation[] = [];
    prestaDataList.forEach(presta => {
      // we could merge this to reduce the code but it would be less easier to understand IMO
      if (presta.modeSaisie == ModeSaisieEnum.Standard) {
        // Cas standard
        // On envoie pas les effectifQuantiteControl == null car non modifiable /disabled
        if (presta.effectifQuantiteControl != null) {
          prestas.push({
            effectifId: presta.effectifId,
            effectif: presta.effectifQuantiteControl,
            modeSaisie: presta.modeSaisie
          });
        }

        // Ajout d'une presta si regime sans porc
        // On envoie pas les effectifSansPorcQuantiteControl == null car non modifiable /disabled
        if (presta.regimeId != null && presta.effectifSansPorcQuantiteControl != null) {
          prestas.push({
            effectifId: presta.regimeEffectifId,
            effectif: presta.effectifSansPorcQuantiteControl,
            regimeId: presta.regimeId,
            modeSaisie: presta.modeSaisie
          });
        }
      } else {
        // Cas des portions
        prestas.push({
          effectifId: presta.effectifId,
          effectif: presta.effectifQuantiteControl,
          portions: this.projectPortionUpdate(presta.famillePlatsControls),
          modeSaisie: presta.modeSaisie
        });
      }
    });


    return {
      contratId,
      restaurantId,
      lieuCommandeId,
      conviveId,
      cuisineCentraleId,
      date: DateHelper.toISODateString(date),
      prestations: prestas
    };
  }

  private static projectPortionUpdate(famillesPlats: FamillePlatsControl[]): PostCommandeRepasApiRequest.Portion[] {
    const portions: PostCommandeRepasApiRequest.Portion[] = Array<PostCommandeRepasApiRequest.Portion>();
    famillesPlats.map(famillePlat => {
      famillePlat.portionsControls.map(portion => {
        // On ne renvoie pas les portions disabled/non modifiable
        if (portion.effectifPortionQuantiteControl != null) {
          const model: PostCommandeRepasApiRequest.Portion = {
            effectifPortionId: portion.effectifPortionId,
            effectif: portion.effectifPortionQuantiteControl
          };
          portions.push(model);
        }
      });
    });
    return portions;
  }


  public static projectCmdRepasError(apiError: PostCommandeRepasErrorApiResponse.ErrorContent): CommandeRepasErrorModel {
    const jours = apiError.Jours.map(apiJour => {
      return {
        date: apiJour.Date,
        famillePlats: apiJour.FamillePlats.map(apiFp => {
          return new FamillePlatErrorModel(apiFp.EffectifId, apiFp.Name, apiFp.Index, apiFp.Code, apiFp
            .Message, apiJour.Date);
        }),
        portions: apiJour.Portions.map(apiPortion => {
          return new PortionErrorModel(apiPortion.Id, apiPortion.Index, apiPortion.Code, apiPortion.Message, apiJour.Date);
        }),
        prestations: apiJour.Prestations.map(apiPresta => {
          return new PrestationErrorModel(apiPresta.Id, apiPresta.RegimeId, apiPresta.Index, apiPresta.Code, apiPresta.Message, apiJour.Date);

        })
      };
    });
    return new CommandeRepasErrorModel(jours, apiError.ErrorType as CommandeRepasUpdateErrorTypeEnum);
  }
}
