
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiSettings } from '../../../shared/models/api/api-settings';
import { GetContactsApiResponse } from '../../models/api/get-contacts.apiresponse';

@Injectable()
export class ContactApiService {

  private controllerUrl: string = 'contacts';

  constructor(private apiSettings: ApiSettings, private http: HttpClient) {
  }

  getContacts(contratId: number, cuisineCentraleId: number): Observable<GetContactsApiResponse.Contact[]> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}?contratId=${contratId}&cuisineCentraleId=${cuisineCentraleId}`;
    return this.http.get<GetContactsApiResponse.Contact[]>(url);
  }

}
