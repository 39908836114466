<ng-container *ngIf="isModifiable; else elseTemplate">
  <div class="input-temperature-container">
    <button class="generic-button inputPlusMoins input-moins" (click)="incrementTemperature(step*-1)"><i class="material-icons">remove</i></button>
    <input type="text" class="inputTemperature temperature temperature-color-{{ TemperatureStatusEnum[temperatureStatus] }}" [ngModel]="plat.livraisonTemperature | formatTemperature" (ngModelChange)="parseTemperature($event)" placeholder="T°C">
    <button class="generic-button inputPlusMoins input-plus" (click)="incrementTemperature(step)"><i class="material-icons">add</i></button>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <span class="temperature temperature-color-{{ TemperatureStatusEnum[temperatureStatus] }}">{{ plat.livraisonTemperature | formatTemperature:'°C' }}</span>
</ng-template>
