
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileHelper } from 'src/app/shared/helpers/file.helper';
import { DateHelper } from '../../../shared/helpers/date.helper';
import { ApiSettings } from '../../../shared/models/api/api-settings';
import { PostCommandeRepasWarningApiResponse } from '../../../shared/models/api/warning/post-commande-repas-warning.apiresponse';
import { FileResultModel } from '../../../shared/models/fileresult.model';
import { LogService } from '../../../shared/services/log.service';
import { ExportBilanRepasRequest } from '../../models/api/requests/export-bilan-repas-request.apirequest';
import { ExportEcartsRequest } from '../../models/api/requests/export-ecarts-request.apirequest';
import { PostCommandeRepasApiRequest } from '../../models/api/requests/post-commande-repas.apirequest';
import { GetCommandeRepasApiResponse } from '../../models/api/responses/get-commande-repas.apiresponse';
import { GetDailyMenuApiResponse } from '../../models/api/responses/get-daily-menu.apiresponse';
import { ExportCommandeValoriseeRequest } from '../../models/api/requests/export-commande-valorisee-request.apirequest';

@Injectable()
export class CommandeRepasApiService {
  private controllerUrl: string = 'commanderepas';

  constructor(private apiSettings: ApiSettings, private http: HttpClient, private logService: LogService) {
  }

  getCommandeRepas(isPiqueNique: boolean, cuisineId: number, restaurantId: number, conviveId: number, lieuCommandeId: number, date: Date): Observable<GetCommandeRepasApiResponse.CommandeRepas> {
    let httpParams = new HttpParams()
      .set('cuisineCentraleId', cuisineId.toString())
      .set('restaurantId', restaurantId.toString());
    if (date) {
      httpParams = httpParams.set('date', DateHelper.toISODateString(date));
    }
    if (isPiqueNique) {
      httpParams = httpParams.set('isPiqueNique', isPiqueNique.toString());
    }
    if (conviveId) {
      httpParams = httpParams.set('conviveId', conviveId.toString());
    }
    if (lieuCommandeId) {
      httpParams = httpParams.set('lieuCommandeId', lieuCommandeId.toString());
    }

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/get`;
    return this.http.get<GetCommandeRepasApiResponse.CommandeRepas>(url, { params: httpParams });
  }

  postCommandeRepas(commandeRepasUpdate: PostCommandeRepasApiRequest.CommandeRepasUpdate): Observable<PostCommandeRepasWarningApiResponse.Warning> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/post`;
    return this.http.post<PostCommandeRepasWarningApiResponse.Warning>(url, commandeRepasUpdate);
  }

  getMenu(cuisineId: number, restId: number, date: Date): Observable<GetDailyMenuApiResponse.Prestation[]> {
    const httpParams = new HttpParams()
      .set('cuisineCentraleId', cuisineId.toString())
      .set('restaurantId', restId.toString())
      .set('date', DateHelper.toISODateString(date));
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/dailyMenu`;
    return this.http.get<GetDailyMenuApiResponse.Prestation[]>(url, { params: httpParams });
  }

  exportEcarts(request: ExportEcartsRequest): Observable<FileResultModel> {
    let httpParams = new HttpParams();
    if (request.cuisineCentraleId) {
      httpParams = httpParams.set('cuisineCentraleId', String(request.cuisineCentraleId));
    }
    if (request.grandCompteId) {
      httpParams = httpParams.set('grandCompteId', String(request.grandCompteId));
    }
    if (request.contratId) {
      httpParams = httpParams.set('contratId', String(request.contratId));
    }
    if (request.date) {
      httpParams = httpParams.set('date', DateHelper.toISODateString(request.date));
    }

    const url = `${this.apiSettings.apiUrl}/commanderepas/exportEcarts`;
    return FileHelper.getFile(this.http, url, httpParams);
  }

  exportBilanRepas(request: ExportBilanRepasRequest): Observable<FileResultModel> {
    let httpParams = new HttpParams();

    if (request.cuisineCentraleId) {
      httpParams = httpParams.set('cuisineCentraleId', String(request.cuisineCentraleId));
    }
    if (request.grandCompteId) {
      httpParams = httpParams.set('grandCompteId', String(request.grandCompteId));
    }
    if (request.contratId) {
      httpParams = httpParams.set('contratId', String(request.contratId));
    }
    if (request.dateDebut) {
      httpParams = httpParams.set('dateDebut', DateHelper.toISODateString(request.dateDebut));
    }
    if (request.dateFin) {
      httpParams = httpParams.set('dateFin', DateHelper.toISODateString(request.dateFin));
    }
    if (request.isPiqueNique !== null) {
      httpParams = httpParams.set('isPiqueNique', String(request.isPiqueNique));
    }

    const url = `${this.apiSettings.apiUrl}/commanderepas/exportBilanRepas`;
    return FileHelper.getFile(this.http, url, httpParams);
  }

  exportCommandeValorisee(request: ExportCommandeValoriseeRequest): Observable<FileResultModel> {
    let httpParams = new HttpParams();

    if (request.cuisineCentraleId) {
      httpParams = httpParams.set('cuisineCentraleId', String(request.cuisineCentraleId));
    }
    if (request.grandCompteId) {
      httpParams = httpParams.set('grandCompteId', String(request.grandCompteId));
    }
    if (request.contratId) {
      httpParams = httpParams.set('contratId', String(request.contratId));
    }
    if (request.dateDebut) {
      httpParams = httpParams.set('dateDebut', DateHelper.toISODateString(request.dateDebut));
    }
    if (request.dateFin) {
      httpParams = httpParams.set('dateFin', DateHelper.toISODateString(request.dateFin));
    }
    if (request.isPiqueNique !== null) {
      httpParams = httpParams.set('isPiqueNique', String(request.isPiqueNique));
    }

    const url = `${this.apiSettings.apiUrl}/commanderepas/exportCommandeValorisee`;
    return FileHelper.getFile(this.http, url, httpParams);
  }
}
