import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { RestaurantTypeEnum } from '../../shared/enums/restaurant-type.enum';
import { OdgEvaluationModel, OdgPrestationModel } from '../models/odg.model';

@Component({
  selector: 'odg-prestation',
  templateUrl: './odg-prestation.component.html',
  styleUrls: ['./odg-prestation.component.scss']
})
export class OdgPrestationComponent implements OnInit {
  @Input()
  odgPresta: OdgPrestationModel;
  @Input()
  currentType: RestaurantTypeEnum;

  @Output()
  prestaToggle: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  openBadEvaluation: EventEmitter<OdgEvaluationModel> = new EventEmitter<OdgEvaluationModel>();

  constructor() { }

  ngOnInit() {
  }

  toggle() {
    this.prestaToggle.next(this.odgPresta.id);
  }

  onOpenEvaluation(evaluation: OdgEvaluationModel) {
    this.openBadEvaluation.emit(evaluation);
  }
}
