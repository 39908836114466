
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FileHelper } from '../shared/helpers/file.helper';
import { FileResultModel } from '../shared/models/fileresult.model';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, finalize, first } from 'rxjs/operators';
import { DatePickerInfo } from '../date-input/date-input.component';
import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { DateHelper } from '../shared/helpers/date.helper';
import { ErrorApiModel } from '../shared/models/api/error/error.apimodel';
import { NotificationConfirmModel } from '../shared/models/notification.model';
import { LogoRouteService } from '../shared/services/api/logo/logo-route.service';
import { ApplicationDataService } from '../shared/services/application-data.service';
import { InteractionService } from '../shared/services/interaction.service';
import { LogService } from '../shared/services/log.service';
import { EtatCommandeEnum, HistoryCommandeModel } from './models/history-commande.model';
import { TypeListeArticleModel, TypeListeEnum } from './models/type-liste-articles.model';
import { EpicerieInventaireService } from './services/epicerie-inventaire.service';
import { ReportingService } from '../reporting/services/reporting.service';

const PAGE_LENGTH = 10;

@Component({
  selector: 'app-epicerie-inventaire',
  templateUrl: './epicerie-inventaire.component.html',
  styleUrls: ['./epicerie-inventaire.component.scss']
})
export class EpicerieInventaireComponent implements OnInit, OnDestroy {
  EtatCommandeEnum: typeof EtatCommandeEnum = EtatCommandeEnum; // declare enum property to allow use in component template. see: http://stackoverflow.com/a/40034109/590741
  TypeListeEnum: typeof TypeListeEnum = TypeListeEnum;

  /** fields */
  public isLoadingCommandes: boolean;
  public hasLoadedForm: boolean;
  private typeListeArticlesEpicerie: TypeListeArticleModel[];
  private typeListeArticlesInventaire: TypeListeArticleModel[];
  public selectedTypeListeArticles: TypeListeArticleModel[];
  public commandes: HistoryCommandeModel[];

  /** pagination */
  public displayedCommandes: HistoryCommandeModel[];
  public pages: number[];
  public totalPage: number;


  public startDate: Date;
  public endDate: Date;
  private askLoadListeArticlesEmitter: EventEmitter<void> = new EventEmitter<void>();
  public estEnAttenteValidation: boolean = false;
  public dateCalendarData: DatePickerInfo;
  public isPrintCommandeDownloading: boolean = false;

  /** Contrat, restau, logo */
  private _contratId: number;
  private _cuisineCentraleId: number;
  private _restaurantId: number;
  private restaurantName: string;

  public currentType: RestaurantTypeEnum;
  public logoContratUrl: string = '';
  private calendarBase: any;

  /** Emitters / Subscription */
  private restaurantSubscription: Subscription;
  private contratSubscription: Subscription;

  /** getter setter */
  private _selectedTypeListeArticle: TypeListeArticleModel;
  public get selectedTypeListeArticle(): TypeListeArticleModel {
    return this._selectedTypeListeArticle;
  }

  public set selectedTypeListeArticle(val: TypeListeArticleModel) {
    // Si la nouvelle liste n'est pas "validationRequise", on reset la checkbox
    if (val != null && !val.validationRequise) {
      this.estEnAttenteValidation = false;
    }

    const oldListeArticleId = this.selectedTypeListeArticle && this.selectedTypeListeArticle.id;
    this._selectedTypeListeArticle = val;

    if (oldListeArticleId !== (this._selectedTypeListeArticle && this._selectedTypeListeArticle.id)) {
      this.getCommandes(); // Recharge les commandes si la liste a changé
    }

  }

  private _page: number = 1;
  public get page(): number {
    return this._page;
  }
  public set page(value: number) {
    if (value > 0 && value <= this.totalPage) {
      this._page = value;
    }
  }

  private _isInventaire: boolean = false;
  public get isInventaire(): boolean {
    return this._isInventaire;
  }

  public set isInventaire(isInv: boolean) {
    if (this._isInventaire !== isInv) {
      this._isInventaire = isInv;
      this.displayListeArticles();
    }
  }

  private displayListeArticles() {
    const newListeArticles = (this.isInventaire)
      ? this.typeListeArticlesInventaire
      : this.typeListeArticlesEpicerie;

    if (newListeArticles !== this.selectedTypeListeArticles) {
      const oldListeArticleId = this.selectedTypeListeArticle && this.selectedTypeListeArticle.id;
      this.selectedTypeListeArticles = newListeArticles;
      this.selectedTypeListeArticle = (this.selectedTypeListeArticles && this.selectedTypeListeArticles.find((l) => l.id === oldListeArticleId))
        || this.selectedTypeListeArticles[0];
    }

  }

  public get isTypeListValidationRequise() {
    return this.selectedTypeListeArticle != null && this.selectedTypeListeArticle.validationRequise;
  }

  constructor(
    private router: Router,
    private logoService: LogoRouteService,
    private logService: LogService,
    private appDataService: ApplicationDataService,
    private epicerieInventaireService: EpicerieInventaireService,
    private interactionService: InteractionService,
    private _reportingService: ReportingService
    ) {
  }

  public ngOnInit() {
    /** Dates  */
    this.calendarBase = {};
    this.startDate = moment().startOf('month').toDate();
    this.endDate = moment().endOf('month').toDate();
    this.dateCalendarData = {
      minDate: moment().year(1970).startOf('year').toDate()
    };

    this.askLoadListeArticlesEmitter.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.epicerieInventaireService.getTypeListeArticles(this._cuisineCentraleId, this._restaurantId, this._contratId, this.startDate, this.endDate)
          .subscribe(res => {
            this.typeListeArticlesEpicerie = res.typesListeArticleEpicerie;
            this.typeListeArticlesInventaire = res.typesListeArticleInventaire;

            // Une seule fois pour l'init la search box
            if (!this.hasLoadedForm) {
              this.hasLoadedForm = true;
            }

            this.displayListeArticles();
          });

      });

    this.contratSubscription = this.appDataService.contrat$.subscribe(
      contrat => {
        if (contrat != null) {
          this._contratId = contrat.id;
          this._cuisineCentraleId = contrat.cuisineCentraleId;
          this.logoContratUrl = this.logoService.GetPicture2Url(contrat.id, contrat.cuisineCentraleId);
        }
      });

    this.restaurantSubscription = this.appDataService.restaurant$.subscribe(
      restaurant => {
        if (restaurant != null) {
          // Le next sur le contratSubscription n'est pas assurement trigger avant le restaurantSubscription, donc on s'assure d'avoir le bon _contratId  && _ccId à ce moment au moins.
          this._contratId = restaurant.contratId;
          this._cuisineCentraleId = restaurant.cuisineCentraleId;
          this._restaurantId = restaurant.id;
          this.currentType = restaurant.type;
          this.restaurantName = restaurant.name;
          this.askLoadListeArticles();
        }
      });


  }

  public ngOnDestroy() {
    this.contratSubscription.unsubscribe();
    this.restaurantSubscription.unsubscribe();
  }

  askLoadListeArticles() {
    this.askLoadListeArticlesEmitter.next();
  }

  getToolTip(etat: EtatCommandeEnum) {
    switch (etat) {
      case EtatCommandeEnum.ValideeNonTransmise:
        return 'Commande validée, non transmise';
      case EtatCommandeEnum.Transmise:
        return 'Commande transmise';
      case EtatCommandeEnum.EnAttenteValidation:
        return 'Commande en attente de validation';
      default:
        return 'Commande non transmise';
    }
  }


  searchCommandes(e: any) {
    e.preventDefault();

    // On check si les champs sont valides, si c'est le cas, on getCommande, sinon prompt d'un message d'erreur
    if (!this.selectedTypeListeArticles || this.selectedTypeListeArticles.length === 0) {
      this.interactionService.showSimpleMessage('Erreur', 'Aucun type de liste n\'est disponible.');
    } else if (!this.selectedTypeListeArticle) {
      this.interactionService.showSimpleMessage('Erreur', 'Veuillez choisir un type de liste.');
    } else if (!this.startDate || !this.endDate) {
      this.interactionService.showSimpleMessage('Erreur', 'Veuillez renseigner des dates valides.');
    } else {
      this.getCommandes();
    }
  }

  handleNewCommandes(res: HistoryCommandeModel[]) {
    this.commandes = res;
    this.computePagination();
    this.refreshDisplayedCommandes();
  }

  private getCommandes() {
    // On check ici, mais les messages d'erreur sont géré au niveau de searchCommandes()
    if (!this.selectedTypeListeArticle || !this.startDate || !this.endDate || this.selectedTypeListeArticles.length === 0) {
      this.handleNewCommandes([]);
      return;
    }

    this.isLoadingCommandes = true;
    this.epicerieInventaireService.getCommandes(this._cuisineCentraleId, this.selectedTypeListeArticle, this.estEnAttenteValidation, this._restaurantId, this._contratId, this.startDate, this.endDate).pipe(
      debounceTime(1000),
      first(),
      finalize(() => {
        this.isLoadingCommandes = false;
      }))
      .subscribe(res => {
        this.handleNewCommandes(res);
      },
        err => {
          this.interactionService.showSimpleMessage('Erreur', 'Une erreur est survenue lors de la récupération des commandes.');
          this.handleNewCommandes([]);
          // On refresh, pour rester cohérent si changement de restau, etc...
          this.refreshDisplayedCommandes();
        });
  }

  /** Pagination */
  private refreshDisplayedCommandes() {
    const start = (this.page - 1) * PAGE_LENGTH;
    const end = this.page * PAGE_LENGTH;

    if (this.commandes) {
      this.displayedCommandes = this.commandes.slice(start, end);
    }
  }

  /** Calcule le nombre de pages et créer chaque indicateur */
  private computePagination() {
    this.page = 1;
    this.totalPage = Math.ceil(this.commandes.length / PAGE_LENGTH);
    this.pages = new Array<number>();
    for (let i = 2; i <= this.totalPage - 1; i++) {
      this.pages.push(i);
    }
  }

   prev() {
    this.page--;
    this.refreshDisplayedCommandes();
  }

   next() {
    this.page++;
    this.refreshDisplayedCommandes();
  }

  changePage(page: number) {
    this.page = page;
    this.refreshDisplayedCommandes();
  }

  isPageDisplayed(page: number): boolean {
    if (this.page === 1) {
      return page <= this.page + 2 && page >= this.page - 1;
    } else if (this.page === this.totalPage) {
      return page <= this.page + 1 && page >= this.page - 2;
    } else {
      return page <= this.page + 1 && page >= this.page - 1;
    }
  }

  /** navigation */
  openCommande(commande: HistoryCommandeModel) {
    if (commande.canEdit) {
      this.navigateToEdit(commande.id);
    } else {
      this.navigateToConsult(commande.id);
    }
  }

  navigateToCreate() {
    if (!this.selectedTypeListeArticle) {
      this.interactionService.showSimpleMessage('Erreur', 'Aucune liste sélectionnée.');
      return;
    }

    if (!this.selectedTypeListeArticle.actif) {
      this.interactionService.showSimpleMessage('Liste désactivée', `La liste '${this.selectedTypeListeArticle.libelle}' est désactivée. Il n'est pas autorisé de créer ${this.selectedTypeListeArticle.type === TypeListeEnum.Epicerie ? 'une nouvelle commande' : 'un nouvel inventaire'}.`);
      return;
    }

    if (this.selectedTypeListeArticle.type === TypeListeEnum.Inventaire) {
      const today = moment();
      // vérification si l'inventaire est réalisable aujourd'hui
      if (this.selectedTypeListeArticle.dateFinInventaire == null
        || !today.isSameOrAfter(this.selectedTypeListeArticle.dateDebutInventaire, 'day')
        || !today.isSameOrBefore(this.selectedTypeListeArticle.dateFinInventaire, 'day')) {

        const noInventaireError = (this.selectedTypeListeArticle.dateDebutInventaire
          ? `L'inventaire "${this.selectedTypeListeArticle.libelle}" n'est n'est pas réalisable aujourd'hui \n(entre le ${DateHelper.toShortDateString(this.selectedTypeListeArticle.dateDebutInventaire)} et le ${DateHelper.toShortDateString(this.selectedTypeListeArticle.dateFinInventaire)}).`
          : 'Pas d\'inventaire prochainement');
        this.interactionService.showSimpleMessage('Inventaire non autorisé', noInventaireError);
        return;
      }

    }

    this.router.navigate(['/epicerieinventaire/create'], { queryParams: { isInventaire: this.isInventaire, typeListeArticleId: this.selectedTypeListeArticle.id } });
  }

  private navigateToConsult(id: number) {
    this.router.navigate(['/epicerieinventaire/create'], { queryParams: { isInventaire: this.isInventaire, commandeId: id, typeListeArticleId: this.selectedTypeListeArticle.id, consult: true } });
  }

  private navigateToEdit(id: number) {
    this.router.navigate(['/epicerieinventaire/create'], { queryParams: { isInventaire: this.isInventaire, commandeId: id, typeListeArticleId: this.selectedTypeListeArticle.id } });
  }

  navigateToDuplicate(id: number, e: any) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.router.navigate(['/epicerieinventaire/create'], { queryParams: { isInventaire: this.isInventaire, commandeId: id, typeListeArticleId: this.selectedTypeListeArticle.id, duplicate: true } });
  }

  /** suppression de la commande */
  deleteCommande(commande: HistoryCommandeModel, e: any) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const notifConfirmModel: NotificationConfirmModel = new NotificationConfirmModel();
    notifConfirmModel.title = 'Suppression';
    notifConfirmModel.message = 'Voulez vous vraiment supprimer la commande ?';
    notifConfirmModel.fullScreen = true;
    notifConfirmModel.onOk = () => {
      this.epicerieInventaireService.deleteCommande(commande.id)
        .subscribe(res => {
          if (res) {
            this.interactionService.showSimpleMessage('Suppression', 'La commande a été supprimée.');
            // remove item from list
            const index = this.commandes.indexOf(commande, 0);
            if (index > -1) {
              this.commandes.splice(index, 1);
              this.refreshDisplayedCommandes();
            }
          }
        },
          (error: ErrorApiModel) => {
            this.interactionService.showSimpleMessage('Erreur', error.Message);
          });
    };

    this.interactionService.showNotification(notifConfirmModel);
  }

  printCommande(commandeId: number, e: any) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (this.isPrintCommandeDownloading) {
      return;
    }

    this.isPrintCommandeDownloading = true;
    this.isLoadingCommandes = true; // on affiche le loader
    const report$ = this.epicerieInventaireService.imprimerCommande(commandeId).pipe(
      finalize(() => {
        this.isPrintCommandeDownloading = false;
        this.isLoadingCommandes = false;
      }),
      first());

      this._reportingService.handleReportResponse(report$);
  }

}
