import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiSettings } from '../../models/api/api-settings';
import { BaseHttpInterceptor } from '../base-http-interceptor';
import { CorrelationTokenService } from '../correlationToken.service';
import { LogService } from '../log.service';
import { StringHelper } from '../../helpers/string.helper';

const { version: appVersion } = require('../../../../../package.json');

@Injectable()
export class AppHttpInterceptor extends BaseHttpInterceptor {

  constructor(
    private _correlationTokenService: CorrelationTokenService,
    private _apiSettings: ApiSettings,
    logService: LogService
  ) {
    super(
      logService.getLogger('AppHttpInterceptor'),
      {
        whitelistedDomains: [null, /localhost(:\d+)/i],
        blacklistedRoutes: [
          /// (?!\/(api|api-internal)\/)/i // on bypass tout ce qui n'est pas API
          RegExp(`^(?!${StringHelper.escapeRegExp(_apiSettings.apiUrl)})`, 'gi')
        ]
      }
    );
  }

  protected handleInterception(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        // ajout de la version de l'api
        'client-version': appVersion,
        // ajout du correlationId
        'client-correlationId': this._correlationTokenService.correlationId,
        // ajout de la subscriberkey API Managment
        'Ocp-Apim-Subscription-Key': this._apiSettings.subscriberKey
      }
    });

    return next.handle(request);
  }
}
