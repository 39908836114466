import { OdgComponent } from './odg.component';
import { Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth-guard';
const ɵ0 = { title: "Observatoire du goût" };
const odgRoutes = [
    {
        path: 'observatoire',
        component: OdgComponent,
        canActivate: [AuthGuard],
        data: ɵ0
    }
];
export class OdgRoutingModule {
}
export { ɵ0 };
