import { Pipe, PipeTransform } from '@angular/core';
import { BonLivraisonFamillePlatModel } from '../models/bon-livraison-famille-plat.model';
import { BonLivraisonSettingsModel } from '../models/bon-livraison-settings.model';
import { includes } from 'lodash';

/**
 * Filtre les Familles de plat selon les exclusions paramétrées deans les settings (BonLivraisonSettingsModel)
 */
@Pipe({
  name: 'filterAllowedFamillePlat'
})
export class FilterAllowedFamillePlatPipe implements PipeTransform {

  // transform(value: any, ...args: any[]): any {
  transform(value: BonLivraisonFamillePlatModel[], settings: BonLivraisonSettingsModel): any {
    return value && value.filter(fp => !includes(settings.livraisonExcludeFamillePlatIds, fp.famillePlatId));
  }

}
