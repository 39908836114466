import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParametreApiService } from './api/parametre/parametre.apiservice';

@Injectable()
export class ParametreService {

  constructor(private apiService: ParametreApiService) { }

  getParametre(code: string): Observable<string> {
    return this.apiService.getParametre(code);
  }
}
