/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-notifications.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../alerte/alerte.component.ngfactory";
import * as i3 from "../alerte/alerte.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../shared/services/application-data.service";
import * as i6 from "../enquete/enquete.component.ngfactory";
import * as i7 from "../enquete/enquete.component";
import * as i8 from "../../../../../node_modules/ngx-swiper-wrapper/dist/ngx-swiper-wrapper.ngfactory";
import * as i9 from "ngx-swiper-wrapper";
import * as i10 from "../../../shared/directives/pagination-color.directive";
import * as i11 from "@angular/common";
import * as i12 from "../../../shared/directives/swiper-restaurant-theme.directive";
import * as i13 from "./popup-notifications.component";
import * as i14 from "ngx-toastr";
var styles_PopupNotificationsComponent = [i0.styles];
var RenderType_PopupNotificationsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PopupNotificationsComponent, data: {} });
export { RenderType_PopupNotificationsComponent as RenderType_PopupNotificationsComponent };
function View_PopupNotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-alerte", [], null, [[null, "alerteDismissed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("alerteDismissed" === en)) {
        var pd_0 = (_co.dismissAlerte() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AlerteComponent_0, i2.RenderType_AlerteComponent)), i1.ɵdid(2, 114688, null, 0, i3.AlerteComponent, [i4.DomSanitizer, i5.ApplicationDataService], { alerte: [0, "alerte"], showTitle: [1, "showTitle"], receivedAlerte: [2, "receivedAlerte"] }, { alerteDismissed: "alerteDismissed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.alerte; var currVal_1 = _co.hasMultipleNotifications; var currVal_2 = _v.context.$implicit.state; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_PopupNotificationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-enquete", [], null, [[null, "enqueteDismissed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("enqueteDismissed" === en)) {
        var pd_0 = (_co.dismissEnquete($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_EnqueteComponent_0, i6.RenderType_EnqueteComponent)), i1.ɵdid(2, 573440, null, 0, i7.EnqueteComponent, [i4.DomSanitizer, i5.ApplicationDataService], { enquete: [0, "enquete"], showTitle: [1, "showTitle"], receivedEnquete: [2, "receivedEnquete"] }, { enqueteDismissed: "enqueteDismissed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.enquete; var currVal_1 = _co.hasMultipleNotifications; var currVal_2 = _v.context.$implicit.state; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_PopupNotificationsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { componentRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "swiper", [["class", "swiper-custom-navigation"]], [[2, "notifications-swiper--active", null]], [[null, "indexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("indexChange" === en)) {
        var pd_0 = ((_co.slideIndex = $event) !== false);
        ad = (pd_0 && ad);
    } if (("indexChange" === en)) {
        var pd_1 = (true !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_SwiperComponent_0, i8.RenderType_SwiperComponent)), i1.ɵdid(2, 4374528, [[1, 4]], 0, i9.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i9.SWIPER_CONFIG]], { index: [0, "index"], config: [1, "config"] }, { indexChange: "indexChange" }), i1.ɵdid(3, 278528, null, 0, i10.PaginationColorDirective, [i1.ElementRef], { paginationColor: [0, "paginationColor"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵdid(5, 4210688, null, 0, i12.SwiperRestaurantThemeDirective, [i1.ElementRef, i1.Renderer2, [2, i9.SWIPER_CONFIG]], { config: [0, "config"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PopupNotificationsComponent_1)), i1.ɵdid(7, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PopupNotificationsComponent_2)), i1.ɵdid(9, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.slideIndex; var currVal_2 = _co.swiperConfig; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.restaurantType$)); _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.swiperConfig; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.alertes; _ck(_v, 7, 0, currVal_5); var currVal_6 = _co.enquetes; _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasMultipleNotifications; _ck(_v, 1, 0, currVal_0); }); }
export function View_PopupNotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-notifications", [], null, null, null, View_PopupNotificationsComponent_0, RenderType_PopupNotificationsComponent)), i1.ɵdid(1, 245760, null, 0, i13.PopupNotificationsComponent, [i5.ApplicationDataService, i14.ToastrService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupNotificationsComponentNgFactory = i1.ɵccf("app-popup-notifications", i13.PopupNotificationsComponent, View_PopupNotificationsComponent_Host_0, {}, {}, []);
export { PopupNotificationsComponentNgFactory as PopupNotificationsComponentNgFactory };
