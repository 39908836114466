import { Directive, Input, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
    selector: '[selectAllOnFocus]',
    host: {
        '(focus)': 'onFocus($event)'
    }
})
export class SelectAllOnFocusDirective {
    private formControl: FormControl;

    constructor(private el: ElementRef) {
    }

    onFocus(event:Event) {
        this.el.nativeElement.select(0, this.el.nativeElement.value.length);
    }
}