import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DateHelper } from 'src/app/shared/helpers/date.helper';
import { FileHelper } from 'src/app/shared/helpers/file.helper';
import { ApiSettings } from 'src/app/shared/models/api/api-settings';
import { FileResultModel } from 'src/app/shared/models/fileresult.model';
import { LogService } from 'src/app/shared/services/log.service';
import { EnqueteModel } from '../models/enquete.model';
import { ExportEnquetesSatisfactionRequest } from '../models/export-enquetes-satisfaction-request.model';
import { RepondreEnqueteRequest } from '../models/repondre-enquete-request.model';

const CONTROLLER_URL = 'enquete';

@Injectable({
  providedIn: 'root'
})
export class EnquetesApiService {

  constructor(private _apiSettings: ApiSettings,
              private _http: HttpClient,
              private _logService: LogService) {
  }

  getRestaurantEnquetes(cuisineCentraleId: number, restaurantId: number) {
    const url = `${this._apiSettings.apiUrl}/enquetes`;
    return this._http.get<EnqueteModel[]>(url,
      {
        params: {
          cuisineCentraleId: cuisineCentraleId.toString(),
          restaurantId: restaurantId.toString()
        }
      });
  }

  repondreEnquete(reponse: RepondreEnqueteRequest) {
    const url = `${this._apiSettings.apiUrl}/${CONTROLLER_URL}`;
    return this._http.post(url, reponse);
  }

  exportEnquetesSatisfaction(request: ExportEnquetesSatisfactionRequest): Observable<FileResultModel> {
    let params = new HttpParams();
    if (request.grandCompteId) {
      params = params.append('grandCompteId', String(request.grandCompteId));
    }
    if (request.cuisineCentraleId) {
      params = params.append('cuisineCentraleId', String(request.cuisineCentraleId));
    }
    if (request.contratId) {
      params = params.append('contratId', String(request.contratId));
    }
    if (request.dateDebut) {
      params = params.append('dateDebut', DateHelper.toISODateString(request.dateDebut));
    }
    if (request.dateFin) {
      params = params.append('dateFin', DateHelper.toISODateString(request.dateFin));
    }

    const url = `${this._apiSettings.apiUrl}/${CONTROLLER_URL}/ExportEnquetesSatisfaction`;
    return FileHelper.getFile(this._http, url, params);
  }
}
