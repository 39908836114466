/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../contact-card/contact-card.component.ngfactory";
import * as i3 from "../../contact-card/contact-card.component";
import * as i4 from "@angular/common";
import * as i5 from "./contact-list.component";
var styles_ContactListComponent = [i0.styles];
var RenderType_ContactListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactListComponent, data: {} });
export { RenderType_ContactListComponent as RenderType_ContactListComponent };
function View_ContactListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-card", [], null, null, null, i2.View_ContactCardComponent_0, i2.RenderType_ContactCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.ContactCardComponent, [], { contact: [0, "contact"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ContactListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactListComponent_1)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contacts; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ContactListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-list", [], null, null, null, View_ContactListComponent_0, RenderType_ContactListComponent)), i1.ɵdid(1, 49152, null, 0, i5.ContactListComponent, [], null, null)], null, null); }
var ContactListComponentNgFactory = i1.ɵccf("app-contact-list", i5.ContactListComponent, View_ContactListComponent_Host_0, { contacts: "contacts", restType: "restType" }, {}, []);
export { ContactListComponentNgFactory as ContactListComponentNgFactory };
