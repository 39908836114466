
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileHelper } from 'src/app/shared/helpers/file.helper';
import { DateHelper } from '../../../shared/helpers/date.helper';
import { ApiSettings } from '../../../shared/models/api/api-settings';
import { FileResultModel } from '../../../shared/models/fileresult.model';
import { LogService } from '../../../shared/services/log.service';
import { ArticleApiModel } from '../../models/api/article.apimodel';
import { CommandeApiModel } from '../../models/api/commande.apimodel';
import { CreateCommandeApiModel } from '../../models/api/create-commande.apimodel';
import { CreateCommandeApiResponse } from '../../models/api/create-commande.apiresponse';
import { DateLivraisonApiModel } from '../../models/api/date-livraison.apimodel';
import { GetCommandesApiResponse } from '../../models/api/get-commandes.apiresponse';
import { TypeListeArticleApiModel, TypeListeArticlesApiModel } from '../../models/api/type-liste-articles.apimodel';

@Injectable()
export class EpicerieInventaireApiService {
  private controllerUrl: string = 'epicerie';

  constructor(private apiSettings: ApiSettings, private http: HttpClient, private logService: LogService) {
  }

  getArticles(typeListeArticleId: number, cuisineCentraleId: number, restaurantId: number, contratId: number): Observable<ArticleApiModel[]> {
    const httpParams = new HttpParams()
      .set('typeListeArticleId', typeListeArticleId.toString())
      .set('cuisineCentraleId', cuisineCentraleId.toString())
      .set('restaurantId', restaurantId.toString())
      .set('contratId', contratId.toString());

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/articles`;
    return this.http.get<ArticleApiModel[]>(url, { params: httpParams });
  }

  getArticlePicture(id: string): string {
    return `${this.apiSettings.apiUrl}/${this.controllerUrl}/articles/picture?id=${id}`;
  }

  getTypeListeArticle(cuisineCentraleId: number, restaurantId: number, typeListeArticleId: number): Observable<TypeListeArticleApiModel> {
    const httpParams = new HttpParams()
      .set('cuisineCentraleId', cuisineCentraleId.toString())
      .set('restaurantId', restaurantId.toString())
      .set('typeListeArticleId', typeListeArticleId.toString());

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/articles/typeliste`;
    return this.http.get<TypeListeArticleApiModel>(url, { params: httpParams });
  }

  getTypeListeArticles(cuisineCentraleId: number, restaurantId: number, contratId: number, dateDebut?: Date, dateFin?: Date): Observable<TypeListeArticlesApiModel> {
    let httpParams = new HttpParams()
      .set('cuisineCentraleId', cuisineCentraleId.toString())
      .set('restaurantId', restaurantId.toString())
      .set('contratId', contratId.toString());
    if (dateDebut) {
      httpParams = httpParams.set('dateDebut', DateHelper.toISODateString(dateDebut));
    }
    if (dateFin) {
      httpParams = httpParams.set('dateFin', DateHelper.toISODateString(dateFin));
    }

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/articles/typelistes`;
    return this.http.get<TypeListeArticlesApiModel>(url, { params: httpParams });
  }

  getAvailableDateLivraison(cuisineCentraleId: number, restaurantId: number, contratId: number, typeListeArticleId: number, dateDuMois: Date): Observable<DateLivraisonApiModel[]> {
    const httpParams = new HttpParams()
      .set('cuisineCentraleId', cuisineCentraleId.toString())
      .set('restaurantId', restaurantId.toString())
      .set('contratId', contratId.toString())
      .set('typeListeArticleId', typeListeArticleId.toString())
      .set('dateDuMois', DateHelper.toISODateString(dateDuMois));

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/datelivraisons`;
    return this.http.get<DateLivraisonApiModel[]>(url, { params: httpParams });
  }

  getCommandes(estEnAttenteValidation: boolean, cuisineCentraleId: number, typeListeArticleId: number, restaurantId: number, contratId: number, startDate: Date = null, endDate: Date = null): Observable<GetCommandesApiResponse.Response> {
    let httpParams = new HttpParams()
      .set('cuisineCentraleId', cuisineCentraleId.toString())
      .set('restaurantId', restaurantId.toString())
      .set('estEnAttenteValidation', estEnAttenteValidation.toString())
      .set('contratId', contratId.toString())
      .set('typeListeArticleId', typeListeArticleId.toString());
    if (startDate) {
      httpParams = httpParams.set('dateDebut', DateHelper.toISODateString(startDate));
    }
    if (endDate) {
      httpParams = httpParams.set('dateFin', DateHelper.toISODateString(endDate));
    }

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/commandes`;
    return this.http.get<GetCommandesApiResponse.Response>(url, { params: httpParams });
  }

  getCommande(id: number): Observable<CommandeApiModel> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/commandes/${id}`;
    return this.http.get<CommandeApiModel>(url);
  }

  saveCommande(commande: CreateCommandeApiModel): Observable<CreateCommandeApiResponse.SaveResult> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/commandes`;
    return this.http.post<CreateCommandeApiResponse.SaveResult>(url, commande);
  }

  deleteCommande(id: number): Observable<boolean> {
    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/commandes/${id}`;
    return this.http.delete(url)
      .pipe(
        map(res => true)
      );
  }

  imprimerCommande(commandeId: number): Observable<FileResultModel> {
    const httpParams = new HttpParams()
      .set('commandeId', commandeId.toString());

    const url = `${this.apiSettings.apiUrl}/${this.controllerUrl}/imprimercommande`;
    return FileHelper.getFile(this.http, url, httpParams);
  }
}
