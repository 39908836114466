<div class="error-popup-container">
    <div *ngIf="data">
        <div class="jour" *ngFor="let jour of errorPopupData">
            <span class="jour-date">{{jour.jourDate | errorPrettyDate}}</span>
            <ul class="error-messages">
                <li class="error-message pointerHover" *ngFor="let messageData of jour.messagesDatas" (click)="onClick(messageData)">
                    <span>{{messageData.message}}</span>
                </li>
            </ul>
            <hr/>
        </div>
    </div>
</div>
