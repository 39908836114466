import { RythmeContratEnum } from '../enums/rythme-contrat.enum';
import { SemaineModel } from '../models/semaine.model';
import * as moment from 'moment';

export class DateHelper {
  static monthNames = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
  static dayNames = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  static dayNamesShort = ['Dim', 'Lun', 'Ma', 'Me', 'Jeu', 'Ven', 'Sam'];

  static getDayName(date:Date){
    return moment(date).format('dddd'); //https://momentjs.com/docs/#/displaying/format/
  }

  static getDayNameShort(date:Date){
    return moment(date).format('ddd');
  }

  static getMonthName(date:Date){
    return moment(date).format('MMMM');
  }

  static getMonthNameShort(date:Date){
    return moment(date).format('MMM');
  }

  static toISODateString(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  static format(date: Date, format: string) {
    return moment(date).format(format);
  }

  static toShortDateString(date: Date) {
    return moment(date).format('DD/MM/YYYY');
  }

  static errorPrettyDate(date: Date): string {
    return moment(date).format('dddd D MMMM YYYY');
  }

  static areSameDayOfYear(date1: Date, date2: Date): boolean {
    return this.getWithoutHours(date1) === this.getWithoutHours(date2);
  }

  static compareDates(date1: Date, date2: Date): number {
    return moment(date1).valueOf() - moment(date2).valueOf();
  }

  static getWithoutHours(date: Date): number {
    const newDate = moment(date).startOf('day');
    return newDate.valueOf();
  }

  static getDateWithoutHours(date: moment.Moment): moment.Moment {
    return date.startOf('day');
  }

  static getDaysBetween(dateMin: moment.Moment, dateMax: moment.Moment): Date[] {
    const days: Date[] = [];
    const dateToAdd = dateMin.clone();
    dateMax = this.getDateWithoutHours(dateMax);
    while (dateToAdd <= dateMax) {
      days.push(dateToAdd.toDate());
      dateToAdd.add(1, 'day');
    }

    return days;
  }

  static addMonth(date: Date, month: number): Date {
    const newDate = moment(date).add(month, 'month');
    return newDate.toDate();
  }

  static isBetweenDates(dateMin: Date, dateMax: Date, dateToCheck: Date): boolean {
    return this.getWithoutHours(dateMin) <= this.getWithoutHours(dateToCheck)
      && this.getWithoutHours(dateMax) >= this.getWithoutHours(dateToCheck);
  }

  static initialize() {
    const fr = moment.defineLocale('fr', {
      months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
      monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
      monthsParseExact: true,
      weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
      weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
      weekdaysMin: 'di_lu_ma_me_je_ve_sa'.split('_'),
      weekdaysParseExact: true,
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm'
      },
      calendar: {
        sameDay: '[Aujourd’hui à] LT',
        nextDay: '[Demain à] LT',
        nextWeek: 'dddd [à] LT',
        lastDay: '[Hier à] LT',
        lastWeek: 'dddd [dernier à] LT',
        sameElse: 'L'
      },
      relativeTime: {
        future: 'dans %s',
        past: 'il y a %s',
        s: 'quelques secondes',
        ss: '%d secondes',
        m: 'une minute',
        mm: '%d minutes',
        h: 'une heure',
        hh: '%d heures',
        d: 'un jour',
        dd: '%d jours',
        M: 'un mois',
        MM: '%d mois',
        y: 'un an',
        yy: '%d ans'
      },
      dayOfMonthOrdinalParse: /\d{1,2}(er|)/,
      // ordinal : function (number, period) {
      ordinal(number) {
        const period =  arguments[1] as string; // HACK contournement d'un problème dans le typings de moment
        switch (period) {
          // TODO: Return 'e' when day of month > 1. Move this case inside
          // block for masculine words below.
          // See https://github.com/moment/moment/issues/3375
          case 'D':
            return number + (number === 1 ? 'er' : '');

          // Words with masculine grammatical gender: mois, trimestre, jour
          default:
          case 'M':
          case 'Q':
          case 'DDD':
          case 'd':
            return number + (number === 1 ? 'er' : 'e');

          // Words with feminine grammatical gender: semaine
          case 'w':
          case 'W':
            return number + (number === 1 ? 're' : 'e');
        }
      },
      week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4  // The week that contains Jan 4th is the first week of the year.
      }
    });
    moment.locale('fr');
  }
}

DateHelper.initialize();
