/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerte.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/directives/restaurant-type-color.directive";
import * as i4 from "./alerte.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../shared/services/application-data.service";
var styles_AlerteComponent = [i0.styles];
var RenderType_AlerteComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AlerteComponent, data: {} });
export { RenderType_AlerteComponent as RenderType_AlerteComponent };
function View_AlerteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "alerte-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerte.titre; _ck(_v, 2, 0, currVal_0); }); }
function View_AlerteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["check_box"]))], null, null); }
export function View_AlerteComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "alerte-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlerteComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "alerte-message"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "alerte-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "button", [["class", "generic-button btn-alerte"]], [[2, "btn-alerte-lue", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissAlerte() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i3.RestaurantTypeColorDirective, [i1.ElementRef, i1.Renderer2], { restaurantColor: [0, "restaurantColor"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(8, { "type": 0, "background": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlerteComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTitle; _ck(_v, 2, 0, currVal_0); var currVal_3 = (!_co.receivedAlerte.isRead && _ck(_v, 8, 0, i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.restaurantType$)), true)); _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.receivedAlerte.isRead; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.safeMessage; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.receivedAlerte.isRead; _ck(_v, 5, 0, currVal_2); var currVal_5 = (_co.receivedAlerte.isRead ? "Lu" : "Ok"); _ck(_v, 12, 0, currVal_5); }); }
export function View_AlerteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alerte", [], null, null, null, View_AlerteComponent_0, RenderType_AlerteComponent)), i1.ɵdid(1, 114688, null, 0, i4.AlerteComponent, [i5.DomSanitizer, i6.ApplicationDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlerteComponentNgFactory = i1.ɵccf("app-alerte", i4.AlerteComponent, View_AlerteComponent_Host_0, { alerte: "alerte", showTitle: "showTitle", receivedAlerte: "receivedAlerte" }, { alerteDismissed: "alerteDismissed" }, []);
export { AlerteComponentNgFactory as AlerteComponentNgFactory };
